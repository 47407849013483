import React from "react";
import styles from "./TeachingSubjectSelect.module.scss";
import { FormField } from "../FormField/FormField";
import Select, { MultiValue } from "react-select";
import { useFormikContext } from "formik";
import { UpdateUserSchoolSchema } from "../UserProfile/UserProfile";
import { SubjectType, getSubjectTypes } from "../AddSchool/SubjectTypes";

interface TeachingSubjectSelectProps {
  id: string;
  label: string;
  value?: SubjectType[] | string[] | undefined;
  errorMessage?: string;
  onBlur?: () => void;
  disable?: boolean;
  adminUsed?: boolean;
}

export const TeachingSubjectSelect: React.FC<TeachingSubjectSelectProps> = ({
  id,
  label,
  value,
  errorMessage,
  onBlur,
  disable,
  adminUsed = false,
}) => {
  console.log("adminUsed", adminUsed);
  const formik = useFormikContext<UpdateUserSchoolSchema>();
  const options = getSubjectTypes(adminUsed ? "en" : "fr");

  const onChange = (subjects: MultiValue<SubjectType | string>) => {
    // setSelectedOptions(subjects);
    formik.setFieldValue("subjects", subjects);
  };

  const onBlurHandle = () => {
    onBlur && onBlur();
    formik.setFieldTouched("subjects", true, true);
  };

  return (
    <>
      <div className={styles.teachingSubjectSelect}>
        <FormField
          id={id}
          label={label}
          // errorMessage={errorMessage ? errorMessage : formikError}
        >
          <Select
            defaultValue={value as SubjectType[]}
            isMulti
            name="subjects"
            options={options}
            className={styles.teachingSubjectSelect__reactSelect__custom}
            classNamePrefix={"teachingSubjectSelect__reactSelect__custom"}
            placeholder=""
            onChange={onChange}
            onBlur={onBlurHandle}
            isDisabled={disable}
          />
        </FormField>
      </div>
    </>
  );
};
