import React, { TextareaHTMLAttributes } from "react";
import { FormField } from "../FormField/FormField";

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  label: string;
  errorMessage?: string;
  whiteBackground?: boolean;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      id,
      label,
      value,
      errorMessage,
      whiteBackground,
      ...inputProps
    }: TextAreaProps,
    externalRef
  ) => {
    return (
      <FormField id={id} label={label} errorMessage={errorMessage}>
        <textarea
          id={id}
          defaultValue={value}
          ref={externalRef}
          style={
            whiteBackground
              ? {
                  backgroundColor: "white",
                  border: "1px solid #eeeeee",
                  padding: "0.3rem 0.5rem 0.5rem 0.5rem",
                  margin: 0,
                  width: "100%",
                  height: "100%",
                  resize: "none",
                  outline: "none",
                }
              : undefined
          }
          {...inputProps}
        >
          {value}
        </textarea>
      </FormField>
    );
  }
);
