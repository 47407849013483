import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./VerificationRequestDetailModal.module.scss";
import { Modal } from "../../Modal/Modal";
import { Grid, GridItem } from "../../Grid/Grid";
import { TextArea } from "../../TextArea/TextArea";
import { Button } from "../../Button/Button";
import {
  SearchTeacherVerificationCondition,
  adminApproveTeacherVerificationRequests,
  adminRejectTeacherVerificationRequests,
} from "../../../api/teacher-verification";
import { Spinner } from "../../Spinner/Spinner";
import { i18n } from "@lingui/core";
import { Country } from "../../../types/Country";
import { IdProvider } from "../../../types/IdProvider";
import { SortOptions, TeacherVerificationStatus } from "../../../types/User";
import { ConfirmationModal } from "../../Admin/ConfirmationModal/ConfirmationModal";
import { ProcessFailedModal } from "../../Admin/ProcessFailedModal/ProcessFailedModal";
import { AnnouncementModal } from "../../Admin/AnnouncementModal/AnnouncementModal";
import { FormikProps, FormikValues } from "formik";

const VerificationRequestDetailModal = ({
  selectedRequest,
  isShowing,
  onConfirm,
  onCancel,
  fetchTeacherVerifications,
  teacherVerificationFormikRef,
}: {
  selectedRequest?: {
    id: string;
    requestId: string;
    email: string | undefined;
    status: TeacherVerificationStatus;
    submittedDate: string;
    nickname: string | undefined;
    firstname: string | undefined;
    lastname: string | undefined;
    country: Country;
    idProvider: IdProvider;
    school: string;
    note?: string;
    role: string;
    schoolCode: string;
  };
  isShowing: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  fetchTeacherVerifications: (
    sortOptions: SortOptions,
    searchCondition: SearchTeacherVerificationCondition
  ) => Promise<void>;
  teacherVerificationFormikRef: RefObject<FormikProps<FormikValues>>;
}) => {
  const [rejectNotes, setRejectNotes] = useState<string>("");
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const [isShowApproveConfirm, setIsShowApproveConfirm] =
    useState<boolean>(false);
  const [isShowRejectConfirm, setIsShowRejectConfirm] =
    useState<boolean>(false);

  const [isShowErrorModal, setIsShowErrorModal] = useState<boolean>(false);
  const [errorRejectNoteConfirm, setErrorRejectNoteConfirm] =
    useState<string>();
  const [isShowApproveSuccessModal, setIsShowApproveSuccessModal] =
    useState<boolean>(false);

  const [isShowRejectSuccessModal, setIsShowRejectSuccessModal] =
    useState<boolean>(false);

  const ApproveButton = useCallback((): JSX.Element => {
    return (
      <Button
        label="Approve"
        onClick={() => {
          setIsShowApproveConfirm(true);
        }}
      ></Button>
    );
  }, []);

  const CloseButton = useCallback((): JSX.Element => {
    return (
      <>
        <p></p>
        <Button
          btnStyle="secondary"
          label="Close"
          onClick={() => {
            onCancel();
          }}
        ></Button>
      </>
    );
  }, [onCancel]);

  const renderButton: JSX.Element | undefined = useMemo(() => {
    if (selectedRequest?.status === TeacherVerificationStatus.PENDING) {
      return (
        <>
          <Button
            btnStyle="danger"
            label="Reject"
            onClick={() => {
              setIsShowRejectConfirm(true);
            }}
          ></Button>

          <ApproveButton />
        </>
      );
    }

    if (selectedRequest?.status === TeacherVerificationStatus.REJECTED) {
      return (
        <>
          <ApproveButton />
          <CloseButton />
        </>
      );
    }

    if (selectedRequest?.status === TeacherVerificationStatus.APPROVED) {
      return <CloseButton />;
    }
  }, [ApproveButton, CloseButton, selectedRequest?.status]);

  const submittedDate = useMemo(() => {
    return selectedRequest?.submittedDate.slice(
      11,
      selectedRequest.submittedDate.length
    );
  }, [selectedRequest?.submittedDate]);

  useEffect(() => {
    if (isShowRejectConfirm && errorRejectNoteConfirm) {
      setErrorRejectNoteConfirm(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowRejectConfirm]);

  return (
    <Modal
      title="Verification Request"
      isShowing={isShowing}
      closeButton={true}
      size="large"
      centerTitle={true}
      onClose={onCancel}
    >
      <div className={styles.container}>
        <Grid>
          <GridItem width="1">
            <p className={styles.container__text}>
              User ID: {selectedRequest?.id}
            </p>
          </GridItem>

          <GridItem width="1">
            <p className={styles.container__text}>
              Request ID: {selectedRequest?.requestId}
            </p>
          </GridItem>

          <GridItem width="1">
            <p className={styles.container__text}>
              Status: {selectedRequest?.status}
            </p>
          </GridItem>
        </Grid>

        <Grid>
          <GridItem width="1/2">
            <p className={styles.container__text}>
              Email: {selectedRequest?.email}
            </p>
          </GridItem>

          <GridItem width="1/2">
            <p className={styles.container__text}>
              Nickname: {selectedRequest?.nickname}
            </p>
          </GridItem>
        </Grid>

        <Grid>
          <GridItem width="1/2">
            <p className={styles.container__text}>
              First name: {selectedRequest?.firstname}
            </p>
          </GridItem>

          <GridItem width="1/2">
            <p className={styles.container__text}>
              Last name: {selectedRequest?.lastname}
            </p>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem width="1/2">
            <p className={styles.container__text}>
              Country: {selectedRequest?.country}
            </p>
          </GridItem>

          <GridItem width="1/2">
            <p className={styles.container__text}>
              Id Provider: {selectedRequest?.idProvider}
            </p>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem width="1/2">
            <p className={styles.container__text}>
              Submitted date: {submittedDate}
            </p>
          </GridItem>

          <GridItem width="1/2">
            <p className={styles.container__text}>
              Role: {selectedRequest?.role}
            </p>
          </GridItem>
        </Grid>

        <Grid>
          <GridItem width="1/2">
            <p className={styles.container__text}>
              School: {selectedRequest?.school}
            </p>
          </GridItem>
          <GridItem width="1/2">
            <p className={styles.container__text}>
              School Code: {selectedRequest?.schoolCode}
            </p>
          </GridItem>
        </Grid>

        <div className={styles.container__note}>
          <p>Note</p>

          <TextArea
            className={styles.container__note__textArea}
            id="note"
            label=""
            whiteBackground={true}
            disabled={true}
            defaultValue={selectedRequest?.note}
          />
        </div>

        <div className={styles.container__uploadedFile}>
          <p>Uploaded Files</p>
        </div>
        <div className={styles.container__groupBtn}>{renderButton}</div>
      </div>

      <ConfirmationModal
        title="Confirm Approve Request"
        isShowing={isShowApproveConfirm}
        onConfirm={async () => {
          try {
            setIsShowSpinner(true);
            await adminApproveTeacherVerificationRequests([
              selectedRequest?.id || "",
            ]);

            await fetchTeacherVerifications(
              {
                page: 1,
                limit: 10,
                sortBy: "submittedDate",
                sortOrder: "desc",
              },
              {}
            );
            teacherVerificationFormikRef.current?.resetForm();

            setIsShowApproveSuccessModal(true);
          } catch (error) {
            setIsShowSpinner(false);
            setIsShowErrorModal(true);
          } finally {
            setIsShowSpinner(false);
            setIsShowApproveConfirm(false);
          }
        }}
        onCancel={() => {
          setIsShowApproveConfirm(false);
        }}
      >
        <p style={{ fontSize: 15 }}>
          Are you sure you would like to approve the teacher verification
          request(s)?
        </p>
      </ConfirmationModal>

      <ConfirmationModal
        title="Confirm Reject"
        isShowing={isShowRejectConfirm}
        onConfirm={async () => {
          try {
            setIsShowSpinner(true);
            await adminRejectTeacherVerificationRequests(
              selectedRequest?.id || "",
              rejectNotes
            );

            await fetchTeacherVerifications(
              {
                page: 1,
                limit: 10,
                sortBy: "submittedDate",
                sortOrder: "desc",
              },
              {}
            );
            teacherVerificationFormikRef.current?.resetForm();

            setIsShowRejectSuccessModal(true);
          } catch (error) {
            setIsShowSpinner(false);
            setIsShowErrorModal(true);
          } finally {
            setIsShowSpinner(false);
            setIsShowRejectConfirm(false);
          }
        }}
        onCancel={() => {
          setIsShowRejectConfirm(false);
        }}
        isRejectModal={true}
        disableConfirmBtn={rejectNotes === null || rejectNotes?.length === 0}
      >
        <p style={{ fontSize: 15, marginBottom: 10 }}>
          Are you sure you would like to reject the teacher verification
          request?
        </p>

        <p style={{ fontSize: 16, marginBottom: 10 }}>Note*</p>
        <TextArea
          className={styles.container__note__textArea}
          id="note"
          label=""
          whiteBackground={true}
          onChange={(event) => {
            setRejectNotes(event.target.value);
            if (event.target.value.length === 0) {
              setErrorRejectNoteConfirm("Champ requis!");
            } else {
              setErrorRejectNoteConfirm(undefined);
            }
          }}
          errorMessage={errorRejectNoteConfirm}
        />
      </ConfirmationModal>

      <AnnouncementModal
        title="Approve Request Successfully"
        isShowing={isShowApproveSuccessModal}
        setVisible={(isVisible) => {
          setIsShowApproveSuccessModal(isVisible);
          onCancel();
        }}
      >
        <div className={styles.announcementText}>
          Teacher Verification Request(s) has been approved!
        </div>
      </AnnouncementModal>

      <AnnouncementModal
        title="Reject Request Successfully"
        isShowing={isShowRejectSuccessModal}
        setVisible={(isVisible) => {
          setIsShowRejectSuccessModal(isVisible);
          onCancel();
        }}
      >
        <div className={styles.announcementText}>
          Teacher Verification Request has been rejected!
        </div>
      </AnnouncementModal>

      <ProcessFailedModal
        isShowing={isShowErrorModal}
        setVisible={() => {
          setIsShowErrorModal(false);
        }}
      />
      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </Modal>
  );
};

export default VerificationRequestDetailModal;
