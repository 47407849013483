import React, { ChangeEvent } from "react";
import styles from "./RegisterForm.module.scss";
import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import { BlockContent } from "../BlockContent/BlockContent";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Language } from "../../types/Country";

interface TermsAndConditionsStepProps {
  handleTermsAcceptedChange: (checked: boolean) => void;
}

export const TermsAndConditionsStep: React.FC<TermsAndConditionsStepProps> = ({
  handleTermsAcceptedChange,
}) => {
  function handleChange(event: ChangeEvent): void {
    const checkbox = event.target as HTMLInputElement;
    handleTermsAcceptedChange(checkbox.checked);
  }
  const locale = detectLocale();
  const french = locale === Language.fr;
  return (
    <div className={styles.registerForm__termsAndConditions}>
      <BlockContent className={styles.registerForm__privacyPolicy}>
        <Trans
          id="register.form.privacy_policy"
          values={{
            data_protection_link: (
              <a
                target="_blank"
                href={
                  french
                    ? "/fr/politique-de-protection-des-donnees-casio-education"
                    : "/datenschutz"
                }
                rel="noreferrer"
              >
                {i18n._({
                  id: "register.form.terms_and_conditions.data_protection",
                })}
              </a>
            ),
          }}
        />
      </BlockContent>
      <LabeledCheckbox
        label={
          <BlockContent>
            <Trans
              id="register.form.terms_and_conditions"
              values={{
                conditions_link: (
                  <a
                    target="_blank"
                    href={
                      french
                        ? "/fr/conditions-de-service"
                        : "/nutzungsbedingungen"
                    }
                    rel="noreferrer"
                  >
                    {i18n._({
                      id: "register.form.terms_and_conditions.conditions",
                    })}
                  </a>
                ),
              }}
            />
          </BlockContent>
        }
        value={"terms-and-conditions"}
        name={"terms-and-conditions"}
        onChange={handleChange}
      />
    </div>
  );
};
