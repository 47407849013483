import { i18n } from "@lingui/core";
import { Key } from "react-feather";
import { classList } from "../../lib/helpers";
import { Color } from "../../types/Color";
import { License, LicenseTrial } from "../../types/License";
import { validUntilMsg } from "./utils-learning";
import styles from "./LicenseEntry.module.scss";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";
import { Product } from "../../types/Product";
import { useAuth } from "../../hooks/use-auth";

export interface LicenseEntryViewProps {
  productName: string;
  license?: License;
  licenseTrial?: LicenseTrial;
  individualLicense?: {
    product: Product;
    validUntil: Date | null;
  };
  isIndividualTrialExpired?: boolean;
  color?: Color | "bluegrey" | "mintgreen";
  children?: React.ReactNode;
  isTeacher?: boolean;
  schoolLicense?: SchoolLicenseWithAddtitionalLicense;
  isInMathMarathonDate?: boolean;
  inTrialChecker?: {
    isInTrialClass: boolean;
    cplTestPeriodEndDate: string;
  };
  isClasspadLearningValid?: boolean;
  isAccountExpired?: boolean | null;
}

export const LicenseEntryViewLearning = ({
  productName,
  license,
  licenseTrial,
  individualLicense,
  isIndividualTrialExpired,
  color,
  children,
  isTeacher,
  schoolLicense,
  isInMathMarathonDate,
  inTrialChecker,
  isClasspadLearningValid,
  isAccountExpired,
}: LicenseEntryViewProps) => {
  const auth = useAuth();
  const licenseAccessDate = auth.user?.licenseDate;
  const validUntil = validUntilMsg({
    license,
    licenseTrial,
    individualLicense,
    isIndividualTrialExpired,
    schoolLicense,
    isTeacher,
    isInMathMarathonDate,
    inTrialChecker,
    isClasspadLearningValid,
    isAccountExpired,
    licenseAccessDate,
  });

  const classes = classList(
    styles.licenseEntryView,
    styles.licenseEntryView_valid,
    color && styles["licenseEntryView_" + color.toLowerCase()]
  );

  const validUntilDate = () => {
    if (!isTeacher) {
      if (license && isClasspadLearningValid) {
        return <strong>{i18n.date(license.validUntil)}</strong>;
      }

      if (schoolLicense) {
        return <strong>{i18n.date(schoolLicense.validUntil)}</strong>;
      }

      if (!license && !schoolLicense && isInMathMarathonDate) {
        return <strong>{`31.5.${new Date().getFullYear()}`}</strong>;
      }

      if (
        license &&
        !isClasspadLearningValid &&
        !schoolLicense &&
        isInMathMarathonDate
      ) {
        return <strong>{`31.5.${new Date().getFullYear()}`}</strong>;
      }

      if (
        !license &&
        !schoolLicense &&
        !isInMathMarathonDate &&
        inTrialChecker &&
        inTrialChecker.isInTrialClass &&
        licenseTrial
      ) {
        const validUntil = new Date(licenseTrial.validUntil);
        return (
          <strong>{`${validUntil.getDate()}.${
            validUntil.getMonth() + 1
          }.${validUntil.getFullYear()}`}</strong>
        );
      }

      if (
        license &&
        !isClasspadLearningValid &&
        !schoolLicense &&
        !isInMathMarathonDate &&
        inTrialChecker &&
        inTrialChecker.isInTrialClass &&
        licenseTrial
      ) {
        const validUntil = new Date(licenseTrial.validUntil);
        return (
          <strong>{`${validUntil.getDate()}.${
            validUntil.getMonth() + 1
          }.${validUntil.getFullYear()}`}</strong>
        );
      }

      if (
        license &&
        !isClasspadLearningValid &&
        !isInMathMarathonDate &&
        isAccountExpired &&
        inTrialChecker?.isInTrialClass &&
        licenseTrial
      ) {
        const validUntil = new Date(licenseTrial.validUntil);
        return (
          <strong>{`${validUntil.getDate()}.${
            validUntil.getMonth() + 1
          }.${validUntil.getFullYear()}`}</strong>
        );
      }

      if (
        license &&
        !isClasspadLearningValid &&
        !schoolLicense &&
        !isInMathMarathonDate &&
        !isAccountExpired &&
        inTrialChecker?.isInTrialClass &&
        licenseTrial
      ) {
        const validUntil = new Date(licenseTrial.validUntil);
        return (
          <strong>{`${validUntil.getDate()}.${
            validUntil.getMonth() + 1
          }.${validUntil.getFullYear()}`}</strong>
        );
      }

      if (
        !license &&
        !schoolLicense &&
        !isInMathMarathonDate &&
        inTrialChecker &&
        !inTrialChecker.isInTrialClass &&
        !isIndividualTrialExpired &&
        individualLicense?.validUntil
      ) {
        const validUntil = new Date(individualLicense.validUntil);

        return (
          <strong>{`${validUntil.getDate()}.${
            validUntil.getMonth() + 1
          }.${validUntil.getFullYear()}`}</strong>
        );
      }

      if (
        license &&
        !isClasspadLearningValid &&
        !schoolLicense &&
        !isInMathMarathonDate &&
        inTrialChecker &&
        !inTrialChecker.isInTrialClass &&
        !isIndividualTrialExpired &&
        individualLicense?.validUntil
      ) {
        const validUntil = new Date(individualLicense?.validUntil);
        return (
          <strong>{`${validUntil.getDate()}.${
            validUntil.getMonth() + 1
          }.${validUntil.getFullYear()}`}</strong>
        );
      }
    }
    return;
  };

  return (
    <div className={classes}>
      <div className={styles.licenseEntryView__container}>
        <span
          className={classList(
            styles.licenseEntryView__name,
            color && styles["licenseEntryView__name_" + color.toLowerCase()]
          )}
        >
          <i>{<Key />}</i>
          <span>{productName}</span>
        </span>
        <span
          className={classList(
            styles.licenseEntryView__status,
            color && styles["licenseEntryView__status_" + color.toLowerCase()]
          )}
        >
          <span>
            {validUntil} {validUntilDate()}
          </span>
        </span>
      </div>
      {children && (
        <div className={styles.licenseEntryView__children}>{children}</div>
      )}
    </div>
  );
};
