import { useState } from "react";
import "./ClassCard.scss";
import { Check } from "react-feather";

export const ClassCard = ({
  label,
  value,
  color,
  checkedList,
  setCheckedList,
}: {
  label: string;
  value: string;
  color: string;
  checkedList: string[];
  setCheckedList: (checkedList: string[]) => void;
}) => {
  const [checked, setChecked] = useState(false);

  const isChecked = checked || checkedList.includes(value);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
    setCheckedList(
      checkedList.includes(value)
        ? checkedList.filter((checked) => checked !== value)
        : [...checkedList, value]
    );
  };

  return (
    <>
      <div
        className={`checkbox-card ${isChecked ? "checked" : ""}`}
        onClick={handleCheckboxChange}
      >
        <span
          role="img"
          className={`colorIndicator colorIndicator_${color.toLowerCase()}`}
        ></span>
        <input
          id={value}
          className="checkbox-input"
          type="checkbox"
          checked={isChecked}
          value={value}
          onChange={handleCheckboxChange}
        />
        <label className="checkbox-card-label">
          {isChecked && <Check />} {label}
        </label>
      </div>
    </>
  );
};
