import { useEffect } from "react";
import { ContentLayout } from "../ContentLayout/ContentLayout";

export const Logout = () => {
  useEffect(() => {
    sessionStorage.setItem("logoutRequest", String(true));
    window.location.replace("/app");
  }, []);

  return (
    <ContentLayout>
      <div></div>
    </ContentLayout>
  );
};
