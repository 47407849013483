import { useState } from "react";
import { Button } from "../../../Button/Button";
import { StepBody } from "../../../Stepper/Stepper";
import { SelectedSchoolStep1 } from "../MergeSchoolsStep1/MergeSchoolsStep1";
import { SelectedSchoolStep2 } from "../MergeSchoolsStep2/MergeSchoolsStep2";
import { ComparisonBox } from "./ComparisonBox";
import styles from "./MergeSchoolsStep3.module.scss";
import { ArrowRightCircle } from "react-feather";
import {
  MergedSchoolResponse,
  SchoolType,
  mergeSchools,
} from "../../../../api/school";
import { Spinner } from "../../../Spinner/Spinner";
import { useToasts } from "../../../../provider/toast-provider";
import { Country } from "../../../../types/Country";
import { StatusLinkWithSalesForce } from "../../../../types/Salesforce";
import { ErrorModal } from "../../ErrorModal/ErrorModal";

export interface MergeSchoolsStep3Props {
  handleNext?(): void;
  handleBack?(): void;
  getMergedSchoolResult?(mergeSchoolsResponse?: MergedSchoolResponse): void;
  mergingSchoolRecord?: SelectedSchoolStep1;
  cpaSchoolRecord?: SelectedSchoolStep2;
}

export const MergeSchoolsStep3 = ({
  handleNext,
  handleBack,
  getMergedSchoolResult,
  mergingSchoolRecord,
  cpaSchoolRecord,
}: MergeSchoolsStep3Props) => {
  const [isShowInvalidModal, setIsShowInvalidModal] = useState<boolean>(false);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const { addToast } = useToasts();
  const formatResponse = (
    response?: MergedSchoolResponse | SelectedSchoolStep2
  ) => {
    return {
      id: response?.id ?? "",
      name: response?.name ?? "",
      status: response?.status ?? ("" as StatusLinkWithSalesForce),
      country: response?.country ?? ("" as Country),
      address: response?.address ?? "",
      addressAddition: response?.addressAddition ?? "",
      city: response?.city ?? "",
      state: response?.state ?? "",
      zip: response?.zip ?? "",
      schoolId: response?.schoolId ?? "",
      salesforceId: response?.salesforceId ?? "",
      eduplacesSchoolId: response?.eduplacesSchoolId ?? "",
      salesforceRecordId: response?.salesforceRecordId ?? "",
      type: response?.type ?? "",
      addressCountry: response?.addressCountry ?? "",
      lmsInstance: response?.lmsInstance ?? "",
    };
  };

  return (
    <StepBody
      noBackgroundColor={true}
      nextButton={
        <Button
          label={"Confirm"}
          onClick={() => {
            // 1. Check if both Merging school record and CPA (SF) School record has Eduplaces School ID not NULL:

            if (
              mergingSchoolRecord?.eduplacesSchoolId &&
              cpaSchoolRecord?.eduplacesSchoolId
            ) {
              setIsShowInvalidModal(true);
              return;
            }
            let schoolType = mergingSchoolRecord?.eduplacesSchoolId
              ? SchoolType.FROM_EDUPLACES
              : SchoolType.MANUALLY_CREATED;

            // Call API Merge Schools.
            setIsShowSpinner(true);
            mergeSchools({
              mergingSchoolId: mergingSchoolRecord?.id ?? "",
              mergingSchoolType: schoolType,
              cpaSchoolId: cpaSchoolRecord?.id ?? "",
            })
              .then((response: MergedSchoolResponse) => {
                // -------- sau khi call xong API Merge Schools.
                if (schoolType === SchoolType.MANUALLY_CREATED) {
                  getMergedSchoolResult &&
                    getMergedSchoolResult(formatResponse(cpaSchoolRecord));
                }

                if (schoolType === SchoolType.FROM_EDUPLACES) {
                  getMergedSchoolResult &&
                    getMergedSchoolResult(formatResponse(response));
                }
                setIsShowSpinner(false);

                handleNext && handleNext();
              })
              .catch((err) => {
                setIsShowSpinner(false);
                // Show toast message
                addToast(err.message, "error");
              })
              .finally(() => {
                setIsShowSpinner(false);
              });
          }}
        />
      }
      previousButton={
        <Button label={"Back"} onClick={handleBack} btnStyle="secondary" />
      }
    >
      <div className={styles.container}>
        <div className={styles.container__comparisonBoxOne}>
          <ComparisonBox
            title="Merging School Record"
            data={mergingSchoolRecord}
          />
        </div>

        <div className={styles.container__arrow}>
          <ArrowRightCircle color="#092981" />
        </div>

        <div className={styles.container__comparisonBoxTwo}>
          <ComparisonBox
            title="CPA School Record"
            data={cpaSchoolRecord}
            disabled={true}
          />
        </div>
      </div>

      <ErrorModal
        isShowing={isShowInvalidModal}
        setVisible={(value) => {
          setIsShowInvalidModal(value);
        }}
        title="Error"
        text="The CPA (SF) School record has already been linked with another
            Eduplaces School ID."
      />

      <Spinner
        type="fullContainer"
        title="Please wait..."
        visible={isShowSpinner}
      />
    </StepBody>
  );
};
