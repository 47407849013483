import { i18n } from "@lingui/core";
import React, { useMemo } from "react";
import {
  ArrowRight,
  Award,
  Book,
  BookOpen,
  Box,
  Folder,
  Gift,
  Info,
  Key,
  Link as LinkIcon,
  User,
} from "react-feather";
import { Link } from "react-router-dom";
import { ReactComponent as IservLogo } from "../../assets/images/svg/iserv-logo.svg";
import { ReactComponent as MilLogo } from "../../assets/images/svg/mathe-im-leben-logo.svg";
import { ReactComponent as MatheMarathonLogo } from "../../assets/images/svg/mm-icon-2023-original.svg";
import { useAppConfig } from "../../hooks/use-app-config";
import { AuthContext, useAuth } from "../../hooks/use-auth";
import { classList as cl } from "../../lib/helpers";
import { Country } from "../../types/Country";
import { FrenchInstructorRole, Role } from "../../types/Role";
import { Badge } from "../Badge/Badge";
import { Card } from "../Card/Card";
import { Grid, GridItem } from "../Grid/Grid";
import { PageHeader } from "../PageHeader/PageHeader";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import styles from "./NavigationPage.module.scss";
import { HashLink } from "react-router-hash-link";
import { IdProvider } from "../../types/IdProvider";
// import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { captureLicenseUsage } from "../../api/user";
import { Product } from "../../types/Product";
import { Trans } from "@lingui/react";
import { NewsBoxIFrame } from "../NewsBoxIFrame/NewsBoxIFrame";
import { TeacherVerificationMetadataStatus } from "../../types/User";

export interface NavigationPageProps {
  remainingLearnerTestDays?: number;
  learnerTestPeriodActive?: boolean;
}

export const NavigationPage = ({
  remainingLearnerTestDays,
  learnerTestPeriodActive,
}: NavigationPageProps) => {
  const {
    classpadNetUrl,
    classpadLearningUrl,
    mesExcercisesLyceeLaunchUrl,
    dtcLaunchUrl,
  } = useAppConfig();

  const auth = useAuth();

  const isDisableDTCCard = useMemo(() => {
    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.APPROVED
    ) {
      return false;
    } else {
      return true;
    }
  }, [auth]);

  const role = auth.userInfo?.role;

  const redirectToClassPadDotNet = async () => {
    /** Capture license usage here */
    window.open(classpadNetUrl, "_blank", "noreferrer");
    // setIsShowConfirmRedirect(false);

    await captureLicenseUsage(auth.user?.id, {
      userId: auth.user?.id,
      externalId: auth.user?.externalUserId,
      product: Product.CLASSPAD_NET_PLUS,
      accessDate: new Date(),
    });
  };

  const renderContent = (auth: AuthContext, country?: Country) => {
    const DTCNavigationBox = () => {
      return (
        <NavigationBox
          title={"Commandes groupées CASIO"}
          description={
            <>
              <p style={{ fontWeight: "bold", paddingBottom: 15 }}>
                Organisez une cagnotte ou un achat groupé sur notre plateforme
                CASIO pour faciliter l'accès à l'équipement des élèves de votre
                établissement.
              </p>

              <p style={{ fontSize: 15, paddingBottom: 15 }}>
                Votre validation d'enseignant a été approuvée. Cliquez ici pour
                procéder aux commandes groupées.
              </p>

              {isDisableDTCCard && (
                <p style={{ fontSize: 12, paddingBottom: 15 }}>
                  Seuls les enseignants validés ont accès à notre plateforme de
                  commandes groupées. Veuillez vous rendre sur votre page de
                  profil pour activer cette fonctionnalité.
                </p>
              )}
            </>
          }
          icon={<BookOpen />}
          disabled={isDisableDTCCard}
        />
      );
    };

    if (country && country === Country.DE) {
      return (
        <Grid>
          <GridItem width="2/3">
            <Grid>
              {window.featureToggles?.FT_MATHE_MARATHON_VISIBLE &&
                auth.user?.country === Country.DE && (
                  <GridItem
                    width="1/1"
                    className={styles.navigationBoxGridItem}
                  >
                    <Link to="/mathe-marathon">
                      <NavigationBox
                        title={i18n._({
                          id: `navigation-page.card.title.mathe-marathon.${role?.toLowerCase()}`,
                        })}
                        description={
                          <div className={styles.matheMarathonContent}>
                            <div
                              className={styles.matheMarathonContent__body}
                              dangerouslySetInnerHTML={{
                                __html: i18n._({
                                  id: `navigation-page.card.description.mathe-marathon.${role?.toLowerCase()}`,
                                }),
                              }}
                            ></div>
                            <div
                              className={styles.matheMarathonContent__footer}
                            >
                              <span>
                                <i>
                                  <ArrowRight />
                                </i>
                                <span>
                                  {i18n._({
                                    id: `navigation-page.card.button-description.mathe-marathon.${role?.toLowerCase()}`,
                                  })}
                                </span>
                              </span>
                              <div
                                className={
                                  styles.matheMarathonContent__footer__logo
                                }
                              >
                                <span>
                                  {i18n._({
                                    id: `navigation-page.card.logo-info.mathe-marathon`,
                                  })}
                                </span>
                                <MilLogo />
                              </div>
                            </div>
                          </div>
                        }
                        variant="matheMarathon"
                      />
                    </Link>
                  </GridItem>
                )}
              <RestrictedTo roles={[Role.INSTRUCTOR]}>
                <GridItem
                  width={"1/1"}
                  className={styles.navigationBoxGridItem}
                  hidden={auth.user?.country === Country.FR}
                >
                  <HashLink to="/profile#tis">
                    <NavigationBox
                      title={i18n._({
                        id: `teacher_info_service`,
                      })}
                      description={i18n._({
                        id: `navigation-page.card.description.teacher-info-service`,
                      })}
                      icon={<Info />}
                    />
                  </HashLink>
                </GridItem>
              </RestrictedTo>
              <GridItem
                width={"1/1"}
                className={styles.navigationBoxGridItem}
                hidden={auth.user?.country === Country.FR}
              >
                {/* <HashLink to="/profile#tis"> */}
                <Link to="/contexts">
                  <NavigationBox
                    title={i18n._({
                      id: `navigation-page.card.title.contexts`,
                    })}
                    description="Hier kannst du virtuelle Klassenräume beitreten oder sie als Lehrkraft erstellen."
                    icon={<Folder />}
                  />
                </Link>
              </GridItem>

              {/* Classpad Learning */}
              <GridItem width="1/2" className={styles.navigationBoxGridItem}>
                <a
                  href={classpadLearningUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={async () => {
                    /** Capture license usage here */
                    await captureLicenseUsage(auth.user?.id, {
                      userId: auth.user?.id,
                      externalId: auth.user?.externalUserId,
                      product: Product.CLASSPAD_LEARNING,
                      accessDate: new Date(),
                    });
                  }}
                >
                  <NavigationBox
                    title={i18n._({
                      id: `navigation-page.card.title.kikora`,
                    })}
                    description={i18n._({
                      id: `navigation-page.card.description.kikora`, // ------------------------------------------------------------------------------------
                    })}
                    icon={<BookOpen />}
                    variant={
                      learnerTestPeriodActive ? "cplTestPeriod" : "default"
                    }
                    remainingLearnerTestDays={remainingLearnerTestDays}
                  />
                </a>
              </GridItem>

              {/* Classpad.net */}
              {auth.user?.idProvider !== IdProvider.GAR && (
                <GridItem width="1/2" className={styles.navigationBoxGridItem}>
                  <div onClick={redirectToClassPadDotNet}>
                    <NavigationBox
                      title={i18n._({
                        id: `navigation-page.card.title.classpad_net`,
                      })}
                      description={
                        <>
                          <p>
                            {i18n._({
                              id: `navigation-page.card.description.classpad_net`,
                            })}
                          </p>
                          <p style={{ fontSize: 15, paddingBottom: 20 }}>
                            {i18n._({
                              id: `sidebar.links.redirect.description.classpad_net`,
                            })}
                          </p>
                        </>
                      }
                      icon={<Box />}
                    />
                  </div>
                </GridItem>
              )}

              {/* My License */}
              <RestrictedTo roles={[Role.LEARNER, Role.INSTRUCTOR]}>
                <GridItem
                  width="1/2"
                  hidden={
                    auth.user?.country === Country.FR &&
                    !window.featureToggles?.FT_ENABLED_FOR_FRANCE
                  }
                  className={styles.navigationBoxGridItem}
                >
                  <Link to="/licenses">
                    <NavigationBox
                      title={i18n._({
                        id: `navigation-page.card.title.licenses`,
                      })}
                      description={i18n._({
                        id: `navigation-page.card.description.licenses`,
                      })}
                      icon={<Key />}
                    />
                  </Link>
                </GridItem>
              </RestrictedTo>

              <GridItem width="1/2" className={styles.navigationBoxGridItem}>
                <Link to="/profile">
                  <NavigationBox
                    title={i18n._({
                      id: `navigation-page.card.title.profile`,
                    })}
                    description={i18n._({
                      id: `navigation-page.card.description.profile`,
                    })}
                    icon={<User />}
                  />
                </Link>
              </GridItem>

              {/* Mes Excercise Lycee */}
              {(auth.user?.idProvider === IdProvider.GAR ||
                (auth.user?.idProvider === IdProvider.CLASSPAD &&
                  auth.user.metadata?.role !==
                    FrenchInstructorRole.SCHOOL_ADMIN &&
                  auth.user.metadata?.role !==
                    FrenchInstructorRole.SCHOOL_ASSOCIATION &&
                  auth.user.metadata?.role !== FrenchInstructorRole.OTHER &&
                  auth.user?.country === Country.FR)) && (
                <GridItem width="1" className={styles.navigationBoxGridItem}>
                  <a
                    href={mesExcercisesLyceeLaunchUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={async () => {
                      await captureLicenseUsage(auth.user?.id, {
                        userId: auth.user?.id,
                        externalId: auth.user?.externalUserId,
                        product: Product.MES_EXERCISES_LYCEE,
                        accessDate: new Date(),
                      });
                    }}
                  >
                    <NavigationBox
                      title="Mes Exercices Lycée"
                      description={
                        <>
                          <p>
                            Entraînez-vous sur des exercices lycées avec les
                            calculatrices CASIO
                          </p>
                          <br />
                          <p>
                            Vous serez redirigé vers un site Web exploité par
                            Casio Computer Co. Ltd
                          </p>
                        </>
                      }
                      icon={<Book />}
                    />
                  </a>
                </GridItem>
              )}
            </Grid>
          </GridItem>
          <GridItem width="1/3">
            <div className={styles.navigationPageAside}>
              <div className={styles.navigationPageAside__header}>
                <h3>
                  <Trans id="navigation-page.newsPostBox.headline" />
                </h3>
              </div>
              <NewsBoxIFrame />
            </div>
          </GridItem>
        </Grid>
      );
    }

    if (country && country === Country.FR) {
      return (
        <Grid>
          <GridItem width="2/3">
            <Grid>
              {/* Classpad.net */}
              {auth.user?.idProvider !== IdProvider.GAR && (
                <GridItem width="1" className={styles.navigationBoxGridItem}>
                  <div onClick={redirectToClassPadDotNet}>
                    <NavigationBox
                      title={"Émulateurs sur ClassPad.net"}
                      description={
                        <>
                          <p style={{ fontWeight: "bold" }}>
                            Accédez aux émulateurs des calculatrices fx-92
                            Collège, Graph Light et Graph Math+.
                          </p>
                          <br />
                          <p>
                            Créez vos contenus mathématiques avec tous les
                            outils CASIO.
                          </p>
                          <br />
                          <p style={{ fontSize: 15, paddingBottom: 20 }}>
                            Vous serez redirigé vers un site web exploité par
                            Casio Computer Co. Ltd.
                          </p>
                          <p style={{ fontSize: 15, paddingBottom: 20 }}>
                            <Trans
                              id="sidebar.links.redirect.description.classpad_net"
                              values={{
                                classpad_net: (
                                  <a
                                    target="_blank"
                                    href="http://ClassPad.net"
                                    rel="noreferrer"
                                    style={{ color: "#339af0" }}
                                  >
                                    {i18n._({
                                      id: "sidebar.links.classpad_net",
                                    })}
                                  </a>
                                ),
                              }}
                            />
                          </p>
                        </>
                      }
                      icon={<Box />}
                    />
                  </div>
                </GridItem>
              )}

              {/* Mes Excercise Lycee */}
              {(auth.user?.idProvider === IdProvider.GAR ||
                (auth.user?.idProvider === IdProvider.CLASSPAD &&
                  auth.user.metadata?.role !==
                    FrenchInstructorRole.SCHOOL_ADMIN &&
                  auth.user.metadata?.role !==
                    FrenchInstructorRole.SCHOOL_ASSOCIATION &&
                  auth.user.metadata?.role !== FrenchInstructorRole.OTHER &&
                  auth.user?.country === Country.FR)) && (
                <GridItem width="1" className={styles.navigationBoxGridItem}>
                  <a
                    href={mesExcercisesLyceeLaunchUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={async () => {
                      await captureLicenseUsage(auth.user?.id, {
                        userId: auth.user?.id,
                        externalId: auth.user?.externalUserId,
                        product: Product.MES_EXERCISES_LYCEE,
                        accessDate: new Date(),
                      });
                    }}
                  >
                    <NavigationBox
                      title="Mes Exercices Lycée"
                      description={
                        <>
                          <p style={{ fontWeight: "bold" }}>
                            Testez la version beta de notre solution
                            d’apprentissage Mes exercices Lycée et
                            entraînez-vous sur des exercices de Seconde avec les
                            calculatrices CASIO Graph Light et Graph Math+.
                          </p>
                          <br />
                          <p style={{ fontSize: 15, paddingBottom: 15 }}>
                            Vous serez redirigé vers un site web exploité par
                            Casio Computer Co. Ltd.
                          </p>
                        </>
                      }
                      icon={<Book />}
                    />
                  </a>
                </GridItem>
              )}

              {/* Classpad Learning */}
              <GridItem width="1" className={styles.navigationBoxGridItem}>
                <a
                  href={classpadLearningUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={async () => {
                    /** Capture license usage here */
                    await captureLicenseUsage(auth.user?.id, {
                      userId: auth.user?.id,
                      externalId: auth.user?.externalUserId,
                      product: Product.CLASSPAD_LEARNING,
                      accessDate: new Date(),
                    });
                  }}
                >
                  <NavigationBox
                    title={"ClassPad Learning Collège"}
                    description={
                      <p style={{ fontWeight: "bold" }}>
                        Entraînez-vous sur des exercices de Collège pour
                        acquérir des automatismes.
                      </p>
                    }
                    icon={<BookOpen />}
                    variant={
                      learnerTestPeriodActive ? "cplTestPeriod" : "default"
                    }
                    remainingLearnerTestDays={remainingLearnerTestDays}
                  />
                </a>
              </GridItem>

              {auth.user?.type === Role.INSTRUCTOR &&
                auth.user.idProvider === IdProvider.CLASSPAD && (
                  <GridItem width="1" className={styles.navigationBoxGridItem}>
                    {!isDisableDTCCard ? (
                      <a
                        href={dtcLaunchUrl}
                        target="_blank"
                        rel="noreferrer"
                        onClick={async () => {}}
                      >
                        <DTCNavigationBox />
                      </a>
                    ) : (
                      <DTCNavigationBox />
                    )}
                  </GridItem>
                )}

              {/* My profile (Mon compte) */}
              <GridItem width="1" className={styles.navigationBoxGridItem}>
                <Link to="/profile">
                  {auth.user?.idProvider === IdProvider.CLASSPAD &&
                  auth.user.country === Country.FR &&
                  auth.user.type === Role.INSTRUCTOR ? (
                    <NavigationBox
                      title={"Mon compte"}
                      description={
                        <>
                          <p style={{ fontWeight: "bold", paddingBottom: 15 }}>
                            Gérez vos données personnelles.
                          </p>
                          <p style={{ fontSize: 15, paddingBottom: 15 }}>
                            Validez ici votre compte enseignant pour accéder au
                            portail de commandes groupées CASIO.{" "}
                          </p>
                        </>
                      }
                      icon={<User />}
                      variant={"dtcTeacherVerification"}
                    />
                  ) : (
                    <NavigationBox
                      title={i18n._({
                        id: `navigation-page.card.title.profile`,
                      })}
                      description={i18n._({
                        id: `navigation-page.card.description.profile`,
                      })}
                      icon={<User />}
                    />
                  )}
                </Link>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      );
    }
  };

  return (
    <div>
      <PageHeader
        title={i18n._({
          id: `navigation-page.title`,
        })}
      />
      {renderContent(auth, auth.user?.country)}
      {/* <ConfirmModal
        header={i18n._({
          id: `sidebar.links.redirect.title.classpad_net`,
        })}
        description={
          <Trans
            id="sidebar.links.redirect.description.classpad_net"
            values={{
              classpad_net: (
                <a
                  target="_blank"
                  href="http://ClassPad.net"
                  rel="noreferrer"
                  style={{ color: "#339af0" }}
                >
                  {i18n._({
                    id: "sidebar.links.classpad_net",
                  })}
                </a>
              ),
            }}
          />
        }
        onDone={redirectToClassPadDotNet}
        onRejection={() => setIsShowConfirmRedirect(false)}
        isShowing={isShowConfirmRedirect}
      ></ConfirmModal> */}
    </div>
  );
};

interface NavigationBoxProps {
  title: string;
  description: React.ReactNode;
  icon?: React.ReactNode;
  variant?:
    | "default"
    | "matheMarathon"
    | "cplTestPeriod"
    | "dtcTeacherVerification";
  remainingLearnerTestDays?: number;
  badgeText?: string;
  disabled?: boolean;
}

const NavigationBox = ({
  title,
  description,
  icon,
  variant = "default",
  remainingLearnerTestDays,
  badgeText,
  disabled = false,
}: NavigationBoxProps) => {
  const auth = useAuth();

  const frenchTeacherBadgeType = useMemo(() => {
    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.APPROVED
    ) {
      return "dtcTeacherVerification_verified";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.PENDING
    ) {
      return "dtcTeacherVerification_pending";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.REJECTED
    ) {
      return "dtcTeacherVerification_rejected";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.NOT_SUBMITTED
    ) {
      return "dtcTeacherVerification_notsubmitted";
    }
  }, [auth.user]);

  const teacherVerificationStatus = useMemo(() => {
    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.APPROVED
    ) {
      return "Validé";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.PENDING
    ) {
      return "Validation en cours";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.REJECTED
    ) {
      return "Refusé";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.NOT_SUBMITTED
    ) {
      return "Validation non effectuée";
    }
  }, [auth.user?.metadata?.teacherVerificationStatus]);

  return (
    <Card
      className={cl(styles.navigationBox, styles[`navigationBox_${variant}`])}
      variant={variant}
      clickable={!disabled}
      disabled={disabled}
    >
      <div className={styles.navigationBox__header}>
        <h3>{title}</h3>
        <i>
          {variant === "matheMarathon" ? <MatheMarathonLogo /> : <ArrowRight />}
        </i>
      </div>
      <div className={styles.navigationBox__content}>{description}</div>
      <div className={styles[`navigationBox__${variant}__footer`]}>
        {icon && <i>{icon}</i>}
        {auth.userInfo?.externalGroups &&
          auth.userInfo?.externalGroups.length > 0 &&
          badgeText && (
            <Badge
              icon={
                <>
                  <LinkIcon />
                  <IservLogo />
                </>
              }
              type="secondary"
              size="small"
              label={badgeText}
            ></Badge>
          )}
        {variant === "cplTestPeriod" && (
          <Badge
            className={styles.navigationBox__cplTestPeriod__footer__badge}
            size="small"
            type="cplTestPeriod"
            label={i18n._({
              id:
                remainingLearnerTestDays === 1
                  ? "navigation-page.card.cplTestPeriod.1day"
                  : "navigation-page.card.cplTestPeriod",
              values: { days: remainingLearnerTestDays },
            })}
            icon={<Gift />}
          />
        )}

        {variant === "dtcTeacherVerification" && (
          <Badge
            className={
              styles.navigationBox__dtcTeacherVerification__footer__badge
            }
            size="small"
            type={frenchTeacherBadgeType}
            label={`Compte enseignant: ${teacherVerificationStatus}`}
            icon={<Award />}
          />
        )}
      </div>
    </Card>
  );
};
