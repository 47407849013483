import { i18n } from "@lingui/core";
import { useFormikContext } from "formik";
import { X } from "react-feather";
import styles from "./AddSchoolTeacherFrench.module.scss";
import { Language } from "../../../types/Country";
import { GridItem, Grid } from "../../Grid/Grid";
import { detectLocale } from "../../IntlHandler/IntlHelper";
import { TextInput } from "../../TextInput/TextInput";

export interface AddSchoolTeacherFrenchFormSchema {
  schoolcode?: string;
  schoolname: string;
  schooltype: string;
  schoolcountry: string;
  schoolzip: string;
  schoolstreet: string;
  schooladdressaddition?: string;
  schoolcity: string;
  schooladdresscountry: string;
}

interface AddSchoolProps {
  onClose: () => void;
}
export const AddSchoolTeacherFrench = ({ onClose }: AddSchoolProps) => {
  const locale = detectLocale();
  const formik = useFormikContext<{
    addSchool: AddSchoolTeacherFrenchFormSchema;
  }>();
  // const schooltypes = getTypes(locale);

  return (
    <div className={styles.addSchool}>
      <div className={styles.addSchool__header}>
        {i18n._({ id: "schoolselect.addSchool" })}{" "}
        <button onClick={onClose} className={styles.addSchool__header__close}>
          <X />
        </button>
      </div>
      <Grid>
        {locale === Language.fr && (
          <GridItem width="1/2">
            <TextInput
              label={i18n._({ id: "register.form.schoolcode.french.teacher" })}
              id="schoolcode"
              name="addSchool.schoolcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.addSchool?.schoolcode}
            ></TextInput>
          </GridItem>
        )}
        <GridItem width="1/2">
          <TextInput
            label={i18n._({ id: "register.form.schoolname.french.teacher" })}
            id="schoolname"
            name="addSchool.schoolname"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schoolname
                ? formik.errors.addSchool?.schoolname
                : undefined
            }
            defaultValue={formik.values.addSchool?.schoolname}
          ></TextInput>
        </GridItem>
        <GridItem width="1/2">
          <TextInput
            label={i18n._({ id: "register.form.street.french.teacher" })}
            id="schoolstreet"
            name="addSchool.schoolstreet"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schoolstreet &&
              formik.errors.addSchool?.schoolstreet
                ? i18n._({ id: formik.errors.addSchool.schoolstreet })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schoolstreet}
          ></TextInput>
        </GridItem>

        <GridItem width="1/2">
          <TextInput
            label={i18n._({
              id: "register.form.addressaddition.french.teacher",
            })}
            id="schooladdressaddition"
            name="addSchool.schooladdressaddition"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schooladdressaddition &&
              formik.errors.addSchool?.schooladdressaddition
                ? i18n._({
                    id: formik.errors.addSchool.schooladdressaddition,
                  })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schooladdressaddition}
          ></TextInput>
        </GridItem>

        <GridItem width="1/2">
          <TextInput
            label={i18n._({ id: "register.form.zip.french.teacher" })}
            id="schoolzip"
            name="addSchool.schoolzip"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schoolzip &&
              formik.errors.addSchool?.schoolzip
                ? i18n._({ id: formik.errors.addSchool.schoolzip })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schoolzip}
          ></TextInput>
        </GridItem>
        <GridItem width="1/2">
          <TextInput
            id={"schoolcity"}
            label={i18n._({ id: "register.form.city.french.teacher" })}
            name={"addSchool.schoolcity"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schoolcity &&
              formik.errors.addSchool?.schoolcity
                ? i18n._({ id: formik.errors.addSchool.schoolcity })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schoolcity}
          ></TextInput>
        </GridItem>

        <GridItem width="1/2">
          <TextInput
            id={"schooladdresscountry"}
            label={i18n._({ id: "register.form.country.french.teacher" })}
            name={"addSchool.schooladdresscountry"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schooladdresscountry &&
              formik.errors.addSchool?.schooladdresscountry
                ? i18n._({ id: formik.errors.addSchool.schooladdresscountry })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schooladdresscountry}
          ></TextInput>
        </GridItem>
      </Grid>
    </div>
  );
};
