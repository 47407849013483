import { i18n } from "@lingui/core";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { School, getSchool } from "../../api/school";
import { Language } from "../../types/Country";
import { User } from "../../types/User";
import { validEmailFormat } from "../../utils/emailValidation";
import { Button } from "../Button/Button";
import { InstructorSchoolDataStep } from "../DataStep/InstructorSchoolDataStep";
import { Grid, GridItem } from "../Grid/Grid";
import { SupportedLanguage, detectLocale } from "../IntlHandler/IntlHelper";
import { salutationOptions } from "../Register/RegisterHelper";
import { Select } from "../SelectWithoutFormik/Select";
import { TextInput } from "../TextInput/TextInput";
import { UpdateTisConsentLMSSchema } from "./UserProfile";
import styles from "./UserProfile.module.scss";
import { validateAllSchoolData } from "./UserProfileSchoolData";
import { UserProfileTISConsent } from "./UserProfileTISConsent";

export interface UserProfileTeacherInfoServiceProps {
  user: User;
  handleTISSubmit: (values: UpdateTisConsentLMSSchema) => Promise<void>;
  loading: boolean;
}

export const UserProfileTeacherInfoServiceLMS = ({
  user,
  handleTISSubmit,
  loading,
}: UserProfileTeacherInfoServiceProps) => {
  const [school, setSchool] = useState<School>();

  useEffect(() => {
    const fetchSchool = async () => {
      if (user.schoolId) {
        const foundSchool = await getSchool(user.schoolId);
        setSchool(foundSchool);
      }
    };
    fetchSchool();
  }, [user]);
  const createFormValues = useCallback(() => {
    return {
      schoolData: {
        school: school
          ? {
              value: school?.id,
              label: school?.name,
              school: school,
            }
          : undefined,
        subjects: user.subjects || [],
        studentCount: user.studentCount || 0,
        addManualSchool: false,
      },
      tisData: {
        email: user.email || "",
        salutation: user.salutation || "",
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        tisMarketingConsent: user?.tisMarketingConsent || false,
        tisProfilingConsent: user?.tisProfilingConsent || false,
      },
    };
  }, [school, user]);
  const [initialValues, setInitialValues] = useState(createFormValues());
  const formMethods = useForm({
    mode: "all",
    defaultValues: useMemo(() => {
      return initialValues.tisData;
    }, [initialValues]),
  });

  useEffect(() => {
    const values = createFormValues();
    formMethods.reset(values.tisData);
    setInitialValues(values);
  }, [school, user, createFormValues, formMethods]);

  const reactFormSubmitValues = (values: {
    email: string;
    salutation: string;
    firstname: string;
    lastname: string;
    tisMarketingConsent: boolean;
    tisProfilingConsent: boolean;
  }) => {
    return {
      user: {
        email: values.email,
        salutation: values.salutation,
        firstname: values.firstname,
        lastname: values.lastname,
        nickname: user.nickname,
        type: user.type,
        internalUser: false,
      },
      tisConsent: {
        tisProfilingConsent: values.tisProfilingConsent,
        tisMarketingConsent: values.tisMarketingConsent,
      },
    };
  };

  const tisMarketingConsent = formMethods.watch("tisMarketingConsent");
  const tisProfilingConsent = formMethods.watch("tisProfilingConsent");

  const defaultLocale = detectLocale();
  const isFrench = defaultLocale === Language.fr;

  const disabled = (dirty: boolean, isValid: boolean) => {
    const tisConsentChanged =
      user.tisMarketingConsent !== tisMarketingConsent ||
      user.tisProfilingConsent !== tisProfilingConsent;
    return (
      !formMethods.formState.isValid ||
      (!formMethods.formState.isDirty && !dirty && !tisConsentChanged) ||
      !isValid ||
      loading
    );
  };

  return (
    //@ts-ignore
    <FormProvider {...formMethods}>
      <Formik
        initialValues={initialValues.schoolData}
        validate={validateAllSchoolData}
        onSubmit={async (values) => {
          await handleTISSubmit({
            school: values,
            ...reactFormSubmitValues(formMethods.getValues()),
          });
        }}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {({ isValid, values, setFieldValue, dirty }) => (
          <Form>
            <div data-testid="userProfileTISLMS">
              <div
                className={styles.userProfile__teacherInfoServiceLMS__header}
              >
                {i18n._({
                  id: "user.profile.schooldata.header",
                })}
              </div>
              <div className={styles.userProfile__form}>
                {/* <InstructorSubjectDataStep locale={user.language} /> */}
                <InstructorSchoolDataStep
                  setAddManualSchool={async (addSchool: boolean) => {
                    setFieldValue("addManualSchool", addSchool, true);
                  }}
                  addManualSchool={values.addManualSchool}
                  user={user}
                />
              </div>

              {!isFrench && (
                <>
                  <div
                    className={
                      styles.userProfile__teacherInfoServiceLMS__header
                    }
                  >
                    {i18n._({
                      id: "user.profile.tis_lms.header.tis",
                    })}
                  </div>
                  <p
                    className={styles.userProfile__teacherInfoServiceLMS__info}
                  >
                    {i18n._({
                      id: "register.form.teacher_info_service.info.profil",
                    })}
                  </p>
                  <TeacherInfoServiceLMSPersonalData user={user} />
                  <div
                    className={styles.userProfile__teacherInfoService__boxes}
                  >
                    <UserProfileTISConsent
                      tisMarketingConsent={tisMarketingConsent}
                      tisProfilingConsent={tisProfilingConsent}
                      setValue={formMethods.setValue}
                    />
                  </div>
                </>
              )}
            </div>
            <Button
              label={i18n._({ id: "user.profile.save" })}
              type="submit"
              id="update-user-submit-button"
              disabled={disabled(dirty, isValid)}
            ></Button>
          </Form>
        )}
      </Formik>
    </FormProvider>
  );
};

const TeacherInfoServiceLMSPersonalData = ({ user }: { user: User }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Grid>
      <GridItem width="1/2">
        <Select
          id={"salutation"}
          options={salutationOptions(user.language as SupportedLanguage)}
          label={i18n._({ id: "register.form.salutation" })}
          placeholderLabel={i18n._({
            id: "register.form.select.placeholder",
          })}
          defaultValue={user.salutation}
          errorMessage={
            errors.salutation && i18n._({ id: "forms.validation.required" })
          }
          {...register("salutation", {
            required: true,
          })}
        />
      </GridItem>
      <GridItem width="1/2">
        <TextInput
          id={"firstname"}
          label={i18n._({ id: "register.form.firstname" })}
          defaultValue={user.firstname}
          errorMessage={
            errors.firstname && i18n._({ id: "forms.validation.required" })
          }
          {...register("firstname", {
            required: true,
          })}
        />
      </GridItem>
      <GridItem width="1/2">
        <TextInput
          id={"lastname"}
          label={i18n._({ id: "register.form.lastname" })}
          {...register("lastname", {
            required: true,
          })}
          errorMessage={
            errors.lastname && i18n._({ id: "forms.validation.required" })
          }
        />
      </GridItem>
      <GridItem width="1/2">
        <TextInput
          id={"mail"}
          label={i18n._({ id: "register.form.email" })}
          {...register("email", {
            required: true,
            validate: (email: string) => validEmailFormat(email),
          })}
          errorMessage={
            errors.email &&
            i18n._({
              id: "forms.validation.invalid_email",
            })
          }
        ></TextInput>
      </GridItem>
    </Grid>
  );
};
