import { createContext, ReactNode, useContext } from "react";

export interface AppConfigContext {
  publicUrl: string;
  featureToggles: Record<string, boolean>;
  matheMarathonDomain: string;
  classpadNetUrl: string;
  cplTestPeriodLearnerDuration: number;
  classpadLearningUrl: string;
  casioEduLaunchUrl: string;
  casioEduProfileUrl: string;
  mesExcercisesLyceeLaunchUrl: string;
  dtcLaunchUrl: string;
  cpLearningLicenseForDE: string;
  cpLearningLicenseForFR: string;
}

const getAppConfig = () => ({
  publicUrl: process.env.PUBLIC_URL || "/",
  featureToggles: window.featureToggles || {},
  matheMarathonDomain: window.matheMarathonDomain || "",
  classpadNetUrl: window.classpadNetUrl || "",
  cplTestPeriodLearnerDuration:
    Number(window.cplTestPeriodLearnerDuration) || 0,
  classpadLearningUrl: window.classpadLearningUrl || "",
  casioEduLaunchUrl: window.casioEduLaunchUrl || "",
  casioEduProfileUrl: window.casioEduProfileUrl || "",
  mesExcercisesLyceeLaunchUrl: window.mesExcercisesLyceeLaunchUrl || "",
  dtcLaunchUrl: window.dtcLaunchUrl || "",
  cpLearningLicenseForDE: window.cpLearningLicenseForDE || "",
  cpLearningLicenseForFR: window.cpLearningLicenseForFR || "",
});

export const appConfigContext = createContext<AppConfigContext>(getAppConfig());

interface AppConfigProviderProps {
  children: ReactNode;
}

export const AppConfigProvider = ({ children }: AppConfigProviderProps) => {
  const appConfig = getAppConfig();
  return (
    <appConfigContext.Provider value={appConfig}>
      {children}
    </appConfigContext.Provider>
  );
};

export const useAppConfig = () => useContext(appConfigContext);
