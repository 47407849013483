import { Trans } from "@lingui/react";
import React, { useEffect } from "react";
import { ArrowRight } from "react-feather";
import { detectLocale } from "../IntlHandler/IntlHelper";
import styles from "./NewsBoxIFrame.module.scss";
import { useAuth } from "../../hooks/use-auth";
import { IdProvider } from "../../types/IdProvider";

export const NewsBoxIFrame: React.FC = () => {
  const host = window.location.host;
  // now we temporary disable resizeIframe function to avoid CORS error.
  // Solution: https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage

  useEffect(() => {
    if (host !== "classpad.academy") {
      const interval = setInterval(() => resizeIframe(), 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [host]);

  const resizeIframe = () => {
    const iframe: HTMLIFrameElement = document.getElementById(
      "news-box-iframe"
    ) as HTMLIFrameElement;
    if (iframe) {
      iframe.style.height =
        iframe.contentWindow?.document.getElementById(
          "standalone-news-post-box"
        )?.scrollHeight + "px";
    }
  };

  const lang = detectLocale();
  let hardCodeDomain: string;
  let newsBoxSrc;
  let moreHref;

  if (host === "classpad.academy") {
    hardCodeDomain = "https://casio-education.eu";

    newsBoxSrc = hardCodeDomain + "/news-post-box/";
    moreHref = hardCodeDomain + "/news/";
    if (lang !== "de") {
      newsBoxSrc = hardCodeDomain + `/${lang}/news-post-box/`;
      moreHref = hardCodeDomain + `/${lang}/news/`;
    }
  } else {
    newsBoxSrc = "/news-post-box/";
    moreHref = "/news/";
    if (lang !== "de") {
      newsBoxSrc = `/${lang}/news-post-box/`;
      moreHref = `/${lang}/news/`;
    }
  }

  const auth = useAuth();

  console.log("host", window.location.host);

  return (
    <div className={styles.newsBoxIFrame}>
      <iframe
        id="news-box-iframe"
        src={newsBoxSrc}
        title="news"
        onLoad={host !== "classpad.academy" ? resizeIframe : undefined}
        style={
          host !== "classpad.academy"
            ? undefined
            : {
                height: "45vh", // temporary hard code
              }
        }
      />
      {auth.user?.idProvider !== IdProvider.GAR && (
        <>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={moreHref}
            target="_blank"
            className={styles.newsBoxIFrame__more}
          >
            <span>
              <Trans id={"navigation-page.newsPostBox.more"} />
            </span>
            <i>
              <ArrowRight />
            </i>
          </a>
        </>
      )}
    </div>
  );
};
