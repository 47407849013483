import axios from "axios";
import { buildSearchParams } from "../utils/utils";
import { Country } from "../types/Country";
import { IdProvider } from "../types/IdProvider";
import { DataWithPagination, TeacherVerificationStatus } from "../types/User";
import { FrenchInstructorRole } from "../types/Role";

const url = "internal/v2/teacher-verification";
export interface SearchTeacherVerificationCondition {
  country?: { value: Country; label: string; data: Country }[];
  idProvider?: { value: IdProvider; label: string; data: IdProvider }[];
  status?: {
    value: TeacherVerificationStatus;
    label: string;
    data: TeacherVerificationStatus;
  }[];
  userId?: string;
  firstName?: string;
  lastName?: string;
  nickname?: string;
  email?: string;
  requestId?: string;
  submittedDateFrom?: string;
  submittedDateTo?: string;
  schoolId?: string;
  role?: {
    value: FrenchInstructorRole;
    label: string;
    data: FrenchInstructorRole;
  }[];
  schoolCode?: string;
}

export interface SearchTeacherVerificationData {
  country?: Country[];
  idProvider?: IdProvider[];
  status?: TeacherVerificationStatus[];
  userId?: string;
  firstName?: string;
  lastName?: string;
  nickname?: string;
  email?: string;
  requestId?: string;
  submittedDateFrom?: string;
  submittedDateTo?: string;
  schoolId?: string;
  role?: FrenchInstructorRole[];
  schoolCode?: string;
}

export interface TeacherVerificationWithUserAndSchool {
  id: string;
  userId: string;
  status: TeacherVerificationStatus;
  submittedDate: string;
  updatedAt: string;
  note: string;
  reviewerNote: string | null;
  user: {
    id: string;
    email: string;
    nickname: string;
    firstname: string;
    lastname: string;
    country: Country;
    idProvider: IdProvider;
    school: {
      id: string;
      name: string;
      schoolId: string;
    };
    metadata: {
      role: FrenchInstructorRole;
    };
  };
}

export const sendRequestVerification = async (
  files: File[],
  userId: string | undefined,
  note: string | null,
  apiBaseUrl = "/app/api"
) => {
  try {
    if (!userId) {
      console.error("send.request.verification: user id is required");
      return;
    }

    if (!note) {
      console.error("send.request.verification: note is required");
      return;
    }

    await axios.post<void>(
      `
        ${apiBaseUrl}/${url}/request-verification
      `,
      {
        userId,
        note,
      }
    );

    // send teacher verification submitted email. (or re-submitted).
    await sendTeacherVerificationRequestEmail(userId, files);
  } catch (error) {
    throw new Error(`send.request.verification.error: ${error}`);
  }
};

export const sendTeacherVerificationRequestEmail = async (
  userId: string,
  files: File[],
  apiBaseUrl = "/app/api"
) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });

  await axios.post<any>(
    `${apiBaseUrl}/${url}/user/${userId}/send-verification-request-email`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const searchTeacherVerification = async (
  {
    page,
    limit,
    sortOrder,
    sortBy,
  }: {
    page: number;
    limit: number;
    sortOrder?: "asc" | "desc";
    sortBy?: string;
  },
  search: SearchTeacherVerificationCondition,
  apiBaseUrl = "/app/api"
) => {
  try {
    if (!page) {
      throw new Error("page is required");
    }

    if (!limit) {
      throw new Error("limit is required");
    }

    let queryUrl = `${apiBaseUrl}/${url}/search?page=${page}&limit=${limit}`;

    if (sortOrder) {
      queryUrl += `&sortOrder=${sortOrder}`;
    }

    if (sortBy) {
      queryUrl += `&sortBy=${sortBy}`;
    }

    let searchCondition: SearchTeacherVerificationData = {};

    if (search.country && search.country.length > 0) {
      searchCondition.country = search.country.map((item) => item.data);
    }

    if (search.idProvider && search.idProvider.length > 0) {
      searchCondition.idProvider = search.idProvider.map((item) => item.data);
    }

    if (search.status && search.status.length > 0) {
      searchCondition.status = search.status.map((item) => item.data);
    }

    if (search.userId) {
      searchCondition.userId = search.userId;
    }

    if (search.firstName) {
      searchCondition.firstName = search.firstName;
    }

    if (search.lastName) {
      searchCondition.lastName = search.lastName;
    }

    if (search.nickname) {
      searchCondition.nickname = search.nickname;
    }

    if (search.requestId) {
      searchCondition.requestId = search.requestId;
    }

    if (search.email) {
      searchCondition.email = search.email;
    }

    if (search.submittedDateFrom) {
      searchCondition.submittedDateFrom = search.submittedDateFrom;
    }

    if (search.submittedDateTo) {
      searchCondition.submittedDateTo = search.submittedDateTo;
    }

    if (search.schoolId) {
      searchCondition.schoolId = search.schoolId;
    }

    if (search.role && search.role.length > 0) {
      searchCondition.role = search.role?.map((item) => item.data);
    }

    if (search.schoolCode) {
      searchCondition.schoolCode = search.schoolCode;
    }

    const searchParams = buildSearchParams(searchCondition);
    const endpoint = queryUrl + searchParams;

    const response = await axios.get<
      DataWithPagination<TeacherVerificationWithUserAndSchool>
    >(endpoint);
    return response.data;
  } catch (error) {
    console.error("search.teacher.verification.error", error);
    throw new Error(`search.teacher.verification.error ${error}`);
  }
};

export const adminApproveTeacherVerificationRequests = async (
  userIds: string[]
) => {
  try {
    // approve request by user with userId
    // If the user ID is not specified, approve every request.
    const apiBaseUrl = "/app/api";

    await axios.put<void>(`${apiBaseUrl}/${url}/approve`, {
      userIds,
    });
  } catch (error) {
    console.error("admin.approve.teacher.verification.error", error);
    throw new Error(`admin.approve.teacher.verification.error ${error}`);
  }
};

export const adminRejectTeacherVerificationRequests = async (
  userId: string,
  reviewerNote: string
) => {
  try {
    // approve request by user with userId
    // If the user ID is not specified, approve every request.
    const apiBaseUrl = "/app/api";

    await axios.put<void>(`${apiBaseUrl}/${url}/reject`, {
      userId,
      reviewerNote,
    });
  } catch (error) {
    console.error("admin.approve.teacher.verification.error", error);
    throw new Error(`admin.approve.teacher.verification.error ${error}`);
  }
};
