import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import React, { useEffect, useState } from "react";
import { ArrowLeft, Edit, LogOut } from "react-feather";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  checkLastTeacher,
  deleteUserOnContext,
  getContext,
} from "../../api/context";
import { ReactComponent as IservLogo } from "../../assets/images/svg/iserv-logo-color-neutral.svg";
import { ReactComponent as EduplacesLogo } from "../../assets/images/svg/eduplaces_logo.svg";
import garLogo from "../../assets/images/gar-image.png";
import { useAuth } from "../../hooks/use-auth";
import { useToasts } from "../../provider/toast-provider";
import { Context } from "../../types/Context";
import { Role } from "../../types/Role";
import { Button } from "../Button/Button";
import { Card } from "../Card/Card";
import { CodeGeneratorContainer } from "../CodeGenerator/CodeGeneratorContainer";
import { Modal } from "../Modal/Modal";
import { PageHeader } from "../PageHeader/PageHeader";
import { PageToolbar, PageToolbarAction } from "../PageToolbar/PageToolbar";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { Spinner } from "../Spinner/Spinner";
import styles from "./ContextDetails.module.scss";
import { CreateOrUpdateContext } from "./CreateOrUpdateContext";
import { UserList } from "./UserList/UserList";
import { IdProvider } from "../../types/IdProvider";
import { Grid, GridItem } from "../Grid/Grid";
import { TextInput } from "../TextInput/TextInput";

const MATCHING_DELETE = "DELETE";

export const ContextDetails: React.FC = () => {
  const location = useLocation();
  const { newViewMode } = location.state || {};
  const { contextId = "" } = useParams<{ contextId: string }>();
  const [context, setContext] = useState<Context | null>(null);
  const [leaveModalVisible, setLeaveModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [
    deleteClassroomConfirmationVisible,
    setDeleteClassroomConfirmationVisible,
  ] = useState<boolean>(false);
  const [
    disableDeleteClassroomConfirmationBtn,
    setDisableDeleteClassroomConfirmationBtn,
  ] = useState<boolean>(true);
  const [deleteText, setDeleteText] = useState<string>("");

  const auth = useAuth();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const userId = auth?.userInfo?.userId || "";
  const isExternalContext = !!context?.externalId;

  useEffect(() => {
    const fetchContext = async () => {
      const context = await getContext(contextId);
      setContext(context);
    };

    fetchContext();
  }, [contextId, updateModalVisible]);

  useEffect(() => {
    if (deleteText === MATCHING_DELETE) {
      setDisableDeleteClassroomConfirmationBtn(false);
    } else {
      setDisableDeleteClassroomConfirmationBtn(true);
    }
  }, [deleteText]);

  async function leaveContext(isLastTeacher: boolean) {
    try {
      await deleteUserOnContext(contextId, userId);
      navigate(`/contexts`);

      if (isLastTeacher) {
        addToast(
          i18n._({
            id: "contexts.leave.lastteacher.success",
          })
        );
      } else {
        addToast(
          i18n._({
            id: "contexts.leave.success",
            values: { name: context?.name },
          })
        );
      }
    } catch (err) {
      addToast(i18n._({ id: "contexts.leave.error" }), "error");
    }
  }

  if (!context) {
    return <Spinner type="fullPage" title={i18n._({ id: "loading" })} />;
  }
  const handleChangeDeleteText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteText(event.target.value.toLocaleUpperCase());
  };

  function leaveContextModal() {
    return (
      <Modal
        title={i18n._({ id: "contexts.leave.header" })}
        isShowing={leaveModalVisible}
        onHide={() => setLeaveModalVisible(false)}
      >
        <p className={styles.leaveContextDescription}>
          <Trans
            id={"contexts.leave.description"}
            values={{ name: context?.name }}
          />
        </p>
        <Button
          label={i18n._({ id: "contexts.leave.button" })}
          onClick={() => leaveContext(false)}
          btnStyle="warning"
          icon={<LogOut />}
        />
      </Modal>
    );
  }

  function deleteClassroomConfirmationModal() {
    return (
      <Modal
        title={i18n._({
          id: "contexts.leave.lastteacher.header",
        })}
        isShowing={deleteClassroomConfirmationVisible}
        onHide={() => {
          setDeleteClassroomConfirmationVisible(false);
          setDisableDeleteClassroomConfirmationBtn(true);
          setDeleteText("");
        }}
        size="medium"
      >
        <p className={styles.deleteClassroomConfirmationText}>
          {i18n._({
            id: "contexts.leave.lastteacher.description",
          })}
        </p>

        <div className={styles.confirmDeleteTextbox}>
          <TextInput
            id="confirmDeleteTextbox"
            label=""
            onChange={handleChangeDeleteText}
            value={deleteText}
          />
        </div>

        <div className={styles.deleteConfirmBtnGroup}>
          <Grid>
            <GridItem width="1/2" className={styles.groupBtn1}>
              <Button
                label={i18n._({
                  id: "contexts.leave.lastteacher.delete",
                })}
                btnStyle="danger"
                disabled={disableDeleteClassroomConfirmationBtn}
                onClick={() => leaveContext(true)}
              ></Button>
            </GridItem>

            <GridItem width="1/2" className={styles.groupBtn2}>
              <Button
                btnStyle="secondary"
                label={i18n._({
                  id: "contexts.leave.lastteacher.cancel",
                })}
                onClick={() => {
                  setDeleteClassroomConfirmationVisible(false);
                  setDisableDeleteClassroomConfirmationBtn(true);
                  setDeleteText("");
                }}
              ></Button>
            </GridItem>
          </Grid>
        </div>
      </Modal>
    );
  }

  const renderExternalClassLogoByIdProvider = () => {
    if (context.origin === IdProvider.ISERV) {
      return <IservLogo />;
    }

    if (context.origin === IdProvider.GAR) {
      return (
        <div className={styles.gar_logo}>
          <img src={garLogo} alt="gar-logo" />
        </div>
      );
    }

    if (context.origin === IdProvider.EDUPLACES) {
      return <EduplacesLogo />;
    }
  };

  return (
    <>
      <PageToolbar
        leftActions={
          <Link to="/contexts" state={{ newViewMode }}>
            <PageToolbarAction
              icon={<ArrowLeft />}
              label={i18n._({ id: "contexts.toolbar.back" })}
              type="back"
            />
          </Link>
        }
        rightActions={
          !isExternalContext && (
            <>
              <RestrictedTo roles={[Role.INSTRUCTOR]}>
                <PageToolbarAction
                  icon={<Edit />}
                  label={i18n._({ id: "contexts.toolbar.edit" })}
                  type="base"
                  onClick={() => setUpdateModalVisible(true)}
                />
              </RestrictedTo>
              <PageToolbarAction
                icon={<LogOut />}
                label={i18n._({ id: "contexts.toolbar.leave" })}
                type="warning"
                onClick={async () => {
                  // Call API check last teacher
                  const isLastTeacher = await checkLastTeacher(contextId);
                  if (isLastTeacher) {
                    setDeleteClassroomConfirmationVisible(true);
                  } else {
                    setLeaveModalVisible(true);
                  }
                }}
              />
            </>
          )
        }
      />
      <PageHeader
        title={context?.name}
        color={context?.color}
        colorIcon={isExternalContext && renderExternalClassLogoByIdProvider()}
        contextCode={context?.code}
      />
      {leaveContextModal()}
      {deleteClassroomConfirmationModal()}
      <RestrictedTo roles={[Role.INSTRUCTOR]}>
        <Modal
          title={i18n._({ id: "contexts.update.header" })}
          isShowing={updateModalVisible}
          onHide={() => setUpdateModalVisible(false)}
        >
          <CreateOrUpdateContext
            update={true}
            updateContextProps={{ contextId, setUpdateModalVisible }}
          />
        </Modal>

        {isExternalContext ? (
          <div className={styles.externalInfo}>
            <h2>
              {i18n._({
                id: `contexts.details.externalInfo.header.${auth.user?.idProvider}`,
              })}
            </h2>
            <p>
              {i18n._({
                id: `contexts.details.externalInfo.text.${auth.user?.idProvider}`,
              })}
            </p>
          </div>
        ) : (
          <Card title={i18n._({ id: "contexts.details.joinCodes.header" })}>
            <div className={styles.codeGenerators}>
              <CodeGeneratorContainer
                role={Role.LEARNER}
                contextId={contextId}
              />
              <CodeGeneratorContainer
                role={Role.INSTRUCTOR}
                contextId={contextId}
              />
            </div>
          </Card>
        )}
      </RestrictedTo>
      <Card title={i18n._({ id: "contexts.details.userlist.header" })}>
        <UserList
          context={context}
          setContext={setContext}
          messages={{ instructor: i18n._({ id: "instructor" }) }}
        />
      </Card>
    </>
  );
};
