import { Navigate, useParams } from "react-router-dom";
import { switchLanguage } from "./Register";
import { Language } from "../../types/Country";
import { Role } from "../../types/Role";
import { RegisterFromEnum } from "../../types/RegisterFrom";

export function RegisterFrom() {
  const { from } = useParams<{ from: string }>();

  switch (from?.toLowerCase()) {
    case RegisterFromEnum.CASIO_EDU:
      switchLanguage(Language.fr);
      sessionStorage.setItem("classpadRegistrationRole", Role.INSTRUCTOR);
      sessionStorage.setItem("registerRequestedFrom", from);
      break;
    default:
  }
  return (
    <>
      {from?.toLowerCase() === RegisterFromEnum.CASIO_EDU && (
        <Navigate to={"/registration/"} />
      )}
    </>
  );
}
