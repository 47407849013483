import React, { useState } from "react";
import { AutoLinkParagraph } from "./AutoLinkParagraphProps";
import "./FAQItem.scss";
import { SecondLevel } from "./SecondLevel";
import { Minus, Plus } from "react-feather";
import AnimateHeight from "react-animate-height";
export interface FirstLevelProps {
  firstLevelText?: String;
  text: string[];
  listStyle: string;
  isHiding?: boolean;
  secondLevel?: { text: string[]; listStyle: string; index: number };
}

export const FirstLevel: React.FC<FirstLevelProps> = ({
  text,
  listStyle,
  secondLevel,
  firstLevelText,
  isHiding,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleCollapse = (event: any) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  if (isHiding)
    return (
      <div>
        <div
          className="first-level"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <button className="collapse__button" onClick={toggleCollapse}>
            <h3>{firstLevelText}</h3>
            <span>{isOpen ? <Minus /> : <Plus />}</span>
          </button>
          <AnimateHeight
            className="collapse__items"
            duration={500}
            height={isOpen ? "auto" : 0}
          >
            <ol>
              {text.map((fcText, fcIndex) => (
                <li
                  className={`collapse__items__item__${listStyle}`}
                  key={fcIndex}
                >
                  <AutoLinkParagraph key={fcIndex} text={fcText} />
                  {secondLevel?.index === fcIndex && (
                    <SecondLevel
                      text={secondLevel.text}
                      listStyle={secondLevel.listStyle}
                    />
                  )}
                </li>
              ))}
            </ol>
          </AnimateHeight>
        </div>
      </div>
    );
  else
    return (
      <div>
        <ol>
          {text.map((fcText, fcIndex) => (
            <li className={`collapse__items__item__${listStyle}`} key={fcIndex}>
              <AutoLinkParagraph key={fcIndex} text={fcText} />
              {secondLevel?.index === fcIndex && (
                <SecondLevel
                  text={secondLevel.text}
                  listStyle={secondLevel.listStyle}
                />
              )}
            </li>
          ))}
        </ol>
      </div>
    );
};
