import { Modal } from "../../Modal/Modal";
import { Grid, GridItem } from "../../Grid/Grid";
import styles from "./ConfirmationModal.module.scss";
import { Button } from "../../Button/Button";

export const ConfirmationModal = ({
  title,
  isShowing,
  onConfirm,
  onCancel,
  isRejectModal,
  children,
  disableConfirmBtn,
}: {
  title: string;
  isShowing: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isRejectModal?: boolean;
  children?: React.ReactNode;
  disableConfirmBtn?: boolean;
}) => {
  return (
    <>
      <Modal
        title={title}
        isShowing={isShowing}
        closeButton={false}
        size="medium"
      >
        <div className={styles.container}>
          {children}
          <div className={styles.container__confirmModalBtnGroup}>
            <Grid>
              <GridItem
                width="1/2"
                className={styles.container__confirmModalBtnGroup__groupBtn1}
              >
                {!isRejectModal ? (
                  <Button
                    label="confirm"
                    btnStyle="primary"
                    onClick={onConfirm}
                    disabled={disableConfirmBtn}
                  ></Button>
                ) : (
                  <Button
                    label="Reject"
                    btnStyle="danger"
                    onClick={onConfirm}
                    disabled={disableConfirmBtn}
                  ></Button>
                )}
              </GridItem>

              <GridItem
                width="1/2"
                className={styles.container__confirmModalBtnGroup__groupBtn2}
              >
                <Button
                  btnStyle="secondary"
                  label="cancel"
                  onClick={onCancel}
                ></Button>
              </GridItem>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
};
