import { Button } from "../Button/Button";
import { Modal, ModalProps } from "../Modal/Modal";
import { TextInput } from "../TextInput/TextInput";
import styles from "./LMSNicknameModal.module.scss";
import { i18n } from "@lingui/core";
import { useForm } from "react-hook-form";
import { updateUser } from "../../api/user";
import { Operation } from "fast-json-patch";
import { useToasts } from "../../provider/toast-provider";
import { IdProvider } from "../../types/IdProvider";
import { LMSBadge } from "../LMSBadge/LMSBadge";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { BlockContent } from "../BlockContent/BlockContent";
import { Trans } from "@lingui/react";
import { useAuth } from "../../hooks/use-auth";
import { Role } from "../../types/Role";
import { EmailLink } from "../StudentList/StudentList";
import { Language } from "../../types/Country";

export interface LMSNicknameModalViewProps extends ModalProps {
  welcomeText: string;
  description: string;
  idProvider: IdProvider;
  onSubmit: (data: NicknameData) => void;
}

export interface NicknameData {
  nickname?: string;
  tacAccepted?: boolean;
}

export interface LMSNicknameModalProps {
  idProvider: IdProvider;
  nicknameChanged: boolean;
  setNicknameChanged: (nicknameChanged: boolean) => void;
}

export const LMSNicknameModal = ({
  idProvider,
  nicknameChanged,
  setNicknameChanged,
}: LMSNicknameModalProps) => {
  const auth = useAuth();
  const userId = auth.user?.id || "";
  const nickname = auth.user?.nickname;
  const { addToast } = useToasts();

  async function onSubmit(data: NicknameData) {
    try {
      const patch: Operation[] = [];
      if (nickname === userId)
        patch.push({ op: "replace", path: "/nickname", value: data.nickname });

      patch.push({
        op: "replace",
        path: "/tacAccepted",
        value: data.tacAccepted,
      });

      await updateUser({ userId, patch });
    } catch (err) {
      addToast(i18n._({ id: "user.profile.save.error" }), "error");
    } finally {
      setNicknameChanged(true);
    }
  }

  return (
    <LMSNicknameModalView
      welcomeText={i18n._({ id: "lms.nickname.modal.welcome" })}
      description={i18n._({ id: "lms.nickname.modal.description" })}
      isShowing={!nicknameChanged}
      onSubmit={onSubmit}
      icon={
        idProvider !== IdProvider.CLASSPAD ? (
          <LMSBadge idProvider={idProvider} />
        ) : (
          <></>
        )
      }
      closeButton={false}
      title={i18n._({ id: "lms.nickname.modal.title" })}
      idProvider={idProvider}
    />
  );
};

export const LMSNicknameModalView = ({
  welcomeText,
  description,
  idProvider,
  onSubmit,
  ...modalProps
}: LMSNicknameModalViewProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm<NicknameData>({
    mode: "onChange",
    defaultValues: { nickname: "", tacAccepted: false },
  });
  const auth = useAuth();
  const userId = auth.user?.id;
  const nickname = auth.user?.nickname;
  const role = auth.user?.type;

  // Watch the form values
  const formValues = watch();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    // Optionally set the value in the form manually
    setValue("tacAccepted", isChecked);
  };

  return (
    <>
      <form data-testid="nicknameForm">
        <Modal
          {...modalProps}
          className={styles.lmsNicknameModal}
          size="medium"
        >
          <div className={styles.lmsNicknameModal__text}>
            <p>{welcomeText}</p>
            {(nickname === userId || !nickname) && <p>{description}</p>}
          </div>

          {(nickname === userId || !nickname) && (
            <TextInput
              id="lms_nickname_modal"
              label={i18n._({ id: "lms.nickname.modal.input.label" })}
              placeholder={i18n._({
                id: "lms.nickname.modal.input.placeholder",
              })}
              {...register("nickname", {
                minLength: 2,
                maxLength: 35,
                required: true,
              })}
              errorMessage={
                errors.nickname &&
                i18n._({
                  id: "forms.validation.length.between",
                  values: { min: 2, max: 35 },
                })
              }
            />
          )}
          {role === Role.INSTRUCTOR && (
            <div className={styles.lmsNicknameModal__text}>
              <BlockContent className={styles.lmsNicknameModal__privacyPolicy}>
                <p>
                  <Trans
                    id="lms.nickname.modal.teacher.inform"
                    values={{
                      label: (
                        <a
                          className={styles.hyperlink}
                          target="_blank"
                          href={i18n._({
                            id: "lms.nickname.modal.teacher.inform.url",
                          })}
                          rel="noreferrer"
                        >
                          {i18n._({
                            id: "lms.nickname.modal.teacher.inform.label",
                          })}
                        </a>
                      ),
                      email: (
                        <EmailLink
                          email="lms.nickname.modal.teacher.inform.email"
                          label="lms.nickname.modal.teacher.inform.email"
                        />
                      ),
                    }}
                  />
                </p>
              </BlockContent>
            </div>
          )}

          <div className={styles.lmsNicknameModal__text}>
            <BlockContent className={styles.lmsNicknameModal__privacyPolicy}>
              <p>
                <Trans
                  id="lms.nickname.modal.privacy_policy"
                  values={{
                    data_protection_link: (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          auth.user?.language === Language.fr
                            ? "/fr/politique-de-protection-des-donnees-casio-education"
                            : "/datenschutz"
                        }
                      >
                        {i18n._({
                          id: "lms.nickname.modal.terms_and_conditions.data_protection",
                        })}
                      </a>
                    ),
                  }}
                />
              </p>
            </BlockContent>
            <BlockContent className={styles.lmsNicknameModal__privacyPolicy}>
              <p>
                <LabeledCheckbox
                  id="tacAccepted"
                  label={
                    <Trans
                      id="lms.nickname.modal.tacAccepted"
                      values={{
                        condition_link: (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                              auth.user?.language === Language.fr
                                ? "https://casio-education.eu/fr/conditions-de-service"
                                : "/nutzungsbedingungen"
                            }
                          >
                            {i18n._({
                              id: "lms.nickname.modal.condition_link",
                            })}
                          </a>
                        ),
                      }}
                    />
                  }
                  value={"tacAccepted"}
                  mode="small"
                  {...register("tacAccepted")}
                  onChange={handleCheckboxChange}
                />
              </p>
            </BlockContent>
          </div>
          <Button
            label={i18n._({ id: "lms.nickname.modal.button.submit" })}
            type="submit"
            disabled={!isValid || !formValues.tacAccepted}
            onClick={handleSubmit(onSubmit)}
          />
        </Modal>
      </form>
    </>
  );
};
