import { i18n } from "@lingui/core";
import React, { ReactNode } from "react";
import styles from "./PageHeader.module.scss";
import { classList } from "../../lib/helpers";
import { Color } from "../../types/Color";
import { ArrowLeft, Copy } from "react-feather";
import { Link } from "react-router-dom";
import { useToasts } from "../../provider/toast-provider";

export interface PageHeaderProps {
  actions?: React.ReactNode;
  className?: string;
  color?: Color;
  subtitle?: PageHeaderSubtitleProps;
  title: string;
  icon?: ReactNode;
  colorIcon?: ReactNode;
  secondary?: boolean;
  custom?: number; // pixel
  contextCode?: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  actions,
  className,
  color,
  subtitle,
  title,
  icon,
  colorIcon,
  secondary,
  custom,
  contextCode,
}) => {
  const toast = useToasts();
  const classes = classList(
    styles.pageHeader,
    secondary && styles["secondary"],
    className && className
  );
  const renderTitle = (): React.ReactNode => {
    // const titleRender = secondary ? <h2>{title}</h2> : <h1>{title}</h1>;
    if (secondary) return <h2>{title}</h2>;

    if (contextCode)
      return (
        <>
          <h1>{title}</h1>
          <div
            className={
              styles.pageHeader__heading__title__withContextCode__contextCode
            }
            onClick={() => {
              navigator.clipboard.writeText(contextCode);
              toast.addToast(i18n._({ id: "copied_to_clipboard" }), "success");
            }}
          >
            <h1>#{contextCode}</h1>
            <Copy />
          </div>
        </>
      );

    if (custom)
      return <p style={{ fontSize: custom, fontWeight: "bold" }}>{title}</p>;
    return <h1>{title}</h1>;
  };

  return (
    <div className={classes}>
      <div className={styles.pageHeader__heading}>
        {color && (
          <span
            role="img"
            className={classList(
              styles.pageHeader__heading__colorIndicator,
              styles[
                "pageHeader__heading__colorIndicator_" + color.toLowerCase()
              ]
            )}
          >
            {colorIcon && (
              <div className={styles.pageHeader__heading__colorIcon}>
                {colorIcon}
              </div>
            )}
          </span>
        )}
        {icon && (
          <div
            className={
              styles[
                `pageHeader__heading__icon${secondary ? "__secondary" : ""}`
              ]
            }
          >
            {" "}
            {icon}
          </div>
        )}
        <div
          className={
            styles[
              `pageHeader__heading__title${
                contextCode ? "__withContextCode" : ""
              }`
            ]
          }
        >
          {renderTitle()}
          {subtitle && <PageHeaderSubtitle {...subtitle} />}
        </div>
      </div>
      <div className={styles.pageHeader__actions}>{actions}</div>
    </div>
  );
};

export interface PageHeaderSubtitleProps {
  backLinkPath?: string;
  title: string;
}

const PageHeaderSubtitle: React.FC<PageHeaderSubtitleProps> = ({
  title,
  backLinkPath,
}) => {
  return backLinkPath ? (
    <Link to={backLinkPath}>
      <h2 className={styles.pageHeaderSubtitle}>
        <i>
          <ArrowLeft />
        </i>
        {title}
      </h2>
    </Link>
  ) : (
    <h2 className={styles.pageHeaderSubtitle}>{title}</h2>
  );
};
