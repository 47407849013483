import React, { PropsWithChildren } from "react";
import { classList } from "../../lib/helpers";
import styles from "./Toast.module.scss";
import { X, CheckCircle, AlertTriangle } from "react-feather";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import AnimateHeight from "react-animate-height";

export type ToastType = "success" | "error";

export interface ToastProps {
  id: number;
  type: ToastType;
  message?: string;
  time?: number;
  onDismiss?: () => void;
}

export interface ToastContainerProps {
  children?: React.ReactNode;
}

export const Toast = ({ message, type, onDismiss }: ToastProps) => {
  const classes = classList(styles.toast, styles["toast_" + type]);
  return (
    <div className={classes}>
      <div className={styles.toast__icon}>
        {type === "success" && <CheckCircle />}
        {type === "error" && <AlertTriangle />}
      </div>
      <p>{message}</p>
      <button className={styles.toast__close} onClick={onDismiss}>
        <X />
      </button>
    </div>
  );
};

export const ToastTransition = ({
  children,
  ...transitionProps
}: PropsWithChildren<CSSTransitionProps>) => {
  const [entered, setEntered] = React.useState(false);
  const nodeRef = React.useRef(null);

  return (
    <CSSTransition
      {...transitionProps}
      nodeRef={nodeRef} // this Ref to avoid warning: findDOMNode is deprecated in StrictMode.
      appear
      exit
      classNames={{
        appear: styles.transition_appear,
        appearActive: "",
        appearDone: styles.transition_appearDone,
        enter: styles.transition_enter,
        enterActive: "",
        enterDone: styles.transition_enterDone,
        exit: styles.transition_exit,
        exitActive: styles.transition_exitActive,
        exitDone: "",
      }}
      onEnter={() => setEntered(true)}
      onExit={() => setEntered(false)}
    >
      <div className={styles.toastTransition} ref={nodeRef}>
        <AnimateHeight duration={300} height={entered ? "auto" : 0}>
          {children}
        </AnimateHeight>
      </div>
    </CSSTransition>
  );
};

export const ToastContainer = ({ children }: ToastContainerProps) => {
  return <div className={styles.toastContainer}>{children}</div>;
};
