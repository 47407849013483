import {
  AdditionalLicenseType,
  ClasspadNetPlusAppLicense,
} from "../../types/AdditionalLicenseType";
import { Button } from "../Button/Button";
import { Grid, GridItem } from "../Grid/Grid";
import { TextInput } from "../TextInput/TextInput";
import {
  AppDownloadLink,
  LicenseEntryAddon,
  LicenseEntryAddonStep,
} from "./LicenseEntryAddon";
import { i18n } from "@lingui/core";
import {
  LicenseEntryAddonTutorial,
  LicenseEntryAddonTutorialStepProps,
} from "./LicenseEntryAddonTutorial";
import cpAppScreenshot01Src from "../../assets/images/tutorial/cp-app-screenshot-01.jpg";
import cpAppScreenshot02Src from "../../assets/images/tutorial/cp-app-screenshot-02-update.jpg";
import cpAppScreenshot03Src from "../../assets/images/tutorial/cp-app-screenshot-03-update.jpg";
import { LicenseEntryAddonHelpBox } from "./LicenseEntryAddonHelpBox";
import { AdminUserLicenseViewSelectedUserData } from "../UserManagement/LicenseDetail/AdminUserLicenseView/AdminUserLicenseView";
import styles from "./LicenseEntryAddon.module.scss";
import { formatUTCDate } from "../../utils/utils";
import { useMemo } from "react";

export const LicenseEntryAddonClasspadApp = (
  onClick: (additionalLicenseType: AdditionalLicenseType) => void,
  activeLicense?: ClasspadNetPlusAppLicense,
  selectedUserData?: AdminUserLicenseViewSelectedUserData,
  isRedeemFailed?: boolean,
  validUntil?: Date
) => {
  const cpAppTutorialSteps: LicenseEntryAddonTutorialStepProps[] = [
    {
      device: "mobile",
      position: "top",
      title: i18n._({
        id: "additional_licenses.classpad_app.step3.screenshot1",
      }),
      img: { src: cpAppScreenshot01Src, alt: "" },
    },
    {
      device: "mobile",
      position: "center",
      title: i18n._({
        id: "additional_licenses.classpad_app.step3.screenshot2",
      }),
      img: { src: cpAppScreenshot02Src, alt: "" },
    },
    {
      device: "mobile",
      position: "top",
      title: i18n._({
        id: `additional_licenses.classpad_app.step3.screenshot3`,
      }),
      img: { src: cpAppScreenshot03Src, alt: "" },
    },
  ];
  const disableRedeemButton = useMemo(() => {
    if (selectedUserData) {
      // check if admin view
      return false;
    } else {
      return isRedeemFailed;
    }
  }, [isRedeemFailed, selectedUserData]);

  return (
    <LicenseEntryAddon
      title={i18n._({
        id: `additional_licenses.name.classpad_app`,
      })}
      description={i18n._({
        id: `additional_licenses.description.classpad_app`,
      })}
    >
      <LicenseEntryAddonStep
        number={1}
        title={i18n._({
          id: `additional_licenses.classpad_app.step1.title`,
        })}
      >
        {activeLicense ? (
          <>
            <Grid>
              <GridItem width={"1/2"}>
                <TextInput
                  id="email"
                  label={i18n._({
                    id: `additional_licenses.classpad_app.email`,
                  })}
                  value={activeLicense.email}
                  readOnly
                ></TextInput>
              </GridItem>
              <GridItem width={"1/2"}>
                <TextInput
                  id="password"
                  label={i18n._({
                    id: `additional_licenses.classpad_app.password`,
                  })}
                  value={activeLicense.password}
                  readOnly
                ></TextInput>
              </GridItem>
            </Grid>

            {validUntil ? (
              <p className={styles.validUntilAdditionalLicenseText}>
                {i18n._({
                  id: `additional_licenses.valid.until`,
                })}
                &nbsp;
                {formatUTCDate(validUntil as unknown as string, "dd/mm/yyyy")}
              </p>
            ) : null}
          </>
        ) : (
          <Button
            label={i18n._({
              id: `additional_licenses.classpad_app.step1.button`,
            })}
            disabled={disableRedeemButton}
            onClick={() => {
              if (selectedUserData) {
                return;
              }
              onClick(AdditionalLicenseType.CLASSPAD_PLUS_APP);
            }}
          />
        )}
      </LicenseEntryAddonStep>

      <LicenseEntryAddonStep
        number={2}
        title={i18n._({
          id: `additional_licenses.classpad_app.step2.title`,
        })}
      >
        <AppDownloadLink
          href={i18n._({
            id: "additional_licenses.classpad_app.step2.download_link.apple",
          })}
          store="Apple"
          lang="de"
        />
        <AppDownloadLink
          href={i18n._({
            id: "additional_licenses.classpad_app.step2.download_link.google",
          })}
          store="Google"
          lang="de"
        />
      </LicenseEntryAddonStep>
      <LicenseEntryAddonStep
        number={3}
        title={i18n._({
          id: "additional_licenses.classpad_app.step3.title",
        })}
      >
        <LicenseEntryAddonTutorial steps={cpAppTutorialSteps} />
      </LicenseEntryAddonStep>
      <LicenseEntryAddonHelpBox
        title={i18n._({
          id: "additional_licenses.classpad_app.help_box.title",
        })}
        description={i18n._({
          id: "additional_licenses.classpad_app.help_box.description",
        })}
        email={i18n._({
          id: "additional_licenses.classpad_app.help_box.email",
        })}
      />
    </LicenseEntryAddon>
  );
};
