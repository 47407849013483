import {
  License,
  licenseFromPlain,
  PlainAdditionalLicense,
  PlainLicense,
  additionalLicenseFromPlain,
  AdditionalLicense,
} from "../types/License";
import axios from "axios";
import { buildSearchParams } from "../utils/utils";
import {
  FetchSortedAndPagedLicensesResponse,
  Status,
} from "../components/UserManagement/LicenseDetail/LicenseDetail";
import { CentrializedLicenseDetail } from "../types/CentrializedLicenseDetail";
import {
  CentralizedLicenseStatus,
  CentralizedLicenseType,
} from "../types/CentralizedLicenseType";
import { Product } from "../types/Product";
import { SchoolLicense } from "../components/UserManagement/UserDetail/UserDetail";
import { AdditionalLicenseType } from "../types/AdditionalLicenseType";
import { Country } from "../types/Country";

const urlV2 = "internal/v2/users";

interface GetLicensesParam {
  userId: string;
  apiBaseUrl?: string;
}

export interface SearchLicensesCondition {
  license?: { value: string; label: CentrializedLicenseDetail; data: string }[];
  useDateFrom?: string;
  useDateTo?: string;
  type?: {
    value: CentralizedLicenseType;
    label: string;
    data: CentralizedLicenseType;
  }[];
  startDateFrom?: string;
  startDateTo?: string;
  status?: { value: Status; label: string; data: Status }[];
  endDateFrom?: string;
  endDateTo?: string;
  product?: { data: Product; label: string; value: Product }[];
}

enum StopReason {
  REFUND = "REFUND",
  TEACHER_LEAVE_CLASS = "TEACHER_LEAVE_CLASS",
  STUDENT_LEAVE_CLASS = "STUDENT_LEAVE_CLASS",
  SUSPENDED_BY_TEACHER = "SUSPENDED_BY_TEACHER",
  SCHOOL_LICENSE_EXPIRED = "SCHOOL_LICENSE_EXPIRED",
  SUSPENDED_BY_LMS = "SUSPENDED_BY_LMS",
}

export interface FetchSortedAndPagedLicenses {
  id: string;
  userId: string;
  name: string | null;
  product: Product;
  type: CentralizedLicenseType;
  promotional: boolean | null;
  useDate: Date | null;
  startDate: Date;
  endDate: Date;
  status: "Active" | "Expired" | "Returned";
  stopDate: Date | null;
  stopReason: StopReason | null;
  classId: string | null;
  teacherId: string | null;
  schoolId: string | null;
  schoolLicenseId: string | null;
  purchaseId: string | null;
  redeemId: string | null;
  detail: string | null;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface GetLicenseDetailResponse {
  detail: string;
  name: string;
  type: CentralizedLicenseType;
  startDate: Date;
  status: CentralizedLicenseStatus;
  purchaseId?: string;
  schoolLicenseId?: string;
  schoolId?: string;
  schoolName: string;
  teacherId?: string;
  teacherNickname?: string;
  recordId: string;
  useDate?: Date;
  endDate: Date;
  product: Product;
  redeemId?: string;
  classId?: string;
  className?: string;
  stopDate?: Date;
  stopReason?: string;
}

export interface CountValidAdditionalLicensesResponse {
  countValidCPAppLicense: number;
  countValidCPManagerLicense: number;
}

export interface SchoolLicenseInLicenseDetail extends SchoolLicense {
  school: {
    name: string;
  };
  status: string;
  noUsers: number;
}

export interface AddLicenseCodeParams {
  product?: {
    products: Product[];
    isPromotional: boolean;
    duration: number;
    licenseName: string;
  };
  expiry?: Date;
  email: string | undefined;
  country: Country | undefined;
}

export const getLicensesV2 = async ({
  userId,
  apiBaseUrl = "/app/api",
}: GetLicensesParam): Promise<License[]> => {
  const response = await axios.get<PlainLicense[]>(
    `${apiBaseUrl}/${urlV2}/${userId}/licenses`
  );

  return response.data.map((plain) => licenseFromPlain(plain));
};

export const getUserAdditionalLicense = async ({
  userId,
  apiBaseUrl = "/app/api",
}: GetLicensesParam): Promise<AdditionalLicense[]> => {
  const response = await axios.get<PlainAdditionalLicense[]>(
    `${apiBaseUrl}/${urlV2}/${userId}/additional-licenses`
  );

  return response.data.map((additionalLicense) =>
    additionalLicenseFromPlain(additionalLicense)
  );
};

export interface SearchLicensesData {
  licenses?: string[];
  useDateFrom?: string;
  useDateTo?: string;
  type?: CentralizedLicenseType[];
  startDateFrom?: string;
  startDateTo?: string;
  status?: Status[];
  endDateFrom?: string;
  endDateTo?: string;
  product?: Product[];
}

export const adminGetAndSearchLicenses = async (
  {
    page,
    limit,
    sortOrder,
    sortBy,
  }: {
    page: number;
    limit: number;
    sortOrder?: "asc" | "desc";
    sortBy?: string;
  },
  userId: string | undefined,
  search: SearchLicensesCondition,
  apiBaseUrl = "/app/api"
): Promise<FetchSortedAndPagedLicensesResponse> => {
  try {
    if (!page) {
      throw new Error("page is required");
    }

    if (!limit) {
      throw new Error("limit is required");
    }

    let queryUrl = `${apiBaseUrl}/${urlV2}/${userId}/admin/search/licenses?page=${page}&limit=${limit}`;

    if (sortOrder) {
      queryUrl += `&sortOrder=${sortOrder}`;
    }

    if (sortBy) {
      queryUrl += `&sortBy=${sortBy}`;
    }

    let searchCondition: SearchLicensesData = {};

    if (search.license && search.license.length > 0) {
      searchCondition.licenses = search.license.map((item) => item.data);
    }

    if (search.useDateFrom) {
      searchCondition.useDateFrom = search.useDateFrom;
    }

    if (search.useDateTo) {
      searchCondition.useDateTo = search.useDateTo;
    }

    if (search.type && search.type.length > 0) {
      searchCondition.type = search.type.map((item) => item.data);
    }

    if (search.startDateFrom) {
      searchCondition.startDateFrom = search.startDateFrom;
    }

    if (search.startDateTo) {
      searchCondition.startDateTo = search.startDateTo;
    }

    if (search.status && search.status.length > 0) {
      searchCondition.status = search.status.map((item) => item.data);
    }

    if (search.endDateFrom) {
      searchCondition.endDateFrom = search.endDateFrom;
    }

    if (search.endDateTo) {
      searchCondition.endDateTo = search.endDateTo;
    }

    if (search.product && search.product.length > 0) {
      searchCondition.product = search.product.map((item) => item.data);
    }

    const searchParams = buildSearchParams(searchCondition);
    const endpoint = queryUrl + searchParams;

    const licenses = await axios.get<FetchSortedAndPagedLicensesResponse>(
      endpoint
    );

    return licenses.data;
  } catch (err) {
    console.error(`Error in get and search licenses:${err}`);
    throw new Error(`get.admin.get.and.search.license.error: ${err}`);
  }
};

export const adminGetLicenseDetail = async (
  licenseId: string,
  apiBaseUrl = "/app/api"
): Promise<GetLicenseDetailResponse> => {
  const license = await axios.get<GetLicenseDetailResponse>(
    `${apiBaseUrl}/${urlV2}/admin/detail/license/${licenseId}`
  );

  console.log("license", license);
  return license.data;
};

export const getSchoolLicenseListByUser = async (
  userId?: string,
  apiBaseUrl = "/app/api"
): Promise<SchoolLicenseInLicenseDetail[]> => {
  try {
    const response = await axios.get<SchoolLicenseInLicenseDetail[]>(
      `${apiBaseUrl}/${urlV2}/${userId}/admin/school-licenses`
    );

    return response.data;
  } catch (err) {
    throw new Error(`error.get.school.license.list.by.user: ${err}`);
  }
};

export const adminCountValidAdditionalLicenses = async (
  apiBaseUrl = "/app/api"
): Promise<CountValidAdditionalLicensesResponse> => {
  try {
    const response = await axios.get<CountValidAdditionalLicensesResponse>(
      `${apiBaseUrl}/${urlV2}/admin/count-valid-additional-licenses`
    );

    return response.data;
  } catch (err) {
    throw new Error(`error.admin.count.valid.additional.licenses: ${err}`);
  }
};

export const adminAddReplaceAdditionalLicense = async (
  userId: string | undefined,
  email: string | undefined,
  country: Country | undefined,
  additionalLicenseType: AdditionalLicenseType[],
  apiBaseUrl = "/app/api"
): Promise<void> => {
  try {
    if (!userId) {
      return;
    }
    await axios.post<void>(
      `${apiBaseUrl}/${urlV2}/${userId}/admin/add-replace-additional-license`,
      {
        email,
        country,
        additionalLicenseType,
      }
    );
  } catch (err) {
    throw new Error(`error.admin.add.replace.additional.license: ${err}`);
  }
};

export const adminAddLicenseCode = async (
  userId: string | undefined,
  data: AddLicenseCodeParams,
  apiBaseUrl = "/app/api"
): Promise<void> => {
  try {
    if (!userId) {
      return;
    }

    await axios.post<void>(
      `${apiBaseUrl}/${urlV2}/${userId}/admin/add-license-code`,
      data
    );
  } catch (err) {
    throw new Error(`error.admin.add.license.code: ${err}`);
  }
};
