import { Route, Routes } from "react-router-dom";
import { FAQ } from "./FAQ";

export const FAQRouter = () => {
  return (
    <Routes>
      <Route index element={<FAQ />}></Route>
    </Routes>
  );
};
