import { Modal } from "../../Modal/Modal";
import { ClassList } from "./ClassList";
import { Button } from "../../Button/Button";
import "./AddStudentToClassModal.scss";
import { useEffect, useState } from "react";
import { Spinner } from "../../Spinner/Spinner";
import { i18n } from "@lingui/core";
import { InformingModal } from "./InformingModal";

export const AddStudentToClassModal = ({
  isShowing,
  setVisible,
  teacherId,
  checkedList,
  setCheckedList,
  onAddStudentToClass,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  teacherId: string;
  checkedList: string[];
  setCheckedList: (checkedList: string[]) => void;
  onAddStudentToClass: () => void;
}) => {
  const [isShowInformingModal, setIsShowInformingModal] = useState(false);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setCheckedList([]);
  }, [setCheckedList]);

  const handleAddStudentToClass = async () => {
    try {
      setIsShowSpinner(true);
      await onAddStudentToClass();
      setIsShowInformingModal(true);
    } catch (err) {
      console.log(err);
      setIsError(true);
      setIsShowInformingModal(true);
    } finally {
      setIsShowSpinner(false);
    }
  };

  const handleClose = () => {
    setIsShowInformingModal(false);
    setVisible(false);
  };

  return (
    <Modal
      className="container"
      title={i18n._({
        id: "student_list.student.add_student_to_class_modal.title",
      })}
      isShowing={isShowing}
      closeButton={true}
      onClose={() => {
        setVisible(false);
      }}
      size="large"
    >
      <p className="headline">
        {i18n._({
          id: "student_list.student.add_student_to_class_modal.headline",
        })}
      </p>

      <ClassList
        teacherId={teacherId}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
      />

      <div className="btn">
        <Button
          label={i18n._({
            id: "student_list.student.add_student_to_class_modal.button.confirm",
          })}
          type="submit"
          onClick={handleAddStudentToClass}
          disabled={checkedList.length === 0}
        />
        <Button
          label={i18n._({
            id: "student_list.student.add_student_to_class_modal.button.cancel",
          })}
          btnStyle="secondary"
          onClick={() => setVisible(false)}
        />
      </div>
      {isShowInformingModal && (
        <InformingModal
          isShowing={isShowInformingModal}
          setVisible={handleClose}
          isError={isError}
        ></InformingModal>
      )}
      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </Modal>
  );
};
