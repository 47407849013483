import { useCallback, useEffect, useRef, useState } from "react";

import styles from "./TeacherVerification.module.scss";
import { FrenchInstructorRole, Role } from "../../types/Role";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { PageHeader } from "../PageHeader/PageHeader";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { Grid, GridItem } from "../Grid/Grid";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import { Country } from "../../types/Country";
import { IdProvider } from "../../types/IdProvider";
import {
  DataWithPagination,
  SortOptions,
  TeacherVerificationStatus,
} from "../../types/User";
import { TextInput } from "../TextInput/TextInput";
import { DatepickerField } from "../DatepickerField/DatepickerField";
import { SchoolSelect } from "../SchoolSelect/SchoolSelect";
import {
  querySchools,
  querySchoolsWithNoCountryFilter,
} from "../../api/school";
import { Button } from "../Button/Button";
import { Table } from "../Table/Table";
import { Column, SelectColumn, SortColumn } from "react-data-grid";
import {
  SearchTeacherVerificationCondition,
  TeacherVerificationWithUserAndSchool,
  adminApproveTeacherVerificationRequests,
  searchTeacherVerification,
} from "../../api/teacher-verification";
import Pagination from "../Paginate/Pagination";
import { isEmpty } from "lodash";
import { Spinner } from "../Spinner/Spinner";
import { i18n } from "@lingui/core";
import { NoRecordFoundModal } from "../UserManagement/NoRecordFoundModal/NoRecordFoundModal";
import {
  convertFrenchInstructorRole,
  dateWithoutTimezone,
  formatUTCDate,
} from "../../utils/utils";
import VerificationRequestDetailModal from "./VerificationRequestDetailModal/VerificationRequestDetailModal";
import { ConfirmationModal } from "../Admin/ConfirmationModal/ConfirmationModal";
import { ProcessFailedModal } from "../Admin/ProcessFailedModal/ProcessFailedModal";
import { AnnouncementModal } from "../Admin/AnnouncementModal/AnnouncementModal";

const columns: readonly Column<Row>[] = [
  {
    ...SelectColumn,
  },
  {
    key: "id",
    name: "UserID",
  },
  {
    key: "requestId",
    name: "RequestID",
  },
  {
    key: "email",
    name: "Email",
  },
  {
    key: "role",
    name: "Role",
    sortable: false,
  },
  {
    key: "status",
    name: "Request Status",
  },
  {
    key: "submittedDate",
    name: "Submitted Date",
  },
  {
    key: "nickname",
    name: "Nickname",
  },
  {
    key: "firstname",
    name: "Firstname",
  },
  {
    key: "lastname",
    name: "Lastname",
  },
  {
    key: "country",
    name: "Country",
  },
  {
    key: "idProvider",
    name: "IDProvider",
  },
  {
    key: "school",
    name: "School",
  },
  {
    key: "schoolCode",
    name: "School Code",
  },
];

interface Row {
  id: string;
  requestId: string;
  email: string | undefined;
  status: TeacherVerificationStatus;
  submittedDate: string;
  nickname: string | undefined;
  firstname: string | undefined;
  lastname: string | undefined;
  country: Country;
  idProvider: IdProvider;
  school: string;
  note?: string;
  role: string;
  schoolCode: string;
}

function rowKeyGetter(row: Row): any {
  return row.id;
}

const initialValues = {
  country: [],
  idProvider: [],
  status: [],
  userId: "",
  firstname: "",
  lastname: "",
  requestId: "",
  nickname: "",
  email: "",
  submittedDateFrom: undefined,
  submittedDateTo: undefined,
  role: [],
  schoolCode: "",
  schools: {
    value: "",
    label: "",
    school: {
      id: "",
      name: "",
      address: "",
      addressAddition: "",
      zip: "",
      city: "",
      addressCountry: "",
      country: Country.DE,
      type: "",
      schoolId: "",
      salesforceId: "",
    },
  },
};

const TeacherVerification = () => {
  const [rows, setRows] = useState<Row[]>([]);

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    page: 1,
    limit: 10,
    sortBy: "submittedDate",
    sortOrder: "desc",
  });

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<string> => new Set()
  );
  const [chosenUserIds, setChosenUserIds] = useState<string[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<Row>();

  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchCondition, setSearchCondition] =
    useState<SearchTeacherVerificationCondition>({});
  const [forcePage, setForcePage] = useState<number>();

  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const [isShowNoRecordFoundModal, setIsShowNoRecordFoundModal] =
    useState<boolean>(false);

  const [isShowVerificationRequestModal, setIsShowVerificationRequestModal] =
    useState<boolean>(false);
  const [isShowApproveConfirm, setIsShowApproveConfirm] =
    useState<boolean>(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState<boolean>(false);

  const [disableSearchBtn, setIsDisableSearchBtn] = useState<boolean>(false);
  const [disableApproveBtn, setDisableApproveBtn] = useState<boolean>(false);
  const [isSelectedApprovedRequest, setIsSelectedApprovedRequest] =
    useState<boolean>(false);

  // error message
  const [errorMessageSubmittedDate, setErrorMessageSubmittedDate] =
    useState<string>();

  const [isShowApproveSuccessModal, setIsShowApproveSuccessModal] =
    useState<boolean>(false);

  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const setRowsFromResponse = useCallback(
    (response: TeacherVerificationWithUserAndSchool[]) => {
      let rows: Row[] = [];

      for (let data of response) {
        rows.push({
          id: data.userId,
          requestId: data.id,
          email: data.user.email,
          status: data.status,
          submittedDate:
            formatUTCDate(new Date(data.submittedDate).toUTCString()) || "",
          nickname: data.user.nickname,
          firstname: data.user.firstname,
          lastname: data.user.lastname,
          country: data.user.country,
          idProvider: data.user.idProvider,
          school: data.user.school.name,
          note: data.note,
          role: convertFrenchInstructorRole(data.user.metadata.role),
          schoolCode: data.user.school.schoolId,
        });
      }
      setRows(rows);
    },
    []
  );
  const fetchTeacherVerifications = useCallback(
    async (
      sortOptions: SortOptions,
      searchCondition: SearchTeacherVerificationCondition
    ) => {
      setIsShowSpinner(true);
      const responses:
        | DataWithPagination<TeacherVerificationWithUserAndSchool>
        | undefined = await searchTeacherVerification(
        sortOptions,
        searchCondition
      );

      if (responses?.paginateData && responses.paginateData.length > 0) {
        setRowsFromResponse(responses.paginateData);
        setTotalCount(responses.totalCount);
      }

      setIsShowSpinner(false);
    },
    [setRowsFromResponse]
  );

  const onSortColumns = useCallback(
    (
      sortColumns: SortColumn[],
      searchCondition: SearchTeacherVerificationCondition
    ) => {
      let sortOptionsUpdate = { ...sortOptions };

      if (!isEmpty(sortColumns)) {
        sortOptionsUpdate.sortBy = sortColumns[0].columnKey;
        sortOptionsUpdate.sortOrder = sortColumns[0].direction.toLowerCase() as
          | "asc"
          | "desc";
      }
      setSortOptions(sortOptionsUpdate);

      fetchTeacherVerifications(sortOptionsUpdate, searchCondition);
      setSortColumns(sortColumns);
    },
    [fetchTeacherVerifications, sortOptions]
  );

  useEffect(() => {
    if (chosenUserIds.length > 0 && isSelectedApprovedRequest) {
      setDisableApproveBtn(true);
    } else if (chosenUserIds.length === 0) {
      setDisableApproveBtn(true);
    } else {
      setDisableApproveBtn(false);
    }
  }, [chosenUserIds.length, isSelectedApprovedRequest]);

  return (
    <RestrictedTo roles={[Role.ADMINISTRATOR]} redirects={true}>
      <div className={styles.container}>
        <PageHeader title="Teacher Profile Verification" />

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={(values) => {
            let isSubmittedDateValid: boolean = true;

            if (
              values.submittedDateFrom &&
              values.submittedDateTo &&
              new Date(values.submittedDateFrom).getTime() >
                new Date(values.submittedDateTo).getTime()
            ) {
              setErrorMessageSubmittedDate("Invalid Date");
              setIsDisableSearchBtn(true);

              isSubmittedDateValid = false;
            }

            if (isSubmittedDateValid && errorMessageSubmittedDate) {
              setErrorMessageSubmittedDate(undefined);
            }

            if (isSubmittedDateValid) {
              setIsDisableSearchBtn(false);
            }
          }}
          onSubmit={async (values) => {
            console.log("onSubmit values", values);
            setIsShowSpinner(true);
            const searchParams: SearchTeacherVerificationCondition = {
              country: values.country && values.country,
              idProvider: values.idProvider && values.idProvider,
              status: values.status && values.status,
              userId: values.userId && values.userId,
              firstName: values.firstname && values.firstname,
              lastName: values.lastname && values.lastname,
              requestId: values.requestId && values.requestId,
              nickname: values.nickname && values.nickname,
              email: values.email && values.email,
              submittedDateFrom: values.submittedDateFrom
                ? dateWithoutTimezone(values.submittedDateFrom)
                : undefined,
              submittedDateTo: values.submittedDateTo
                ? dateWithoutTimezone(values.submittedDateTo).replace(
                    "00:00:00",
                    "23:59:59"
                  )
                : undefined,
              schoolId: values.schools && values.schools.school?.id,
              role: values.role,
              schoolCode: values.schoolCode && String(values.schoolCode),
            };
            console.log("searchParams", searchParams);

            const sortOptionsUpdate = { ...sortOptions };
            sortOptionsUpdate.page = 1;

            const response = await searchTeacherVerification(
              sortOptionsUpdate,
              searchParams
            );

            if (response?.paginateData && response.paginateData.length === 0) {
              setIsShowNoRecordFoundModal(true);
            }

            setRowsFromResponse(response.paginateData);
            setTotalCount(response.totalCount);

            setSearchCondition(searchParams);

            // This condition to force component Pagination re-render.
            setForcePage((prev) => (prev === 0 ? undefined : 0));

            setSelectedRows(new Set());
            setIsShowSpinner(false);
          }}
          innerRef={formikRef}
        >
          {({ handleChange, values, setFieldValue, resetForm }) => (
            <Form>
              <div className={styles.container__searchFieldsContainer}>
                <div className={styles.row}>
                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="country"
                        options={[
                          {
                            value: Country.DE,
                            label: "DE",
                            data: Country.DE,
                          },
                          {
                            value: Country.FR,
                            label: "FR",
                            data: Country.FR,
                          },
                        ]}
                        label="Country"
                        value={values.country}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <MultiSelect
                        id="idProvider"
                        options={[
                          {
                            value: IdProvider.CLASSPAD,
                            label: "CPA",
                            data: IdProvider.CLASSPAD,
                          },
                          {
                            value: IdProvider.ISERV,
                            label: "Iserv",
                            data: IdProvider.ISERV,
                          },
                          {
                            value: IdProvider.MOODLE,
                            label: "Moodle",
                            data: IdProvider.MOODLE,
                          },
                          {
                            value: IdProvider.MIL,
                            label: "MIL",
                            data: IdProvider.MIL,
                          },
                          {
                            value: IdProvider.GAR,
                            label: "GAR",
                            data: IdProvider.GAR,
                          },
                        ]}
                        label="IDProvider"
                        value={values.idProvider}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <MultiSelect
                        id="status"
                        options={[
                          {
                            value: TeacherVerificationStatus.APPROVED,
                            label: "Approved",
                            data: TeacherVerificationStatus.APPROVED,
                          },
                          {
                            value: TeacherVerificationStatus.PENDING,
                            label: "Pending",
                            data: TeacherVerificationStatus.PENDING,
                          },
                          {
                            value: TeacherVerificationStatus.REJECTED,
                            label: "Rejected",
                            data: TeacherVerificationStatus.REJECTED,
                          },
                        ]}
                        label="Status"
                        value={values.status}
                      />
                    </GridItem>
                  </Grid>
                </div>

                <div className={styles.row}>
                  <Grid>
                    <GridItem width="1/3">
                      <TextInput
                        id="userId"
                        label="UserId"
                        onChange={handleChange}
                        value={values.userId}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <TextInput
                        id="firstname"
                        label="First Name"
                        onChange={handleChange}
                        value={values.firstname}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <TextInput
                        id="lastname"
                        label="Last Name"
                        onChange={handleChange}
                        value={values.lastname}
                      />
                    </GridItem>
                  </Grid>
                </div>

                <div className={styles.row}>
                  <Grid>
                    <GridItem width="1/3">
                      <TextInput
                        id="requestId"
                        label="Request ID"
                        onChange={handleChange}
                        value={values.requestId}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <TextInput
                        id="nickname"
                        label="Nick Name"
                        onChange={handleChange}
                        value={values.nickname}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <TextInput
                        id="email"
                        label="Email"
                        onChange={handleChange}
                        value={values.email}
                      />
                    </GridItem>
                  </Grid>
                </div>

                <div className={styles.row}>
                  <Grid>
                    <GridItem width="1/3">
                      <DatepickerField
                        label="Submitted Date From"
                        placeholderLabel="dd/mm/yyyy"
                        id="=submittedDateFrom"
                        name="submittedDateFrom"
                        errorMessage={errorMessageSubmittedDate}
                        value={values.submittedDateFrom}
                        locale="en"
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <DatepickerField
                        label="Submitted Date To"
                        placeholderLabel="dd/mm/yyyy"
                        id="submittedDateTo"
                        name="submittedDateTo"
                        errorMessage={errorMessageSubmittedDate}
                        value={values.submittedDateTo}
                        locale="en"
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <MultiSelect
                        id="role"
                        options={[
                          {
                            value: FrenchInstructorRole.INSTRUCTOR,
                            label: "Teacher",
                            data: FrenchInstructorRole.INSTRUCTOR,
                          },
                          {
                            value: FrenchInstructorRole.SCHOOL_ADMIN,
                            label: "School Admin",
                            data: FrenchInstructorRole.SCHOOL_ADMIN,
                          },
                          {
                            value: FrenchInstructorRole.SCHOOL_ASSOCIATION,
                            label: "School Association",
                            data: FrenchInstructorRole.SCHOOL_ASSOCIATION,
                          },
                          {
                            value: FrenchInstructorRole.OTHER,
                            label: "Other",
                            data: FrenchInstructorRole.OTHER,
                          },
                        ]}
                        label="Role"
                        value={values.role}
                      />
                    </GridItem>
                  </Grid>

                  <Grid>
                    <GridItem width="1/3">
                      <SchoolSelect
                        id="schools"
                        label="School"
                        value={values.schools}
                        onChange={(school) => {
                          setFieldValue("schools", school);
                        }}
                        onBlur={() => {}}
                        querySchools={querySchools}
                        addManualSchool={false}
                        setAddManualSchool={() => {}}
                        isBroadcast={false}
                        errorMessage={""}
                        locale={"de"}
                        disablePlaceholder={false}
                        onlyEnglishLang={true}
                        disableEnterSchoolManually={true}
                        noLocale={true}
                        querySchoolsWithNoCountryFilter={
                          querySchoolsWithNoCountryFilter
                        }
                      ></SchoolSelect>
                    </GridItem>

                    <GridItem width="1/3">
                      <TextInput
                        id="schoolCode"
                        label="School Code"
                        onChange={handleChange}
                        value={values.schoolCode}
                      />
                    </GridItem>
                  </Grid>
                </div>
              </div>

              <div className={styles.container__btnGroup}>
                <Grid>
                  <GridItem
                    width="1/3"
                    className={styles.container__btnGroup__groupOne}
                  >
                    <Button
                      label="View"
                      disabled={
                        chosenUserIds.length === 0 || chosenUserIds.length > 1
                      }
                      onClick={() => {
                        setIsShowVerificationRequestModal(true);
                      }}
                    ></Button>
                    <Button
                      label="Approve"
                      onClick={async () => {
                        setIsShowApproveConfirm(true);
                      }}
                      // disabled={chosenUserIds.length === 0}
                      disabled={disableApproveBtn}
                    ></Button>
                  </GridItem>

                  {/* <div className={styles.container__btnGroup__groupTwo}> */}
                  <GridItem width="1/3">
                    <></>
                  </GridItem>
                  {/* </div> */}

                  <GridItem
                    width="1/3"
                    className={styles.container__btnGroup__groupThree}
                  >
                    <Button
                      label="Clear"
                      btnStyle="secondary"
                      onClick={() => {
                        resetForm(initialValues);
                        if (disableSearchBtn) {
                          setIsDisableSearchBtn(false);
                        }

                        if (errorMessageSubmittedDate) {
                          setErrorMessageSubmittedDate(undefined);
                        }
                      }}
                    ></Button>

                    <Button
                      label="Search"
                      type="submit"
                      disabled={disableSearchBtn}
                    ></Button>
                  </GridItem>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>

        <div className={styles.container__tableContainer}>
          <Table
            columns={columns}
            rows={rows}
            onRowsChange={setRows}
            sortColumns={sortColumns}
            onSortColumnsChange={(sortColumns) => {
              onSortColumns(sortColumns, searchCondition);
            }}
            rowKeyGetter={rowKeyGetter}
            selectedRows={selectedRows}
            onSelectedRowsChange={(selectedRow, selectedData: Row[]) => {
              setSelectedRows(selectedRow);
              setChosenUserIds(Array.from(selectedRow));
              setSelectedRequest(selectedData[0]);

              let isIncludeApprove = false;
              for (let data of selectedData) {
                if (data.status === TeacherVerificationStatus.APPROVED) {
                  isIncludeApprove = true;
                  break;
                }
              }

              if (isIncludeApprove) {
                setIsSelectedApprovedRequest(true);
              } else {
                setIsSelectedApprovedRequest(false);
              }
            }}
            onCellClick={(event) => {
              if (event.column.key !== "select-row") {
                setIsShowVerificationRequestModal(true);
                setSelectedRequest(event.row);
              }
            }}
          />

          {rows.length > 0 && (
            <Pagination
              items={rows}
              totalCount={totalCount}
              forcePage={forcePage}
              getItemsPerPage={async (itemsPerPage: number) => {
                // limit
                let sortOptionsUpdate = { ...sortOptions };

                sortOptionsUpdate.limit = itemsPerPage;
                sortOptionsUpdate.page = 1;
                setSortOptions(sortOptionsUpdate);

                await fetchTeacherVerifications(
                  sortOptionsUpdate,
                  searchCondition
                );

                setForcePage((prev) => (prev === 0 ? undefined : 0));
              }}
              getPageNumber={async (pageNumber: number) => {
                // page
                let sortOptionsUpdate = { ...sortOptions };

                sortOptionsUpdate.page = pageNumber;
                setSortOptions(sortOptionsUpdate);

                await fetchTeacherVerifications(
                  sortOptionsUpdate,
                  searchCondition
                );
              }}
            />
          )}

          {isShowNoRecordFoundModal && (
            <NoRecordFoundModal
              isShowing={isShowNoRecordFoundModal}
              setVisible={setIsShowNoRecordFoundModal}
            />
          )}

          {isShowVerificationRequestModal && (
            <VerificationRequestDetailModal
              isShowing={isShowVerificationRequestModal}
              onConfirm={() => {}}
              onCancel={() => {
                setIsShowVerificationRequestModal(false);
              }}
              selectedRequest={selectedRequest}
              fetchTeacherVerifications={fetchTeacherVerifications}
              teacherVerificationFormikRef={formikRef}
            />
          )}

          {isShowApproveConfirm && (
            <ConfirmationModal
              title="Confirm Approve Request"
              isShowing={isShowApproveConfirm}
              onConfirm={async () => {
                try {
                  setIsShowSpinner(true);
                  await adminApproveTeacherVerificationRequests(chosenUserIds);

                  await fetchTeacherVerifications(
                    {
                      page: 1,
                      limit: 10,
                      sortBy: "submittedDate",
                      sortOrder: "desc",
                    },
                    {}
                  );
                  setIsShowApproveSuccessModal(true);
                } catch (error) {
                  setIsShowSpinner(false);
                  setIsShowErrorModal(true);
                } finally {
                  setIsShowSpinner(false);
                  setIsShowApproveConfirm(false);
                }
              }}
              onCancel={() => {
                setIsShowApproveConfirm(false);
              }}
            >
              <p style={{ fontSize: 15 }}>
                Are you sure you would like to approve the teacher verification
                request(s)?
              </p>
            </ConfirmationModal>
          )}

          <ProcessFailedModal
            isShowing={isShowErrorModal}
            setVisible={() => {
              setIsShowErrorModal(false);
            }}
          />

          <AnnouncementModal
            title="Approve Request Successfully"
            isShowing={isShowApproveSuccessModal}
            setVisible={setIsShowApproveSuccessModal}
          >
            <div className={styles.announcementText}>
              Teacher Verification Request(s) has been approved!
            </div>
          </AnnouncementModal>

          <Spinner
            type="fullPage"
            title={i18n._({ id: "loading" })}
            visible={isShowSpinner}
          />
        </div>
      </div>
    </RestrictedTo>
  );
};

export default TeacherVerification;
