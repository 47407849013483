import {
  AdditionalLicenseType,
  ClasspadNetPlusManagerLicense,
} from "../../types/AdditionalLicenseType";
import {
  DesktopAppDownloadLink,
  LicenseEntryAddon,
  LicenseEntryAddonStep,
} from "./LicenseEntryAddon";
import { i18n } from "@lingui/core";
import { TextInput } from "../TextInput/TextInput";
import { Button } from "../Button/Button";
import cpManagerScreenshot01Src from "../../assets/images/tutorial/cp-manager-screenshot-01.jpg";
import cpManagerScreenshot02Src from "../../assets/images/tutorial/cp-manager-screenshot-02.jpg";
import cpManagerScreenshot03Src from "../../assets/images/tutorial/cp-manager-screenshot-03.jpg";
import {
  LicenseEntryAddonTutorial,
  LicenseEntryAddonTutorialStepProps,
} from "./LicenseEntryAddonTutorial";
import { LicenseEntryAddonHelpBox } from "./LicenseEntryAddonHelpBox";
import { AdminUserLicenseViewSelectedUserData } from "../UserManagement/LicenseDetail/AdminUserLicenseView/AdminUserLicenseView";
import styles from "./LicenseEntryAddon.module.scss";
import { formatUTCDate } from "../../utils/utils";
import { useMemo } from "react";

export const LicenseEntryAddonClasspadManager = (
  onClick: (additionalLicenseType: AdditionalLicenseType) => void,
  activeLicense?: ClasspadNetPlusManagerLicense,
  selectedUserData?: AdminUserLicenseViewSelectedUserData,
  isRedeemFailed?: boolean,
  validUntil?: Date
) => {
  const cpManagerTutorialSteps: LicenseEntryAddonTutorialStepProps[] = [
    {
      device: "desktop",
      position: "center",
      title: i18n._({
        id: `additional_licenses.classpad_manager.step3.screenshot1`,
      }),
      img: { src: cpManagerScreenshot01Src, alt: "" },
    },
    {
      device: "desktop",
      position: "center",
      title: i18n._({
        id: `additional_licenses.classpad_manager.step3.screenshot2`,
      }),
      img: { src: cpManagerScreenshot02Src, alt: "" },
    },
    {
      device: "desktop",
      position: "center",
      title: i18n._({
        id: `additional_licenses.classpad_manager.step3.screenshot3`,
      }),
      img: { src: cpManagerScreenshot03Src, alt: "" },
    },
  ];

  const disableRedeemButton = useMemo(() => {
    if (selectedUserData) {
      // check if admin view
      return false;
    } else {
      return isRedeemFailed;
    }
  }, [isRedeemFailed, selectedUserData]);

  return (
    <LicenseEntryAddon
      title={i18n._({
        id: `additional_licenses.name.classpad_manager`,
      })}
      description={i18n._({
        id: `additional_licenses.description.classpad_manager`,
      })}
    >
      <LicenseEntryAddonStep
        number={1}
        title={i18n._({
          id: `additional_licenses.classpad_manager.step1.title`,
        })}
      >
        {activeLicense ? (
          <>
            <TextInput
              id="code"
              label={i18n._({
                id: `additional_licenses.classpad_manager.code`,
              })}
              value={activeLicense.code}
              readOnly
            ></TextInput>
            {validUntil ? (
              <p className={styles.validUntilAdditionalLicenseText}>
                {i18n._({
                  id: `additional_licenses.valid.until`,
                })}
                &nbsp;
                {formatUTCDate(validUntil as unknown as string, "dd/mm/yyyy")}
              </p>
            ) : null}
          </>
        ) : (
          <Button
            label={i18n._({
              id: `additional_licenses.classpad_manager.step1.button`,
            })}
            disabled={disableRedeemButton}
            onClick={() => {
              if (selectedUserData) {
                return;
              }
              onClick(AdditionalLicenseType.CLASSPAD_PLUS_MANAGER);
            }}
          />
        )}
      </LicenseEntryAddonStep>

      <LicenseEntryAddonStep
        number={2}
        title={i18n._({
          id: `additional_licenses.classpad_manager.step2.title`,
        })}
      >
        <DesktopAppDownloadLink
          href={i18n._({
            id: `additional_licenses.classpad_manager.step2.download_link.windows`,
          })}
          os="Windows"
          downloadLabel={i18n._({
            id: `additional_licenses.classpad_manager.step2.download.label`,
          })}
        />
        <DesktopAppDownloadLink
          href={i18n._({
            id: `additional_licenses.classpad_manager.step2.download_link.mac`,
          })}
          os="Mac OS"
          downloadLabel={i18n._({
            id: `additional_licenses.classpad_manager.step2.download.label`,
          })}
        />
      </LicenseEntryAddonStep>
      <LicenseEntryAddonStep
        number={3}
        title={i18n._({
          id: `additional_licenses.classpad_manager.step3.title`,
        })}
      >
        <LicenseEntryAddonTutorial steps={cpManagerTutorialSteps} />
      </LicenseEntryAddonStep>
      <LicenseEntryAddonHelpBox
        title={i18n._({
          id: "additional_licenses.classpad_manager.help_box.title",
        })}
        description={i18n._({
          id: "additional_licenses.classpad_manager.help_box.description",
        })}
        email={i18n._({
          id: "additional_licenses.classpad_manager.help_box.email",
        })}
      />
    </LicenseEntryAddon>
  );
};
