import React, {
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  KeyboardEvent,
} from "react";
import { FormField } from "../FormField/FormField";
import styles from "./TextInput.module.scss";

import { CheckCircle, XCircle, Loader } from "react-feather";
import { validatePhoneNumber } from "../../utils/phoneValidation";
export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  errorMessage?: string;
  status?: "valid" | "invalid" | "validating";
  disable?: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  type?: HTMLInputTypeAttribute | "custom-telephone" | undefined;
}

// Allow 20 characters and prevent in 21st character
const MAX_TELEPHONE_LENGTH = 19;

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      label,
      errorMessage,
      status,
      disable,
      onKeyDown,
      type,
      ...inputProps
    }: TextInputProps,
    externalRef
  ) => {
    const handleCustomTelKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
        "Home",
        "End",
        "Enter",
      ];

      if (allowedKeys.includes(event.key) || event.ctrlKey || event.metaKey) {
        return;
      }

      if (!validatePhoneNumber(event.key)) {
        event.preventDefault(); // Block invalid character
      }

      if (event.currentTarget.value.length > MAX_TELEPHONE_LENGTH) {
        event.preventDefault();
      }
    };
    return (
      <FormField
        id={id}
        label={label}
        errorMessage={errorMessage}
        disable={disable}
      >
        <input
          type={type}
          ref={externalRef}
          id={id}
          {...inputProps}
          className={disable ? "disable" : ""}
          disabled={disable}
          onKeyDown={
            type === "custom-telephone" ? handleCustomTelKeyDown : onKeyDown
          }
        />
        {status && (
          <i className={styles.icon}>
            {status === "valid" && <CheckCircle></CheckCircle>}
            {status === "invalid" && <XCircle></XCircle>}
            {status === "validating" && <Loader></Loader>}
          </i>
        )}
      </FormField>
    );
  }
);
