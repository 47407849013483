import { differenceInSeconds } from "date-fns";
import {
  getTypeByValue,
  SchoolType,
} from "../components/AddSchool/SchoolTypes";
import { Country, Language } from "./Country";
import { IdProvider } from "./IdProvider";
import { FrenchInstructorRole, Role } from "./Role";
import { School } from "../api/school";
import { ClasswizEmulatorData } from "./../api/license-codes";
import {
  frenchSubjectTypesEngVer,
  frenchSubjectTypesFrVer,
  SubjectType,
} from "../components/AddSchool/SubjectTypes";

export interface ExternalGroup {
  name: string;
  uuid: string;
}

export interface UserLicenseOverview {
  cplValidUntil?: Date;
  cppValidUntil?: Date;
  cpAppValidUntil?: Date;
  cpAppEmail?: string;
  cpAppPassword?: string;
  cpManagerValidUntil?: Date;
  cpManagerCode?: string;
  classwizLicense?: ClasswizEmulatorData;
}

export interface PlainUser {
  id: string;
  email?: string;
  contactEmail?: string;
  telephone?: string;
  firstname?: string;
  lastname?: string;
  nickname: string;
  country: string;
  language: string;
  type: string;
  idProvider: string;
  cplTestPeriodEndDate?: string;
  createdAt: string;
  licensesFromTrial: string[];
  schoolId?: string;
  externalId?: string;
  metadata?: {
    teacherVerification?: boolean;
    registeredFrom?: string;
    salutation?: string;
    subjects?: string[];
    school?: { studentCount?: number };
    tisMarketingConsent?: boolean;
    tisProfilingConsent?: boolean;
    isPilotAccount?: boolean;
    teachingLevel?: string[];
    additionalVerification?: {
      verified: boolean;
    };
    gar?: { groups?: ExternalGroup[] };
    guardian?: {
      email: string;
      firstname: string;
      lastname: string;
    };
    teacherVerificationStatus?: TeacherVerificationMetadataStatus;
  };
  lastLoginAt?: Date;
  school?: School;
  status?: boolean;
  licenseOverview?: UserLicenseOverview;
  guardianVerified?: boolean;
  tacAccepted?: boolean;
  teacherVerificationRequests?: {
    id: string;
    status: TeacherVerificationStatus;
    reviewerNote: string;
  };
  licenseDate?: Date;
}

export enum TeacherVerificationMetadataStatus {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected",
  NOT_SUBMITTED = "not_submitted",
}

export enum TeacherVerificationStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export class User {
  constructor(
    public readonly id: string,
    public readonly nickname: string,
    public readonly country: Country,
    public readonly language: Language,
    public readonly type: Role,
    public readonly idProvider: IdProvider,
    public readonly daysSinceRegistration: number,
    public readonly tisMarketingConsent: boolean,
    public readonly tisProfilingConsent: boolean,
    public readonly licensesFromTrial: string[],
    public readonly firstname?: string,
    public readonly lastname?: string,
    public readonly cplTestPeriodEndDate?: Date,
    public readonly metadata?: {
      isPilotAccount?: boolean;
      guardian?: {
        email: string;
        firstname: string;
        lastname: string;
      };
      salutation?: string;
      subjects?: string[];
      school?: {
        studentCount?: number;
      };
      tisMarketingConsent?: boolean;
      tisProfilingConsent?: boolean;
      teachingLevel?: string[];
      teacherVerificationStatus?: TeacherVerificationMetadataStatus;
      teacherMarketingProfillingConsent?: boolean;
      role?: FrenchInstructorRole;
      registeredFrom?: string;
    },
    public readonly schoolId?: string,
    public readonly subjects?: { value: string; label: string }[] | string[],
    public readonly studentCount?: number,
    public readonly salutation?: string,
    public readonly email?: string,
    public readonly contactEmail?: string,
    public readonly telephone?: string,
    public readonly teachingLevel?: SchoolType[],
    public readonly verified?: boolean,
    public readonly garGroups?: ExternalGroup[],
    public readonly externalUserId?: string,
    public readonly createdAt?: Date,
    public readonly lastLoginAt?: Date,
    public readonly school?: School,
    public readonly status?: boolean,
    public readonly isActivated?: boolean,
    public readonly isActivatedByAdmin?: boolean,
    public readonly licenseOverview?: UserLicenseOverview,
    public readonly guardianVerified?: boolean,
    public readonly registeredFrom?: string,
    public readonly tacAccepted?: boolean,
    public readonly teacherVerificationRequests?: {
      id: string;
      status: TeacherVerificationStatus;
      reviewerNote: string;
    },
    public readonly licenseDate?: Date
  ) {}
}
/**
 *
 * @param pUser
 * @param admin This param is only to format user data that is retrieved by admin.
 * @returns
 */
export function userFromPlain(pUser: PlainUser, admin?: boolean): User {
  const secondsPerDay = 86400;
  const validFrTeachingSubjects = [
    "maths",
    "physics",
    "math-science",
    "svt",
    "nsi",
    "others",
  ];
  const validFrTeachingLevels = [
    "primary_level",
    "secondary_school",
    "secondary_school_2",
    "university",
    "segpa",
    "erea",
    "mfr",
    "inspe",
    "cfa",
    "bts",
  ];

  const filteredFrTeachingSubjects = pUser.metadata?.subjects?.filter((item) =>
    validFrTeachingSubjects.includes(item)
  );
  const filteredFrTeachingLevels = pUser.metadata?.teachingLevel?.filter(
    (item) => validFrTeachingLevels.includes(item)
  );

  const returnUser = new User(
    pUser.id,
    pUser.nickname,
    Country[pUser.country as keyof typeof Country],
    Language[pUser.language as keyof typeof Language],
    Role[pUser.type as keyof typeof Role],
    IdProvider[pUser.idProvider as keyof typeof IdProvider],
    Math.ceil(
      differenceInSeconds(new Date(), new Date(pUser.createdAt)) / secondsPerDay
    ),
    pUser.metadata?.tisMarketingConsent || false,
    pUser.metadata?.tisProfilingConsent || false,
    pUser.licensesFromTrial,
    pUser.firstname,
    pUser.lastname,
    pUser.cplTestPeriodEndDate
      ? new Date(pUser.cplTestPeriodEndDate)
      : undefined,
    pUser.metadata && pUser.metadata,
    pUser.schoolId,
    filteredFrTeachingSubjects
      ? pUser.country === Country.DE
        ? filteredFrTeachingSubjects
        : filteredFrTeachingSubjects.map((subject) => {
            let type: SubjectType | undefined;

            if (admin) {
              type = frenchSubjectTypesEngVer.find(
                (type) => type.value === subject
              );
            } else {
              type = frenchSubjectTypesFrVer.find(
                (type) => type.value === subject
              );
            }

            return type ? type : { value: "", label: "" };
          })
      : undefined,
    pUser.metadata?.school?.studentCount,
    pUser.metadata?.salutation,
    pUser.email,
    pUser.contactEmail,
    pUser.telephone,
    filteredFrTeachingLevels
      ? filteredFrTeachingLevels.map((level) => getTypeByValue(level))
      : undefined,
    pUser.metadata?.additionalVerification?.verified,
    pUser.metadata?.gar?.groups ?? [],
    pUser.externalId ? pUser.externalId : undefined,
    pUser.createdAt ? new Date(pUser.createdAt) : undefined,
    pUser.lastLoginAt ? new Date(pUser.lastLoginAt) : undefined,
    pUser.school ? pUser.school : undefined,
    pUser.status !== undefined
      ? pUser.status
        ? pUser.status
        : false
      : undefined,
    undefined,
    undefined,
    pUser.licenseOverview ? pUser.licenseOverview : undefined,
    pUser.guardianVerified ? pUser.guardianVerified : false,
    pUser.metadata?.registeredFrom ? pUser.metadata.registeredFrom : undefined,
    pUser.tacAccepted ? pUser.tacAccepted : false,
    pUser.teacherVerificationRequests
      ? pUser.teacherVerificationRequests
      : undefined,
    pUser.licenseDate ? new Date(pUser.licenseDate) : undefined
  );
  return returnUser;
}

export interface DataWithPagination<T> {
  totalCount: number;
  totalPage: number;
  currentPage: number;
  next?: {
    page: number;
    limit: number;
  };
  previous?: {
    page: number;
    limit: number;
  };
  last?: {
    page: number;
    limit: number;
  };
  paginateData: T[];
  currentCountPerPage: number;
  range: number;
}

export interface SortOptions {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
}
