// import { Country } from "../../../../types/Country";
import { useState } from "react";
import { Button } from "../../../Button/Button";
import { ComparisonBox } from "../../../MergeSchools/Steps/MergeSchoolsStep3/ComparisonBox";
import { Modal } from "../../../Modal/Modal";
import { Spinner } from "../../../Spinner/Spinner";
import { StepBody } from "../../../Stepper/Stepper";
import { SelectedUnMergedSchoolStep1 } from "../UnmergeSchoolsStep1/UnmergeSchoolsStep1";
import styles from "./UnmergeSchoolsStep2.module.scss";
import { UnmergeSchoolReponse, unMergeSchools } from "../../../../api/school";
import { useToasts } from "../../../../provider/toast-provider";

export interface UnmergeSchoolsStep2Props {
  handleNext?(): void;
  handleBack?(): void;
  confirmationData?: SelectedUnMergedSchoolStep1;
  getUnmergedResult?: (unmergedResult: UnmergeSchoolReponse) => void;
}

export const UnmergeSchoolsStep2 = ({
  handleNext,
  handleBack,
  confirmationData,
  getUnmergedResult,
}: UnmergeSchoolsStep2Props) => {
  const [isShowInvalidModal, setIsShowInvalidModal] = useState<boolean>(false);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  const { addToast } = useToasts();

  const formatResponse = (
    response: UnmergeSchoolReponse,
    type: "eduplacesSchool" | "cpaSchool"
  ) => {
    return {
      id: response[type].id,
      name: response[type].name,
      status: response[type].status,
      country: response[type].country,
      address: response[type].address,
      addressAddition: response[type].addressAddition,
      city: response[type].city,
      state: response[type].state,
      zip: response[type].zip,
      schoolId: response[type].schoolId,
      salesforceId: response[type].salesforceId,
      eduplacesSchoolId: response[type].eduplacesSchoolId,
      salesforceRecordId: response[type].salesforceRecordId,
      type: response[type].type,
      addressCountry: response[type].addressCountry,
      lmsInstance: response[type].lmsInstance,
    };
  };

  return (
    <StepBody
      noBackgroundColor={true}
      nextButton={
        <Button
          label={"Confirm"}
          onClick={() => {
            setIsShowSpinner(true);
            unMergeSchools(confirmationData?.id || "")
              .then((response: UnmergeSchoolReponse) => {
                // to render in correct order.
                const data: UnmergeSchoolReponse = {
                  eduplacesSchool: formatResponse(response, "eduplacesSchool"),
                  cpaSchool: formatResponse(response, "cpaSchool"),
                };
                getUnmergedResult && getUnmergedResult(data);
                handleNext && handleNext();
              })
              .catch((err) => {
                setIsShowSpinner(false);
                // Show toast message
                addToast(err.message, "error");
              })
              .finally(() => {
                setIsShowSpinner(false);
              });
          }}
        />
      }
      previousButton={
        <Button label={"Back"} btnStyle="secondary" onClick={handleBack} />
      }
    >
      <div className={styles.container}>
        {/* <div className={styles.container__intro}>
          <p>
            After unmerging the school record, it will split into two distinct
            records CPA school record and Eduplaces school record.
          </p>
          <p>
            The Eduplaces School ID and SF School ID Local 2 will be detached
            from the CPA school record.
          </p>
          <p>
            Post-unmerge, the general information (such as name and address) for
            the Eduplaces school record will remain identical to the original
            record until an Eduplaces user from the school logs into CPA, at
            which point the Eduplaces school record details will be refreshed.
          </p>
          <p>
            {" "}
            The School ID for all CPA users associated with the original school
            record will remain unchanged, while the School ID for all Eduplaces
            users associated with the original record will be updated to reflect
            the newly created Eduplaces school record.
          </p>
        </div> */}
        <div className={styles.container__comparisonBox}>
          <ComparisonBox title="School Record Detail" data={confirmationData} />
        </div>

        <Modal
          title="Invalid"
          closeButton
          children={
            <p>
              The CPA (SF) School record has already been linked with another
              Eduplaces School ID.
            </p>
          }
          isShowing={isShowInvalidModal}
          onClose={() => {
            setIsShowInvalidModal(false);
          }}
        />

        <Spinner
          type="fullContainer"
          title="Please wait..."
          visible={isShowSpinner}
        />
      </div>
    </StepBody>
  );
};
