import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";
import "./InformingModal.scss";
import { i18n } from "@lingui/core";

export const InformingModal = ({
  isShowing,
  setVisible,
  isError,
}: {
  isShowing: boolean;
  setVisible: () => void;
  isError: boolean;
}) => {
  return (
    <Modal
      title={
        !isError
          ? i18n._({
              id: "student_list.student.add_student_to_class_modal.success_modal.title",
            })
          : i18n._({
              id: "student_list.student.add_student_to_class_modal.error_modal.title",
            })
      }
      isShowing={isShowing}
      closeButton={false}
      size="medium"
      type={isError ? "error" : "default"}
    >
      <div className="container">
        {!isError && (
          <p className="message">
            {i18n._({
              id: "student_list.student.add_student_to_class_modal.success_modal.message",
            })}
          </p>
        )}

        {isError && (
          <div className="message">
            <p>
              {i18n._({
                id: "student_list.student.add_student_to_class_modal.error_modal.message_one",
              })}
            </p>
            <p>
              {i18n._({
                id: "student_list.student.add_student_to_class_modal.error_modal.message_two",
              })}
            </p>
            <p>
              {i18n._({
                id: "student_list.student.add_student_to_class_modal.error_modal.message_three",
              })}
            </p>
          </div>
        )}

        <div className="btn-group">
          <div></div>
          <Button
            btnStyle="secondary"
            label={i18n._({
              id: "student_list.student.add_student_to_class_modal.modal.btn.close",
            })}
            onClick={() => setVisible()}
          ></Button>
        </div>
      </div>
    </Modal>
  );
};
