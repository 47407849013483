import { useParams, Navigate } from "react-router-dom";
import { RegisterFromEnum } from "../../types/RegisterFrom";

export const UserProfileFrom = () => {
  const { from } = useParams<{ from: string }>();

  switch (from?.toLowerCase()) {
    case RegisterFromEnum.CASIO_EDU:
      sessionStorage.setItem("userProfileFrom", from);
      break;
    default:
  }
  return (
    <>
      {from?.toLowerCase() === RegisterFromEnum.CASIO_EDU && (
        <Navigate to={"/"} />
      )}
    </>
  );
};
