import styles from "./Table.module.scss";
import "react-data-grid/lib/styles.css";

import ReactDataGrid, {
  Column,
  SortColumn,
  RowsChangeData,
  CellClickArgs,
  CellSelectArgs,
  CellMouseEvent,
  SELECT_COLUMN_KEY,
} from "react-data-grid";

function mapCustomStyles(column: Column<any>) {
  return {
    headerCellClass: styles.headerCell,
    cellClass: column.cellClass ? styles[`${column.cellClass}`] : styles.cell,
  };
}

export const Table = ({
  columns,
  rows,
  onRowsChange,
  sortColumns,
  onSortColumnsChange,
  rowKeyGetter,
  selectedRows,
  onSelectedRowsChange,
  onCellClick,
  onSelectedCellChange,
  sortable = true,
  rowHeight,
  disableCellClick,
  isOnlyOneRowCanBeSelected,
}: {
  columns: readonly Column<any>[];
  rows: any[];
  onRowsChange?: (rows: any[], data: RowsChangeData<any, unknown>) => void;
  sortColumns?: readonly SortColumn[];
  onSortColumnsChange?: (sortColumns: SortColumn[]) => void;
  rowKeyGetter?: (row: any) => any;
  selectedRows?: ReadonlySet<any>;
  onSelectedRowsChange?: (selectedRows: Set<any>, selectedData: any) => void;
  onCellClick?: (
    args: CellClickArgs<any, unknown>,
    event: CellMouseEvent
  ) => void;
  onSelectedCellChange?: (event: CellSelectArgs<any, unknown>) => void;
  sortable?: boolean;
  rowHeight?: number;
  disableCellClick?: boolean;
  isOnlyOneRowCanBeSelected?: boolean;
}) => {
  let resultColums;

  if (isOnlyOneRowCanBeSelected) {
    const modifiedColumns = columns.map((column: Column<any>) => {
      if (column.key === SELECT_COLUMN_KEY) {
        return {
          ...column,
          renderHeaderCell: null, // Remove the header checkbox
          ...mapCustomStyles(column),
        };
      } else {
        return {
          ...column,
          ...mapCustomStyles(column),
        };
      }
    });

    resultColums = modifiedColumns;
  } else {
    resultColums = columns.map((column) => {
      return {
        ...column,
        ...mapCustomStyles(column),
      };
    });
  }

  return (
    <ReactDataGrid
      rowHeight={rowHeight || undefined}
      headerRowHeight={35}
      className="rdg-light"
      columns={resultColums}
      rows={rows}
      sortColumns={sortColumns}
      style={{
        borderBottom: "2px solid #EEEEEE",
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
      }}
      onSortColumnsChange={(sortColumns) =>
        onSortColumnsChange && onSortColumnsChange(sortColumns)
      }
      onRowsChange={onRowsChange}
      selectedRows={selectedRows}
      onSelectedRowsChange={(data) => {
        const rowIds = Array.from(data);

        if (isOnlyOneRowCanBeSelected) {
          const lastRowId = rowIds[rowIds.length - 1];

          const lastSelectedData = rows.filter((row) => {
            return row.id === lastRowId;
          });
          const lastData = new Set([lastRowId]);

          onSelectedRowsChange &&
            onSelectedRowsChange(lastData, lastSelectedData);

          return;
        }

        const selectedData = rows.filter((row) => rowIds.includes(row.id));
        onSelectedRowsChange && onSelectedRowsChange(data, selectedData);
      }}
      rowKeyGetter={rowKeyGetter}
      rowClass={(row, index) => {
        return styles.rdgCell;
      }}
      defaultColumnOptions={{
        sortable,
        resizable: true,
      }}
      onCellClick={(
        args: CellClickArgs<any, unknown>,
        event: CellMouseEvent
      ) => {
        if (disableCellClick) {
          event.preventGridDefault();
          return;
        }
        onCellClick && onCellClick(args, event);
      }}
      onSelectedCellChange={(event: CellSelectArgs<any, unknown>) => {
        onSelectedCellChange && onSelectedCellChange(event);
      }}
    />
  );
};
