import { useAuth } from "../../../hooks/use-auth";
import { Role } from "../../../types/Role";
import { Button } from "../../Button/Button";
import { Grid, GridItem } from "../../Grid/Grid";
import { Modal } from "../../Modal/Modal";
import styles from "./ErrorModal.module.scss";
// import { i18n } from "@lingui/core";

export const ErrorModal = ({
  isShowing,
  setVisible,
  title,
  text,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  title: string;
  text: string;
}) => {
  const auth = useAuth();
  return (
    <Modal
      title={title}
      isShowing={isShowing}
      size="medium"
      closeButton={false}
      onHide={() => {
        setVisible(false);
      }}
    >
      <p className={styles.errorModalText}>{text}</p>

      <div className={styles.errorModalBtnGroup}>
        <Grid>
          <GridItem width="1" className={styles.groupBtn2}>
            <Button
              btnStyle="secondary"
              label={auth.user?.type === Role.ADMINISTRATOR ? "close" : ""}
              onClick={() => setVisible(false)}
            ></Button>
          </GridItem>
        </Grid>
      </div>
    </Modal>
  );
};
