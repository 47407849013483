import { i18n } from "@lingui/core";
import { redeemLicense } from "../../api/license-codes";
import { useToasts } from "../../provider/toast-provider";
import { LicenseRedeemModalContainer } from "./LicenseRedeemModalContainer";
import { useState } from "react";
import { License } from "../../types/License";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";

export interface LicenseReedeemModalProps {
  onClose: () => void;
  schoolLicenses: SchoolLicenseWithAddtitionalLicense[];
  fetchLicenseCodes: () => void;
}

export const validateLicenseCode = (values: { licenseCode: string }) => {
  let errors: any = {};
  if (!values.licenseCode) {
    errors.licenseCode = "forms.validation.required";
  }

  const classpadCodeRegex =
    /^[A-Z0-9]{6}-[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/i;

  const input = values.licenseCode.trim();

  if (!classpadCodeRegex.test(input)) {
    errors.licenseCode = "license.redeem.error.invalid_license";
  }

  return errors;
};

export const LicenseRedeemModal = ({
  onClose,
  schoolLicenses,
  fetchLicenseCodes,
}: LicenseReedeemModalProps) => {
  const { addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [redeemedLicenses, setRedeemedLicenses] = useState<License[]>([]);

  const handleSubmit = async (values: { licenseCode: string }) => {
    try {
      const { licenses } = await redeemLicense({
        licenseCode: values.licenseCode.trim().toUpperCase(),
      });
      setRedeemedLicenses(licenses);
      setVisible(false);
      fetchLicenseCodes();
      setSuccessVisible(true);
    } catch (err: any) {
      addToast(i18n._({ id: err.message }), "error");
    }
  };

  return (
    <>
      <LicenseRedeemModalContainer
        handleSubmit={handleSubmit}
        visible={visible}
        setVisible={setVisible}
        validateLicenseCode={validateLicenseCode}
        successVisible={successVisible}
        setSuccessVisible={setSuccessVisible}
        onClose={onClose}
        licenses={redeemedLicenses}
        schoolLicenses={schoolLicenses}
      />
    </>
  );
};
