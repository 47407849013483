import React, { ChangeEvent, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styles from "./Pagination.module.scss";
import { useAuth } from "../../hooks/use-auth";
import { Role } from "../../types/Role";
import { i18n } from "@lingui/core";

const Pagination = ({
  items,
  totalCount,
  forcePage,
  getItemsPerPage,
  getPageNumber,
}: {
  items: any[];
  totalCount: number;
  forcePage?: number;
  getItemsPerPage: (itemsPerPage: number) => void;
  getPageNumber: (pageNumber: number) => void;
}) => {
  const auth = useAuth();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    // page 1 => event: {selected: 0} => pageNumber = selected + 1;
    const pageNumber = event.selected + 1;

    setPageNumber(pageNumber);
    getPageNumber(pageNumber);
  };

  const handleSelectRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    const itemsPerPage = parseInt(event.target.value);
    // console.log('handle select itemsPerPage', itemsPerPage)
    setItemsPerPage(itemsPerPage);

    // Pass items per page number to outside.
    getItemsPerPage(itemsPerPage);
  };

  useEffect(() => {
    setPageNumber(1);
  }, [forcePage]);

  return (
    <div className={styles.container}>
      <div className={styles.container__rowsPerpage}>
        {auth.user?.type === Role.ADMINISTRATOR ? (
          <p>Rows per page:</p>
        ) : (
          <p>
            {i18n._({
              id: "student_list.student.screen.table.rows_per_page",
            })}
          </p>
        )}
        <select onChange={handleSelectRowsPerPage}>
          {[10, 20, 50, 100].map((option, index) => (
            <option key={`option-${index}`}>{option}</option>
          ))}
        </select>

        <p style={{ paddingLeft: "30px" }}>
          {itemsPerPage * (pageNumber - 1) + 1}-
          {pageNumber < pageCount ? pageNumber * itemsPerPage : totalCount} of{" "}
          {totalCount}
        </p>
      </div>

      <ReactPaginate
        key={forcePage} // add this key to force ReactPaginate re-render when forcePage changes.
        activeClassName={styles.container__paginate__active}
        className={styles.container__paginate}
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        forcePage={forcePage}
      />
    </div>
  );
};

export default Pagination;
