import { i18n } from "@lingui/core";
import { Context } from "../../../types/Context";
import { Column } from "react-data-grid";
import { Table } from "../../Table/Table";
import React, { useEffect, useState } from "react";
import { Button } from "../../Button/Button";
import { Role } from "../../../types/Role";
import {
  createContextJoinCode,
  getContextJoinCode,
} from "../../../api/context";
import { Copy, RefreshCw, Search } from "react-feather";
import "./ContextListV2.scss";
import { Link } from "react-router-dom";
import { useToasts } from "../../../provider/toast-provider";
import { getUserContexts } from "../../../api/user-contexts";
import { IdProvider } from "../../../types/IdProvider";
import { Spinner } from "../../Spinner/Spinner";
import { useAuth } from "../../../hooks/use-auth";
import { Country } from "../../../types/Country";
const exportToCSV = require("export-to-csv");

export interface Row {
  id?: string;
  color?: string | React.ReactElement;
  name: string;
  numOfStudents?: string;
  studentJoinCode?: string | React.ReactElement;
  teacherJoinCode?: string | React.ReactElement;
  code: string | React.ReactElement;
  action?: React.ReactElement;
}

export interface ContextListProps {
  userId: string;
}

export const ContextListV2: React.FC<ContextListProps> = ({ userId }) => {
  const columns: readonly Column<Row>[] = [
    {
      key: "color",
      name: i18n._({ id: "contexts.v2.column.color" }),
      width: "100px",
      resizable: false,
    },
    {
      key: "name",
      name: i18n._({ id: "contexts.v2.column.classname" }),
    },
    {
      key: "numOfStudents",
      name: i18n._({ id: "contexts.v2.column.numOfStudents" }),
    },
    {
      key: "studentJoinCode",
      name: i18n._({ id: "contexts.v2.column.studentJoinCode" }),
      resizable: false,
    },
    {
      key: "teacherJoinCode",
      name: i18n._({ id: "contexts.v2.column.teacherJoinCode" }),
      resizable: false,
    },
    {
      key: "code",
      name: i18n._({ id: "contexts.v2.column.classCode" }),
      resizable: false,
    },
    {
      key: "action",
      name: i18n._({ id: "contexts.v2.column.action" }),
      width: "150px",
    },
  ];

  const { user } = useAuth();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<Row[]>([]);
  const [contexts, setContexts] = useState<Context[]>([]);
  const [search, setSearch] = useState("");
  const [isGenerateCode, setIsGenerateCode] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvConfig = exportToCSV.mkConfig({
    useKeysAsHeaders: true,
    fieldSeparator: ",",
    quoteCharacter: "",
    filename: i18n._({ id: "contexts.v2.csv_file_name" }),
  });
  const classpadContexts = contexts.filter(
    (context) => context.origin === IdProvider.CLASSPAD
  );

  const handleDownloadClassList = async () => {
    const csv = exportToCSV.generateCsv(csvConfig)(csvData);
    await exportToCSV.download(csvConfig)(csv); // Make sure this is awaited
  };

  const handleGenerateJoinCode = async (contextId: string, role: Role) => {
    try {
      setIsGenerateCode(true);
      return await createContextJoinCode(contextId, role);
    } catch (err) {
    } finally {
      setIsGenerateCode(false);
    }
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    addToast(i18n._({ id: "copied_to_clipboard" }), "success");
  };

  useEffect(() => {
    const fetchContexts = async () => {
      const contexts = await getUserContexts({
        userId,
      });

      setContexts(contexts);
    };

    fetchContexts();
  }, [userId]);

  useEffect(() => {
    const restructTableRows = async () => {
      setLoading(true);

      const mappedContexts = [];
      const mappedCsv = [];

      let filteredClasspadContexts = classpadContexts.filter((context) =>
        context.name.toLowerCase().includes(search.toLowerCase())
      );

      for (let i = 0; i < filteredClasspadContexts.length; i++) {
        const context = filteredClasspadContexts[i];

        const studentJoinCode = await getContextJoinCode(
          context.id,
          Role.LEARNER
        )
          .then((data) => data.code)
          .catch((err) => undefined);

        const teacherJoinCode = await getContextJoinCode(
          context.id,
          Role.INSTRUCTOR
        )
          .then((data) => data.code)
          .catch((err) => undefined);

        // Push Table Row
        mappedContexts.push({
          id: context.id,
          color: (
            <div className="color">
              <div className={`box ${context.color.toLowerCase()}`}></div>
            </div>
          ),
          name: context.name,
          numOfStudents: String(
            context.users?.filter((user) => user.type === Role.LEARNER)
              .length || 0
          ),
          studentJoinCode: studentJoinCode ? (
            <div className="copyable">
              <span className="text">{studentJoinCode}</span>
              <div className="action">
                <Copy
                  className="logo"
                  size={16}
                  onClick={() => handleCopyToClipboard(studentJoinCode)}
                />
                <RefreshCw
                  className="logo"
                  size={16}
                  onClick={() =>
                    handleGenerateJoinCode(context.id, Role.LEARNER)
                  }
                />
              </div>
            </div>
          ) : (
            <span
              className="action"
              onClick={() => handleGenerateJoinCode(context.id, Role.LEARNER)}
            >
              {i18n._({ id: "contexts.v2.generate" })}
            </span>
          ),
          teacherJoinCode: teacherJoinCode ? (
            <div className="copyable">
              <span className="text">{teacherJoinCode}</span>
              <div className="action">
                <Copy
                  className="logo"
                  size={16}
                  onClick={() => handleCopyToClipboard(teacherJoinCode)}
                />
                <RefreshCw
                  className="logo"
                  size={16}
                  onClick={() =>
                    handleGenerateJoinCode(context.id, Role.INSTRUCTOR)
                  }
                />
              </div>
            </div>
          ) : (
            <span
              className="action"
              onClick={() =>
                handleGenerateJoinCode(context.id, Role.INSTRUCTOR)
              }
            >
              {i18n._({ id: "contexts.v2.generate" })}
            </span>
          ),
          code: (
            <div className="copyable">
              <span className="text">{context.code}</span>
              <div className="action">
                <Copy
                  className="logo"
                  size={16}
                  onClick={() => handleCopyToClipboard(context.code)}
                />
              </div>
            </div>
          ),
          action: (
            <Link
              className="action"
              to={`details/${context.id}`}
              state={{ newViewMode: true }}
            >
              {i18n._({ id: "contexts.v2.column.action.view" })}
            </Link>
          ),
        });

        // Push CSV Row
        let csvRow = {};
        if (user?.country === Country.DE) {
          csvRow = {
            Klassenname: context.name,
            "Anzahl der Schüler": String(
              context.users?.filter((user) => user.type === Role.LEARNER)
                .length || 0
            ),
            "Beitrittscode Schüler": studentJoinCode,
            "Beitrittscode Lehrer": teacherJoinCode,
            Klassencode: context.code,
          };
        } else {
          csvRow = {
            "Nom du groupe": context.name,
            "Nombre d'élèves": String(
              context.users?.filter((user) => user.type === Role.LEARNER)
                .length || 0
            ),
            "Code d’invitation pour les éleves": studentJoinCode,
            "Code d’invitation pour les enseignants": teacherJoinCode,
            "Code du groupe": context.code,
          };
        }
        mappedCsv.push(csvRow);
      }

      setLoading(false);
      setRows(mappedContexts);
      setCsvData(mappedCsv as any);
    };

    restructTableRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGenerateCode, contexts, search]);

  return (
    <>
      {loading === true && (
        <Spinner type="fullPage" title={i18n._({ id: "loading" })} />
      )}

      <div className="container">
        <div className="search-box">
          <Search className="search-icon" />
          <input
            id="search"
            className="search-input"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={i18n._({
              id: "contexts.v2.filter",
            })}
          />
        </div>
        <Table
          columns={columns}
          rows={rows}
          onRowsChange={setRows}
          rowHeight={45}
        />
        <div className="btn">
          <div></div>
          <Button
            label={i18n._({ id: "contexts.v2.download_csv" })}
            onClick={handleDownloadClassList}
            disabled={rows.length === 0}
          />
        </div>
      </div>
    </>
  );
};
