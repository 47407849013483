import { i18n } from "@lingui/core";
import { SupportedLanguage } from "../IntlHandler/IntlHelper";

export interface SchoolType {
  readonly value: string;
  readonly label: string;
}

export const getTypeByValue = (typeValue: string): SchoolType => {
  const allTypes = new Set<SchoolType>();
  for (const value of Object.values(getAllTypes())) {
    for (const type of value) {
      allTypes.add(type);
    }
  }

  const typeArray = Array.from(allTypes);
  for (const type of typeArray) {
    if (type.value === typeValue) {
      return type;
    }
  }
  return { value: typeValue, label: "undefined" };
};

export const getTypes = (language: SupportedLanguage): SchoolType[] => {
  const types = getAllTypes();
  return types[language];
};

export const getAllTypes = (): {
  de: {
    value: string;
    label: string;
  }[];
  fr: {
    value: string;
    label: string;
  }[];
  en: {
    value: string;
    label: string;
  }[];
} => {
  const germanSchoolTypes = [
    {
      value: "primary_level",
      label: i18n._({ id: "schooltype.primary_level" }),
    },
    {
      value: "secondary_school",
      label: i18n._({ id: "schooltype.secondary_school" }),
    },
    {
      value: "secondary_school_2",
      label: i18n._({ id: "schooltype.secondary_school_2" }),
    },
    {
      value: "tutoring_school",
      label: i18n._({ id: "schooltype.tutoring_school" }),
    },
    {
      value: "second_chance_education",
      label: i18n._({ id: "schooltype.second_chance_education" }),
    },
    {
      value: "professional_school",
      label: i18n._({ id: "schooltype.professional_school" }),
    },
    {
      value: "university",
      label: i18n._({ id: "schooltype.university" }),
    },
    { value: "other", label: i18n._({ id: "schooltype.other" }) },
  ];

  const frenchSchoolTypes = [
    {
      value: "primary_level",
      label: i18n._({ id: "schooltype.primary_level" }),
    },
    {
      value: "secondary_school",
      label: i18n._({ id: "schooltype.secondary_school" }),
    },
    {
      value: "secondary_school_2",
      label: i18n._({ id: "schooltype.secondary_school_2" }),
    },
    {
      value: "university",
      label: i18n._({ id: "schooltype.university" }),
    },
    { value: "segpa", label: "SEGPA" },
    { value: "erea", label: "EREA" },
    { value: "mfr", label: "MFR" },
    { value: "inspe", label: "INSPE" },
    { value: "cfa", label: "CFA" },
    { value: "bts", label: "BTS" },
  ];

  const schoolTypes = {
    de: germanSchoolTypes,
    fr: frenchSchoolTypes,
    en: germanSchoolTypes,
  };
  return schoolTypes;
};
