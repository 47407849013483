import { useEffect, useState } from "react";
import { Formik, Form } from "formik";

import { Modal } from "../../Modal/Modal";
import { Grid, GridItem } from "../../Grid/Grid";
import styles from "./DeleteUserAccount.module.scss";
import { Button } from "../../Button/Button";
import { TextInput } from "../../TextInput/TextInput";
import { TextArea } from "../../TextArea/TextArea";
import { Table } from "../../Table/Table";
import { Role } from "../../../types/Role";
import { Country } from "../../../types/Country";
import { IdProvider } from "../../../types/IdProvider";
import { Column } from "react-data-grid";
import {
  AdminDeleteUser,
  KeepTis,
  adminDeleteUser,
  adminUpdateKeepTIS,
} from "../../../api/user";
import { Spinner } from "../../Spinner/Spinner";
import { i18n } from "@lingui/core";
import { capitalizeFirstLetter } from "../../../utils/utils";

interface Row {
  email: string | undefined;
  role: Role | string;
  remainRegisteredTis?: React.ReactElement;
  tisMarketingConsent?: string;
  tisProfilingConsent?: string;
  userId: string;
  nickname: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  country: Country;
  idProvider: IdProvider;
  status: string;
  result?: string;
}

const KEEP = "Keep";
const NOT_KEEP = "Not Keep";

export const DeleteUserAccountModal = ({
  isShowing,
  setVisible,
  selectedAccount,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  selectedAccount: {
    id: string;
    email: string | undefined;
    type: Role | string;
    status: string;
    nickname: string | undefined;
    firstname: string | undefined;
    lastname: string | undefined;
    country: Country;
    idProvider: IdProvider;
    tisMarketingConsent?: boolean;
    tisProfilingConsent?: boolean;
  }[];
}) => {
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  const [rows, setRows] = useState<Row[]>([]);
  const [columns, setColumns] = useState<Column<Row>[]>([
    {
      key: "email",
      name: "Email",
    },
    {
      key: "role",
      name: "Role",
    },
    {
      key: "remainRegisteredTis",
      name: "RemainRegisteredTIS",
    },
    {
      key: "tisMarketingConsent",
      name: "tisMarketingConsent",
    },
    {
      key: "tisProfilingConsent",
      name: "TisProfilingConsent",
    },
    {
      key: "userId",
      name: "UserID",
    },
    {
      key: "nickname",
      name: "Nickname",
    },
    {
      key: "firstName",
      name: "Firstname",
    },
    {
      key: "lastName",
      name: "Lastname",
    },
    {
      key: "country",
      name: "Country",
    },
    {
      key: "idProvider",
      name: "ID Provider",
    },
    {
      key: "status",
      name: "Status",
    },
  ]);
  const [errorMsgRequestedBy, setErrorMsgRequestedBy] = useState<string>();
  const [errorMsgNote, setErrorMsgNote] = useState<string>();
  const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(true);

  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [valueSubmit, setValueSubmit] = useState<{
    requestedBy: string;
    note: string;
    data: AdminDeleteUser[];
  }>();
  const [isResultView, setIsResultView] = useState<boolean>(false);
  const [successData, setSuccessData] = useState<{
    number: number;
    data: {
      userId: string;
      isDeleted: boolean;
    }[];
  }>();
  const [failedData, setFailedData] = useState<{
    number: number;
    data: {
      userId: string;
      isDeleted: false;
    }[];
  }>();

  const [keepTisData, setKeepTisData] = useState<KeepTis[]>([]);

  useEffect(() => {
    let rows: Row[] = [];
    let keepTisData: KeepTis[] = [];

    // If the page go to result view, means that end the process of activation and show the result.
    if (isResultView && successData && failedData) {
      setRows([]);
      const successAndFail = [...successData.data, ...failedData.data];

      for (let account of selectedAccount) {
        const findAccount = successAndFail.find(
          (item) => item.userId === account.id
        );

        if (findAccount) {
          rows.push({
            email: account.email,
            role: account.type,
            userId: account.id,
            nickname: account.nickname,
            firstName: account.firstname,
            lastName: account.lastname,
            country: account.country,
            idProvider: account.idProvider,
            status: account.status,
            result: findAccount.isDeleted
              ? "Deletion Success"
              : "Deletion Failed",
          });
        }
      }
      setRows(rows);
      return;
    }

    if (selectedAccount && selectedAccount.length > 0) {
      for (let account of selectedAccount) {
        rows.push({
          email: account.email,
          role: account.type,
          remainRegisteredTis: (
            <>
              {account.type === Role.INSTRUCTOR &&
              account.tisMarketingConsent === true ? (
                <select
                  className={styles.keepTisSelect}
                  onChange={(event) => {
                    const isKeepTis = event.target.value === KEEP;
                    const updatedKeepTisData = [...keepTisData];

                    for (let item of updatedKeepTisData) {
                      if (item.userId === account.id) {
                        item.keepTis = isKeepTis;
                      }
                    }

                    setKeepTisData(updatedKeepTisData);
                  }}
                >
                  <option>{KEEP}</option>
                  <option>{NOT_KEEP}</option>
                </select>
              ) : (
                ""
              )}
            </>
          ),
          tisMarketingConsent:
            account?.type === Role.INSTRUCTOR
              ? capitalizeFirstLetter(
                  String(account?.tisMarketingConsent || false)
                )
              : "",
          tisProfilingConsent:
            account?.type === Role.INSTRUCTOR
              ? capitalizeFirstLetter(
                  String(account?.tisProfilingConsent || false)
                )
              : "",
          userId: account.id,
          nickname: account.nickname,
          firstName: account.firstname,
          lastName: account.lastname,
          country: account.country,
          idProvider: account.idProvider,
          status: account.status,
        });

        if (account.type === Role.INSTRUCTOR && account.tisMarketingConsent) {
          keepTisData.push({
            userId: account.id,
            keepTis: true,
          });
        }
      }
      setRows(rows);
      setKeepTisData(keepTisData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, isResultView, successData, failedData]);

  useEffect(() => {
    if (isResultView) {
      const updateColumns = [...columns];
      updateColumns.push({
        key: "result",
        name: "Result",
      });

      setColumns(updateColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResultView]);

  return (
    <>
      <Modal
        title={
          isResultView ? "Delete User Account Result" : "Delete User Account"
        }
        isShowing={isShowing}
        closeButton={true}
        size="superlarge"
        onHide={() => {
          setVisible(false);
          if (isResultView) {
            window.location.reload(); // TODO: Improve: Just reload the table, not reload entire page.
          }
        }}
      >
        <div className={styles.container}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              requestedBy: "",
              note: "",
            }}
            validate={(values) => {
              if (values.requestedBy?.trim().length !== 0) {
                setErrorMsgRequestedBy(undefined);
              }

              if (values.note?.trim().length !== 0) {
                setErrorMsgNote(undefined);
              }

              if (
                values.requestedBy?.trim().length > 0 &&
                values.note?.trim().length > 0
              ) {
                setDisableConfirmBtn(false);
              } else {
                setDisableConfirmBtn(true);
              }
            }}
            onSubmit={async (values) => {
              setIsShowConfirmModal(true);
              const deleteAccountData: AdminDeleteUser[] = rows.map((row) => ({
                userId: row.userId,
                email: row.email,
                idProvider: row.idProvider,
                country: row.country,
              }));
              setValueSubmit({
                ...values,
                data: deleteAccountData,
              });
            }}
          >
            {({ handleChange, values }) => (
              <Form>
                <Grid>
                  <GridItem width="1">
                    <TextInput
                      id="requestedBy"
                      label="Requested by*"
                      onChange={handleChange}
                      placeholder="Input option: Request from User/from Guardian of User/ from teacher or school / Other; if other specify without personal data."
                      errorMessage={errorMsgRequestedBy}
                      onBlur={() => {
                        if (values.requestedBy?.trim().length === 0) {
                          setErrorMsgRequestedBy("Please input");
                        } else {
                          setErrorMsgRequestedBy(undefined);
                        }
                      }}
                    />
                  </GridItem>
                  <div className={styles.noteContainer}>
                    <GridItem width="1">
                      <TextArea
                        id="note"
                        label="Note*"
                        className={styles.textArea}
                        onChange={handleChange}
                        placeholder="Input how, who, when this request was received, but do not input any personal information of users. For example: user contacted casio-education@casio.de by email on [Date], [Casio Staff name] forwarded email to admin [Admin name]"
                        errorMessage={errorMsgNote}
                        onBlur={() => {
                          if (values.note?.trim().length === 0) {
                            setErrorMsgNote("Please input");
                          } else {
                            setErrorMsgNote(undefined);
                          }
                        }}
                      />
                    </GridItem>
                  </div>

                  <div className={styles.tableContainer}>
                    <GridItem width="1">
                      <div className={styles.tableTitle}>
                        <p>Number of Records: {rows.length}</p>{" "}
                        {isResultView && (
                          <>
                            <p>Success: {successData?.number}</p>{" "}
                            <p>Fail: {failedData?.number}</p>
                          </>
                        )}
                      </div>
                    </GridItem>

                    <div className={styles.table}>
                      <GridItem width="1">
                        <Table
                          columns={columns}
                          rows={rows}
                          onRowsChange={() => {}}
                          sortColumns={[]}
                          onSortColumnsChange={() => {}}
                          rowKeyGetter={() => {}}
                          selectedRows={undefined}
                          onSelectedRowsChange={() => {}}
                          sortable={true}
                          disableCellClick={true}
                        />
                      </GridItem>
                    </div>

                    <div className={styles.btnGroup}>
                      <Grid>
                        <GridItem width="2/3" className={styles.groupBtn1}>
                          <></>
                        </GridItem>

                        <GridItem width="1/3" className={styles.groupBtn2}>
                          {!isResultView && (
                            <Button
                              type="submit"
                              label="confirm"
                              disabled={disableConfirmBtn}
                            ></Button>
                          )}
                          <Button
                            btnStyle="secondary"
                            label={isResultView ? "close" : "cancel"}
                            onClick={() => {
                              setVisible(false);
                              // Force User management page to reload to show the new user lists
                              if (isResultView) {
                                window.location.reload(); // TODO: Improve: Just reload the table, not reload entire page.
                              }
                            }}
                          ></Button>
                        </GridItem>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      <Modal
        title="Confirm Deletion"
        isShowing={isShowConfirmModal}
        closeButton={true}
        size="medium"
        onHide={() => {
          setIsShowConfirmModal(false);
        }}
      >
        <p className={styles.confirmModalText}>
          Are you sure you would like to delete the user(s) from the database?
          This action cannot be undone.
        </p>

        <div className={styles.confirmModalBtnGroup}>
          <Grid>
            <GridItem width="1/2" className={styles.groupBtn1}>
              <Button
                label="delete"
                btnStyle="danger"
                onClick={async () => {
                  setIsShowSpinner(true);
                  try {
                    await adminUpdateKeepTIS(keepTisData);

                    const response = await adminDeleteUser({
                      deleteData: valueSubmit,
                    });

                    setIsResultView(true);
                    setSuccessData(response?.success);
                    setFailedData(response?.fail);
                    setIsShowConfirmModal(false);
                  } catch (e) {
                    setIsShowSpinner(false);
                  } finally {
                    setIsShowSpinner(false);
                  }
                }}
              ></Button>
            </GridItem>

            <GridItem width="1/2" className={styles.groupBtn2}>
              <Button
                btnStyle="secondary"
                label="cancel"
                onClick={() => setIsShowConfirmModal(false)}
              ></Button>
            </GridItem>
          </Grid>
        </div>
      </Modal>

      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </>
  );
};
