/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { Role } from "../../types/Role";
import { IdProvider } from "../../types/IdProvider";
import { Country } from "../../types/Country";
import { SchoolOption, SchoolSelect } from "../SchoolSelect/SchoolSelect";
import { querySchools } from "../../api/school";
import styles from "./BroadcastGroupBox.module.scss";
import { Check } from "react-feather";
import { ActionMeta, SingleValue } from "react-select";

export enum BroadcastSchoolLicenseFilter {
  VALID = "VALID",
  EXPIRED = "EXPIRED",
  NOT_EXIST = "NOT_EXIST",
}

export interface DataStructure {
  role: { id: string; label: string; value: Role };
  children: {
    id: string;
    label: string;
    isCheckbox?: boolean;
    isSchoolId?: boolean;
    isRequired?: boolean;
    children?: {
      id: string;
      label: string;
      value: string | boolean;
      isCheckbox?: boolean;
    }[];
  }[];
}

export interface BroadcastObject {
  country: Country;
  objects: {
    role: Role;
    filters: {
      idProvider: IdProvider[];
      tisMarketingConsent?: boolean[];
      tisProfilingConsent?: boolean[];
      schoolLicenseCPLearning?: BroadcastSchoolLicenseFilter[];
      schoolLicenseCPPlus?: BroadcastSchoolLicenseFilter[];
      schoolId?: string;
      teachingLevel?: string[];
      trialLicense?: boolean[];
      cpLearningLicense?: boolean[];
      cpPlusLicense?: boolean[];
    };
  }[];
}

// DE Render
function deData(): DataStructure[] {
  const teacher = "teacher";
  const student = "student";
  return [
    {
      role: {
        id: Role.INSTRUCTOR,
        label: "Teacher Users",
        value: Role.INSTRUCTOR,
      },
      children: [
        {
          id: "idProvider",
          label: "ID Provider:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_cpa`,
              label: "CPA",
              value: IdProvider.CLASSPAD,
              isCheckbox: true,
            },
            {
              id: `${teacher}_iserv`,
              label: "IServ",
              value: IdProvider.ISERV,
              isCheckbox: true,
            },
            {
              id: `${teacher}_moodle`,
              label: "Moodle",
              value: IdProvider.MOODLE,
              isCheckbox: true,
            },
            {
              id: `${teacher}_mil`,
              label: "MIL",
              value: IdProvider.MIL,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "idProvider",
          label: "",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_eduplaces`,
              label: "EDUPLACES",
              value: IdProvider.EDUPLACES,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "tisMarketingConsent",
          label: "tisMarketingConsent:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_tisMarketingConsent_yes`,
              label: "Yes",
              value: true,
              isCheckbox: true,
            },
            {
              id: `${teacher}_tisMarketingConsent_no`,
              label: "No",
              value: false,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "tisProfilingConsent",
          label: "tisProfilingConsent:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_tisProfilingConsent_yes`,
              label: "Yes",
              value: true,
              isCheckbox: true,
            },
            {
              id: `${teacher}_tisProfilingConsent_no`,
              label: "No",
              value: false,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolLicenseCPLearning",
          label: "School License (CP Learning):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_school_license_learning_valid`,
              label: "Valid",
              value: BroadcastSchoolLicenseFilter.VALID,
              isCheckbox: true,
            },
            {
              id: `${teacher}_school_license_learning_expired`,
              label: "Expired",
              value: BroadcastSchoolLicenseFilter.EXPIRED,
              isCheckbox: true,
            },
            {
              id: `${teacher}_school_license_learning_not_exist`,
              label: "Not existing",
              value: BroadcastSchoolLicenseFilter.NOT_EXIST,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolLicenseCPPlus",
          label: "School License (CP Plus):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_school_license_plus_valid`,
              label: "Valid",
              value: BroadcastSchoolLicenseFilter.VALID,
              isCheckbox: true,
            },
            {
              id: `${teacher}_school_license_plus_expired`,
              label: "Expired",
              value: BroadcastSchoolLicenseFilter.EXPIRED,
              isCheckbox: true,
            },
            {
              id: `${teacher}_school_license_plus_not_exist`,
              label: "Not existing",
              value: BroadcastSchoolLicenseFilter.NOT_EXIST,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolId",
          label: "School:",
          isCheckbox: false,
          isSchoolId: true,
        },
      ],
    },
    {
      role: { id: Role.LEARNER, label: "Student Users", value: Role.LEARNER },
      children: [
        {
          id: "idProvider",
          label: "ID Provider:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_cpa`,
              label: "CPA",
              value: IdProvider.CLASSPAD,
              isCheckbox: true,
            },
            {
              id: `${student}_iserv`,
              label: "IServ",
              value: IdProvider.ISERV,
              isCheckbox: true,
            },
            {
              id: `${student}_moodle`,
              label: "Moodle",
              value: IdProvider.MOODLE,
              isCheckbox: true,
            },
            {
              id: `${student}_mil`,
              label: "MIL",
              value: IdProvider.MIL,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "idProvider",
          label: "",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_eduplaces`,
              label: "EDUPLACES",
              value: IdProvider.EDUPLACES,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "cpLearningLicense",
          label: "Individual License(CP Learning):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_individual_license_learning_yes`,
              label: "Yes",
              value: true,
              isCheckbox: true,
            },
            {
              id: `${student}_individual_license_learning_no`,
              label: "No",
              value: false,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "cpPlusLicense",
          label: "Individual License(CP Plus):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_individual_license_plus_yes`,
              label: "Yes",
              value: true,
              isCheckbox: true,
            },
            {
              id: `${student}_individual_license_plus_no`,
              label: "No",
              value: false,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolLicenseCPLearning",
          label: "School License (CP Learning):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_school_license_learning_valid`,
              label: "Valid",
              value: BroadcastSchoolLicenseFilter.VALID,
              isCheckbox: true,
            },
            {
              id: `${student}_school_license_learning_expired`,
              label: "Expired",
              value: BroadcastSchoolLicenseFilter.EXPIRED,
              isCheckbox: true,
            },
            {
              id: `${student}_school_license_learning_not_exist`,
              label: "Not existing",
              value: BroadcastSchoolLicenseFilter.NOT_EXIST,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolLicenseCPPlus",
          label: "School License (CP Plus):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_school_license_plus_valid`,
              label: "Valid",
              value: BroadcastSchoolLicenseFilter.VALID,
              isCheckbox: true,
            },
            {
              id: `${student}_school_license_plus_expired`,
              label: "Expired",
              value: BroadcastSchoolLicenseFilter.EXPIRED,
              isCheckbox: true,
            },
            {
              id: `${student}_school_license_plus_not_exist`,
              label: "Not existing",
              value: BroadcastSchoolLicenseFilter.NOT_EXIST,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "trialLicense",
          label: "Trial License:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_trial_license_plus_yes`,
              label: "Yes",
              value: true,
              isCheckbox: true,
            },
            {
              id: `${student}_trial_license_plus_no`,
              label: "No",
              value: false,
              isCheckbox: true,
            },
          ],
        },
      ],
    },
  ];
}

// FR Render
function frData(): DataStructure[] {
  const teacher = "teacher";
  const student = "student";
  return [
    {
      role: {
        id: Role.INSTRUCTOR,
        label: "Teacher Users",
        value: Role.INSTRUCTOR,
      },
      children: [
        {
          id: "idProvider",
          label: "ID Provider:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_cpa`,
              label: "CPA",
              value: IdProvider.CLASSPAD,
              isCheckbox: true,
            },
            {
              id: `${teacher}_gar`,
              label: "GAR",
              value: IdProvider.GAR,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "teachingLevel",
          label: "Teaching Level:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_teachinglevel_primary`,
              label: "Primary",
              value: "primary_level",
              isCheckbox: true,
            },
            {
              id: `${teacher}_teachinglevel_secondary`,
              label: "College",
              value: "secondary_school",
              isCheckbox: true,
            },
            {
              id: `${teacher}_teachinglevel_secondary_2`,
              label: "High School",
              value: "secondary_school_2",
              isCheckbox: true,
            },
            {
              id: `${teacher}_teachinglevel_tutoring`,
              label: "Tutoring",
              value: "tutoring_school",
              isCheckbox: true,
            },
          ],
        },
        {
          id: "teachingLevel",
          label: "",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_teachinglevel_university`,
              label: "Superior",
              value: "university",
              isCheckbox: true,
            },
            {
              id: `${teacher}_teachinglevel_other`,
              label: "Other",
              value: "other",
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolLicenseCPLearning",
          label: "School License (CP Learning):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_school_license_learning_valid`,
              label: "Valid",
              value: BroadcastSchoolLicenseFilter.VALID,
              isCheckbox: true,
            },
            {
              id: `${teacher}_school_license_learning_expired`,
              label: "Expired",
              value: BroadcastSchoolLicenseFilter.EXPIRED,
              isCheckbox: true,
            },
            {
              id: `${teacher}_school_license_learning_not_exist`,
              label: "Not existing",
              value: BroadcastSchoolLicenseFilter.NOT_EXIST,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolLicenseCPPlus",
          label: "School License (CP Plus):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${teacher}_school_license_plus_valid`,
              label: "Valid",
              value: BroadcastSchoolLicenseFilter.VALID,
              isCheckbox: true,
            },
            {
              id: `${teacher}_school_license_plus_expired`,
              label: "Expired",
              value: BroadcastSchoolLicenseFilter.EXPIRED,
              isCheckbox: true,
            },
            {
              id: `${teacher}_school_license_plus_not_exist`,
              label: "Not existing",
              value: BroadcastSchoolLicenseFilter.NOT_EXIST,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolId",
          label: "School:",
          isCheckbox: false,
          isSchoolId: true,
        },
      ],
    },
    {
      role: { id: Role.LEARNER, label: "Student Users", value: Role.LEARNER },
      children: [
        {
          id: "idProvider",
          label: "ID Provider:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_cpa`,
              label: "CPA",
              value: IdProvider.CLASSPAD,
              isCheckbox: true,
            },
            {
              id: `${student}_gar`,
              label: "GAR",
              value: IdProvider.GAR,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolLicenseCPLearning",
          label: "School License (CP Learning):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_school_license_learning_valid`,
              label: "Valid",
              value: BroadcastSchoolLicenseFilter.VALID,
              isCheckbox: true,
            },
            {
              id: `${student}_school_license_learning_expired`,
              label: "Expired",
              value: BroadcastSchoolLicenseFilter.EXPIRED,
              isCheckbox: true,
            },
            {
              id: `${student}_school_license_learning_not_exist`,
              label: "Not existing",
              value: BroadcastSchoolLicenseFilter.NOT_EXIST,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "schoolLicenseCPPlus",
          label: "School License (CP Plus):",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_school_license_plus_valid`,
              label: "Valid",
              value: BroadcastSchoolLicenseFilter.VALID,
              isCheckbox: true,
            },
            {
              id: `${student}_school_license_plus_expired`,
              label: "Expired",
              value: BroadcastSchoolLicenseFilter.EXPIRED,
              isCheckbox: true,
            },
            {
              id: `${student}_school_license_plus_not_exist`,
              label: "Not existing",
              value: BroadcastSchoolLicenseFilter.NOT_EXIST,
              isCheckbox: true,
            },
          ],
        },
        {
          id: "trialLicense",
          label: "Trial License:",
          isCheckbox: false,
          isRequired: true,
          children: [
            {
              id: `${student}_trial_license_plus_yes`,
              label: "Yes",
              value: true,
              isCheckbox: true,
            },
            {
              id: `${student}_trial_license_plus_no`,
              label: "No",
              value: false,
              isCheckbox: true,
            },
          ],
        },
      ],
    },
  ];
}

function BroadcastGroupBox({
  getDataFromBroadcast,
  getIsDisable,
  isSubmitted,
}: {
  getDataFromBroadcast: (data: any) => any;
  isSubmitted: boolean;
  getIsDisable: (data: boolean) => any;
}) {
  const [country, setCountry] = useState<Country>(Country.DE);
  const [data, setData] = useState<DataStructure[]>(deData());
  const [checkBoxItems, setCheckBoxItem] = useState<any>({});
  const [initData, setInitData] = useState<BroadcastObject>();
  const [schoolSelected, setSchoolSelected] = useState<SchoolOption>();
  const [errors, setErrors] = useState([
    { role: Role.INSTRUCTOR, errors: [] },
    { role: Role.LEARNER, errors: [] },
  ] as any);
  const [isTeacherChecked, setIsTeacherChecked] = useState(true);
  const [isStudentChecked, setIsStudentChecked] = useState(true);
  const [isChildTeacherChecked, setIsChildTeacherChecked] = useState(true);
  const [isChildStudentChecked, setIsChildStudentChecked] = useState(true);

  function onChangeCountry(event: any) {
    setCountry(event.target.value);
  }

  function onChangeSchool(newValue: SingleValue<SchoolOption>) {
    setSchoolSelected(newValue as SchoolOption);
    initData!.objects!.find(
      (e) => e.role === Role.INSTRUCTOR
    )!.filters.schoolId = newValue?.value;
    setInitData(initData);
  }

  // Data for each country
  useEffect(() => {
    if (country === Country.DE) {
      setData(deData);
    } else {
      setData(frData);
    }
  }, [country]);

  // Init Data
  useEffect(() => {
    // check items
    const updatedItems = { ...checkBoxItems };
    // data for BE
    const rawData = { country, objects: [] as any };
    for (const e of data) {
      const childRaw = { role: e.role.value, filters: {} as any };
      updatedItems[e.role.id] = true;
      for (const child of e.children) {
        const childId = child.id;
        if (!childRaw.filters[`${childId}`])
          childRaw.filters[`${childId}`] = [];
        if (child.isSchoolId) {
          childRaw.filters[`${childId}`] = "";
        }
        if (child.children) {
          for (const childA of child.children) {
            if (childA.id) updatedItems[childA.id] = true;
            childRaw.filters[`${childId}`].push(childA.value);
          }
        }
      }
      rawData.objects.push(childRaw);
    }
    setCheckBoxItem(updatedItems);
    setInitData(rawData);
    setSchoolSelected(undefined);
    getDataFromBroadcast(rawData);
    setIsTeacherChecked(true);
    setIsChildTeacherChecked(true);
    setIsStudentChecked(true);
    setIsChildStudentChecked(true);
    getIsDisable(false);
    setErrors([
      { role: Role.INSTRUCTOR, errors: [] },
      { role: Role.LEARNER, errors: [] },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSubmitted]);

  // disable button send message
  useEffect(() => {
    getIsDisable(false);

    if (!isTeacherChecked && !isStudentChecked) {
      getIsDisable(true);
    }

    const studentErrors = errors.find((e: any) => e.role === Role.LEARNER);
    const teacherErrors = errors.find((e: any) => e.role === Role.INSTRUCTOR);

    if (
      (isTeacherChecked && Number(teacherErrors.errors.length) > 0) ||
      (isStudentChecked && Number(studentErrors.errors.length) > 0)
    ) {
      getIsDisable(true);
    } else {
      getIsDisable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  function checkError(role: string) {
    // Check error
    const filters = initData?.objects.find((e) => e.role === role)?.filters;
    const childData = data.find((e) => e.role.id === role);
    const isRequiredArray = childData?.children
      .map((e) => {
        if (e.isRequired && !e.isSchoolId) {
          return e.id;
        }
      })
      .filter((e) => e !== undefined);
    let isNullValues = isRequiredArray;
    if (filters !== undefined) {
      const filtersKeys = Object.keys(filters!);
      isNullValues = isNullValues?.filter(function (obj) {
        return filtersKeys.indexOf(obj as any) === -1;
      });
    }
    isNullValues = isNullValues?.filter((e) => e !== undefined);
    if (Number(isNullValues?.length) !== Number(isRequiredArray?.length)) {
      if (role === Role.INSTRUCTOR) {
        setIsChildTeacherChecked(false);
      } else {
        setIsChildStudentChecked(false);
      }
    }
    if (Number(isNullValues?.length) === Number(isRequiredArray?.length)) {
      if (role === Role.INSTRUCTOR) {
        setIsChildTeacherChecked(true);
      } else {
        setIsChildStudentChecked(true);
      }
    }
    const existsError = errors.find((e: any) => e.role === role);
    if (existsError) {
      existsError.errors = isNullValues;
      setErrors(errors);
    }
  }

  const handleChildChange = (
    event: any,
    parentId: string,
    childId: string,
    subId: string,
    role: Role
  ) => {
    // Child checkbox
    const isChildChecked = checkBoxItems[childId] === true;
    let updatedItems = {
      ...checkBoxItems,
      [childId]: isChildChecked ? false : true,
    };
    // Parent checkbox
    const itemsExceptParent = JSON.parse(JSON.stringify(updatedItems));
    delete itemsExceptParent[`${parentId}`];
    const parentChildren = data.find((e) => e.role.id === parentId)?.children;
    let isRestChildChecked = false;
    if (parentChildren) {
      for (const child of parentChildren) {
        const childA = child.children;
        if (childA) {
          const ids = childA.map((childB) => childB.id);
          for (const id of ids) {
            if (itemsExceptParent[id] === true) {
              isRestChildChecked = true;
              if (role === Role.INSTRUCTOR) {
                setIsTeacherChecked(isRestChildChecked);
              } else {
                setIsStudentChecked(isRestChildChecked);
              }
              break;
            }
          }
        }
      }
      updatedItems = {
        ...updatedItems,
        [parentId]: isRestChildChecked,
      };
    }
    setCheckBoxItem(updatedItems);

    // Data
    let editedData: any = initData?.objects.find(
      (object) => object.role === role
    )?.filters;
    if (!editedData) {
      editedData = {};
    }
    const valuesOfSub = parentChildren
      ?.filter((e) => e.id === subId)
      .map((e) => e.children);

    if (!editedData[subId]) {
      editedData[subId] = [];
    }

    for (const valueOfSub of valuesOfSub!) {
      const value = valueOfSub?.find((e) => e.id === childId)?.value;
      if (value !== undefined || value !== null) {
        if (event.target.checked) {
          editedData[subId].push(value);
        } else {
          editedData[subId] = editedData[subId].filter(function (item: any) {
            return item !== value;
          });
          // remove if child is empty
          if (editedData[subId].length === 0) {
            delete editedData[subId];
          }
        }
      }
      const dataByRole = initData?.objects.find((element) => {
        return element.role === role;
      });
      if (dataByRole) {
        dataByRole.filters = editedData;
      } else {
        initData?.objects.push({ role, filters: editedData });
      }
      // remove empty filters
      let roleNeedToRemove = "";
      initData?.objects!.forEach((object) => {
        if (Object.keys(object.filters).length === 0) {
          roleNeedToRemove = object.role;
        }
      });
      if (initData?.objects) {
        initData.objects = initData.objects.filter(
          (e) => e.role !== roleNeedToRemove
        );
      }
      checkError(role);
      setInitData(initData);
      getDataFromBroadcast(initData);
    }
  };

  const handleClick = (event: any, parentId: string, role: Role) => {
    const isChecked = event.target.checked;
    const updatedItems = { ...checkBoxItems, [parentId]: isChecked };
    const initRole = { role, filters: {} as any };
    // Check/uncheck all child checkboxes related to this parent
    const parentItem = data.find((item) => item.role.id === parentId);
    if (parentItem?.children) {
      parentItem.children.forEach((child) => {
        if (child.children) {
          if (!child.isSchoolId) {
            if (!initRole.filters[child.id]) initRole.filters[child.id] = [];
          } else {
            initRole.filters[child.id] = "";
          }
          for (const childA of child.children) {
            if (childA.id) updatedItems[childA.id] = isChecked;
            if (isChecked) {
              initRole.filters[`${child.id}`].push(childA.value);
            }
          }
        }
      });
    }
    // Checkbox
    setCheckBoxItem(updatedItems);

    // Set data
    initData?.objects.push(initRole);
    setInitData(initData);
    getDataFromBroadcast(initData);

    // If uncheck
    if (!isChecked) {
      const restData: BroadcastObject = JSON.parse(JSON.stringify(initData));
      restData.objects = restData.objects.filter((e) => e.role !== role);
      setInitData(restData);
      getDataFromBroadcast(restData);

      // handle errors
      const childData = data.find((e) => e.role.id === role);
      const isRequiredArray = childData?.children
        .map((e) => {
          if (e.isRequired && !e.isSchoolId) {
            return e.id;
          }
        })
        .filter((e) => e !== undefined);
      const existsError = errors.find((e: any) => e.role === role);
      if (existsError) {
        existsError.errors = isRequiredArray;
        if (role === Role.INSTRUCTOR) {
          setIsTeacherChecked(false);
          setIsChildTeacherChecked(false);
        } else {
          setIsStudentChecked(false);
          setIsChildStudentChecked(false);
        }
        setErrors(errors);
      }
    } else {
      const existsError = errors.find((e: any) => e.role === role);
      if (existsError) {
        existsError.errors = [];
        if (role === Role.INSTRUCTOR) {
          setIsTeacherChecked(true);
          setIsChildTeacherChecked(true);
        } else {
          setIsStudentChecked(true);
          setIsChildStudentChecked(true);
        }
        setErrors(errors);
      }
    }
  };

  const ChildBox = ({
    child,
    parentId,
    childId,
    subId,
    role,
  }: {
    child: any;
    parentId: string;
    childId: string;
    subId: string;
    role: Role;
  }) => {
    return (
      <>
        <div className={styles.userChildrenCheckbox}>
          {child.isCheckbox && (
            <div className={styles.childLabelInputCheckbox}>
              <input
                id={childId}
                type="checkbox"
                checked={checkBoxItems[childId]}
                onChange={(e) =>
                  handleChildChange(e, parentId, childId, subId, role)
                }
                className={styles.inputCheckbox}
              />
              <label
                className={styles.childLabelInputCheckbox}
                htmlFor={childId}
              >
                <span className={styles.childLabelInputCheckbox__checkmark}>
                  <Check />
                </span>
                <span className={styles.childLabelInputCheckbox__text}>
                  {child.label}
                </span>
              </label>
            </div>
          )}
          {!child.isCheckbox && (
            <>
              <div className={styles.fullLabelNonCheckbox}>
                <label className={styles.labelNonCheckbox}>{child.label}</label>
                {child.label &&
                  errors &&
                  errors.map((error: any) => (
                    <>
                      {(!isTeacherChecked &&
                        !isStudentChecked &&
                        error.role === role &&
                        error.errors.includes(subId) && (
                          <span>Required</span>
                        )) ||
                        (((role === Role.INSTRUCTOR &&
                          isTeacherChecked &&
                          !isChildTeacherChecked) ||
                          (role === Role.LEARNER &&
                            isStudentChecked &&
                            !isChildStudentChecked)) &&
                          error.role === role &&
                          error.errors.includes(subId) && (
                            <span>Required</span>
                          ))}
                    </>
                  ))}
              </div>
            </>
          )}

          <div className={styles.childrenGroup}>
            {child.children &&
              (child.children.length === 4 ||
                child.children.length === 2 ||
                child.children.length === 1) &&
              child.children.map((subItem: any) => (
                <div className={styles.twoOrFourItems}>
                  <ChildBox
                    key={subItem.label}
                    child={subItem}
                    parentId={parentId}
                    childId={subItem.id}
                    subId={subId}
                    role={role}
                  />
                </div>
              ))}
            {child.children &&
              child.children.length === 3 &&
              child.children.map((subItem: any) => (
                <>
                  <div className={styles.threeItems}>
                    <ChildBox
                      key={subItem.label}
                      child={subItem}
                      parentId={parentId}
                      childId={subItem.id}
                      subId={subId}
                      role={role}
                    />
                  </div>
                </>
              ))}
            {child.isSchoolId && (
              <div className={styles.schoolSelect}>
                <SchoolSelect
                  id={child.id}
                  label={""}
                  querySchools={querySchools}
                  locale={country.toLowerCase()}
                  addManualSchool={true}
                  setAddManualSchool={function (addSchool: boolean): void {
                    throw new Error("Function not implemented.");
                  }}
                  isBroadcast={true}
                  value={schoolSelected}
                  onChange={function (
                    newValue: SingleValue<SchoolOption>,
                    actionMeta: ActionMeta<SchoolOption>
                  ): void {
                    onChangeSchool(newValue);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div onChange={onChangeCountry}>
        <div className={styles.countryContainer}>
          <h4>Country</h4>
          <div className={styles.countryItem}>
            <label className={styles.countryItemLabel}>
              Germany
              <input
                type="radio"
                value={Country.DE}
                name="country"
                checked={country === Country.DE}
              />
              <span className={styles.countryItemCheckmark}></span>
            </label>
          </div>
          <div className={styles.countryItem}>
            <label className={styles.countryItemLabel}>
              France
              <input
                type="radio"
                value={Country.FR}
                name="country"
                checked={country === Country.FR}
              />
              <span className={styles.countryItemCheckmark}></span>
            </label>
          </div>
        </div>

        <></>
      </div>
      {data.map((item) => (
        <div className={styles.userCheckbox} key={item.role.id}>
          <div className={styles.childLabelInputCheckbox}>
            <input
              id={item.role.id}
              type="checkbox"
              checked={checkBoxItems[item.role.id]}
              onChange={(e) => handleClick(e, item.role.id, item.role.value)}
            />
            <label
              className={styles.childLabelInputCheckbox}
              htmlFor={item.role.id}
            >
              <span className={styles.childLabelInputCheckbox__checkmark}>
                <Check />
              </span>
              <span className={styles.childLabelInputCheckbox__text}>
                {item.role.label}
              </span>
            </label>
          </div>

          {item.children &&
            item.children.map((subItem) => (
              <ChildBox
                key={subItem.label}
                child={subItem}
                parentId={item.role.id}
                childId={subItem.label}
                subId={subItem.id}
                role={item.role.value}
              />
            ))}
        </div>
      ))}
    </>
  );
}

export default BroadcastGroupBox;
