import {
  AdditionalLicense,
  License,
  LicenseCode,
  licenseFromPlain,
  PlainLicense,
} from "../types/License";
import axios from "axios";
import { Product } from "../types/Product";
import { arrayToFilename, dateToString, downloadFile } from "../utils/utils";
import { AdditionalLicenseType } from "../types/AdditionalLicenseType";
import { Role } from "../types/Role";

const url = "internal/v1/license-codes";

interface CreateLicenseCodesParams {
  durationInDays: number;
  amount: number;
  products: Product[];
  isPromotional: boolean;
  expiry?: Date;
  apiBaseUrl?: string;
}

export const createLicenseCodes = async ({
  durationInDays,
  amount,
  products,
  isPromotional,
  expiry,
  apiBaseUrl = "/app/api",
}: CreateLicenseCodesParams): Promise<() => void> => {
  const response = await axios.post<Blob>(
    `${apiBaseUrl}/${url}`,
    {
      durationInDays,
      amount,
      products,
      isPromotional,
      expiry,
    },
    {
      headers: {
        accept: "text/csv",
        "Content-Type": "application/json",
      },
    }
  );
  const productsForName = arrayToFilename(products);
  const timestamp = dateToString(new Date());
  const filename = `${timestamp}_${amount}x_${productsForName}${
    isPromotional ? "_promotional" : ""
  }_${durationInDays}days.csv`;
  const downloadUrl = URL.createObjectURL(new Blob([response.data]));
  return downloadFile(filename, downloadUrl);
};

interface RedeemLicenseParam {
  licenseCode: string;
  apiBaseUrl?: string;
}

export const redeemLicense = async ({
  licenseCode,
  apiBaseUrl = "/app/api",
}: RedeemLicenseParam): Promise<{ licenses: License[] }> => {
  try {
    const response = await axios.post<{ licenses: PlainLicense[] }>(
      `${apiBaseUrl}/${url}/${licenseCode}`
    );
    const licenses = response.data.licenses.map((l) => licenseFromPlain(l));
    return { licenses };
  } catch (err: any) {
    if (err.response && err.response.status === 429) {
      throw new Error("license.redeem.error.rate_limit_reached");
    }
    if (err.response && err.response.status === 403) {
      if (err.response.data.code === "101") {
        throw new Error("license.redeem.error.already_used");
      } else if (err.response.data.code === "102") {
        throw new Error("license.redeem.error.unknown_configuration");
      } else if (err.response.data.code === "103") {
        throw new Error("license.redeem.error.expired");
      } else if (err.response.data.code === "104") {
        throw new Error("license.redeem.error.invalid.license.product");
      }
    }
    throw new Error("license.redeem.error.default");
  }
};

interface RedeemAdditionalLicenseParam {
  type: AdditionalLicenseType;
  role?: Role;
  apiBaseUrl?: string;
}

export const redeemAdditionalLicense = async ({
  type,
  role,
  apiBaseUrl = "/app/api",
}: RedeemAdditionalLicenseParam): Promise<{ license: {} }> => {
  try {
    const response = await axios.post<{ license: {} }>(
      `${apiBaseUrl}/${url}/additional-license-code`,
      { type, role }
    );
    return response.data;
  } catch (err: any) {
    throw err;
  }
};

export const getActivatedLicenseCodesByUser = async (
  apiBaseUrl = "/app/api"
): Promise<LicenseCode[]> => {
  try {
    const response = await axios.get<LicenseCode[]>(`${apiBaseUrl}/${url}`);

    return response.data;
  } catch (err) {
    throw new Error(`get.activated.redeem.error.default ${err}`);
  }
};

export interface ClasswizEmulatorData {
  licenseId?: string;
  code?: string;
  email?: string;
  password?: string;
  validUntil?: string;
}

export const upsertClasswizEmulatorLicenseOfUser = async (
  userId: string | undefined,
  data: ClasswizEmulatorData,
  apiBaseUrl = "/app/api"
): Promise<AdditionalLicense> => {
  try {
    if (!userId) {
      throw new Error();
    }

    const response = await axios.put<AdditionalLicense>(
      `${apiBaseUrl}/${url}/additional-license-code/classwiz-emulator/users/${userId}`,
      {
        data,
      }
    );

    return response.data;
  } catch (err) {
    throw new Error(`upsert.class.wiz.emulator.license.error: ${err}`);
  }
};

export const getClasswizEmulatorLicenseOfUser = async (
  userId: string | undefined,
  apiBaseUrl = "/app/api"
): Promise<AdditionalLicense> => {
  try {
    if (!userId) {
      throw new Error();
    }

    const response = await axios.get<AdditionalLicense>(
      `${apiBaseUrl}/${url}/additional-license-code/classwiz-emulator/users/${userId}`
    );

    return response.data;
  } catch (err) {
    throw new Error(`get.class.wiz.emulator.license.of.user.error: ${err}`);
  }
};

export const deleteClasswizLicense = async (
  licenseId: string | undefined,
  apiBaseUrl = "/app/api"
): Promise<void> => {
  try {
    if (!licenseId) {
      return;
    }

    await axios.delete<void>(
      `${apiBaseUrl}/${url}/additional-license-code/classwiz-emulator/${licenseId}`
    );
  } catch (err) {
    throw new Error(`get.class.wiz.emulator.license.of.user.error: ${err}`);
  }
};
