import { UnmergeSchoolReponse } from "../../../../api/school";
import { Button } from "../../../Button/Button";
import { ComparisonBox } from "../../../MergeSchools/Steps/MergeSchoolsStep3/ComparisonBox";
import { StepBody } from "../../../Stepper/Stepper";
import styles from "./UnmergeSchoolsStep3.module.scss";

export interface UnmergeSchoolsStep3Props {
  handleEndProgress?(): void;
  data?: UnmergeSchoolReponse;
}
export const UnmergeSchoolsStep3 = ({
  handleEndProgress,
  data,
}: UnmergeSchoolsStep3Props) => {
  return (
    <StepBody
      noBackgroundColor={true}
      nextButton={
        <Button
          label={"Done"}
          btnStyle="secondary"
          onClick={handleEndProgress}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.container__intro}>
          <p>
            Unmerging school record successfully. The school record has been
            unmerged to 2 records below:
          </p>
        </div>
        <div className={styles.container__comparisonBox}>
          <div className={styles.container__comparisonBox__comparisonBoxOne}>
            <ComparisonBox
              title="Eduplaces School Record"
              data={data?.eduplacesSchool}
            />
          </div>

          <div className={styles.container__comparisonBox__comparisonBoxTwo}>
            <ComparisonBox
              title="CPA School Record"
              data={data?.cpaSchool}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </StepBody>
  );
};
