import { useFormikContext } from "formik";
import styles from "./DataStep.module.scss";
import React from "react";
import { i18n } from "@lingui/core";
import { Grid, GridItem } from "../Grid/Grid";
import { TextInput } from "../TextInput/TextInput";
import { SchoolSelect } from "../SchoolSelect/SchoolSelect";
import { querySchools } from "../../api/school";
import { UpdateUserSchoolSchema } from "../UserProfile/UserProfile";
import { AddSchool } from "../AddSchool/AddSchool";
import { TeachingLevelSelect } from "../TeachingLevelSelect/TeachingLevelSelect";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Country, Language } from "../../types/Country";
import { InstructorSubjectDataStep } from "./InstructorSubjectDataStep";
import { User } from "../../types/User";
import { TeachingSubjectSelect } from "../TeachingSubjectSelect/TeachingSubjectSelect";
import { SubjectType } from "../AddSchool/SubjectTypes";

export const validateInstructorSchoolData = (
  values: UpdateUserSchoolSchema
) => {
  let errors: any = {};
  if (
    !values.addManualSchool &&
    (values.school === null || values.school === undefined)
  ) {
    errors.school = "forms.validation.required";
  }

  const locale = detectLocale();
  const french = locale === Language.fr;
  if (!french) {
    if (!values.studentCount) {
      errors.studentCount = "forms.validation.required";
    }

    if (isNaN(Number(values.studentCount)) || Number(values.studentCount) < 1) {
      errors.studentCount = "forms.validation.invalid";
    }
  }
  return errors;
};

interface InstructorSchoolDataStepProps {
  setAddManualSchool: (addSchool: boolean) => void;
  addManualSchool: boolean;
  user?: User;
}

export const InstructorSchoolDataStep: React.FC<
  InstructorSchoolDataStepProps
> = ({
  setAddManualSchool,
  addManualSchool,
  user,
}: InstructorSchoolDataStepProps) => {
  const formik = useFormikContext<UpdateUserSchoolSchema>();
  const locale = detectLocale();
  const french = locale === Language.fr;

  return (
    <>
      <Grid>
        {user?.country === Country.DE && (
          <GridItem width="1">
            <InstructorSubjectDataStep locale={user.language} />
          </GridItem>
        )}
      </Grid>
      <p className={styles.info}>
        {i18n._({ id: "register.form.header.school" })}
      </p>

      <Grid>
        <GridItem width={french ? "1/1" : "2/3"}>
          <SchoolSelect
            id="school"
            label={i18n._({ id: "register.form.schoolsearch" })}
            value={formik.values.school}
            onChange={(school) => {
              formik.setFieldValue("school", school);
            }}
            onBlur={() => {
              formik.setFieldTouched("school", true, true);
            }}
            errorMessage={
              formik.touched.school && formik.errors.school
                ? i18n._({ id: formik.errors.school })
                : undefined
            }
            querySchools={querySchools}
            setAddManualSchool={setAddManualSchool}
            addManualSchool={addManualSchool}
          ></SchoolSelect>
        </GridItem>

        {user?.country === Country.FR && (
          <GridItem width="1/1">
            <TeachingSubjectSelect
              id="subjects"
              label="Matière enseignée"
              value={formik.values.subjects as SubjectType[]}
            />
          </GridItem>
        )}

        {!french && (
          <GridItem width="1/3">
            <TextInput
              id="studentCount"
              name="studentCount"
              type="number"
              min="1"
              label={i18n._({ id: "register.form.studentCount" })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.studentCount}
              errorMessage={
                formik.touched.studentCount && formik.errors.studentCount
                  ? i18n._({ id: formik.errors.studentCount })
                  : undefined
              }
            />
          </GridItem>
        )}
      </Grid>
      {addManualSchool && (
        <AddSchool
          onClose={async () => {
            setAddManualSchool(false);
          }}
        />
      )}
      <Grid>
        {french && (
          <GridItem width="1/1">
            <TeachingLevelSelect
              id="school"
              label={i18n._({ id: "register.form.schooltype" })}
            ></TeachingLevelSelect>
          </GridItem>
        )}
      </Grid>
    </>
  );
};
