import React from "react";

interface AutoLinkParagraphProps {
  text: string; // Define the prop type
}

export const AutoLinkParagraph: React.FC<AutoLinkParagraphProps> = ({
  text,
}) => {
  // Function to detect and replace URLs and email addresses with <a> tags
  const renderWithLinks = (text: string): React.ReactNode => {
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g;
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

    const combinedRegex = new RegExp(
      `${urlRegex.source}|${emailRegex.source}`,
      "g"
    );

    // Split the string by URLs and emails, and interleave with <a> elements
    const parts = text.split(combinedRegex);
    const matches = text.match(combinedRegex);

    if (!matches) {
      // If no matches, return the text as-is
      return text;
    }

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < matches.length && (
          <>
            {matches[index].startsWith("http") ? (
              <a
                href={matches[index]}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#3957a8",
                  fontWeight: "bold",
                  wordWrap: "break-word",
                }}
              >
                {matches[index]}
              </a>
            ) : (
              <a
                href={`mailto:${matches[index]}`}
                style={{ color: "#3957a8", fontWeight: "bold" }}
              >
                {matches[index]}
              </a>
            )}
          </>
        )}
      </React.Fragment>
    ));
  };

  return <p>{renderWithLinks(text)}</p>;
};
