import { useCallback, useState } from "react";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { FrenchInstructorRole, Role } from "../../types/Role";
import { PageHeader } from "../PageHeader/PageHeader";
import { Grid, GridItem } from "../Grid/Grid";
import styles from "./UserManagement.module.scss";
import { Country } from "../../types/Country";
import { IdProvider } from "../../types/IdProvider";
import { Button } from "../Button/Button";
import { SelectColumn, Column, SortColumn } from "react-data-grid";
import { TextInput } from "../TextInput/TextInput";
import { DatepickerField } from "../DatepickerField/DatepickerField";
import { Formik, Form } from "formik";
import Pagination from "../Paginate/Pagination";
import { MultiSelect } from "../MultiSelect/MultiSelect";

import {
  getAndSearchUsers,
  SchoolLicenseStatus,
  SearchUserCondition,
  UserStatus,
  UserWithStatus,
} from "./../../api/user";
import { isEmpty } from "lodash";
import { Table } from "./../Table/Table";
import { UserDetailModal } from "./UserDetail/UserDetail";
import {
  convertFrenchInstructorRole,
  convertStatusToText,
  dateWithoutTimezone,
  formatUTCDate,
} from "../../utils/utils";
import { ActivateUserAccountModal } from "./ActivateUserAccount/ActivateUserAccount";
import { DeleteUserAccountModal } from "./DeleteUserAccount/DeleteUserAccount";
import { Spinner } from "../Spinner/Spinner";
import { i18n } from "@lingui/core";
import { NoRecordFoundModal } from "./NoRecordFoundModal/NoRecordFoundModal";
import { SchoolSelect } from "../SchoolSelect/SchoolSelect";
import {
  querySchools,
  querySchoolsWithNoCountryFilter,
} from "../../api/school";

const columns: readonly Column<Row>[] = [
  {
    ...SelectColumn,
  },
  {
    key: "id",
    name: "UserID",
  },
  {
    key: "email",
    name: "Email",
  },
  {
    key: "type",
    name: "Role",
  },
  {
    key: "status",
    name: "Status",
  },
  {
    key: "createdAt",
    name: "Created Date",
  },
  {
    key: "lastLoginAt",
    name: "Last Login Date",
  },
  {
    key: "nickname",
    name: "Nickname",
  },
  {
    key: "firstname",
    name: "Firstname",
  },
  {
    key: "lastname",
    name: "Lastname",
  },
  {
    key: "country",
    name: "Country",
  },
  {
    key: "idProvider",
    name: "IDProvider",
  },
  {
    key: "externalId",
    name: "ExternalID",
  },
];

interface Row {
  id: string;
  email: string | undefined;
  type: Role | string;
  status: string;
  createdAt: string | undefined;
  lastLoginAt: string | undefined;
  nickname: string | undefined;
  firstname: string | undefined;
  lastname: string | undefined;
  country: Country;
  idProvider: IdProvider;
  externalId: string | undefined;
  tisMarketingConsent?: boolean;
  tisProfilingConsent?: boolean;
}

export interface GetUsersWithPagination {
  totalCount: number;
  totalPage: number;
  currentPage: number;
  next?: {
    page: number;
    limit: number;
  };
  previous?: {
    page: number;
    limit: number;
  };
  last?: {
    page: number;
    limit: number;
  };
  paginateData: UserWithStatus[];
  currentCountPerPage: number;
  range: number;
}

interface SortOptions {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
}

function rowKeyGetter(row: Row): any {
  return row.id;
}

const fieldsToClear = [
  { name: "country", value: [] },
  { name: "idProvider", value: [] },
  { name: "role", value: [] },
  { name: "userId", value: "" },
  { name: "firstName", value: "" },
  { name: "lastName", value: "" },
  { name: "externalId", value: "" },
  { name: "nickname", value: "" },
  { name: "email", value: "" },
  { name: "parentFirstName", value: "" },
  { name: "parentLastName", value: "" },
  { name: "parentEmail", value: "" },
  { name: "createdFrom", value: "" },
  { name: "createdTo", value: "" },
  { name: "status", value: [] },
  { name: "lastLoginFrom", value: "" },
  { name: "lastLoginTo", value: "" },
  // ----
  { name: "schools", value: null },
  { name: "tisMarketingConsent", value: [] },
  { name: "tisProfilingConsent", value: [] },
  { name: "teacherSchoolLicenseCPL", value: [] },
  { name: "teacherSchoolLicenseCPP", value: [] },
  { name: "individualLicenseCPL", value: [] },
  { name: "individualLicenseCPP", value: [] },
  { name: "trialLicense", value: [] },
  { name: "studentSchoolLicenseCPL", value: [] },
  { name: "studentSchoolLicenseCPP", value: [] },
];

export const UserManagement = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<string> => new Set()
  );

  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);

  const [sortOptions, setSortOptions] = useState<SortOptions>({
    page: 1,
    limit: 10,
    sortBy: "createdAt",
    sortOrder: "desc",
  });

  const [errorMessageCreatedDate, setErrorMessageCreatedDate] =
    useState<string>();
  const [errorMessageLastLoginDate, setErrorMessageLastLoginDate] =
    useState<string>();
  const [disableSearchButton, setDisableSearchButton] =
    useState<boolean>(false);
  const [disableActivateBtn, setDisableActivateBtn] = useState<boolean>(true);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState<boolean>(true);
  const [disableEditBtn, setDisableEditBtn] = useState<boolean>(true);

  const [searchCondition, setSearchCondition] = useState<SearchUserCondition>(
    {}
  );

  const [forcePage, setForcePage] = useState<number>();

  const [isShowUserDetailModal, setIsShowUserDetailModal] =
    useState<boolean>(false);
  const [isShowActiveUserAccountModal, setIsShowActiveUserAccountModal] =
    useState<boolean>(false);
  const [isShowDeleteUserAccountModal, setIsShowDeleteUserAccountModal] =
    useState<boolean>(false);
  const [isShowNoRecordFoundModal, setIsShowNoRecordFoundModal] =
    useState<boolean>(false);

  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [selectedAccount, setSelectedAccount] = useState<Row[]>([]);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  const [errorMsgSchool, setErrorMsgSchool] = useState<string>();

  const setRowsFromUsersData = useCallback((users: UserWithStatus[]) => {
    let rows: Row[] = [];

    for (let data of users) {
      rows.push({
        id: data.id,
        email: data.email,
        type:
          data.type === Role.LEARNER
            ? "Student"
            : convertFrenchInstructorRole(data.metadata?.role),
        status: convertStatusToText(data.isActivated),
        createdAt: data.createdAt
          ? formatUTCDate(new Date(data.createdAt).toUTCString())
          : "",
        lastLoginAt: data.lastLoginAt
          ? formatUTCDate(new Date(data.lastLoginAt).toUTCString())
          : "",
        nickname: data.nickname,
        firstname: data.firstname,
        lastname: data.lastname,
        country: data.country,
        idProvider: data.idProvider,
        externalId: data.externalId,
        tisMarketingConsent: data.metadata?.tisMarketingConsent,
        tisProfilingConsent: data.metadata?.tisProfilingConsent,
      });
    }
    setRows(rows);
  }, []);

  const fetchUsers = useCallback(
    async (sortOptions: SortOptions, searchCondition: SearchUserCondition) => {
      setIsShowSpinner(true);
      const users: GetUsersWithPagination | undefined = await getAndSearchUsers(
        sortOptions,
        searchCondition
      );

      if (users?.paginateData && users.paginateData.length > 0) {
        setRowsFromUsersData(users.paginateData);
        setTotalUsersCount(users.totalCount);
      }

      setIsShowSpinner(false);
    },
    [setRowsFromUsersData]
  );

  const onSortColumns = useCallback(
    (sortColumns: SortColumn[], searchCondition: SearchUserCondition) => {
      let sortOptionsUpdate = { ...sortOptions };

      if (!isEmpty(sortColumns)) {
        sortOptionsUpdate.sortBy = sortColumns[0].columnKey;
        sortOptionsUpdate.sortOrder = sortColumns[0].direction.toLowerCase() as
          | "asc"
          | "desc";
      }
      setSortOptions(sortOptionsUpdate);

      fetchUsers(sortOptionsUpdate, searchCondition);
      setSortColumns(sortColumns);
    },
    [fetchUsers, sortOptions]
  );

  return (
    <RestrictedTo roles={[Role.ADMINISTRATOR]} redirects={true}>
      <div className={styles.container}>
        <PageHeader title="User Management" />

        <div className={styles.userManagementContainer}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              country: [],
              idProvider: [],
              role: [],
              userId: "",
              firstName: "",
              lastName: "",
              externalId: "",
              nickname: "",
              email: "",
              parentFirstName: "",
              parentLastName: "",
              parentEmail: "",
              createdFrom: undefined,
              createdTo: undefined,
              status: [],
              lastLoginFrom: undefined,
              lastLoginTo: undefined,
              schools: {
                value: "",
                label: "",
                school: {
                  id: "",
                  name: "",
                  address: "",
                  addressAddition: "",
                  zip: "",
                  city: "",
                  addressCountry: "",
                  country: Country.DE,
                  type: "",
                  schoolId: "",
                  salesforceId: "",
                },
              },
              tisMarketingConsent: [],
              tisProfilingConsent: [],
              teacherSchoolLicenseCPL: [],
              teacherSchoolLicenseCPP: [],
              individualLicenseCPL: [],
              individualLicenseCPP: [],
              trialLicense: [],
              studentSchoolLicenseCPL: [],
              studentSchoolLicenseCPP: [],
            }}
            validate={(values) => {
              let isCreatedDateValid: boolean = true;
              let isLastLoginFromValid: boolean = true;

              if (
                values.createdFrom &&
                values.createdTo &&
                new Date(values.createdFrom).getTime() >
                  new Date(values.createdTo).getTime()
              ) {
                setErrorMessageCreatedDate("Invalid date");
                setDisableSearchButton(true);

                isCreatedDateValid = false;
              }

              if (
                values.lastLoginFrom &&
                values.lastLoginTo &&
                new Date(values.lastLoginFrom).getTime() >
                  new Date(values.lastLoginTo).getTime()
              ) {
                setErrorMessageLastLoginDate("Invalid date");
                setDisableSearchButton(true);
                isLastLoginFromValid = false;
              }

              if (isCreatedDateValid && errorMessageCreatedDate) {
                setErrorMessageCreatedDate(undefined);
              }

              if (isLastLoginFromValid && errorMessageLastLoginDate) {
                setErrorMessageLastLoginDate(undefined);
              }

              if (isCreatedDateValid && isLastLoginFromValid) {
                setDisableSearchButton(false);
              }
            }}
            onSubmit={async (values: any) => {
              setIsShowSpinner(true);

              const searchUserParams: SearchUserCondition = {
                country: values.country && values.country,
                idProvider: values.idProvider && values.idProvider,
                role: values.role && values.role,
                userId: values.userId && values.userId.trim(),
                firstName: values.firstName && values.firstName.trim(),
                lastName: values.lastName && values.lastName.trim(),
                externalId: values.externalId && values.externalId.trim(),
                nickname: values.nickname && values.nickname.trim(),
                email: values.email && values.email.trim(),
                parentFirstName:
                  values.parentFirstName && values.parentFirstName.trim(),
                parentLastName:
                  values.parentLastName && values.parentLastName.trim(),
                parentEmail: values.parentEmail && values.parentEmail.trim(),
                createdDateFrom: values.createdFrom
                  ? dateWithoutTimezone(values.createdFrom)
                  : undefined,
                createdTo: values.createdTo
                  ? dateWithoutTimezone(values.createdTo).replace(
                      "00:00:00",
                      "23:59:59"
                    )
                  : undefined,
                status: values.status ? values.status : undefined,
                lastLoginFrom: values.lastLoginFrom
                  ? dateWithoutTimezone(values.lastLoginFrom)
                  : undefined,
                lastLoginTo: values.lastLoginTo
                  ? dateWithoutTimezone(values.lastLoginTo).replace(
                      "00:00:00",
                      "23:59:59"
                    )
                  : undefined,
                schoolId: values.schools?.school.id || null,
                tisMarketingConsent:
                  values.tisMarketingConsent && values.tisMarketingConsent,
                tisProfilingConsent:
                  values.tisProfilingConsent && values.tisProfilingConsent,

                teacherSchoolLicenseCPL:
                  values.teacherSchoolLicenseCPL &&
                  values.teacherSchoolLicenseCPL,
                teacherSchoolLicenseCPP:
                  values.teacherSchoolLicenseCPP &&
                  values.teacherSchoolLicenseCPP,
                individualLicenseCPL:
                  values.individualLicenseCPL && values.individualLicenseCPL,
                individualLicenseCPP:
                  values.individualLicenseCPP && values.individualLicenseCPP,
                trialLicense: values.trialLicense && values.trialLicense,
                studentSchoolLicenseCPL:
                  values.studentSchoolLicenseCPL &&
                  values.studentSchoolLicenseCPL,
                studentSchoolLicenseCPP:
                  values.studentSchoolLicenseCPP &&
                  values.studentSchoolLicenseCPP,
              };

              console.log("searchUserParams", searchUserParams);

              const sortOptionsUpdate = { ...sortOptions };
              sortOptionsUpdate.page = 1;

              const searchedUsers = await getAndSearchUsers(
                sortOptionsUpdate,
                searchUserParams
              );

              // This condition to force component Pagination re-render.
              setForcePage((prev) => (prev === 0 ? undefined : 0));

              if (
                searchedUsers?.paginateData &&
                searchedUsers.paginateData.length === 0
              ) {
                setIsShowNoRecordFoundModal(true);
              }

              setRowsFromUsersData(searchedUsers.paginateData);
              setTotalUsersCount(searchedUsers.totalCount);

              setSearchCondition(searchUserParams);

              // Reset table and button after searching.
              setSelectedAccount([]);
              setSelectedRows(new Set());
              setDisableActivateBtn(true);
              setDisableDeleteBtn(true);
              setDisableEditBtn(true);
              setIsShowSpinner(false);
            }}
          >
            {({ handleChange, values, setFieldValue }) => (
              <Form>
                <div className={styles.filterContainer}>
                  <div className={styles.filterContainer}>
                    <Grid>
                      <GridItem width="1/3">
                        <MultiSelect
                          id="country"
                          options={[
                            {
                              value: Country.DE,
                              label: "DE",
                              data: Country.DE,
                            },
                            {
                              value: Country.FR,
                              label: "FR",
                              data: Country.FR,
                            },
                          ]}
                          label="Country"
                          value={values.country}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <MultiSelect
                          id="idProvider"
                          options={[
                            {
                              value: IdProvider.CLASSPAD,
                              label: "CPA",
                              data: IdProvider.CLASSPAD,
                            },
                            {
                              value: IdProvider.ISERV,
                              label: "Iserv",
                              data: IdProvider.ISERV,
                            },
                            {
                              value: IdProvider.MOODLE,
                              label: "Moodle",
                              data: IdProvider.MOODLE,
                            },
                            {
                              value: IdProvider.MIL,
                              label: "MIL",
                              data: IdProvider.MIL,
                            },
                            {
                              value: IdProvider.GAR,
                              label: "GAR",
                              data: IdProvider.GAR,
                            },
                            {
                              value: IdProvider.EDUPLACES,
                              label: "EDUPLACES",
                              data: IdProvider.EDUPLACES,
                            },
                          ]}
                          label="IDProvider"
                          value={values.idProvider}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <MultiSelect
                          id="role"
                          options={[
                            {
                              value: Role.INSTRUCTOR,
                              label: "Teacher",
                              data: Role.INSTRUCTOR,
                            },
                            {
                              value: Role.LEARNER,
                              label: "Student",
                              data: Role.LEARNER,
                            },
                            {
                              value: FrenchInstructorRole.SCHOOL_ADMIN,
                              label: "School Admin",
                              data: FrenchInstructorRole.SCHOOL_ADMIN,
                            },
                            {
                              value: FrenchInstructorRole.SCHOOL_ASSOCIATION,
                              label: "School Association",
                              data: FrenchInstructorRole.SCHOOL_ASSOCIATION,
                            },
                            {
                              value: FrenchInstructorRole.OTHER,
                              label: "Other",
                              data: FrenchInstructorRole.OTHER,
                            },
                          ]}
                          label="Role"
                          value={values.role}
                        />
                      </GridItem>
                    </Grid>
                  </div>

                  {/* ------------------------ */}

                  <div className={styles.filterContainerRow}>
                    <Grid>
                      <GridItem width="1/3">
                        <TextInput
                          id="userId"
                          label="User ID"
                          onChange={handleChange}
                          value={values.userId}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="firstName"
                          label="First Name"
                          onChange={handleChange}
                          value={values.firstName}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="lastName"
                          label="Last Name"
                          onChange={handleChange}
                          value={values.lastName}
                        />
                      </GridItem>
                    </Grid>
                  </div>

                  {/* ------------------------ */}
                  <div className={styles.filterContainerRow}>
                    <Grid>
                      <GridItem width="1/3">
                        <TextInput
                          id="externalId"
                          label="ExternalID"
                          onChange={handleChange}
                          value={values.externalId}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="nickname"
                          label="Nickname"
                          onChange={handleChange}
                          value={values.nickname}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="email"
                          label="Email"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </GridItem>
                    </Grid>
                  </div>

                  {/* ------------------------ */}
                  <div className={styles.filterContainerRow}>
                    <Grid>
                      <GridItem width="1/3">
                        <TextInput
                          id="parentFirstName"
                          label="Parent's Firstname"
                          onChange={handleChange}
                          value={values.parentFirstName}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="parentLastName"
                          label="Parent's Lastname"
                          onChange={handleChange}
                          value={values.parentLastName}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="parentEmail"
                          label="Parent's Email"
                          onChange={handleChange}
                          value={values.parentEmail}
                        />
                      </GridItem>
                    </Grid>
                  </div>

                  {/* ------------------------ */}
                  <div className={styles.filterContainerRow}>
                    <Grid>
                      <GridItem width="1/3">
                        <DatepickerField
                          label="Created Date From"
                          placeholderLabel="dd/mm/yyyy"
                          id="createdFrom"
                          name="createdFrom"
                          errorMessage={errorMessageCreatedDate}
                          value={values.createdFrom}
                          locale="en"
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <DatepickerField
                          label="Created Date To"
                          placeholderLabel="dd/mm/yyyy"
                          id="createdTo"
                          name="createdTo"
                          errorMessage={errorMessageCreatedDate}
                          value={values.createdTo}
                          locale="en"
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <MultiSelect
                          id="status"
                          options={[
                            {
                              value: UserStatus.ACTIVATED,
                              label: "Activated",
                              data: UserStatus.ACTIVATED,
                            },
                            {
                              value: UserStatus.NONACTIVATED,
                              label: "Non Activated",
                              data: UserStatus.NONACTIVATED,
                            },
                          ]}
                          label="Status"
                          value={values.status}
                        />
                      </GridItem>
                    </Grid>
                  </div>

                  {/* ---- */}
                  <div className={styles.filterContainerRow}>
                    <Grid>
                      <GridItem width="1/3">
                        <DatepickerField
                          label="Last Login from"
                          placeholderLabel="dd/mm/yyyy"
                          id="lastLoginFrom"
                          name="lastLoginFrom"
                          errorMessage={errorMessageLastLoginDate}
                          value={values.lastLoginFrom}
                          locale="en"
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <DatepickerField
                          label="Last Login To"
                          placeholderLabel="dd/mm/yyyy"
                          id="lastLoginTo"
                          name="lastLoginTo"
                          errorMessage={errorMessageLastLoginDate}
                          value={values.lastLoginTo}
                          locale="en"
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <SchoolSelect
                          id="schools"
                          label="School"
                          value={values.schools}
                          onChange={(school) => {
                            setFieldValue("schools", school);
                          }}
                          onBlur={() => {}}
                          querySchools={querySchools}
                          addManualSchool={false}
                          setAddManualSchool={() => {}}
                          isBroadcast={false}
                          errorMessage={errorMsgSchool}
                          locale={"de"}
                          disablePlaceholder={false}
                          onlyEnglishLang={true}
                          disableEnterSchoolManually={true}
                          noLocale={true}
                          querySchoolsWithNoCountryFilter={
                            querySchoolsWithNoCountryFilter
                          }
                        ></SchoolSelect>
                      </GridItem>
                    </Grid>
                  </div>
                </div>

                {/* ---------------- Teacher Advanced Search Conditions */}
                <p className={styles.searchTypeTitle}>
                  Teacher Advanced Search Conditions
                </p>
                <div className={styles.filterContainer}>
                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="tisMarketingConsent"
                        options={[
                          {
                            value: "true",
                            label: "True",
                            data: "true",
                          },
                          {
                            value: "false",
                            label: "False",
                            data: "false",
                          },
                          {
                            value: "null",
                            label: "Null",
                            data: "null",
                          },
                        ]}
                        label="tisMarketingConsent"
                        value={values.tisMarketingConsent}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <MultiSelect
                        id="tisProfilingConsent"
                        options={[
                          {
                            value: "true",
                            label: "True",
                            data: "true",
                          },
                          {
                            value: "false",
                            label: "False",
                            data: "false",
                          },
                          {
                            value: "null",
                            label: "Null",
                            data: "null",
                          },
                        ]}
                        label="tisProfilingConsent"
                        value={values.tisProfilingConsent}
                      />
                    </GridItem>
                  </Grid>

                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="teacherSchoolLicenseCPL"
                        options={[
                          {
                            value: SchoolLicenseStatus.VALID,
                            label: "Valid",
                            data: SchoolLicenseStatus.VALID,
                          },
                          {
                            value: SchoolLicenseStatus.EXPIRED,
                            label: "Expired",
                            data: SchoolLicenseStatus.EXPIRED,
                          },
                          {
                            value: SchoolLicenseStatus.NONEXISTING,
                            label: "Not Existing",
                            data: SchoolLicenseStatus.NONEXISTING,
                          },
                        ]}
                        label="School License (CP Learning)"
                        value={values.teacherSchoolLicenseCPL}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <MultiSelect
                        id="teacherSchoolLicenseCPP"
                        options={[
                          {
                            value: SchoolLicenseStatus.VALID,
                            label: "Valid",
                            data: SchoolLicenseStatus.VALID,
                          },
                          {
                            value: SchoolLicenseStatus.EXPIRED,
                            label: "Expired",
                            data: SchoolLicenseStatus.EXPIRED,
                          },
                          {
                            value: SchoolLicenseStatus.NONEXISTING,
                            label: "Not Existing",
                            data: SchoolLicenseStatus.NONEXISTING,
                          },
                        ]}
                        label="School License (CP Plus)"
                        value={values.teacherSchoolLicenseCPP}
                      />
                    </GridItem>
                  </Grid>
                </div>

                {/* ---------------- Student Advanced Search Conditions */}
                <p className={styles.searchTypeTitle}>
                  Student Advanced Search Conditions
                </p>
                <div className={styles.filterContainer}>
                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="individualLicenseCPL"
                        options={[
                          {
                            value: SchoolLicenseStatus.VALID,
                            label: "Valid",
                            data: SchoolLicenseStatus.VALID,
                          },
                          {
                            value: SchoolLicenseStatus.EXPIRED,
                            label: "Expired",
                            data: SchoolLicenseStatus.EXPIRED,
                          },
                          {
                            value: SchoolLicenseStatus.NONEXISTING,
                            label: "Not Existing",
                            data: SchoolLicenseStatus.NONEXISTING,
                          },
                        ]}
                        label="Individual License (CP Learning)"
                        value={values.individualLicenseCPL}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <MultiSelect
                        id="individualLicenseCPP"
                        options={[
                          {
                            value: SchoolLicenseStatus.VALID,
                            label: "Valid",
                            data: SchoolLicenseStatus.VALID,
                          },
                          {
                            value: SchoolLicenseStatus.EXPIRED,
                            label: "Expired",
                            data: SchoolLicenseStatus.EXPIRED,
                          },
                          {
                            value: SchoolLicenseStatus.NONEXISTING,
                            label: "Not Existing",
                            data: SchoolLicenseStatus.NONEXISTING,
                          },
                        ]}
                        label="Individual License (CP Plus)"
                        value={values.individualLicenseCPP}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <MultiSelect
                        id="trialLicense"
                        options={[
                          {
                            value: "true",
                            label: "Yes",
                            data: true,
                          },
                          {
                            value: "false",
                            label: "No",
                            data: false,
                          },
                        ]}
                        label="Trial License"
                        value={values.trialLicense}
                      />
                    </GridItem>
                  </Grid>

                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="studentSchoolLicenseCPL"
                        options={[
                          {
                            value: SchoolLicenseStatus.VALID,
                            label: "Valid",
                            data: SchoolLicenseStatus.VALID,
                          },
                          {
                            value: SchoolLicenseStatus.EXPIRED,
                            label: "Expired",
                            data: SchoolLicenseStatus.EXPIRED,
                          },
                          {
                            value: SchoolLicenseStatus.NONEXISTING,
                            label: "Not Existing",
                            data: SchoolLicenseStatus.NONEXISTING,
                          },
                        ]}
                        label="School License (CP Learning)"
                        value={values.studentSchoolLicenseCPL}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <MultiSelect
                        id="studentSchoolLicenseCPP"
                        options={[
                          {
                            value: SchoolLicenseStatus.VALID,
                            label: "Valid",
                            data: SchoolLicenseStatus.VALID,
                          },
                          {
                            value: SchoolLicenseStatus.EXPIRED,
                            label: "Expired",
                            data: SchoolLicenseStatus.EXPIRED,
                          },
                          {
                            value: SchoolLicenseStatus.NONEXISTING,
                            label: "Not Existing",
                            data: SchoolLicenseStatus.NONEXISTING,
                          },
                        ]}
                        label="School License (CP Plus)"
                        value={values.studentSchoolLicenseCPP}
                      />
                    </GridItem>
                  </Grid>
                </div>

                <div className={styles.btnGroup}>
                  <Grid>
                    <GridItem width="2/3" className={styles.groupBtn1}>
                      <Button
                        label="edit"
                        disabled={disableEditBtn}
                        onClick={() => setIsShowUserDetailModal(true)}
                      ></Button>
                      <Button
                        label="activate"
                        onClick={() => setIsShowActiveUserAccountModal(true)}
                        disabled={disableActivateBtn}
                      ></Button>
                      <Button
                        label="delete"
                        btnStyle="danger"
                        disabled={disableDeleteBtn}
                        onClick={() => setIsShowDeleteUserAccountModal(true)}
                      ></Button>
                    </GridItem>

                    <GridItem width="1/3" className={styles.groupBtn2}>
                      <Button
                        label="clear"
                        onClick={() => {
                          fieldsToClear.forEach((data) => {
                            handleChange({
                              target: { name: data.name, value: data.value },
                            });
                          });
                          setErrorMessageCreatedDate(undefined);
                          setErrorMessageLastLoginDate(undefined);
                          setErrorMsgSchool(undefined);
                          setDisableSearchButton(false);
                        }}
                      ></Button>
                      <Button
                        type="submit"
                        label="search"
                        disabled={disableSearchButton}
                      ></Button>
                    </GridItem>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>

          <div className={styles.tableContainer}>
            <Table
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              sortColumns={sortColumns}
              onSortColumnsChange={(sortColumns) =>
                onSortColumns(sortColumns, searchCondition)
              }
              rowKeyGetter={rowKeyGetter}
              selectedRows={selectedRows}
              onSelectedRowsChange={(selectedRow, selectedData: Row[]) => {
                setSelectedRows(selectedRow);
                setSelectedAccount(selectedData);
                // check all selected account have status is Activated => setDisableActivateBtn = false
                if (
                  selectedData.filter((item) => item.status === "Activated")
                    .length === 0
                ) {
                  setDisableActivateBtn(false);
                } else {
                  setDisableActivateBtn(true);
                }

                if (selectedData.length === 0) {
                  setDisableActivateBtn(true);
                }

                if (
                  selectedData.length === 0 ||
                  selectedData.filter((item) => item.externalId).length > 0
                ) {
                  setDisableDeleteBtn(true);
                } else {
                  setDisableDeleteBtn(false);
                }

                if (selectedData.length === 1) {
                  setSelectedUserId(selectedData[0].id);
                  setDisableEditBtn(false);
                } else {
                  setDisableEditBtn(true);
                }
              }}
              onCellClick={(event) => {
                if (event.column.key !== "select-row") {
                  setIsShowUserDetailModal(true);
                  setSelectedUserId(event.row.id);
                  setSelectedAccount([event.row]);
                }
              }}
            />

            {rows.length > 0 && (
              <Pagination
                items={rows}
                totalCount={totalUsersCount}
                forcePage={forcePage}
                getItemsPerPage={async (itemsPerPage: number) => {
                  // limit
                  let sortOptionsUpdate = { ...sortOptions };

                  sortOptionsUpdate.limit = itemsPerPage;
                  sortOptionsUpdate.page = 1;
                  setSortOptions(sortOptionsUpdate);

                  await fetchUsers(sortOptionsUpdate, searchCondition);

                  setForcePage((prev) => (prev === 0 ? undefined : 0));
                }}
                getPageNumber={async (pageNumber: number) => {
                  // page
                  let sortOptionsUpdate = { ...sortOptions };

                  sortOptionsUpdate.page = pageNumber;
                  setSortOptions(sortOptionsUpdate);

                  await fetchUsers(sortOptionsUpdate, searchCondition);
                }}
              />
            )}

            {isShowUserDetailModal && (
              <UserDetailModal
                isShowing={isShowUserDetailModal}
                setVisible={(isShowing) => setIsShowUserDetailModal(isShowing)}
                selectedUserId={selectedUserId}
                setIsShowActiveUserAccountModal={
                  setIsShowActiveUserAccountModal
                }
                setIsShowDeleteUserAccountModal={
                  setIsShowDeleteUserAccountModal
                }
              />
            )}

            {isShowActiveUserAccountModal && (
              <ActivateUserAccountModal
                isShowing={isShowActiveUserAccountModal}
                setVisible={(isShowing) => {
                  setIsShowActiveUserAccountModal(isShowing);
                }}
                selectedAccount={selectedAccount.map((item) => ({
                  id: item.id,
                  email: item.email,
                  type: item.type,
                  status: item.status,
                  nickname: item.nickname,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  country: item.country,
                  idProvider: item.idProvider,
                }))}
              />
            )}

            {isShowDeleteUserAccountModal && (
              <DeleteUserAccountModal
                isShowing={isShowDeleteUserAccountModal}
                setVisible={(isShowing) => {
                  setIsShowDeleteUserAccountModal(isShowing);
                }}
                selectedAccount={selectedAccount.map((item) => ({
                  id: item.id,
                  email: item.email,
                  type: item.type,
                  status: item.status,
                  nickname: item.nickname,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  country: item.country,
                  idProvider: item.idProvider,
                  tisMarketingConsent: item.tisMarketingConsent,
                  tisProfilingConsent: item.tisProfilingConsent,
                }))}
              />
            )}

            {isShowNoRecordFoundModal && (
              <NoRecordFoundModal
                isShowing={isShowNoRecordFoundModal}
                setVisible={setIsShowNoRecordFoundModal}
              />
            )}

            <Spinner
              type="fullPage"
              title={i18n._({ id: "loading" })}
              visible={isShowSpinner}
            />
          </div>
        </div>
      </div>
    </RestrictedTo>
  );
};
