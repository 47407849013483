import { FAQItemProps } from "./FAQItem";

export const datensicherheitData: FAQItemProps[] = [
  {
    faqTitle: "Wie werden meine Daten verarbeitet?",
    content: [
      {
        textList: [
          {
            text: "Bitte beachte unsere Datenschutzerklärung: https://casio-education.eu/datenschutz",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wie werden meine Daten geschützt?",
    content: [
      {
        textList: [
          {
            text: "Bitte beachte unsere Datenschutzerklärung: https://casio-education.eu/datenschutz",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Ist CASIO Education DSGVO-konform?",
    content: [
      {
        textList: [
          {
            text: "Bitte beachte unsere Datenschutzerklärung: https://casio-education.eu/datenschutz",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Welche anderen Unternehmen erhalten Daten von mir, wenn ich mich bei CASIO Education registriere?",
    content: [
      {
        textList: [
          {
            text: "Bitte beachte unsere Datenschutzerklärung: https://casio-education.eu/datenschutz",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Werden meine Daten für Werbezwecke verwendet?",
    content: [
      {
        textList: [
          {
            text: "Bitte beachte unsere Datenschutzerklärung: https://casio-education.eu/datenschutz",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Brauche ich ein AVV (Auftragsverarbeitungsvertrag)?",
    content: [
      {
        textList: [
          {
            text: "Ja, wir empfehlen dir oder einer vertretenden Person deiner Schule dringend, eine AVV bei uns zu unterzeichnen. Ein druckfähiges pdf findest du hier: https://cdn.sanity.io/files/4u67rqco/production/bd8e3acf06b2f5d4bbf80e1a65ea918a6b5bac50.pdf",
          },
          {
            text: "Bitte lade dieses herunter, unterschreibe es und sende es an dataprotection@casio.de",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Welche Rolle spiele ich bei der Einführung der Software in meiner Schule?",
    content: [
      {
        textList: [
          {
            text: "Deine Schule ist für die Datenverarbeitung verantwortlich. Bitte informiere dich bei deiner Landes- und Schulbehörde über die Vorgehensweise. Als Vertreter*in deiner Schule bei der Einführung einer Software agierst du uns gegenüber als datenverantwortliche Person.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const anmeldenData: FAQItemProps[] = [
  {
    faqTitle: "Welche Anmeldemöglichkeiten habe ich? ",
    content: [
      {
        textList: [
          { text: "CASIO Education-Account" },
          { text: "IServ-Account" },
          { text: "Mathematik im Leben-Account " },
          { text: "Moodle-Account " },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Melde dich mit einem CASIO Education-Account an",
    content: [
      {
        textList: [
          {
            text: "Welche Informationen benötige ich, um mich anzumelden?",
            firstLevel: {
              text: [
                "Deine E-Mail-Adresse und dein zuvor gewähltes Passwort. (Wenn du diese Informationen von deiner Lehrkraft erhalten hast, wende dich bitte an deine Lehrkraft).",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Was passiert, wenn ich mein Passwort vergessen habe?",
            firstLevel: {
              text: [
                `Ein Passwort kannst du über "Konto Wiederherstellen" anfordern. https://casio-education.eu/auth/recovery. (Wenn du diese Informationen von deiner Lehrkraft erhalten hast, wende dich bitte an deine Lehrkraft.)`,
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Was passiert, wenn ich meine E-Mail-Adresse vergessen habe?",
            firstLevel: {
              text: [
                "Wenn du die E-Mail-Adresse vergessen hast, die du zuvor verwendet hast, durchsuche bitte den Posteingangsordner nach Registrierungs-E-Mails von uns. Wenn du deine E-Mail-Informationen nicht finden kannst, wende dich bitte an casio.education@casio.de und wir können versuchen, dein Konto zu finden.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Ich habe meine E-Mail-Adresse und mein Passwort vergessen. Was kann ich tun?",
            firstLevel: {
              text: [
                "Wenn du die E-Mail-Adresse vergessen hast, die du zuvor verwendet hast, durchsuche bitte den Posteingangsordner nach Registrierungs-E-Mails von uns. Sobald du deine E-Mail-Adresse hast, kannst du versuchen, das Passwort zurückzusetzen https://casio-education.eu/auth/recovery. Wenn du deine E-Mail-Informationen nicht finden kannst, wende dich bitte an casio.education@casio.de und wir können versuchen, dein Konto zu finden.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Obwohl ich die richtige E-Mail-Adresse und das richtige Passwort habe, kann ich mich nicht anmelden. Warum ist das so?",
            firstLevel: {
              text: [
                "Bitte wende dich an: casio.education@casio.de und beschreibe dein Problem. Wir helfen dir dann weiter.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Nach dem Einloggen kann ich nicht mehr auf CASIO Education zugreifen. Was muss ich tun, um das Dashboard zu sehen?",
            firstLevel: {
              text: [
                "Bitte lösche die Cookies und versuche erneut, dich anzumelden. Wenn es immer noch nicht funktioniert, wende dich bitte an: casio.education@casio.de und beschreibe dein Problem. Wir helfen dir dann weiter.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Meine E-Mail-Adresse hat sich geändert. Kann ich es in meinen Kontoeinstellungen ändern?",
            firstLevel: {
              text: [
                "Das ist nicht möglich. Bitte wende dich an: casio.education@casio.de und beschreibe dein Problem. Wir helfen dir dann weiter.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Ich möchte mein Passwort ändern, wo kann ich das tun?",
            firstLevel: {
              text: [
                `Bitte logge dich in dein CASIO Education-Account ein und klicke auf dein Profil in der oberen rechten Ecke. Wähle "Mein Profil". Unten findest du den Bereich "Mein Konto". Hier hast du die Möglichkeit, dein Passwort zu ändern.`,
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Ich habe das Gefühl, dass meine Login-Daten missbraucht wurden. An wen kann ich mich wenden, um dies überprüfen zu lassen?",
            firstLevel: {
              text: [
                "Bitte wende dich an: casio.education@casio.de und beschreibe dein Problem. Wir helfen dir dann weiter.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Welche Funktionen von CASIO Education kann ich nach dem Login kostenlos nutzen?",
            firstLevel: {
              text: [
                "Die Basisversion von ClassPad.net einschließlich ClassPad Math",
                "Einfache Klassenverwaltung",
                "Der Mathe-Marathon",
                "Lehrkräfte können sich hier über die kostenlosen Angebote informieren: https://casio-education.eu/support",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
        ],
        listStyle: "discsubitems",
      },
    ],
  },
  {
    faqTitle: "Anmeldung mit IServ-Account",
    content: [
      {
        textList: [
          {
            text: "Wie kann ich mich mit IServ anmelden?",
            firstLevel: {
              text: [
                "IServ-Schulen können das CASIO Education-Modul vom Schuladministrator aktivieren lassen. Die Anmeldung erfolgt dann ganz einfach mit einem Klick auf das Modul. Weitere Informationen zur IServ-Anbindung findest du hier: https://casio-education.eu/iserv",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Die Registrierung funktioniert nicht, an wen kann ich mich wenden?",
            firstLevel: {
              text: [
                "Deine Registrierung erfolgt bei iServ, nicht bei CASIO Education. Bitte wende dich an deinen Schuladministrator, um den Status deines iServ-Kontos zu überprüfen.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Welche Funktionen von CASIO Education kann ich nach dem Login kostenlos nutzen?",
            firstLevel: {
              text: [
                "Die Basisversion von ClassPad.net einschließlich ClassPad Math",
                "Einfache Klassenverwaltung",
                "Der Mathe-Marathon",
                "Lehrkräfte können sich hier über die kostenlosen Angebote informieren: https://casio-education.eu/support",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
        ],
        listStyle: "discsubitems",
      },
    ],
  },
  {
    faqTitle: "Anmeldung mit einem Mathe im Leben-Account",
    content: [
      {
        textList: [
          {
            text: "Wie kann ich mich mit einem Mathe im Leben-Account anmelden?",
            firstLevel: {
              text: [
                `Du kannst im Login-Bereich auf die Schaltfläche "Mathe im Leben" klicken und im nächsten Fenster einfach deine Anmeldedaten eingeben.`,
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Ich habe nur ein Mathe im Advent-Konto, kann ich mich damit bei CASIO Education anmelden?",
            firstLevel: {
              text: [
                "Ja, du kannst auch deine Daten von Mathe im Advent für die Registrierung verwenden.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Kann ich das Konto von Mathe im Leben oder Mathe im Advent nur während des Mathe-Marathons verwenden, um auf CASIO Education zuzugreifen?",
            firstLevel: {
              text: [
                "Nein, du kannst dich mit deinem Konto auch außerhalb des Mathe-Marathons anmelden.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Welche Funktionen von CASIO Education kann ich nach dem Login kostenlos nutzen?",
            firstLevel: {
              text: [
                "Die Basisversion von ClassPad.net einschließlich ClassPad Math",
                "Einfache Klassenverwaltung",
                "Der Mathe-Marathon",
                "Lehrkräfte können sich hier über die kostenlosen Angebote informieren: https://casio-education.eu/support",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
        ],
        listStyle: "discsubitems",
      },
    ],
  },
  {
    faqTitle: "Anmeldung mit Moodle ",
    content: [
      {
        textList: [
          {
            text: "Wie kann ich mich mit Moodle anmelden?",
            firstLevel: {
              text: [
                "Moodle-Schulen können das CASIO Education-Modul vom Schuladministrator aktivieren lassen. Die Anmeldung erfolgt dann ganz einfach mit einem Klick auf das Modul. Weitere Informationen zur Moodle-Anbindung findest du hier: https://casio-education.eu/moodle",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Die Registrierung funktioniert nicht, an wen kann ich mich wenden?",
            firstLevel: {
              text: [
                "Deine Anmeldung erfolgt über moodle, nicht über CASIO Education. Bitte wende dich an deinen Schuladministrator, um den Status deines Moodle-Kontos zu überprüfen.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Die Schnittstelle zwischen Moodle und CASIO Education scheint nicht zu funktionieren, was kann ich tun?",
            firstLevel: {
              text: [
                "Bitte erkundige dich zunächst bei deinem Schuladministrator nach dem Status des Moduls. Für weitere Unterstützung kann sich dein Schuladministrator an casio.education@casio.de wenden.",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
          {
            text: "Welche Funktionen von CASIO Education kann ich nach dem Login kostenlos nutzen?",
            firstLevel: {
              text: [
                "Die Basisversion von ClassPad.net einschließlich ClassPad Math ",
                "Einfache Klassenverwaltung",
                "Der Mathe-Marathon",
                "Lehrkräfte können sich hier über die kostenlosen Angebote informieren: https://casio-education.eu/support",
              ],
              listStyle: "lower-alpha",
              isHiding: true,
            },
          },
        ],
        listStyle: "discsubitems",
      },
    ],
  },
];

export const kontoFunktionalitätenData: FAQItemProps[] = [
  {
    faqTitle: "Kann ich meine Daten in meinem Account selbst ändern?",
    content: [
      {
        textList: [
          {
            text: `Wenn du dich selbst registriert hast, klicke auf das Symbol oben rechts, nachdem du dich bei CASIO Education angemeldet hast. Klicke dann auf "Mein Profil". Wenn dein Konto von deiner Lehrkraft erstellt wurde, bespreche dies bitte mit deiner Lehrkraft. Hier kannst du:`,
            firstLevel: {
              text: [
                "deinen Vornamen ändern",
                "deinen Nachnamen ändern",
                "deinen Rufnamen ändern",
                "dein Passwort ändern",
                "dein Konto löschen",
              ],
              listStyle: "square",
            },
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const classPadLearningData: FAQItemProps[] = [
  {
    faqTitle: "Was ist ClassPad Learning?",
    content: [
      {
        textList: [
          {
            text: "ClassPad Learning ist eine Lern- und Übungssoftware für Mathematik",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wofür benötige ich ClassPad Learning?",
    content: [
      {
        textList: [
          {
            text: "Mit ClassPad Learning kann man Mathematik üben, sich neue Inhalte erschließen, Aufgaben wiederholen. Es kann im Unterricht, für Hausaufgaben und für das selbständige Lernen eingesetzt werden.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Was kostet ClassPad Learning?",
    content: [
      {
        textList: [
          {
            text: "Die unverbindliche Preisempfehlung für eine Jahreseinzellizenz liegt bei 10,-€.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wo kann ich eine Lizenz für ClassPad Learning kaufen?",
    content: [
      {
        textList: [
          {
            text: "In unserem Webshop nach erfolgter Registrierung.",
          },
          {
            text: "Im Schulfachhandel. Weitere Infos gibt es hier: https://casio-education.eu/haendler",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Was sind die Vorteile von ClassPad Learning?",
    content: [
      {
        textList: [
          {
            text: "Schülervorteile",
            firstLevel: {
              text: [
                "Schrittweises Feedback und gestaffelte Hilfen, Tipps und Hinweise",
                "Schwächen selbst erkennen und Unterstützung anfragen",
                "Vielseitig einsetzbares Übungswerkzeug - geeignet zum Selbststudium oder unter Lehreraufsicht",
                "Sicherheit und Selbstbewusstsein beim Lösen von Aufgaben in individuellem Lerntempo",
                "Ruhiges und fokussiertes Lernen",
                "Eigenkontrolle durch direktes Feedback",
                "Verhindert das Einüben falsch verstandener Regeln, die später schwer zu korrigieren sind",
                "Möglichkeit zum Üben in zeitlich begrenzten Tests zur Einschätzung des eigenen Kenntnisstands",
              ],
              listStyle: "square",
            },
          },
          {
            text: "Lehrervorteile",
            firstLevel: {
              text: [
                "Schnelle, detaillierte Übersicht zum Lernstand und -fortschritt (Historie, Fehler und Erfolge) einzelner Schüler und der gesamten Klasse",
                "Binnendifferenzierung und dadurch gezielte Unterstützung schwacher und Forderung starker Schüler",
                "Schnelle Analyse/Diagnose sowie einfaches Erkennen schwieriger Aufgaben zur Ermittlung des Kenntnisstandes",
                "An den Lernstand angepasste gezielte Unterrichtsgestaltung",
                "Aktivierung aller Schüler und Anregung der Diskussion durch Live-Abstimmung und Echtzeit-Feedback",
                "Interaktive und visuelle Darstellung zur Förderung des entdeckenden Lernens",
                "Zeitersparnis durch Entlastung von Routineaufgaben. z.B. Berichtswesen, Lernstandsdokumentation, Vorbereitung Elterngespräche",
                "Automatische Hausaufgabenkontrolle mit Überblick zu individuellen Leistungen und benötigen Zeiten",
                "Gut geeignet für neue Lernformen (wie Flipped Classroom)",
                "Mehr Ruhe im Klassenraum",
                "Verbesserung der Schülerleistung",
              ],
              listStyle: "square",
            },
          },
          {
            text: "Elternvorteile",
            firstLevel: {
              text: [
                "Ergänzende Unterstützung für den Mathematikunterricht",
                "Kenntnisstand des Kindes wird transparenter",
                "Ermöglicht fundierte Kommunikation mit der Lehrkraft",
                "Einfache Unterstützung des Kindes durch übersichtliche Bedieneroberfläche",
                "Fortschritte im Lernerfolg",
                "Preiswerte Anschaffung",
                "Funktioniert auf unterschiedlichen Geräten, so kann auf additionale Investitionen verzichtet werden",
              ],
              listStyle: "square",
            },
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Welche technischen Voraussetzungen gibt es?",
    content: [
      {
        textList: [
          {
            text: "Browserbasiert, Onlineverbindung notwendig",
          },
          {
            text: "Plattform- und endgeräteunabhängiger Einsatz z.B. für Laptop, PC, Chromebook, Tablet (mindestens 7,9” / 20 cm (z. B. iPad mini) - die Darstellung auf Smartphones ist sehr stark eingeschränkt und nicht empfehlenswert)",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wie viele Aufgaben gibt es insgesamt in ClassPad Learning?",
    content: [
      {
        textList: [
          {
            text: "ca. 25.000 Aufgaben für die Stufen 5-12.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ClassPad Learning auch im Unterricht verwendet werden?",
    content: [
      {
        textList: [
          {
            text: "Natürlich.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ClassPad Learning auch für Hausaufgaben verwendet werden?",
    content: [
      {
        textList: [
          {
            text: "Es können Aufgaben an den Klassenverbund oder einzelnen Schüler*innen vergeben werden, die als Hausaufgaben zu bearbeiten sind.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ClassPad Learning auch zum selber Lernen verwendet werden?",
    content: [
      {
        textList: [
          {
            text: "Natürlich.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Für welche Klassenstufen ist ClassPad Learning geeignet?",
    content: [
      {
        textList: [
          {
            text: "Aktuell decken wir die Stufen 5-12 ab. Weitere Inhalte werden aber folgen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Für welche Schulformen ist ClassPad Learning geeignet?",
    content: [
      {
        textList: [
          {
            text: "ClassPad Learning ist nicht schulformabhängig. Es kann in allen Schulformen eingesetzt werden.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Gibt es in ClassPad Learning auch Aufgaben für andere Fächer? Wie zum Beispiel Physik?",
    content: [
      {
        textList: [
          {
            text: "Aktuell ist ClassPad Learning eine reine Mathematik-Software.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Ist eine Binnendifferenzierung bei ClassPad Learning möglich?",
    content: [
      {
        textList: [
          {
            text: "Ja, es ist möglich durch die Lernstandsanalysen einzelner Schüler*innen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Kann ich als Lehrkraft einer ganzen Klasse eine Aufgabe zuweisen?",
    content: [
      {
        textList: [
          {
            text: "Ja, wenn die Klasse zuvor eingerichtet wurde und die Schüler*innen der Klasse beigetreten sind.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ich als Lehrkraft einzelnen SuS Aufgaben zuweisen?",
    content: [
      {
        textList: [
          {
            text: "Ja, das ist möglich.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Inwiefern kann ich als Lehrkraft Lernstände von SuS sehen?",
    content: [
      {
        textList: [
          {
            text: "Es gibt eine umfassende Lernstandsanalyse von eingerichteten Klassen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Welche Kontrollmöglichkeit habe ich als Erziehungsberechtigter?",
    content: [
      {
        textList: [
          {
            text: "Bitte logge dich mit dem Account deines Kindes ein, um z. B. die aufgegebenen Hausaufgaben zu kontrollieren.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wo finde ich ClassPad Learning in CASIO Education?",
    content: [
      {
        textList: [
          {
            text: "Nach dem Einloggen in CASIO Education kommst du auf eine Übersicht. Auf der linken Seite der Übersicht findest du die Navigation. Wenn du dort auf ClassPad Learning klickst, kommst du direkt in die Mathematik-Software hinein.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ich ClassPad Learning als Schüler*in testen?",
    content: [
      {
        textList: [
          {
            text: "Wenn du dir einen CASIO Education Account zulegst, kannst du ClassPad Learning 30 Tage testen. Die Testmöglichkeit wird mit der Accountregistrierung freigeschaltet und kann zeitlich nicht verändert werden.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Kann ich ClassPad Learning testen, ohne vorher eine Registrierung durchzuführen?",
    content: [
      {
        textList: [
          {
            text: "Nein, das ist nicht möglich.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ich ClassPad Learning mit einer Klasse testen?",
    content: [
      {
        textList: [
          {
            text: "Ja, du kannst in der Klassenverwaltung eine Klasse anlegen, Schüler*innen dazu einladen und einen 30-tägigen Test für die gesamte Klasse freischalten.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Kann ich meine Daten in ClassPad Learning löschen, aber meinen CASIO Education Account behalten, um zum Beispiel ClassPad.net weiter zu nutzen? Oder andersherum?",
    content: [
      {
        textList: [
          {
            text: "Nein, die Konten sind miteinander verknüpft.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Kann ich meinen Lernfortschritt in ClassPad Learning zurücksetzen?",
    content: [
      {
        textList: [
          {
            text: "Nein, das ist nicht möglich.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ich Aufgaben wiederholen, die ich bereits gelöst habe?",
    content: [
      {
        textList: [
          {
            text: "Ja, Aufgaben kannst du zu jeder Zeit wiederholen. Es gibt jedoch nur für das erstmalige Lösen eine virtuelle Belohnung.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Ich habe eine fehlerhafte Aufgabe entdeckt, an wen kann ich mich wenden, um dieses zu melden?",
    content: [
      {
        textList: [
          {
            text: "Bitte nutze die “Aufgabe melden” Funktion in ClassPad Learning.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Meine ClassPad Learning Lizenz läuft aus, wird sie automatisch verlängert?",
    content: [
      {
        textList: [
          {
            text: "Die Lizenz wird nicht automatisch verlängert.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Gibt es eine Themenübersicht für ClassPad Learning?",
    content: [
      {
        textList: [
          {
            text: "Ja, du findest nach dem Einloggen links in der Navigation eine Themenübersicht. Die Themen sind auch direkt mit ClassPad Learning verlinkt, so dass du nur noch auf den Link klicken musst, um die entsprechenden Aufgaben zu öffnen. https://casio-education.eu/app/themenuebersicht",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const classPadPlusData: FAQItemProps[] = [
  {
    faqTitle: "Was ist ClassPad Plus?",
    content: [
      {
        textList: [
          {
            text: "ClassPad Plus ermöglicht das Nutzen der bekannten CASIO CAS-Software Produkte auf PC, Laptop, Tablet und Smartphone. Es besteht aus drei verschiedenen Modulen und ermöglicht somit einen geräteunabhängigen Zugriff auf die Mathematik-Grafik-und CAS-Software:",
            firstLevel: {
              text: [
                "Classpad.net inkl. ClassPad Math Vollversion",
                "ClassPad Manager für PC/Laptop",
                "ClassPad App für Smartphones / Laptops",
              ],
              listStyle: "square",
            },
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wofür benötige ich ClassPad Plus?",
    content: [
      {
        textList: [
          {
            text: "ClassPad Plus benötigt man, wenn man einen geräteunabhängigen Zugriff auf die CAS- und Grafik-Mathematik-Software haben möchte.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Was kostet ClassPad Plus?",
    content: [
      {
        textList: [
          {
            text: "Die unverbindliche Preisempfehlung für eine Jahreslizenz liegt bei 10,-€.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wo kann ich eine Lizenz für ClassPad Plus kaufen?",
    content: [
      {
        textList: [
          {
            text: "In unserem Webshop nach Registrierung.",
          },
          {
            text: "Im Schulfachhandel. Weitere Infos gibt es hier: https://casio-education.eu/haendler",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Was sind die Vorteile von ClassPad Plus?",
    content: [
      {
        textList: [
          {
            text: "Schüler-Vorteile: Du erhältst 3 CAS-Software-Produkte. Den ClassPad Manager, den du auf deinem PC installieren hast und der die identische Handhabung zum Handheld hat, die ClassPad App, die du mit deinem Tablet und Smartphone nutzen kannst und ebenfalls identisch zum Handheld ist und ClassPad.net inklusive ClassPad Math, was eine browserbasierte Anwendung ist. ClassPad Math unterscheidet sich vom Handheld, da der Rechner als solches nicht zu sehen ist. Dadadurch hast du mehr Platz, Berechnungen durchzuführen, Graphen zu erstellen und Notizen zu einzelnen Berechnungen zu machen. Du bist also absolut flexibel aufgestellt, mit was für einem Endgerät du eine CAS-Software nutzen möchtest.",
          },
          {
            text: "Lehrer-Vorteile: Die ClassPad Plus Lizenz bietet sowohl mit dem ClassPad Manager als auch mit der ClassPad App eine absolut bediengleiche Funktionsweise zum Handheld. ClassPad.net inkl. ClassPad Math bietet neben dem CAS System auch die Möglichkeit, Arbeitsblätter vorzubereiten und mit den Schüler*innen zu teilen. Auch können digitale Notizen erstellt und geteilt werden. Mit den drei Software-Produkten kann jedes Endgerät genutzt werden, da für jedes Endgerät eine passende Lösung vorhanden ist.",
          },
          {
            text: "Elternvorteile: Drei Software-Produkte zum Preis von einem. Wenn z. B. das Smartphone ihres Kindes mit der ClassPad App kaputt geht, kann Ihr Kind dennoch noch auf dem Laptop oder PC weiterarbeiten mit dem ClassPad Manager oder mit ClassPad.net.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ClassPad Plus auch im Unterricht verwendet werden?",
    content: [
      {
        textList: [
          {
            text: "Ja, die einzelnen Module von ClassPad Plus können im Unterricht verwendet werden.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Besitzen die Bestandteile von ClassPad Plus einen Prüfungsmodus? Können diese Produkte in Prüfungen verwendet werden?",
    content: [
      {
        textList: [
          {
            text: "Aktuell gibt es keinen Prüfungsmodus. Es wird jedoch demnächst eine neue App von CASIO mit einem Prüfungsmodus geben.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Ist die ClassPad App auch offline nutzbar oder wird immer eine Internetverbindung benötigt?",
    content: [
      {
        textList: [
          {
            text: "Die ClassPad App sollte vorzugsweise mit einer Internetverbindung genutzt werden, da es sonst passieren kann, dass du dich nicht mehr anmelden kannst. Die App erfordert für die Anmeldung einen Internetzugang.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ClassPad Plus auch für Hausaufgaben verwendet werden?",
    content: [
      {
        textList: [
          {
            text: "Ja, die einzelnen Module von ClassPad Plus können für Hausaufgaben verwendet werden.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ClassPad Plus auch zum selber Lernen verwendet werden?",
    content: [
      {
        textList: [
          {
            text: "ClassPad Plus ist keine Lernsoftware. Es ist eine Mathematik-CAS-Grafik Software, die man aber selbstverständlich auch nutzen kann, um sich Berechnungen zu visualisieren und sie so leichter zu verstehen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Für welche Klassenstufen ist ClassPad Plus geeignet?",
    content: [
      {
        textList: [
          {
            text: "ClassPad Plus ist mit den drei CAS Modulen für die Oberstufe geeignet, kann aber natürlich auch schon vorher eingesetzt werden.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Für welche Schulformen ist ClassPad Plus geeignet?",
    content: [
      {
        textList: [
          {
            text: "Alle Schulformen, die zum Abitur führen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wo finde ich ClassPad Plus in CASIO Education?",
    content: [
      {
        textList: [
          {
            text: "Nach dem Einloggen in CASIO Education kommst du auf eine Übersicht. Auf der linken Seite der Übersicht findest du die Navigation. Wenn du dort auf ClassPad.net klickst, kommst du direkt in die Mathematik-Software hinein.",
          },
          {
            text: "Die App und der Manager sowie die Vollversion von ClassPad.net stehen erst nach dem Erwerb einer Lizenz zur Verfügung.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Kann ich ClassPad Plus testen?",
    content: [
      {
        textList: [
          {
            text: "ClassPad.net steht in der Basis-Version kostenfrei zur Verfügung. Weitere Infos zur Basis-Version gibt es hier: https://casio-education.eu/classpad-plus",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Kann ich ClassPad Plus testen, ohne vorher eine Registrierung durchzuführen?",
    content: [
      {
        textList: [
          {
            text: "Nein, das ist nicht möglich.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Was ist der Unterschied von ClassPad.net, ClassPad App und ClassPad Manager?",
    content: [
      {
        textList: [
          {
            text: "ClassPad.net: Eine browserunabhängige Mathematik-Software mit Grafik- und CAS-Rechenwerkzeugen. ClassPad.net ermöglicht die Erstellung verschiedener mathematischer Inhalte mit intuitiver Bedienung und das Teilen der Inhalte mit anderen Benutzern auf der ganzen Welt.",
          },
          {
            text: "ClassPad Manager: Der ClassPad II (FX-CP400) als Emulations-Software für PC, Laptop & Mac. Perfekt für die Unterrichtsvorbereitung und die Visualisierung von mathematischen Sachverhalten im Unterricht.",
          },
          {
            text: "ClassPad App: Der ClassPad II (FX-CP400) als Emulations-App für Tablet und Smartphone. Perfekt für die Unterrichtsvorbereitung und die Visualisierung von mathematischen Sachverhalten im Unterricht.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Kann ich meine Daten in ClassPad.net löschen, aber meinen ClassPad.academy Account behalten, um zum Beispiel ClassPad Learning weiter zu nutzen? Oder andersherum?",
    content: [
      {
        textList: [
          {
            text: "Nein, das ist nicht möglich.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Meine ClassPad Plus Lizenz läuft aus, wird sie automatisch verlängert?",
    content: [
      {
        textList: [
          {
            text: "Die Lizenz wird nicht automatisch verlängert.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const lizenzenData: FAQItemProps[] = [
  {
    faqTitle: "Wie und wo kann eine Lizenz aktiviert werden?",
    content: [
      {
        textList: [
          {
            text: "Gekaufte Lizenzen aus dem CASIO Education Webshop werden automatisch aktiviert.",
          },
          {
            text: "Lizenzen, die über den Schulfachhandel gekauft wurden, werden unter “Meine Lizenzen” → “Lizenz einlösen” → “Code eingeben” aktiviert.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Ich kann meine Lizenz nicht aktivieren, woran liegt das?",
    content: [
      {
        textList: [
          {
            text: "Bitte wende dich bitte an: casio.education@casio.de und schildere dein Anliegen. Wir werden dir dann weiterhelfen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Bei Eingabe meines Lizenzschlüssels erhalte ich eine Fehlermeldung. An wen kann ich mich wenden?",
    content: [
      {
        textList: [
          {
            text: "Bitte wende dich bitte an: casio.education@casio.de und schildere dein Anliegen. Wir werden dir dann weiterhelfen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Gibt es Sammellizenzen?",
    content: [
      {
        textList: [
          {
            text: "Es gibt verschiedene Lizenzpakete. Bitte wende dich an den Schulfachhandel für weitere Informationen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Wie aktiviere ich meine ClassPad Plus Lizenz?",
    content: [
      {
        textList: [
          {
            text: "Bitte gehe auf “Meine Lizenzen”",
          },
          {
            text: "Gib dort unter “Code einlösen” deinen Lizenzcode an und bestätige anschließend mit dem Button “Code einlösen”.",
          },
          {
            text: "Gehe im Anschluss auf “Meine Lizenzen” und klicke neben dem Produkt (ClassPad App oder ClassPad Manager), das du nutzen möchtest, auf “Mehr Informationen”.",
          },
          {
            text: "Klicke auf Zugangsdaten/Zugangscode anfordern und folge den Schritten 2 und 3, um dein Produkt nutzen zu können.",
          },
          {
            text: "Bei der ClassPad App nutzt du für die Anmeldung in der App die Zugangsdaten, die dir unter “Meine Lizenzen” angezeigt werden.",
          },
          {
            text: "Der ClassPad Manager benötigt eine neue Lizenz, die dir unter “Meine Lizenzen” nach dem Klicken auf “Mehr Informationen” angezeigt wird.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Ich erhalte einen Fehlercode, wenn ich die Lizenz in den ClassPad Manager eingebe, was muss ich tun?",
    content: [
      {
        textList: [
          {
            text: "Bitte mache einen Screenshot von dem Fehlercode und sende ihn mit deinem Anliegen an: casio.education@casio.de",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Meine Zugangsdaten für die ClassPad App funktionieren nicht, was muss ich tun?",
    content: [
      {
        textList: [
          {
            text: "Bitte mache einen Screenshot von der Meldung und sende ihn mit deinem Anliegen (bitte sehr detailliert schildern, was genau passiert) an: casio.education@casio.de",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Ich habe eine 7-Jahres-Lizenz gekauft und meine ClassPad App / mein Manager funktionieren nicht mehr, obwohl die 7 Jahre noch nicht um sind. Was muss ich tun?",
    content: [
      {
        textList: [
          {
            text: "Bitte gehe auf “Meine Lizenzen”, klicke auf “Mehr Informationen” und fordere neue Zugangsdaten / einen neuen Zugangscode an. Nutze die neuen Daten, um dich in der App anzumelden bzw. den neuen Code, um den ClassPad Manager wieder zu aktivieren.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const KlassenraumData: FAQItemProps[] = [
  {
    faqTitle:
      "Was genau kann ich mir unter dem Begriff “Klassenraum” vorstellen?",
    content: [
      {
        textList: [
          {
            text: "Ein Klassenraum kann das sein, was du darunter definierst: deine bestehende Klassenzimmerstruktur, eine Lerngruppe usw.",
          },
          {
            text: "Als Lehrkraft kannst du Klassenräume erstellen, Beitrittcodes für weitere Lehrkräfte und Schüler*innen generieren und ClassPad Learning für einen 30-tägigen Test mit der gesamten Klasse freischalten.",
          },
          {
            text: "Als Schüler*in kannst du mit dem Beitrittscode, den du von deiner Lehrkraft erhalten hast, deinen zugeordneten Klassenraum beitreten. Deine Lehrkraft kann eine 30-tägige Testversion von ClassPad Learning für die ganze Klasse freischalten.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Was ist das besondere an IServ Klassenräumen?",
    content: [
      {
        textList: [
          {
            text: "Deine bereits erstellen Klassenräume in IServ können ganz leicht über die Import-Funktion adaptiert werden. Du kannst auswählen, welche Klassen importiert werden sollen und welche nicht.",
          },
          {
            text: "Änderungen an Klassenräumen erfolgen in IServ",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Warum sehe ich einige Schüler*innen nicht im Klassenraum?",
    content: [
      {
        textList: [
          {
            text: "Wenn du und deine Schüler*innen sich direkt über CASIO Education anmelden, ist die wahrscheinlichste Situation, dass deine Schüler*innen sich nicht angemeldet haben oder den Klassencode, den du geteilt hast, nicht eingegeben haben.",
          },
          {
            text: "Schüler*innen werden automatisch in dem Klassenraum angezeigt, wenn die Anmeldung über iServ erfolgt und du den Klassenraum importiert hast, in dem die Schüler*innen Mitglied sind. Bitte überprüfe die Einrichtung Ihres Klassenraumes in iServ.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },

  {
    faqTitle:
      "Warum muss ich eine Klasse in CASIO Education löschen und neu importieren, wenn sich z. B. der Klassenname geändert hat?",
    content: [
      {
        textList: [
          {
            text: "Zwischen IServ und CASIO Education werden nicht ständig Daten ausgetauscht. Somit ist es erforderlich, dass die Klasse in CASIO Education einmal gelöscht wird und neu importiert wird.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const SchuelermanagementData: FAQItemProps[] = [
  {
    faqTitle:
      "Was kann ich mir als Lehrkraft unter dem Begriff “Schülermanagement” vorstellen?",
    content: [
      {
        textList: [
          {
            text: "Im Schülermanagement kannst du Accounts für deine Schüler*innen entweder einzeln anlegen oder per CSV-Datei hochladen. Du kannst insgesamt bis zu 100 Schüleraccounts gleichzeitig anlegen.",
          },
          {
            text: "In der Suchmaske kannst du Parameter angeben, um einzelne Schüler*innen zu suchen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Warum muss ich die erzeugte CSV, die automatisch heruntergeladen wird, behalten?",
    content: [
      {
        textList: [
          {
            text: "In dieser Datei befinden sich die Zugangsdaten für deine Schüler*innen. (E-Mail Adresse, Passwort)",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle: "Warum wird eine CSV-Datei automatisch heruntergeladen?",
    content: [
      {
        textList: [
          {
            text: "Damit du die Zugänge effizienter an deine Schüler*innen verteilen und für zukünftige Zwecke speichern kannst.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Warum muss ich die generierte CSV-Datei aufbewahren, die automatisch heruntergeladen wird?",
    content: [
      {
        textList: [
          {
            text: "Diese Datei enthält die Zugangsdaten deiner Schüler*innen. (E-Mail-Adresse, Passwort) Diese Daten können nicht wiederhergestellt werden, daher bewahre sie bitte sorgfältig auf.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
  {
    faqTitle:
      "Was passiert, wenn ich versehentlich die CSV-Datei gelöscht habe, die automatisch heruntergeladen wird?",
    content: [
      {
        textList: [
          {
            text: "Wir empfehlen, diese Datei sicher aufzubewahren. Passwörter werden verschlüsselt und wir können keine neuen Passwörter ausgeben oder diese Daten ändern. Wenn sich deine Schüler*innen nicht an die Anmeldeinformationen erinnern können, die du mit ihnen geteilt hast, lösche bitte die entsprechenden Schülerkonten und erstelle sie neu. Die Schüler*innen verlieren den Fortschritt, der mit dem vorherigen Konto z. B. in ClassPad Learning gemacht hat.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const TestzugaengeData: FAQItemProps[] = [
  {
    faqTitle:
      "Ich habe mir einen neuen Schüleraccount angelegt und neben ClassPad Learning steht eine Zahl. Was bedeutet das?",
    content: [
      {
        textList: [
          {
            text: "Jeder neue Schüleraccount erhält einen 30-tägigen Testzeitraum von ClassPad Learning. Die Zahl zeigt an, wie viele Tage du ClassPad Learning noch kostenfrei nutzen kannst.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const MitteilungenData: FAQItemProps[] = [
  {
    faqTitle: "Was bedeutet die Glocke ganz oben rechts?",
    content: [
      {
        textList: [
          {
            text: "Es kann vorkommen, dass wir euch über wichtige Informationen eine Mitteilung senden. Wenn du eine Mitteilung erhalten hast, hast du neben der Glocke eine rote Zahl stehen.",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const LehrerInfoServiceData: FAQItemProps[] = [
  {
    faqTitle: "Was ist der Lehrer-Info-Service?",
    content: [
      {
        textList: [
          {
            text: "Der Lehrer-Info-Service bietet regelmäßige Informationen rund um die CASIO Schulprodukte. Wir informieren dich z. B. über Produktneuheiten, Materialien, Fortbildungen und Veranstaltungen und allgemeine Updates. Weitere Informationen und die Anmeldung zum Lehrer-Info-Service findest du hier: https://casio-education.eu/lehrer-info-service",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];

export const WeitereInformationenUndFeedbackData: FAQItemProps[] = [
  {
    faqTitle: "Weitere Informationen und Feedback",
    isHiding: false,
    content: [
      {
        textList: [
          {
            text: "Hast du weitere Fragen oder möchtest uns Feedback zur Lernplattform geben? Bitte kontaktiere uns unter casio.education@casio.de",
          },
        ],
        listStyle: "circle",
      },
    ],
  },
];
