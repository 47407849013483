import { useEffect, useState } from "react";
import { ClassCard } from "./ClassCard";
import "./ClassList.scss";
import { getTeacherContexts } from "../../../api/teacher-create-user";
import { Context } from "../../../types/Context";
import { Card } from "../../Card/Card";
import { Search } from "react-feather";
import { i18n } from "@lingui/core";

export const ClassList = ({
  teacherId,
  checkedList,
  setCheckedList,
}: {
  teacherId: string;
  checkedList: string[];
  setCheckedList: (checkedList: string[]) => void;
}) => {
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<
    { label: string; value: string; color: string }[]
  >([]);

  useEffect(() => {
    async function getContexts() {
      const contexts = await getTeacherContexts(teacherId);

      const newContexts = contexts.map((context: Context) => {
        return { label: context.name, value: context.id, color: context.color };
      });

      if (!search) setOptions(newContexts);
      else
        setOptions(
          newContexts.filter(
            (context: { label: string; value: string; color: string }) =>
              context.label.toLowerCase().includes(search.toLowerCase())
          )
        );
    }

    getContexts();
  }, [search, teacherId]);

  return (
    <div>
      <div className="search-box">
        <Search className="search-icon" />
        <input
          id="search"
          className="search-input"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={i18n._({
            id: "student_list.student.add_student_to_class_modal.filter.place_holder",
          })}
        />
      </div>
      <Card className="class-list">
        <span className="headline">
          {i18n._({
            id: "student_list.student.add_student_to_class_modal.selected_class",
          })}{" "}
          {checkedList.length}
        </span>
        {options.map((option) => (
          <ClassCard
            key={option.value}
            label={option.label}
            value={option.value}
            color={option.color}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
          />
        ))}
      </Card>
    </div>
  );
};
