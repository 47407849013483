import { Formik, Form } from "formik";
import { Role } from "../../types/Role";
import { PageHeader } from "../PageHeader/PageHeader";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import styles from "./StudentList.module.scss";
import { Grid, GridItem } from "../Grid/Grid";
import { TextInput } from "../TextInput/TextInput";
import { DatepickerField } from "../DatepickerField/DatepickerField";
import { Button } from "../Button/Button";
import { Table } from "../Table/Table";
import { SelectColumn, Column, SortColumn } from "react-data-grid";
import Pagination from "../Paginate/Pagination";
import { useEffect, useRef, useState } from "react";
import { AddStudentModal } from "./AddStudentModal/AddStudentModal";
import {
  addStudentToClass,
  getStudentByTeacherId,
} from "../../api/teacher-create-user";
import { useAuth } from "../../hooks/use-auth";
import { formatUTCDate } from "../../utils/utils";
import { isEmpty } from "lodash";
import { Spinner } from "../Spinner/Spinner";
import { i18n } from "@lingui/core";
import { NoRecordFoundModal } from "../UserManagement/NoRecordFoundModal/NoRecordFoundModal";
import { DeleteStudentModal } from "./DeleteStudentModal/DeleteStudentModal";
import { Trans } from "@lingui/react";
import { AddStudentToClassModal } from "./AddStudentToClassModal/AddStudentToClassModal";

function rowKeyGetter(row: Row): any {
  return row.id;
}

export interface Row {
  id?: string;
  firstname: string;
  lastname: string;
  nickname: string;
  email: string;
  createdAt: string;
  status: string;
  action: React.ReactElement;
}

interface SortOptions {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
}

export const StudentList = () => {
  const auth = useAuth();
  const columns: readonly Column<Row>[] = [
    {
      ...SelectColumn,
    },
    {
      key: "firstname",
      name: i18n._({
        id: "student_list.student.screen.column.firstname",
      }),
    },
    {
      key: "lastname",
      name: i18n._({
        id: "student_list.student.screen.column.lastname",
      }),
    },
    {
      key: "nickname",
      name: i18n._({
        id: "student_list.student.screen.column.nickname",
      }),
    },
    {
      key: "email",
      name: i18n._({
        id: "student_list.student.screen.column.email",
      }),
    },
    {
      key: "createdAt",
      name: i18n._({
        id: "student_list.student.screen.column.created_at",
      }),
    },
    {
      key: "status",
      name: i18n._({
        id: "student_list.student.screen.column.status",
      }),
      sortable: false,
    },
    {
      key: "action",
      name: i18n._({
        id: "student_list.student.screen.column.action",
      }),
      sortable: false,
    },
  ];
  const [rows, setRows] = useState<Row[]>([]);
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<string> => new Set()
  );
  const [selectedAccount, setSelectedAccount] = useState<Row[]>([]);
  const [studentIds, setStudentIds] = useState<string[]>([]);
  const [contextCheckedList, setContextCheckedList] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    page: 1,
    limit: 10,
    sortBy: "createdAt",
    sortOrder: "desc",
  });
  const [searchCondition, setSearchCondition] = useState({});
  const [errorMessageCreatedDate, setErrorMessageCreatedDate] =
    useState<string>();
  const [disableSearchButton, setDisableSearchButton] =
    useState<boolean>(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState<boolean>(true);

  const [isShowNoRecordFoundModal, setIsShowNoRecordFoundModal] =
    useState<boolean>(false);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const [isShowAddStudentModal, setIsShowAddStudentModal] =
    useState<boolean>(false);
  const [isShowAddStudentToClassModal, setIsShowAddStudentToClassModal] =
    useState<boolean>(false);
  const [isAddStudentSuccess, setIsAddStudentSuccess] =
    useState<boolean>(false);
  const [isShowDeleteStudentModal, setIsShowDeleteStudentModal] =
    useState<boolean>(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false);

  const [forcePage, setForcePage] = useState<number>();

  const fetchData = async (
    sortOptions: SortOptions,
    searchCondition: any,
    isShowNoRecord: boolean = false
  ) => {
    setIsShowSpinner(true);
    const response = await getStudentByTeacherId(auth.user?.id as string, {
      ...sortOptions,
      ...searchCondition,
    });
    if (response.result.length === 0 && isShowNoRecord) {
      setIsShowNoRecordFoundModal(true);
    }
    response.result.map((row: any) => {
      row.createdAt = formatUTCDate(row.createdAt);
      row.status =
        row.isActivated === true
          ? i18n._({
              id: "student_list.student.screen.column.status.value.activated",
            })
          : i18n._({
              id: "student_list.student.screen.column.status.value.activating",
            });
      row.action = (
        <span
          style={{ color: "#339af0" }}
          onClick={() => {
            setStudentIds([row.id]);
            setIsShowDeleteStudentModal(true);
          }}
        >
          {i18n._({ id: "student_list.student.screen.column.action.value" })}
        </span>
      );
      return row;
    });
    setRows(response.result);
    setTotalCount(response.countSearch);
    setIsShowSpinner(false);
    setSelectedAccount([]);
    setSelectedRows(new Set());
    setDisableDeleteBtn(true);
  };

  const onSortColumns = (sortColumns: SortColumn[], searchCondition: any) => {
    let sortOptionsUpdate = { ...sortOptions };

    if (!isEmpty(sortColumns)) {
      sortOptionsUpdate.sortBy = sortColumns[0].columnKey;
      sortOptionsUpdate.sortOrder = sortColumns[0].direction.toLowerCase() as
        | "asc"
        | "desc";
    }
    setSortOptions(sortOptionsUpdate);

    fetchData(sortOptionsUpdate, searchCondition);
    setSortColumns(sortColumns);
  };

  const formikRef = useRef<any>(null);
  const initialValues = {
    firstname: "",
    lastname: "",
    nickname: "",
    dateFrom: "",
    dateTo: "",
    email: "",
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      fetchData(sortOptions, searchCondition, false);
      setIsDeleteSuccess(false);
    }

    if (isAddStudentSuccess) {
      formikRef.current.resetForm({ values: initialValues });
      fetchData(
        {
          page: 1,
          limit: 10,
          sortBy: "createdAt",
          sortOrder: "desc",
        },
        initialValues,
        false
      );
      setSearchCondition({ ...initialValues });
      setSortColumns([]);
      setSortOptions({
        page: 1,
        limit: 10,
        sortBy: "createdAt",
        sortOrder: "desc",
      });
      setIsAddStudentSuccess(false);
    }
    // eslint-disable-next-line
  }, [isDeleteSuccess, isAddStudentSuccess]);

  useEffect(() => {
    formikRef.current.resetForm({ values: initialValues });
    fetchData(
      {
        page: 1,
        limit: 10,
        sortBy: "createdAt",
        sortOrder: "desc",
      },
      initialValues,
      false
    );
    setSearchCondition({ ...initialValues });
    setSortColumns([]);
    setSortOptions({
      page: 1,
      limit: 10,
      sortBy: "createdAt",
      sortOrder: "desc",
    });
    // eslint-disable-next-line
  }, []);

  const handleAddStudentToClass = async () => {
    await addStudentToClass(contextCheckedList, studentIds);
  };

  return (
    <RestrictedTo roles={[Role.INSTRUCTOR]} redirects={true}>
      <div className={styles.container}>
        <PageHeader
          title={i18n._({ id: "student_list.student.screen.header" })}
        />
        <p className={styles.description}>
          <Trans
            id="student_list.student.screen.description"
            values={{
              link: (
                <a
                  className={styles.hyperlink}
                  target="_blank"
                  href={i18n._({
                    id: "student_list.student.screen.description.link.url",
                  })}
                  rel="noreferrer"
                >
                  {i18n._({
                    id: "student_list.student.screen.description.link.label",
                  })}
                </a>
              ),
              email: (
                <EmailLink
                  email="student_list.student.screen.description.email"
                  label="student_list.student.screen.description.email.label"
                />
              ),
            }}
          />
        </p>
        <div className={styles.studentListContainer}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validate={(values) => {
              setErrorMessageCreatedDate(undefined);
              setDisableSearchButton(false);
              if (
                values.dateFrom &&
                values.dateTo &&
                new Date(values.dateFrom).getTime() >
                  new Date(values.dateTo).getTime()
              ) {
                setDisableSearchButton(true);
                setErrorMessageCreatedDate(
                  i18n._({
                    id: "student_list.student.screen.input.date.error",
                  })
                );
              }
              const searchCondition = {
                firstname: "",
                lastname: "",
                nickname: "",
                email: "",
                dateFrom: "",
                dateTo: "",
              };

              if (values.firstname) {
                searchCondition.firstname = values.firstname;
              }
              if (values.lastname) {
                searchCondition.lastname = values.lastname;
              }
              if (values.nickname) {
                searchCondition.nickname = values.nickname;
              }
              if (values.email) {
                searchCondition.email = values.email;
              }
              if (values.dateFrom) {
                searchCondition.dateFrom = new Date(
                  values.dateFrom
                ).toDateString();
              }
              if (values.dateTo) {
                searchCondition.dateTo = new Date(values.dateTo).toDateString();
              }
              setSearchCondition(searchCondition);
            }}
            onSubmit={async (values) => {
              sortOptions.page = 1;
              await fetchData(sortOptions, searchCondition, true);
              setForcePage((prev) => (prev === 0 ? undefined : 0));
            }}
          >
            {({
              isValid,
              isSubmitting,
              values,
              handleChange,
              touched,
              errors,
              resetForm,
            }) => (
              <Form>
                <Grid>
                  <GridItem width="1/3">
                    <TextInput
                      id="firstname"
                      label={i18n._({
                        id: "student_list.student.screen.input.firstname",
                      })}
                      value={values.firstname}
                      onChange={handleChange}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="lastname"
                      label={i18n._({
                        id: "student_list.student.screen.input.lastname",
                      })}
                      value={values.lastname}
                      onChange={handleChange}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="nickname"
                      label={i18n._({
                        id: "student_list.student.screen.input.nickname",
                      })}
                      value={values.nickname}
                      onChange={handleChange}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <DatepickerField
                      label={i18n._({
                        id: "student_list.student.screen.input.dateFrom",
                      })}
                      placeholderLabel="dd/mm/yyyy"
                      id="dateFrom"
                      name="dateFrom"
                      onChange={handleChange}
                      errorMessage={errorMessageCreatedDate}
                      locale="en"
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <DatepickerField
                      label={i18n._({
                        id: "student_list.student.screen.input.dateTo",
                      })}
                      placeholderLabel="dd/mm/yyyy"
                      id="dateTo"
                      name="dateTo"
                      onChange={handleChange}
                      errorMessage={errorMessageCreatedDate}
                      locale="en"
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="email"
                      label={i18n._({
                        id: "student_list.student.screen.input.email",
                      })}
                      value={values.email}
                      onChange={handleChange}
                    />
                  </GridItem>
                </Grid>

                <div className={styles.groupButtonRow}>
                  <Grid>
                    <GridItem width="2/3" className={styles.groupButtonLeft}>
                      <Button
                        label={i18n._({
                          id: "student_list.student.screen.button.add_student",
                        })}
                        btnStyle="primary"
                        onClick={() => {
                          setIsShowAddStudentModal(true);
                        }}
                      ></Button>

                      <Button
                        label={i18n._({
                          id: "student_list.student.screen.button.delete",
                        })}
                        btnStyle="danger"
                        disabled={disableDeleteBtn}
                        onClick={() => {
                          setStudentIds(
                            selectedAccount.map((e) => e.id) as string[]
                          );
                          setIsShowDeleteStudentModal(true);
                        }}
                      ></Button>

                      <Button
                        label={i18n._({
                          id: "student_list.student.screen.button.add_student_to_class",
                        })}
                        btnStyle="primary"
                        onClick={() => {
                          setStudentIds(
                            selectedAccount.map((e) => e.id) as string[]
                          );
                          setIsShowAddStudentToClassModal(true);
                        }}
                        disabled={disableDeleteBtn}
                      ></Button>
                    </GridItem>
                    <GridItem width="1/3" className={styles.groupButtonRight}>
                      <Button
                        label={i18n._({
                          id: "student_list.student.screen.button.clear",
                        })}
                        btnStyle="secondary"
                        onClick={() => {
                          resetForm();
                          setErrorMessageCreatedDate(undefined);
                          setDisableSearchButton(false);
                        }}
                      ></Button>
                      <Button
                        label={i18n._({
                          id: "student_list.student.screen.button.search",
                        })}
                        className={styles.btnSearch}
                        type="submit"
                        disabled={disableSearchButton}
                      ></Button>
                    </GridItem>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>
          <div className={styles.tableContainer}>
            <Table
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              sortColumns={sortColumns}
              rowKeyGetter={rowKeyGetter}
              selectedRows={selectedRows}
              onSelectedRowsChange={(selectedRow, selectedData: Row[]) => {
                setSelectedRows(selectedRow);
                setSelectedAccount(selectedData);

                if (selectedData.length !== 0) {
                  setDisableDeleteBtn(false);
                } else {
                  setDisableDeleteBtn(true);
                }
              }}
              onSortColumnsChange={(sortColumns) => {
                onSortColumns(sortColumns, searchCondition);
              }}
            />
            <Pagination
              items={rows}
              totalCount={totalCount}
              getItemsPerPage={async (itemsPerPage: number) => {
                let sortOptionsUpdate = { ...sortOptions };
                sortOptionsUpdate.limit = itemsPerPage;
                sortOptionsUpdate.page = 1;
                setSortOptions(sortOptionsUpdate);
                await fetchData(sortOptionsUpdate, searchCondition);

                setForcePage((prev) => (prev === 0 ? undefined : 0));
              }}
              getPageNumber={async (pageNumber: number) => {
                // page
                let sortOptionsUpdate = { ...sortOptions };
                sortOptionsUpdate.page = pageNumber;
                setSortOptions(sortOptionsUpdate);
                await fetchData(sortOptionsUpdate, searchCondition);
              }}
              forcePage={forcePage}
            />
          </div>
        </div>
      </div>
      {isShowAddStudentModal && (
        <AddStudentModal
          isShowing={isShowAddStudentModal}
          setVisible={setIsShowAddStudentModal}
          setIsAddStudentSuccess={setIsAddStudentSuccess}
        />
      )}
      {isShowAddStudentToClassModal && (
        <AddStudentToClassModal
          isShowing={isShowAddStudentToClassModal}
          setVisible={setIsShowAddStudentToClassModal}
          teacherId={auth.user?.id as string}
          checkedList={contextCheckedList}
          setCheckedList={setContextCheckedList}
          onAddStudentToClass={handleAddStudentToClass}
        />
      )}
      {isShowDeleteStudentModal && (
        <DeleteStudentModal
          isShowing={isShowDeleteStudentModal}
          setVisible={setIsShowDeleteStudentModal}
          setIsDeleteSuccess={setIsDeleteSuccess}
          studentIds={studentIds}
        />
      )}
      {isShowNoRecordFoundModal && (
        <NoRecordFoundModal
          isShowing={isShowNoRecordFoundModal}
          setVisible={setIsShowNoRecordFoundModal}
        />
      )}
      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </RestrictedTo>
  );
};

export const EmailLink = ({
  email,
  label,
}: {
  email: string;
  label: string;
}) => {
  // Generate the mailto link
  const mailtoLink = `mailto:${i18n._({
    id: email,
  })}`;

  return (
    <a className={styles.hyperlink__underline} href={mailtoLink}>
      {i18n._({
        id: label,
      })}
    </a>
  );
};
