import React, { useState } from "react";
import "./FAQItem.scss";
import AnimateHeight from "react-animate-height";
import { ChevronDown, ChevronUp, Minus, Plus } from "react-feather";
import { AutoLinkParagraph } from "./AutoLinkParagraphProps";
import { Card } from "../Card/Card";
import { FirstLevel, FirstLevelProps } from "./FirstLevel";
import {
  datensicherheitData,
  anmeldenData,
  kontoFunktionalitätenData,
  classPadLearningData,
  classPadPlusData,
  lizenzenData,
  KlassenraumData,
  SchuelermanagementData,
  TestzugaengeData,
  MitteilungenData,
  LehrerInfoServiceData,
  WeitereInformationenUndFeedbackData,
} from "./FAQData";

interface FAQCategory {
  title: string;
  faqItems: FAQItemProps[];
}

export interface FAQItemProps {
  faqTitle: string;
  isHiding?: boolean;
  content: {
    textList: {
      text: string;
      firstLevel?: FirstLevelProps;
    }[];
    listStyle: string;
  }[];
}

const faqContent: FAQCategory[] = [
  {
    title: "Datensicherheit",
    faqItems: datensicherheitData,
  },
  {
    title: "Anmelden",
    faqItems: anmeldenData,
  },
  { title: "Konto-Funktionalitäten", faqItems: kontoFunktionalitätenData },
  { title: "ClassPad Learning", faqItems: classPadLearningData },
  { title: "ClassPad Plus", faqItems: classPadPlusData },
  { title: "Lizenzen", faqItems: lizenzenData },
  {
    title: "Klassenraum",
    faqItems: KlassenraumData,
  },
  {
    title: "Schülermanagement",
    faqItems: SchuelermanagementData,
  },
  {
    title: "Testzugänge",
    faqItems: TestzugaengeData,
  },
  {
    title: "Mitteilungen",
    faqItems: MitteilungenData,
  },
  {
    title: "Lehrer-Info-Service",
    faqItems: LehrerInfoServiceData,
  },
  {
    title: "Weitere Informationen und Feedback",
    faqItems: WeitereInformationenUndFeedbackData,
  },
];

export const FAQContent = () => {
  const [isOpen, setIsOpen] = useState<number[]>([]);

  const toggleCollapse = (key: number) => {
    setIsOpen((current) =>
      current.includes(key)
        ? current.filter((value) => value !== key)
        : [...current, key]
    );
  };

  return (
    <div>
      {faqContent.map((content, key) => (
        <div key={key}>
          <Card className={`card__${isOpen.includes(key) ? "open" : "closed"}`}>
            <div className="card__header" onClick={() => toggleCollapse(key)}>
              <h2 className={isOpen.includes(key) ? "expanded" : ""}>
                {content.title}
              </h2>
              <span className={`card__header__icon`}>
                {!isOpen.includes(key) ? (
                  <ChevronDown size={32} />
                ) : (
                  <ChevronUp size={32} />
                )}
              </span>
            </div>
            <AnimateHeight
              duration={500}
              height={isOpen.includes(key) ? "auto" : 0}
            >
              <div className="card__faq-items">
                {content.faqItems.map((item, index) => (
                  <FAQItem
                    key={index}
                    faqTitle={item.faqTitle}
                    content={item.content}
                    isHiding={item.isHiding}
                  />
                ))}
              </div>
            </AnimateHeight>
          </Card>
        </div>
      ))}
    </div>
  );
};

export const FAQItem: React.FC<FAQItemProps> = ({
  faqTitle,
  content,
  isHiding,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleCollapse = (event: any) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  if (isHiding !== false)
    //if 2th level hiding button is false then activate it, otherrwise remove it
    return (
      <div
        className="collapse"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <button className="collapse__button" onClick={toggleCollapse}>
          <h3>{faqTitle}</h3>
          <span>{isOpen ? <Minus /> : <Plus />}</span>
        </button>
        <AnimateHeight
          className="collapse__items"
          duration={500}
          height={isOpen ? "auto" : 0}
        >
          {content.map((c) =>
            c.listStyle === "disc" ? (
              <ul className="collapse__items__item">
                {c.textList.map((value, index) => (
                  <li
                    className={`collapse__items__item__${c.listStyle}`}
                    key={index}
                  >
                    {value.firstLevel?.isHiding !== true && (
                      <AutoLinkParagraph text={value.text} key={index} />
                    )}
                    {value.firstLevel && (
                      <FirstLevel
                        firstLevelText={value.text}
                        text={value.firstLevel.text}
                        listStyle={value.firstLevel.listStyle}
                        secondLevel={value.firstLevel.secondLevel}
                        isHiding={value.firstLevel?.isHiding}
                      />
                    )}
                  </li>
                ))}
              </ul>
            ) : c.listStyle === "decimal" ? (
              <ol className="collapse__items__item">
                {c.textList.map((value, index) => (
                  <li
                    className={`collapse__items__item__${c.listStyle}`}
                    key={index}
                  >
                    {value.firstLevel?.isHiding !== true && (
                      <AutoLinkParagraph text={value.text} key={index} />
                    )}
                    {value.firstLevel && (
                      <FirstLevel
                        firstLevelText={value.text}
                        text={value.firstLevel.text}
                        listStyle={value.firstLevel.listStyle}
                        secondLevel={value.firstLevel.secondLevel}
                        isHiding={value.firstLevel?.isHiding}
                      />
                    )}
                  </li>
                ))}
              </ol>
            ) : c.listStyle === "discsubitems" ? (
              <div style={{ backgroundColor: "#eee" }}>
                <ul className="collapse__items__item">
                  {c.textList.map((value, index) => (
                    <>
                      {value.firstLevel?.isHiding !== true && (
                        <AutoLinkParagraph text={value.text} key={index} />
                      )}
                      {value.firstLevel && (
                        <FirstLevel
                          firstLevelText={value.text}
                          text={value.firstLevel.text}
                          listStyle={value.firstLevel.listStyle}
                          secondLevel={value.firstLevel.secondLevel}
                          isHiding={value.firstLevel?.isHiding}
                        />
                      )}
                    </>
                  ))}
                </ul>
              </div>
            ) : c.listStyle === "circle" ? (
              <ul className="collapse__items__item">
                {c.textList.map((value, index) => (
                  <li
                    className={`collapse__items__item__${c.listStyle}`}
                    key={index}
                  >
                    {value.firstLevel?.isHiding !== true && (
                      <AutoLinkParagraph text={value.text} key={index} />
                    )}
                    {value.firstLevel && (
                      <FirstLevel
                        firstLevelText={value.text}
                        text={value.firstLevel.text}
                        listStyle={value.firstLevel.listStyle}
                        secondLevel={value.firstLevel.secondLevel}
                        isHiding={value.firstLevel?.isHiding}
                      />
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="collapse__items__nonelist_item">
                {c.textList.map((value, index) => (
                  <>
                    {value.firstLevel?.isHiding !== true && (
                      <AutoLinkParagraph text={value.text} key={index} />
                    )}
                    {value.firstLevel && (
                      <FirstLevel
                        firstLevelText={value.text}
                        text={value.firstLevel.text}
                        listStyle={value.firstLevel.listStyle}
                        secondLevel={value.firstLevel.secondLevel}
                        isHiding={value.firstLevel?.isHiding}
                      />
                    )}
                  </>
                ))}
              </div>
            )
          )}
        </AnimateHeight>
      </div>
    );
  else {
    return (
      <div
        className="collapse"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        {content.map((c) =>
          c.listStyle === "disc" ? (
            <ul className="collapse__items__item">
              {c.textList.map((value, index) => (
                <li
                  className={`collapse__items__item__${c.listStyle}`}
                  key={index}
                >
                  {value.firstLevel?.isHiding !== true && (
                    <AutoLinkParagraph text={value.text} key={index} />
                  )}
                  {value.firstLevel && (
                    <FirstLevel
                      firstLevelText={value.text}
                      text={value.firstLevel.text}
                      listStyle={value.firstLevel.listStyle}
                      secondLevel={value.firstLevel.secondLevel}
                      isHiding={value.firstLevel?.isHiding}
                    />
                  )}
                </li>
              ))}
            </ul>
          ) : c.listStyle === "decimal" ? (
            <ol className="collapse__items__item">
              {c.textList.map((value, index) => (
                <li
                  className={`collapse__items__item__${c.listStyle}`}
                  key={index}
                >
                  {value.firstLevel?.isHiding !== true && (
                    <AutoLinkParagraph text={value.text} key={index} />
                  )}
                  {value.firstLevel && (
                    <FirstLevel
                      firstLevelText={value.text}
                      text={value.firstLevel.text}
                      listStyle={value.firstLevel.listStyle}
                      secondLevel={value.firstLevel.secondLevel}
                      isHiding={value.firstLevel?.isHiding}
                    />
                  )}
                </li>
              ))}
            </ol>
          ) : c.listStyle === "discsubitems" ? (
            <div style={{ backgroundColor: "#eee" }}>
              <ul className="collapse__items__item">
                {c.textList.map((value, index) => (
                  <>
                    {value.firstLevel?.isHiding !== true && (
                      <AutoLinkParagraph text={value.text} key={index} />
                    )}
                    {value.firstLevel && (
                      <FirstLevel
                        firstLevelText={value.text}
                        text={value.firstLevel.text}
                        listStyle={value.firstLevel.listStyle}
                        secondLevel={value.firstLevel.secondLevel}
                        isHiding={value.firstLevel?.isHiding}
                      />
                    )}
                  </>
                ))}
              </ul>
            </div>
          ) : c.listStyle === "circle" ? (
            <ul className="collapse__items__item">
              {c.textList.map((value, index) => (
                <li
                  className={`collapse__items__item__${c.listStyle}`}
                  key={index}
                >
                  {value.firstLevel?.isHiding !== true && (
                    <AutoLinkParagraph text={value.text} key={index} />
                  )}
                  {value.firstLevel && (
                    <FirstLevel
                      firstLevelText={value.text}
                      text={value.firstLevel.text}
                      listStyle={value.firstLevel.listStyle}
                      secondLevel={value.firstLevel.secondLevel}
                      isHiding={value.firstLevel?.isHiding}
                    />
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <div className="collapse__items__nonelist_item">
              {c.textList.map((value, index) => (
                <>
                  {value.firstLevel?.isHiding !== true && (
                    <AutoLinkParagraph text={value.text} key={index} />
                  )}
                  {value.firstLevel && (
                    <FirstLevel
                      firstLevelText={value.text}
                      text={value.firstLevel.text}
                      listStyle={value.firstLevel.listStyle}
                      secondLevel={value.firstLevel.secondLevel}
                      isHiding={value.firstLevel?.isHiding}
                    />
                  )}
                </>
              ))}
            </div>
          )
        )}
      </div>
    );
  }
};
