import { AutoLinkParagraph } from "./AutoLinkParagraphProps";
import "./FAQItem.scss";

export interface SecondLevelProps {
  text: string[];
  listStyle: string;
}

export const SecondLevel: React.FC<SecondLevelProps> = ({
  text,
  listStyle,
}) => {
  return (
    <div>
      <ol>
        {text.map((fcText, fcIndex) => (
          <li className={`${listStyle}`} key={fcIndex}>
            <AutoLinkParagraph key={fcIndex} text={fcText} />
          </li>
        ))}
      </ol>
    </div>
  );
};
