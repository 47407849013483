export interface SubjectType {
  readonly value: string;
  readonly label: string;
}

export const getSubjectTypes = (language: "fr" | "en"): SubjectType[] => {
  const types = getAllTypes();
  return types[language];
};

export const frenchSubjectTypesFrVer = [
  { value: "maths", label: "Mathématiques" },
  { value: "physics", label: "Physique-Chimie" },
  { value: "math-science", label: "Maths-sciences" },
  { value: "svt", label: "SVT" },
  { value: "nsi", label: "NSI" },
  { value: "others", label: "Autres" },
];

export const frenchSubjectTypesEngVer = [
  { value: "maths", label: "Mathematics" },
  { value: "physics", label: "Physics - Chemistry" },
  { value: "math-science", label: "Math and Science" },
  { value: "svt", label: "SVT" },
  { value: "nsi", label: "NSI" },
  { value: "others", label: "Others" },
];

export const getAllTypes = (): {
  fr: {
    value: string;
    label: string;
  }[];
  en: {
    value: string;
    label: string;
  }[];
} => {
  const schoolTypes = {
    fr: frenchSubjectTypesFrVer,
    en: frenchSubjectTypesEngVer,
  };
  return schoolTypes;
};
