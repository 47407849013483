import { useState } from "react";
import { Tabs } from "../Tabs/Tabs";
import TabContent from "../Tabs/TabContent";
import TabNavItem from "../Tabs/TabNavItem";
import { MergeSchools } from "./MergeSchools";
import { UnmergeSchools } from "./../UnmergeSchools/UnmergeSchools";

export const MergeSchoolsGeneral = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const tabNavsList = (
    <>
      <TabNavItem
        title="MERGE SCHOOLS"
        id="tab1"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <TabNavItem
        title="UNMERGE SCHOOLS"
        id="tab2"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );

  return (
    <Tabs tabNavs={tabNavsList}>
      <TabContent id="tab1" activeTab={activeTab}>
        <MergeSchools />
      </TabContent>
      <TabContent id="tab2" activeTab={activeTab}>
        <UnmergeSchools />
      </TabContent>
    </Tabs>
  );
};
