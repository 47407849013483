export enum Role {
  ADMINISTRATOR = "ADMINISTRATOR",
  INSTRUCTOR = "INSTRUCTOR",
  LEARNER = "LEARNER",
  SUPPORT = "SUPPORT",
}

export enum FrenchInstructorRole {
  INSTRUCTOR = "instructor",
  SCHOOL_ADMIN = "school_admin",
  SCHOOL_ASSOCIATION = "school_association",
  OTHER = "other",
}
