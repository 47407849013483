import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Formik, Form, FormikProps } from "formik";
import { Column, SortColumn } from "react-data-grid";
import { Grid, GridItem } from "../../Grid/Grid";

import styles from "./LicenseDetail.module.scss";
import { Product } from "../../../types/Product";
import { CentralizedLicenseType } from "../../../types/CentralizedLicenseType";
import { MultiSelect } from "../../MultiSelect/MultiSelect";
import { DatepickerField } from "../../DatepickerField/DatepickerField";
import { Button } from "../../Button/Button";
import { Table } from "../../Table/Table";
import Pagination from "../../Paginate/Pagination";
import { DataWithPagination, User } from "../../../types/User";
import { Role } from "../../../types/Role";
import moment from "moment";
import { CentrializedLicenseDetail } from "../../../types/CentrializedLicenseDetail";
import {
  SearchLicensesCondition,
  adminGetAndSearchLicenses,
  adminGetLicenseDetail,
  FetchSortedAndPagedLicenses,
  GetLicenseDetailResponse,
  SchoolLicenseInLicenseDetail,
  adminCountValidAdditionalLicenses,
  adminAddReplaceAdditionalLicense,
  AddLicenseCodeParams,
  adminAddLicenseCode,
} from "../../../api/user-licenses";
import {
  dateWithoutTimezone,
  formatUTCDate,
  reverseSpecifiedFormatToDate,
} from "../../../utils/utils";
import { Modal } from "../../Modal/Modal";
import { Select } from "../../Select/Select";
import { LabeledCheckbox } from "../../LabeledCheckbox/LabeledCheckbox";
import { Spinner } from "../../Spinner/Spinner";
import { isEmpty } from "lodash";
import {
  AdditionalLicenseType,
  ClasswizEmulatorLicense,
} from "../../../types/AdditionalLicenseType";
import { useToasts } from "../../../provider/toast-provider";
import { Country } from "../../../types/Country";
import { AdminUserLicenseView } from "./AdminUserLicenseView/AdminUserLicenseView";
import { isAfter, isBefore } from "date-fns";
import { NoRecordFoundModal } from "../NoRecordFoundModal/NoRecordFoundModal";
import { IdProvider } from "../../../types/IdProvider";
import { TextInput } from "../../TextInput/TextInput";
import {
  ClasswizEmulatorData,
  deleteClasswizLicense,
  upsertClasswizEmulatorLicenseOfUser,
} from "../../../api/license-codes";
import { AnnouncementModal } from "../../Admin/AnnouncementModal/AnnouncementModal";
import { ConfirmationModal } from "../../Admin/ConfirmationModal/ConfirmationModal";
import { ProcessFailedModal } from "../../Admin/ProcessFailedModal/ProcessFailedModal";

export interface FetchSortedAndPagedLicensesResponse
  extends DataWithPagination<FetchSortedAndPagedLicenses> {}

const licenseRecordsColumns: readonly Column<LicenseRecordsRow>[] = [
  {
    key: "detail",
    name: "License",
    width: 250,
  },
  {
    key: "product",
    name: "Product",
    width: 180,
  },
  {
    key: "status",
    name: "Status",
    sortable: false,
  },
  {
    key: "type",
    name: "Type",
    width: 150,
  },
  {
    key: "useDate",
    name: "Use date",
    width: 200,
  },
  {
    key: "startDate",
    name: "Start Date",
    width: 200,
  },
  {
    key: "endDate",
    name: "End Date",
    width: 200,
  },
  {
    key: "action",
    name: "Action",
    sortable: false,
  },
];

const schoolLicenseColumns: readonly Column<SchoolLicenseRow>[] = [
  {
    key: "school",
    name: "School",
    width: 250,
  },
  {
    key: "product",
    name: "Product",
    width: 180,
  },
  {
    key: "status",
    name: "Status",
  },
  {
    key: "validUntil",
    name: "Valid Until",
    width: 200,
  },
  {
    key: "quantity",
    name: "Quantity",
  },
  {
    key: "noOfUser",
    name: "No. of Users",
  },
  {
    key: "referenceNo",
    name: "Reference No.",
  },
  {
    key: "schoolId",
    name: "School ID",
  },
  {
    key: "action",
    name: "Action",
  },
];

interface LicenseRecordsRow {
  id: string;
  detail: string | undefined;
  product: Product;
  status: string;
  type: CentralizedLicenseType;
  useDate: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  action: React.ReactElement;
}

interface SchoolLicenseRow {
  school: string;
  product: Product;
  status: string;
  validUntil: string;
  quantity: number;
  noOfUser: number;
  referenceNo: string;
  schoolId: string;
  action: React.ReactElement;
}

interface LicenseDetailProps {
  userDetail?: User;
  schoolLicenseList?: SchoolLicenseInLicenseDetail[];
  fetchUserDetail: () => Promise<void>;
}

interface SortOptions {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
}

const fieldsToClear = [
  { name: "license", value: [] },
  { name: "product", value: [] },
  { name: "status", value: [] },
  { name: "type", value: [] },
  { name: "useDateFrom", value: "" },
  { name: "useDateTo", value: "" },
  { name: "startDateFrom", value: "" },
  { name: "startDateTo", value: "" },
  { name: "endDateFrom", value: "" },
  { name: "endDateTo", value: "" },
];

export enum Status {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  RETURNED = "RETURNED",
}

export const ADMIN_ADD_LICENSE_CODE_LABEL = {
  CLASSPAD_LEARNING_30D_PROMOTIONAL: "ClassPad Learning 30 days promotional",
  CLASSPAD_LEARNING_1Y: "ClassPad Learning 1 year",
  CLASSPAD_LEARNING_1Y_PROMOTIONAL: "ClassPad Learning 1 year promotional",
  CLASSPAD_PLUS_1Y: "ClassPad Plus 1 year",
  CLASSPAD_PLUS_7Y: "ClassPad Plus 7 years",
  CLASSPAD_COMBINATION_1Y: "ClassPad Combination 1 year",
};

export const LicenseDetail: React.FC<LicenseDetailProps> = ({
  userDetail,
  schoolLicenseList,
  fetchUserDetail,
}) => {
  const [licenseRecordsRows, setLicenseRecordsRows] = useState<
    LicenseRecordsRow[]
  >([]);
  const [schoolLicenseRows, setSchoolLicenseRows] = useState<
    SchoolLicenseRow[]
  >([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [totalLicensesCount, setTotalLicensesCount] = useState<number>(0);

  const [errorMessageUseDate, setErrorMessageUseDate] = useState<string>();
  const [errorMessageStartDate, setErrorMessageStartDate] = useState<string>();
  const [errorMessageEndDate, setErrorMessageEndDate] = useState<string>();

  const [disableSearchButton, setDisableSearchButton] =
    useState<boolean>(false);
  const [isShowAddLicenseCodeModal, setIsShowAddLicenseCodeModal] =
    useState<boolean>(false);

  const [isShowAddReplaceALModal, setIsShowAddReplaceALModal] =
    useState<boolean>(false);

  const [isShowLicenseInfoModal, setIsShowLicenseInfoModal] =
    useState<boolean>(false);

  const [isShowUserLicenseView, setIsShowUserLicenseView] =
    useState<boolean>(false);

  const [searchCondition, setSearchCondition] =
    useState<SearchLicensesCondition>({});

  const [forcePage, setForcePage] = useState<number>();

  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  const [sortOptions, setSortOptions] = useState<SortOptions>({
    page: 1,
    limit: 10,
    sortBy: "startDate",
    sortOrder: "desc",
  });
  const [licenseDetail, setLicenseDetail] = useState<any>();

  const [isShowNoRecordFoundModal, setIsShowNoRecordFoundModal] =
    useState<boolean>(false);

  const [isShowEditClasswizLicenseModal, setIsShowEditClasswizLicenseModal] =
    useState<boolean>(false);

  const setRowsFromLicensesData = useCallback(
    (licenses: FetchSortedAndPagedLicenses[]) => {
      let rows: LicenseRecordsRow[] = [];

      for (let data of licenses) {
        rows.push({
          id: data.id,
          detail: data.detail ? data.detail : "",
          product: data.product,
          status: data.status,
          type: data.type,
          useDate: data.useDate
            ? formatUTCDate(new Date(data.useDate).toUTCString())
            : "",
          startDate: data.startDate
            ? formatUTCDate(new Date(data.startDate).toUTCString())
            : "",
          endDate: data.endDate
            ? formatUTCDate(new Date(data.endDate).toUTCString())
            : "",
          action: (
            <p className={styles.container__tableContainer__viewButton}>View</p>
          ),
        });
      }

      setLicenseRecordsRows(rows);
    },
    []
  );

  const fetchLicenses = useCallback(
    async (
      sortOptions: SortOptions,
      searchCondition: SearchLicensesCondition
    ) => {
      setIsShowSpinner(true);
      const licenses: FetchSortedAndPagedLicensesResponse | undefined =
        await adminGetAndSearchLicenses(
          sortOptions,
          userDetail?.id,
          searchCondition
        );

      if (licenses?.paginateData && licenses.paginateData.length > 0) {
        setRowsFromLicensesData(licenses.paginateData);
        setTotalLicensesCount(licenses.totalCount);
      }

      setIsShowSpinner(false);
    },
    [setRowsFromLicensesData, userDetail?.id]
  );

  const onSortColumns = useCallback(
    (sortColumns: SortColumn[], searchCondition: SearchLicensesCondition) => {
      let sortOptionsUpdate = { ...sortOptions };
      if (!isEmpty(sortColumns)) {
        sortOptionsUpdate.sortBy = sortColumns[0].columnKey;
        sortOptionsUpdate.sortOrder = sortColumns[0].direction.toLowerCase() as
          | "asc"
          | "desc";
      }

      setSortOptions(sortOptionsUpdate);
      fetchLicenses(sortOptionsUpdate, searchCondition);
      setSortColumns(sortColumns);
    },
    [fetchLicenses, sortOptions]
  );

  useEffect(() => {
    if (schoolLicenseList && schoolLicenseList.length > 0) {
      let rows: SchoolLicenseRow[] = [];

      for (let data of schoolLicenseList) {
        rows.push({
          school: data?.school?.name ? data?.school?.name : "",
          product: data?.products[0],
          status: data?.status ? data?.status : "",
          validUntil: data?.validUntil ? String(data?.validUntil) : "",
          quantity: data?.quantity ? data?.quantity : 0,
          noOfUser: data?.noUsers ? data?.noUsers : 0,
          referenceNo: data?.reference ? data?.reference : "",
          schoolId: data?.schoolId ? data?.schoolId : "",
          action: (
            <p className={styles.container__tableContainer__viewButton}>View</p>
          ),
        });
      }

      setSchoolLicenseRows(rows);
    }
  }, [schoolLicenseList]);

  const renderCPLValidUntil = useCallback((): string | undefined => {
    if (userDetail?.type === Role.LEARNER) {
      return userDetail?.licenseOverview?.cplValidUntil
        ? moment(userDetail?.licenseOverview?.cplValidUntil).format(
            "DD/MM/YYYY"
          )
        : "";
    }

    if (userDetail?.type === Role.INSTRUCTOR) {
      return "Teacher License";
    }
  }, [userDetail?.licenseOverview?.cplValidUntil, userDetail?.type]);

  const renderCPPValidUntil = useCallback((): string | undefined => {
    if (userDetail?.type === Role.LEARNER) {
      return userDetail?.licenseOverview?.cppValidUntil
        ? moment(userDetail?.licenseOverview?.cppValidUntil).format(
            "DD/MM/YYYY"
          )
        : "";
    }

    if (userDetail?.type === Role.INSTRUCTOR) {
      return "Teacher License";
    }
  }, [userDetail?.licenseOverview?.cppValidUntil, userDetail?.type]);

  return (
    <div className={styles.container}>
      <p className={styles.container__title}>License</p>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={{
          license: [],
          type: [],
          status: [],
          product: [],
          useDateFrom: undefined,
          useDateTo: undefined,
          startDateFrom: undefined,
          startDateTo: undefined,
          endDateFrom: undefined,
          endDateTo: undefined,
        }}
        validate={(values) => {
          let isUseDateValid: boolean = true;
          let isStartDateValid: boolean = true;
          let isEndDateValid: boolean = true;

          if (
            values.useDateFrom &&
            values.useDateTo &&
            new Date(values.useDateFrom).getTime() >
              new Date(values.useDateTo).getTime()
          ) {
            setErrorMessageUseDate("Invalid date");
            setDisableSearchButton(true);

            isUseDateValid = false;
          }

          if (
            values.startDateFrom &&
            values.startDateTo &&
            new Date(values.startDateFrom).getTime() >
              new Date(values.startDateTo).getTime()
          ) {
            setErrorMessageStartDate("Invalid date");
            setDisableSearchButton(true);

            isStartDateValid = false;
          }

          if (
            values.endDateFrom &&
            values.endDateTo &&
            new Date(values.endDateFrom).getTime() >
              new Date(values.endDateTo).getTime()
          ) {
            setErrorMessageEndDate("Invalid date");
            setDisableSearchButton(true);

            isEndDateValid = false;
          }

          if (isUseDateValid && errorMessageUseDate) {
            setErrorMessageUseDate(undefined);
          }

          if (isStartDateValid && errorMessageStartDate) {
            setErrorMessageStartDate(undefined);
          }

          if (isEndDateValid && errorMessageEndDate) {
            setErrorMessageEndDate(undefined);
          }

          if (isUseDateValid && isStartDateValid && isEndDateValid) {
            setDisableSearchButton(false);
          }
        }}
        onSubmit={async (values) => {
          const searchLicenseParams: SearchLicensesCondition = {
            license: values.license && values.license,
            useDateFrom: values.useDateFrom
              ? dateWithoutTimezone(values.useDateFrom)
              : undefined,
            useDateTo: values.useDateTo
              ? dateWithoutTimezone(values.useDateTo).replace(
                  "00:00:00",
                  "23:59:59"
                )
              : undefined,
            type: values.type && values.type,
            startDateFrom: values.startDateFrom
              ? dateWithoutTimezone(values.startDateFrom)
              : undefined,
            startDateTo: values.startDateTo
              ? dateWithoutTimezone(values.startDateTo).replace(
                  "00:00:00",
                  "23:59:59"
                )
              : undefined,
            status: values.status && values.status,
            endDateFrom: values.endDateFrom
              ? dateWithoutTimezone(values.endDateFrom)
              : undefined,
            endDateTo: values.endDateTo
              ? dateWithoutTimezone(values.endDateTo).replace(
                  "00:00:00",
                  "23:59:59"
                )
              : undefined,
            product: values.product && values.product,
          };

          const searchedLicenses = await adminGetAndSearchLicenses(
            sortOptions,
            userDetail?.id,
            searchLicenseParams
          );

          if (
            searchedLicenses?.paginateData &&
            searchedLicenses.paginateData.length === 0
          ) {
            setIsShowNoRecordFoundModal(true);
          }

          setRowsFromLicensesData(searchedLicenses.paginateData);
          setTotalLicensesCount(searchedLicenses.totalCount);

          setSearchCondition(searchLicenseParams);

          // This condition to force component Pagination re-render.
          setForcePage((prev) => (prev === 0 ? undefined : 0));
        }}
      >
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <div className={styles.container__licenseInfo}>
              <Grid>
                <GridItem width="1/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      CP Learning Valid Until:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {renderCPLValidUntil()}
                    </p>
                  </div>
                </GridItem>

                <GridItem width="1/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      CP Plus Valid Until:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {renderCPPValidUntil()}
                    </p>
                  </div>
                </GridItem>
              </Grid>

              <Grid>
                <GridItem width="1/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      CP App Valid Until:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.cpAppValidUntil
                        ? moment(
                            userDetail?.licenseOverview?.cpAppValidUntil
                          ).format("DD/MM/YYYY")
                        : ""}
                    </p>
                  </div>
                </GridItem>

                <GridItem width="1/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      CP App Email:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.cpAppEmail}
                    </p>
                  </div>
                </GridItem>

                <GridItem width="1/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      CP App Password:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.cpAppPassword}
                    </p>
                  </div>
                </GridItem>
              </Grid>

              <Grid>
                <GridItem width="1/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      CP Manager Valid Until:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.cpManagerValidUntil
                        ? moment(
                            userDetail?.licenseOverview?.cpManagerValidUntil
                          ).format("DD/MM/YYYY")
                        : ""}
                    </p>
                  </div>
                </GridItem>

                <GridItem width="2/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      CP Manager Code:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.cpManagerCode}
                    </p>
                  </div>
                </GridItem>
              </Grid>

              <Grid>
                <GridItem width="1/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      ClassWiz Valid Until:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.classwizLicense?.validUntil
                        ? formatUTCDate(
                            userDetail?.licenseOverview?.classwizLicense
                              ?.validUntil,
                            "dd/mm/yyyy"
                          )
                        : ""}
                    </p>
                  </div>
                </GridItem>

                <GridItem width="2/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      ClassWiz Code:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.classwizLicense?.code}
                    </p>
                  </div>
                </GridItem>
              </Grid>

              <Grid>
                <GridItem width="1/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      ClassWiz Email:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.classwizLicense?.email}
                    </p>
                  </div>
                </GridItem>

                <GridItem width="2/3">
                  <div className={styles.container__licenseInfo__item}>
                    <p className={styles.container__licenseInfo__item__label}>
                      ClassWiz Password:{" "}
                    </p>
                    <p className={styles.container__licenseInfo__item__value}>
                      {userDetail?.licenseOverview?.classwizLicense?.password}
                    </p>
                  </div>
                </GridItem>
              </Grid>
            </div>

            {userDetail?.type === Role.LEARNER && (
              <>
                <div className={styles.container__searchFields}>
                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="license"
                        options={[
                          {
                            value: CentrializedLicenseDetail.CLASS_TRIAL,
                            label: "ClassPad Learning 30 days Class Trial",
                            data: CentrializedLicenseDetail.CLASS_TRIAL,
                          },
                          {
                            value: CentrializedLicenseDetail.TRIAL,
                            label: "ClassPad Learning 30 days Student Trial",
                            data: CentrializedLicenseDetail.TRIAL,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_COMBINATION_1_YEAR_CODE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_COMBINATION_1_YEAR_CODE,
                            data: CentrializedLicenseDetail.CLASSPAD_COMBINATION_1_YEAR_CODE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_COMBINATION_1_YEAR_PURCHASE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_COMBINATION_1_YEAR_PURCHASE,
                            data: CentrializedLicenseDetail.CLASSPAD_COMBINATION_1_YEAR_PURCHASE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_CODE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_CODE,
                            data: CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_CODE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_PURCHASE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_PURCHASE,
                            data: CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_PURCHASE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_PROMOTIONAL_CODE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_PROMOTIONAL_CODE,
                            data: CentrializedLicenseDetail.CLASSPAD_LEARNING_1_YEAR_PROMOTIONAL_CODE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_LEARNING_30_DAY_PROMOTIONAL_CODE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_LEARNING_30_DAY_PROMOTIONAL_CODE,
                            data: CentrializedLicenseDetail.CLASSPAD_LEARNING_30_DAY_PROMOTIONAL_CODE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_PLUS_1_YEAR_CODE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_PLUS_1_YEAR_CODE,
                            data: CentrializedLicenseDetail.CLASSPAD_PLUS_1_YEAR_CODE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_PLUS_1_YEAR_PURCHASE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_PLUS_1_YEAR_PURCHASE,
                            data: CentrializedLicenseDetail.CLASSPAD_PLUS_1_YEAR_PURCHASE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_PLUS_7_YEAR_CODE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_PLUS_7_YEAR_CODE,
                            data: CentrializedLicenseDetail.CLASSPAD_PLUS_7_YEAR_CODE,
                          },
                          {
                            value:
                              CentrializedLicenseDetail.CLASSPAD_PLUS_7_YEAR_PURCHASE,
                            label:
                              CentrializedLicenseDetail.CLASSPAD_PLUS_7_YEAR_PURCHASE,
                            data: CentrializedLicenseDetail.CLASSPAD_PLUS_7_YEAR_PURCHASE,
                          },
                          {
                            value: CentrializedLicenseDetail.MATH_MARATHON,
                            label: CentrializedLicenseDetail.MATH_MARATHON,
                            data: CentrializedLicenseDetail.MATH_MARATHON,
                          },
                          {
                            value: CentrializedLicenseDetail.SCHOOL_FROM_LMS,
                            label: CentrializedLicenseDetail.SCHOOL_FROM_LMS,
                            data: CentrializedLicenseDetail.SCHOOL_FROM_LMS,
                          },
                          {
                            value: CentrializedLicenseDetail.SCHOOL,
                            label: CentrializedLicenseDetail.SCHOOL,
                            data: CentrializedLicenseDetail.SCHOOL,
                          },
                        ]}
                        label="License"
                        value={values.license}
                      />
                    </GridItem>
                    <GridItem width="1/3">
                      <DatepickerField
                        label="Use Date From"
                        placeholderLabel="dd/mm/yyyy"
                        id="useDateFrom"
                        name="useDateFrom"
                        errorMessage={errorMessageUseDate}
                        value={values.useDateFrom}
                        locale="en"
                      />
                    </GridItem>
                    <GridItem width="1/3">
                      <DatepickerField
                        label="Use Date To"
                        placeholderLabel="dd/mm/yyyy"
                        id="useDateTo"
                        name="useDateTo"
                        errorMessage={errorMessageUseDate}
                        value={values.useDateTo}
                        locale="en"
                      />
                    </GridItem>
                  </Grid>

                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="type"
                        options={[
                          {
                            data: CentralizedLicenseType.INDIVIDUAL,
                            label: "Individual",
                            value: CentralizedLicenseType.INDIVIDUAL,
                          },
                          {
                            data: CentralizedLicenseType.TRIAL,
                            label: "Trial",
                            value: CentralizedLicenseType.TRIAL,
                          },
                          {
                            data: CentralizedLicenseType.CLASS_TRIAL,
                            label: "Class Trial",
                            value: CentralizedLicenseType.CLASS_TRIAL,
                          },
                          {
                            data: CentralizedLicenseType.MATH_MARATHON,
                            label: "Math Marathon",
                            value: CentralizedLicenseType.MATH_MARATHON,
                          },
                          {
                            data: CentralizedLicenseType.SCHOOL,
                            label: "School Teacher",
                            value: CentralizedLicenseType.SCHOOL,
                          },
                          {
                            data: CentralizedLicenseType.SCHOOL_LMS,
                            label: "School LMS",
                            value: CentralizedLicenseType.SCHOOL_LMS,
                          },
                        ]}
                        label="Type"
                        value={values.type}
                      />
                    </GridItem>
                    <GridItem width="1/3">
                      <DatepickerField
                        label="Start Date From"
                        placeholderLabel="dd/mm/yyyy"
                        id="startDateFrom"
                        name="startDateFrom"
                        errorMessage={errorMessageStartDate}
                        value={values.startDateFrom}
                        locale="en"
                      />
                    </GridItem>
                    <GridItem width="1/3">
                      <DatepickerField
                        label="Start Date To"
                        placeholderLabel="dd/mm/yyyy"
                        id="startDateTo"
                        name="startDateTo"
                        errorMessage={errorMessageStartDate}
                        value={values.startDateTo}
                        locale="en"
                      />
                    </GridItem>
                  </Grid>

                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="status"
                        options={[
                          {
                            data: Status.ACTIVE,
                            label: "Active",
                            value: Status.ACTIVE,
                          },
                          {
                            data: Status.EXPIRED,
                            label: "Expired",
                            value: Status.EXPIRED,
                          },
                          {
                            data: Status.RETURNED,
                            label: "Returned",
                            value: Status.RETURNED,
                          },
                        ]}
                        label="Status"
                        value={values.status}
                      />
                    </GridItem>
                    <GridItem width="1/3">
                      <DatepickerField
                        label="End Date From"
                        placeholderLabel="dd/mm/yyyy"
                        id="endDateFrom"
                        name="endDateFrom"
                        errorMessage={errorMessageEndDate}
                        value={values.endDateFrom}
                        locale="en"
                      />
                    </GridItem>
                    <GridItem width="1/3">
                      <DatepickerField
                        label="End Date To"
                        placeholderLabel="dd/mm/yyyy"
                        id="endDateTo"
                        name="endDateTo"
                        errorMessage={errorMessageEndDate}
                        value={values.endDateTo}
                        locale="en"
                      />
                    </GridItem>
                  </Grid>

                  <Grid>
                    <GridItem width="1/3">
                      <MultiSelect
                        id="product"
                        options={[
                          {
                            data: Product.CLASSPAD_LEARNING,
                            label: "ClassPad Learning",
                            value: Product.CLASSPAD_LEARNING,
                          },
                          {
                            data: Product.CLASSPAD_NET_PLUS,
                            label: "ClassPad Net Plus",
                            value: Product.CLASSPAD_NET_PLUS,
                          },
                        ]}
                        label="Product"
                        value={values.product}
                      />
                    </GridItem>
                  </Grid>
                </div>

                <div className={styles.container__btnGroup}>
                  <Grid>
                    <GridItem
                      width="2/3"
                      className={styles.container__btnGroup__groupBtn1}
                    >
                      <></>
                    </GridItem>

                    <GridItem
                      width="1/3"
                      className={styles.container__btnGroup__groupBtn2}
                    >
                      <Button
                        label="clear"
                        btnStyle="secondary"
                        size="small"
                        onClick={() => {
                          fieldsToClear.forEach((data) => {
                            handleChange({
                              target: { name: data.name, value: data.value },
                            });
                          });
                          setErrorMessageUseDate(undefined);
                          setErrorMessageStartDate(undefined);
                          setErrorMessageEndDate(undefined);
                          setDisableSearchButton(false);
                        }}
                      ></Button>

                      <Button
                        label="search"
                        size="small"
                        type="submit"
                        disabled={disableSearchButton}
                      ></Button>
                    </GridItem>
                  </Grid>
                </div>
              </>
            )}
            <div className={styles.container__tableContainer}>
              {userDetail?.type === Role.LEARNER ? (
                <>
                  <p>License Records</p>

                  <Table
                    columns={licenseRecordsColumns}
                    rows={licenseRecordsRows}
                    onRowsChange={setLicenseRecordsRows}
                    sortColumns={sortColumns}
                    onSortColumnsChange={(sortColumns) => {
                      onSortColumns(sortColumns, searchCondition);
                    }}
                    rowKeyGetter={undefined}
                    onCellClick={async (event) => {
                      const licenseDetail = await adminGetLicenseDetail(
                        event.row.id
                      );

                      setLicenseDetail({
                        ...licenseDetail,
                        status: (event.row.status as string)?.toUpperCase(), // get status from license records list and uppercase it.
                      });

                      setIsShowLicenseInfoModal(true);
                    }}
                  />
                </>
              ) : (
                <>
                  <p>School License</p>
                  <Table
                    columns={schoolLicenseColumns}
                    rows={schoolLicenseRows}
                    onRowsChange={setSchoolLicenseRows}
                    rowKeyGetter={undefined}
                  />
                </>
              )}

              {licenseRecordsRows.length > 0 &&
                userDetail?.type === Role.LEARNER && (
                  <Pagination
                    items={licenseRecordsRows}
                    totalCount={totalLicensesCount}
                    getItemsPerPage={async (itemsPerPage: number) => {
                      let sortOptionsUpdate = { ...sortOptions };

                      sortOptionsUpdate.limit = itemsPerPage;
                      sortOptionsUpdate.page = 1;
                      setSortOptions(sortOptionsUpdate);

                      await fetchLicenses(sortOptionsUpdate, searchCondition);

                      setForcePage((prev) => (prev === 0 ? undefined : 0));
                    }}
                    getPageNumber={async (pageNumber: number) => {
                      let sortOptionsUpdate = { ...sortOptions };

                      sortOptionsUpdate.page = pageNumber;
                      setSortOptions(sortOptionsUpdate);

                      await fetchLicenses(sortOptionsUpdate, searchCondition);
                    }}
                    forcePage={forcePage}
                  />
                )}
            </div>

            <div className={styles.container__btnGroup}>
              <Grid>
                <GridItem
                  width="1"
                  className={styles.container__btnGroup__groupBtn3}
                >
                  <Button
                    label="User License View"
                    size="small"
                    onClick={() => setIsShowUserLicenseView(true)}
                  ></Button>

                  {userDetail?.type === Role.LEARNER && (
                    <Button
                      label="Add License Code"
                      size="small"
                      onClick={() => {
                        setIsShowAddLicenseCodeModal(true);
                      }}
                    ></Button>
                  )}

                  <Button
                    label="Add/Replace additional license"
                    size="small"
                    onClick={() => {
                      setIsShowAddReplaceALModal(true);
                    }}
                  ></Button>

                  <Button
                    label="Edit Classwiz License"
                    size="small"
                    onClick={() => {
                      setIsShowEditClasswizLicenseModal(true);
                    }}
                  ></Button>
                </GridItem>
              </Grid>
            </div>

            <AddLicenseCodeModal
              isShowing={isShowAddLicenseCodeModal}
              setIsShowing={setIsShowAddLicenseCodeModal}
              userId={userDetail?.id}
              email={userDetail?.email}
              country={userDetail?.country}
              idProvider={userDetail?.idProvider}
              setIsShowSpinner={setIsShowSpinner}
              fetchUserDetail={fetchUserDetail}
            />

            <AddReplaceAdditionalLicenseModal
              isShowing={isShowAddReplaceALModal}
              setIsShowing={setIsShowAddReplaceALModal}
              userId={userDetail?.id}
              email={userDetail?.email}
              country={userDetail?.country}
              fetchUserDetail={fetchUserDetail}
              setIsShowSpinner={setIsShowSpinner}
            />

            <LicenseDetailModal
              isShowing={isShowLicenseInfoModal}
              setIsShowing={setIsShowLicenseInfoModal}
              licenseDetail={licenseDetail}
            />

            <AdminUserLicenseView
              isShowing={isShowUserLicenseView}
              setIsShowing={setIsShowUserLicenseView}
              title={userDetail?.nickname?.toUpperCase()}
              selectedUserData={{
                id: userDetail?.id,
                role: userDetail?.type,
              }}
            />

            <EditClasswizLicenseModal
              isShowing={isShowEditClasswizLicenseModal}
              setIsShowing={setIsShowEditClasswizLicenseModal}
              userId={userDetail?.id}
              fetchUserDetail={fetchUserDetail}
              classwizLicenseProps={
                userDetail?.licenseOverview?.classwizLicense
              }
              classpadAppLicenseProps={{
                email: userDetail?.licenseOverview?.cpAppEmail,
                password: userDetail?.licenseOverview?.cpAppPassword,
              }}
              setIsShowSpinner={setIsShowSpinner}
            />
          </Form>
        )}
      </Formik>

      <Spinner
        type="fullPage"
        title={"Please wait..."}
        visible={isShowSpinner}
      />

      {isShowNoRecordFoundModal && (
        <NoRecordFoundModal
          isShowing={isShowNoRecordFoundModal}
          setVisible={setIsShowNoRecordFoundModal}
        />
      )}
    </div>
  );
};

const ADD_LICENSE_CODE_PRODUCT_DROPDOWN_LIST = [
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_30D_PROMOTIONAL,
    value: {
      products: [Product.CLASSPAD_LEARNING],
      isPromotional: true,
      duration: 30,
      licenseName:
        ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_30D_PROMOTIONAL,
    },
  },
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_1Y,
    value: {
      products: [Product.CLASSPAD_LEARNING],
      isPromotional: false,
      duration: 366,
      licenseName: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_1Y,
    },
  },
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_1Y_PROMOTIONAL,
    value: {
      products: [Product.CLASSPAD_LEARNING],
      isPromotional: true,
      duration: 366,
      licenseName:
        ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_1Y_PROMOTIONAL,
    },
  },
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_PLUS_1Y, // not GAR
    value: {
      products: [Product.CLASSPAD_NET_PLUS],
      isPromotional: false,
      duration: 366,
      licenseName: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_PLUS_1Y,
    },
  },
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_PLUS_7Y, // not GAR
    value: {
      products: [Product.CLASSPAD_NET_PLUS],
      isPromotional: false,
      duration: 2557,
      licenseName: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_PLUS_7Y,
    },
  },
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_COMBINATION_1Y, // not GAR
    value: {
      products: [Product.CLASSPAD_LEARNING, Product.CLASSPAD_NET_PLUS],
      isPromotional: false,
      duration: 366,
      licenseName: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_COMBINATION_1Y,
    },
  },
];

const ADD_LICENSE_CODE_PRODUCT_DROPDOWN_LIST_GAR_USER = [
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_30D_PROMOTIONAL,
    value: {
      products: [Product.CLASSPAD_LEARNING],
      isPromotional: true,
      duration: 30,
      licenseName:
        ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_30D_PROMOTIONAL,
    },
  },
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_1Y,
    value: {
      products: [Product.CLASSPAD_LEARNING],
      isPromotional: false,
      duration: 366,
      licenseName: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_1Y,
    },
  },
  {
    label: ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_1Y_PROMOTIONAL,
    value: {
      products: [Product.CLASSPAD_LEARNING],
      isPromotional: true,
      duration: 366,
      licenseName:
        ADMIN_ADD_LICENSE_CODE_LABEL.CLASSPAD_LEARNING_1Y_PROMOTIONAL,
    },
  },
];

const AddLicenseCodeModal = ({
  isShowing,
  setIsShowing,
  userId,
  email,
  country,
  idProvider,
  setIsShowSpinner,
  fetchUserDetail,
}: {
  isShowing: boolean;
  setIsShowing: (isShowing: boolean) => void;
  userId: string | undefined;
  email: string | undefined;
  country: Country | undefined;
  idProvider: IdProvider | undefined;
  setIsShowSpinner: Dispatch<SetStateAction<boolean>>;
  fetchUserDetail: () => Promise<void>;
}) => {
  const [isDisabledConfirmBtn, setIsDisabledConfirmBtn] =
    useState<boolean>(true);

  const [productErrorMsg, setProductErrorMsg] = useState<string>();
  const [expiryErrorMsg, setExpiryErrorMsg] = useState<string>();

  const { addToast } = useToasts();

  useEffect(() => {
    if (isShowing) {
      setIsDisabledConfirmBtn(true);
      setProductErrorMsg(undefined);
      setExpiryErrorMsg(undefined);
    }
  }, [isShowing]);

  return (
    <div className={styles.addLicenseCodeContainer}>
      <Modal
        title="Add License Code"
        isShowing={isShowing}
        closeButton={true}
        size="large"
        onHide={() => {
          setIsShowing(false);
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            product: undefined,
            expiry: undefined,
          }}
          validate={(values) => {
            if (!values.product) {
              return;
            }
            const now = new Date();
            now.setHours(0, 0, 0, 0);

            if (values.product && !values.expiry) {
              setIsDisabledConfirmBtn(false);
            }

            if (values.expiry && isBefore(new Date(values.expiry), now)) {
              setIsDisabledConfirmBtn(true);
            } else {
              setIsDisabledConfirmBtn(false);
            }

            if (
              (values.product &&
                values.expiry &&
                isAfter(new Date(values.expiry), now)) ||
              (values.expiry &&
                new Date(values.expiry).getTime() === now.getTime())
            ) {
              setIsDisabledConfirmBtn(false);
            }
          }}
          onSubmit={(values) => {
            const addLicenseCodeParams: AddLicenseCodeParams = {
              product:
                values.product &&
                (JSON.parse(values.product) as {
                  products: Product[];
                  isPromotional: boolean;
                  duration: number;
                  licenseName: string;
                }),
              expiry:
                values.expiry &&
                dateWithoutTimezone(values.expiry).replace(
                  "00:00:00",
                  "23:59:59"
                ),
              email,
              country,
            };

            setIsShowSpinner(true);

            adminAddLicenseCode(userId, addLicenseCodeParams)
              .then(async () => {
                // add toast
                addToast(
                  "License code has been added to the user successfully.",
                  "success"
                );

                await fetchUserDetail();
                setIsShowing(false);
              })
              .catch((err) => {
                addToast("Something went wrong...", "error");
              })
              .finally(() => {
                setIsShowSpinner(false);
              });
          }}
        >
          {({ handleChange, values, setFieldValue }) => (
            <Form>
              <p className={styles.addLicenseCodeContainer__addLicenseCodeText}>
                Please choose and confirm the license code that you want to add
                for the user.
              </p>

              <div className={styles.addLicenseCodeContainer__body}>
                <Grid>
                  <GridItem width="2/3">
                    <Select
                      label="Product"
                      id="product"
                      name="product"
                      options={
                        idProvider === IdProvider.GAR
                          ? ADD_LICENSE_CODE_PRODUCT_DROPDOWN_LIST_GAR_USER
                          : ADD_LICENSE_CODE_PRODUCT_DROPDOWN_LIST
                      }
                      onBlur={() => {
                        if (!values.product) {
                          setProductErrorMsg("Please select");
                          setIsDisabledConfirmBtn(true);
                        } else {
                          setProductErrorMsg(undefined);
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event.target.value);
                      }}
                      placeholderLabel={"Choose..."}
                      errorMessage={productErrorMsg}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <DatepickerField
                      label={"Expiry Date (optional)"}
                      placeholderLabel={"no expiration date"}
                      id="expiry"
                      name="expiry"
                      onChange={(event) => {
                        if (event) {
                          const now = new Date();
                          now.setHours(0, 0, 0, 0);

                          if (isBefore(event, now)) {
                            setExpiryErrorMsg("Invalid date");
                          }
                          if (
                            isAfter(event, now) ||
                            event.getTime() === now.getTime()
                          ) {
                            setExpiryErrorMsg(undefined);
                          }
                          handleChange(event.toString());
                        } else {
                          handleChange("");
                        }
                      }}
                      errorMessage={expiryErrorMsg}
                    />
                  </GridItem>
                </Grid>
                <p className={styles.addLicenseCodeContainer__textEmptyLine}>
                  Empty line
                </p>
                <p className={styles.addLicenseCodeContainer__textEmptyLine}>
                  Empty line
                </p>
                <p className={styles.addLicenseCodeContainer__textEmptyLine}>
                  Empty line
                </p>
                <p className={styles.addLicenseCodeContainer__textEmptyLine}>
                  Empty line
                </p>
                <p className={styles.addLicenseCodeContainer__textEmptyLine}>
                  Empty line
                </p>
                <p className={styles.addLicenseCodeContainer__textEmptyLine}>
                  Empty line
                </p>
              </div>

              <div className={styles.addLicenseCodeContainer__btnGroup}>
                <Grid>
                  <GridItem
                    width="1/2"
                    className={
                      styles.addLicenseCodeContainer__btnGroup__groupBtn1
                    }
                  >
                    <Button
                      label="confirm"
                      size="fullWidth"
                      type="submit"
                      disabled={isDisabledConfirmBtn}
                    ></Button>
                  </GridItem>
                  <GridItem
                    width="1/2"
                    className={
                      styles.addLicenseCodeContainer__btnGroup__groupBtn2
                    }
                  >
                    <Button
                      label="cancel"
                      size="fullWidth"
                      btnStyle="secondary"
                      onClick={() => {
                        setIsShowing(false);
                      }}
                    ></Button>
                  </GridItem>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

const AddReplaceAdditionalLicenseModal = ({
  isShowing,
  setIsShowing,
  userId,
  email,
  country,
  fetchUserDetail,
  setIsShowSpinner,
}: {
  isShowing: boolean;
  setIsShowing: (isShowing: boolean) => void;
  userId: string | undefined;
  email: string | undefined;
  country: Country | undefined;
  fetchUserDetail: () => Promise<void>;
  setIsShowSpinner: Dispatch<SetStateAction<boolean>>;
}) => {
  const [countValidCPAppLicenses, setCountValidCPAppLicenses] =
    useState<number>(0);
  const [countValidCPManagerLicenses, setCountValidCPManagerLicenses] =
    useState<number>(0);

  const [isDisableConfirmBtn, setIsDisableConfirmBtn] = useState<boolean>(true);

  const [checkedCPAppAccessingData, setCheckedCPAppAccessingData] =
    useState<boolean>(false);
  const [checkedCPManagerAccessingData, setCheckedCPManagerAccessingData] =
    useState<boolean>(false);
  const [
    isDisabledCPAppAccessDataCheckBox,
    setIsDisabledCPAppAccessDataCheckBox,
  ] = useState<boolean>(true);
  const [
    isDisabledCPManagerAccessDataCheckBox,
    setIsDisabledCPManagerAccessDataCheckBox,
  ] = useState<boolean>(true);

  const { addToast } = useToasts();

  const fetchAPI = useCallback(async () => {
    const countLicenses = await adminCountValidAdditionalLicenses();

    const { countValidCPAppLicense, countValidCPManagerLicense } =
      countLicenses;

    setCountValidCPAppLicenses(countValidCPAppLicense);
    setCountValidCPManagerLicenses(countValidCPManagerLicense);

    if (countValidCPAppLicense > 0) {
      setIsDisabledCPAppAccessDataCheckBox(false);
    }

    if (countValidCPManagerLicense > 0) {
      setIsDisabledCPManagerAccessDataCheckBox(false);
    }

    if (countValidCPAppLicense === 0) {
      setIsDisabledCPAppAccessDataCheckBox(true);
      setCheckedCPAppAccessingData(false);
    }

    if (countValidCPManagerLicense === 0) {
      setIsDisabledCPManagerAccessDataCheckBox(true);
      setCheckedCPManagerAccessingData(false);
    }

    if (countValidCPAppLicense === 0 && countValidCPManagerLicense === 0) {
      setIsDisableConfirmBtn(true);
    }
  }, []);

  useEffect(() => {
    if (isShowing) {
      fetchAPI();
    }
  }, [fetchAPI, isShowing]);

  useEffect(() => {
    if (checkedCPAppAccessingData || checkedCPManagerAccessingData) {
      setIsDisableConfirmBtn(false);
    } else {
      setIsDisableConfirmBtn(true);
    }

    if (countValidCPAppLicenses === 0) {
      setIsDisabledCPAppAccessDataCheckBox(true);
      setCheckedCPAppAccessingData(false);
    }

    if (countValidCPManagerLicenses === 0) {
      setIsDisabledCPManagerAccessDataCheckBox(true);
      setCheckedCPManagerAccessingData(false);
    }

    if (countValidCPAppLicenses === 0 && countValidCPManagerLicenses === 0) {
      setIsDisableConfirmBtn(true);
    }
  }, [
    checkedCPAppAccessingData,
    checkedCPManagerAccessingData,
    countValidCPAppLicenses,
    countValidCPManagerLicenses,
  ]);

  return (
    <div className={styles.addReplaceALContainer}>
      <Modal
        title="Add/Replace Additional License"
        isShowing={isShowing}
        closeButton={true}
        size="large"
        onHide={() => {
          setIsShowing(false);
        }}
      >
        <p className={styles.addReplaceALContainer__intro}>
          Please choose and confirm the Additional License Accessing data that
          you want to add/replace for the user.
        </p>

        <div className={styles.addReplaceALContainer__availableLicenseNum}>
          <p
            className={
              styles.addReplaceALContainer__availableLicenseNum__availableCPANum
            }
          >
            Available Valid ClassPad App Licenses: {countValidCPAppLicenses}
          </p>
          <p
            className={
              styles.addReplaceALContainer__availableLicenseNum__availableCPMNum
            }
          >
            Available Valid ClassPad Manager Licenses:{" "}
            {countValidCPManagerLicenses}
          </p>
        </div>

        <div className={styles.addReplaceALContainer__checkBoxGroup}>
          <LabeledCheckbox
            id="cpapp_accessingdata"
            label="ClassPad App Accessing Data"
            value="cpapp_accessingdata"
            mode="small"
            onChange={(event) => {
              setCheckedCPAppAccessingData(event.target.checked);
            }}
            disabled={isDisabledCPAppAccessDataCheckBox}
            checked={checkedCPAppAccessingData}
          />

          <LabeledCheckbox
            id="cpmanager_accessingdata"
            label="ClassPad Manager Accessing Data"
            value="cpmanager_accessingdata"
            mode="small"
            onChange={(event) => {
              setCheckedCPManagerAccessingData(event.target.checked);
            }}
            disabled={isDisabledCPManagerAccessDataCheckBox}
            checked={checkedCPManagerAccessingData}
          />
        </div>

        <div className={styles.addReplaceALContainer__btnGroup}>
          <Grid>
            <GridItem
              width="1/2"
              className={styles.addReplaceALContainer__btnGroup__groupBtn1}
            >
              <Button
                label="confirm"
                size="fullWidth"
                onClick={() => {
                  let additionalLicenseTypes: AdditionalLicenseType[] = [];
                  if (checkedCPAppAccessingData) {
                    additionalLicenseTypes.push(
                      AdditionalLicenseType.CLASSPAD_PLUS_APP
                    );
                  }

                  if (checkedCPManagerAccessingData) {
                    additionalLicenseTypes.push(
                      AdditionalLicenseType.CLASSPAD_PLUS_MANAGER
                    );
                  }
                  setIsShowSpinner(true);
                  adminAddReplaceAdditionalLicense(
                    userId,
                    email,
                    country,
                    additionalLicenseTypes
                  )
                    .then(async () => {
                      // Display Toast Pop-Up Message "Additional License has been added to the user successfully."
                      addToast(
                        "Additional License has been added to the user successfully.",
                        "success"
                      );
                      await fetchAPI();
                      // re-fetch user detail on component UserDetail.
                      await fetchUserDetail();
                      setIsShowSpinner(false);
                    })
                    .catch(() => {
                      addToast("Something went wrong...", "error");
                    });
                }}
                disabled={isDisableConfirmBtn}
              ></Button>
            </GridItem>
            <GridItem
              width="1/2"
              className={styles.addReplaceALContainer__btnGroup__groupBtn2}
            >
              <Button
                label="cancel"
                size="fullWidth"
                btnStyle="secondary"
                onClick={() => {
                  setIsShowing(false);
                }}
              ></Button>
            </GridItem>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

const LicenseDetailModal = ({
  isShowing,
  setIsShowing,
  licenseDetail,
}: {
  isShowing: boolean;
  setIsShowing: (isShowing: boolean) => void;
  licenseDetail: GetLicenseDetailResponse;
}) => {
  return (
    <Modal
      title={`License: ${licenseDetail?.detail}`}
      isShowing={isShowing}
      closeButton={true}
      size="superlarge"
      onHide={() => {
        setIsShowing(false);
      }}
    >
      <div className={styles.licenseDetailModalContainer}>
        <Grid>
          <GridItem width="1/2">
            <p className={styles.licenseDetailModalContainer__text}>
              Name/Code: {licenseDetail?.name ? licenseDetail?.name : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Type: {licenseDetail?.type ? licenseDetail?.type : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Start date:{" "}
              {licenseDetail?.startDate
                ? formatUTCDate(String(licenseDetail?.startDate))
                : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Status: {licenseDetail?.status ? licenseDetail?.status : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Purchase ID:{" "}
              {licenseDetail?.purchaseId ? licenseDetail?.purchaseId : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              School License ID:{" "}
              {licenseDetail?.schoolLicenseId
                ? licenseDetail?.schoolLicenseId
                : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              School ID:{" "}
              {licenseDetail?.schoolId ? licenseDetail?.schoolId : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              School name:{" "}
              {licenseDetail?.schoolName ? licenseDetail?.schoolName : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Teacher ID:{" "}
              {licenseDetail?.teacherId ? licenseDetail?.teacherId : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Teacher Nickname:{" "}
              {licenseDetail?.teacherNickname
                ? licenseDetail?.teacherNickname
                : ""}
            </p>
          </GridItem>

          <GridItem width="1/2">
            <p className={styles.licenseDetailModalContainer__text}>
              RecordID: {licenseDetail?.recordId ? licenseDetail?.recordId : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Use Date:{" "}
              {licenseDetail?.useDate
                ? formatUTCDate(String(licenseDetail?.useDate))
                : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              End date:{" "}
              {licenseDetail?.endDate
                ? formatUTCDate(String(licenseDetail?.endDate))
                : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Product: {licenseDetail?.product}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              RedeemID: {licenseDetail?.redeemId ? licenseDetail?.redeemId : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__textEmptyLine}>
              Empty line
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              ClassID: {licenseDetail?.classId ? licenseDetail?.classId : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Class name:{" "}
              {licenseDetail?.className ? licenseDetail?.className : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Stop date:{" "}
              {licenseDetail?.stopDate
                ? formatUTCDate(String(licenseDetail?.stopDate))
                : ""}
            </p>
            <p className={styles.licenseDetailModalContainer__text}>
              Stop reason:{" "}
              {licenseDetail?.stopReason ? licenseDetail?.stopReason : ""}
            </p>
          </GridItem>
        </Grid>

        <div className={styles.licenseDetailModalContainer__groupBtn}>
          <Button
            label="close"
            btnStyle="secondary"
            onClick={() => {
              setIsShowing(false);
            }}
          ></Button>
        </div>
      </div>
    </Modal>
  );
};

interface EditClasswizFormikValues {
  code: string | undefined;
  email: string | undefined;
  password: string | undefined;
  validUntil: any;
}

const emptyValue: EditClasswizFormikValues = {
  code: undefined,
  email: undefined,
  password: undefined,
  validUntil: undefined,
};

export const EditClasswizLicenseModal = ({
  isShowing,
  setIsShowing,
  userId,
  fetchUserDetail,
  classpadAppLicenseProps,
  classwizLicenseProps,
  setIsShowSpinner,
}: {
  isShowing: boolean;
  setIsShowing: (isShowing: boolean) => void;
  fetchUserDetail: () => Promise<void>;
  userId?: string;
  classpadAppLicenseProps: {
    email?: string;
    password?: string;
  };
  classwizLicenseProps?: ClasswizEmulatorData;
  setIsShowSpinner: Dispatch<SetStateAction<boolean>>;
}) => {
  const formikRef = useRef<FormikProps<EditClasswizFormikValues>>(null);

  const [classwizLicense, setClasswizLicense] =
    useState<ClasswizEmulatorData>();
  const [previousValidUntil, setPreviousValidUntil] = useState<string>();

  const [defaultLicenseId, setDefaultLicenseId] =
    useState<string>("123-abc-456");
  const [isShowConfirmDeleteModal, setIsShowConfirmDeleteModal] =
    useState<boolean>(false);
  const [
    isShowEditClassWizSuccesfullyModal,
    setIsShowEditClassWizSuccesfullyModal,
  ] = useState<boolean>(false);

  const [
    isShowDeleteClassWizSuccesfullyModal,
    setIsShowDeleteClassWizSuccesfullyModal,
  ] = useState<boolean>(false);

  const [isShowErrorModal, setIsShowErrorModal] = useState<boolean>(false);
  const [isDisabledConfirmBtn, setIsDisableConfirmBtn] =
    useState<boolean>(true);

  const [validUntilErrMsg, setValidUntilErrMsg] = useState<string>();

  useEffect(() => {
    if (isShowing && userId) {
      if (!isDisabledConfirmBtn) {
        setIsDisableConfirmBtn(true);
      }
      if (validUntilErrMsg) {
        setValidUntilErrMsg(undefined);
      }

      setClasswizLicense({
        licenseId: classwizLicenseProps?.licenseId,
        code: classwizLicenseProps?.code,
        email: classwizLicenseProps?.licenseId
          ? classwizLicenseProps?.email
          : classpadAppLicenseProps?.email,
        password: classwizLicenseProps?.licenseId
          ? classwizLicenseProps?.password
          : classpadAppLicenseProps?.password,
        validUntil: classwizLicenseProps?.validUntil,
      });

      setPreviousValidUntil(
        formatUTCDate(classwizLicenseProps?.validUntil ?? "", "dd.mm.yyyy")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing, classpadAppLicenseProps, classpadAppLicenseProps, userId]);

  return (
    <div className={styles.classwiz}>
      <Modal
        title={"Edit ClassWiz Emulator License"}
        isShowing={isShowing}
        closeButton={false}
        size="large"
        onHide={() => {
          setIsShowing(false);
        }}
      >
        <div className={styles.classwiz__editClasswizLicense}>
          <p className={styles.classwiz__editClasswizLicense__intro}>
            Please input the ClassWiz Emulator license detail.
          </p>

          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              code: classwizLicense?.code || "",
              email: classwizLicense?.email || "",
              password: classwizLicense?.password || "",
              validUntil: classwizLicense?.validUntil
                ? formatUTCDate(
                    classwizLicense?.validUntil as unknown as string,
                    "dd.mm.yyyy"
                  )
                : "",
            }}
            validate={(values) => {
              if (!values.validUntil) {
                setIsDisableConfirmBtn(true);
                return;
              }

              if (values.validUntil && validUntilErrMsg) {
                setValidUntilErrMsg(undefined);
              }

              const currentValidUntil =
                values.validUntil instanceof Date
                  ? formatUTCDate(
                      dateWithoutTimezone(
                        values?.validUntil as unknown as Date
                      ).replace("T00:00:00.000", ""),
                      "dd.mm.yyyy"
                    )?.trim()
                  : values.validUntil.trim();

              if (
                values.code === classwizLicense?.code &&
                values.email === classwizLicense?.email &&
                values.password === classwizLicense?.password &&
                previousValidUntil?.trim() === currentValidUntil
              ) {
                setIsDisableConfirmBtn(true);
              } else {
                setIsDisableConfirmBtn(false);
              }
            }}
            validateOnChange={true}
            onSubmit={async (values) => {
              setIsShowSpinner(true);
              const upsertData: ClasswizEmulatorData = {
                licenseId: classwizLicense?.licenseId || defaultLicenseId,
                code: values.code || "",
                email: values.email || "",
                password: values.password || "",
                validUntil:
                  (values.validUntil as unknown as Date) &&
                  dateWithoutTimezone(
                    reverseSpecifiedFormatToDate(values.validUntil) ||
                      new Date()
                  ).replace("00:00:00", "23:59:59"),
              };
              console.log("upsertData", upsertData);

              await upsertClasswizEmulatorLicenseOfUser(userId, upsertData)
                .then(async (response) => {
                  const licenseData =
                    response.license as ClasswizEmulatorLicense;
                  setIsShowEditClassWizSuccesfullyModal(true);
                  setClasswizLicense({
                    licenseId: response.id,
                    code: licenseData.code,
                    email: licenseData.email,
                    password: licenseData.password,
                    validUntil: response.validUntil as any,
                  });
                  setIsDisableConfirmBtn(true);
                  setDefaultLicenseId(response.id);
                  await fetchUserDetail();
                  setIsShowSpinner(false);
                })
                .catch((err) => {
                  setIsShowErrorModal(true);
                  setIsShowSpinner(false);
                });
            }}
          >
            {({ handleChange, values }) => {
              return (
                <Form>
                  <div
                    className={styles.classwiz__editClasswizLicense__content}
                  >
                    <Grid>
                      <GridItem width="1" key="item-1">
                        <TextInput
                          id="code"
                          name="code"
                          label="ClassWiz Emulator Code"
                          value={values.code}
                          onChange={handleChange}
                        />
                      </GridItem>

                      <GridItem width="1" key="item-2">
                        <TextInput
                          id="email"
                          name="email"
                          label="ClassWiz Emulator Email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </GridItem>

                      <GridItem width="1" key="item-3">
                        <TextInput
                          id="password"
                          name="password"
                          label="ClassWiz Emulator Password"
                          value={values.password}
                          onChange={handleChange}
                        />
                      </GridItem>

                      <GridItem width="1" key="item-4">
                        <DatepickerField
                          label="Valid Until Date *"
                          placeholderLabel="dd/mm/yyyy"
                          id="validUntil"
                          name="validUntil"
                          value={values.validUntil}
                          locale="en"
                          errorMessage={validUntilErrMsg}
                          onBlur={() => {
                            if (!values.validUntil) {
                              setValidUntilErrMsg("Please input.");
                              setIsDisableConfirmBtn(true);
                            }
                          }}
                        />
                      </GridItem>
                    </Grid>
                  </div>

                  <div
                    className={styles.classwiz__editClasswizLicense__btnGroup}
                  >
                    <Grid>
                      <GridItem
                        width="1/3"
                        className={
                          styles.classwiz__editClasswizLicense__btnGroup__groupBtn1
                        }
                        key="item-5"
                      >
                        <Button
                          label="confirm"
                          size="fullWidth"
                          disabled={isDisabledConfirmBtn}
                          type="submit"
                        ></Button>
                      </GridItem>
                      <GridItem
                        width="1/3"
                        className={
                          styles.classwiz__editClasswizLicense__btnGroup__groupBtn3
                        }
                        key="item-6"
                      >
                        <Button
                          label="delete"
                          size="fullWidth"
                          onClick={() => {
                            setIsShowConfirmDeleteModal(true);
                          }}
                          disabled={!classwizLicense?.licenseId}
                          btnStyle="danger"
                        ></Button>
                      </GridItem>
                      <GridItem
                        width="1/3"
                        className={
                          styles.classwiz__editClasswizLicense__btnGroup__groupBtn2
                        }
                        key="item-7"
                      >
                        <Button
                          label="cancel"
                          size="fullWidth"
                          btnStyle="secondary"
                          onClick={() => {
                            setIsShowing(false);
                          }}
                        ></Button>
                      </GridItem>
                    </Grid>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>

      <AnnouncementModal
        title="Edit ClassWiz License Successfully"
        isShowing={isShowEditClassWizSuccesfullyModal}
        setVisible={setIsShowEditClassWizSuccesfullyModal}
      >
        <div className={styles.classwiz__announcementText}>
          The ClassWiz Emulator license record has been updated.
        </div>
      </AnnouncementModal>

      <AnnouncementModal
        title="Delete License Successfully"
        isShowing={isShowDeleteClassWizSuccesfullyModal}
        setVisible={setIsShowDeleteClassWizSuccesfullyModal}
      >
        <div className={styles.classwiz__announcementText}>
          The ClassWiz Emulator license record has been deleted.
        </div>
      </AnnouncementModal>

      <ConfirmationModal
        title="Confirm Delete ClassWiz License"
        isShowing={isShowConfirmDeleteModal}
        onConfirm={async () => {
          setIsShowSpinner(true);
          deleteClasswizLicense(classwizLicense?.licenseId)
            .then(async () => {
              setIsShowConfirmDeleteModal(false);
              setIsShowDeleteClassWizSuccesfullyModal(true);
              setClasswizLicense(emptyValue);
              setIsDisableConfirmBtn(true);
              formikRef.current?.resetForm({ values: emptyValue });
              await fetchUserDetail();
              setIsShowSpinner(false);
            })
            .catch((err) => {
              setIsShowSpinner(false);
              setIsShowErrorModal(true);
            });
        }}
        onCancel={() => {
          setIsShowConfirmDeleteModal(false);
        }}
      >
        <p className={styles.deleteClasswizLicenseConfirmText}>
          Are you sure you would like to delete the ClassWiz license?
        </p>
      </ConfirmationModal>

      <ProcessFailedModal
        isShowing={isShowErrorModal}
        setVisible={setIsShowErrorModal}
      />
    </div>
  );
};
