import { i18n } from "@lingui/core";
import styles from "./RegisterForm.module.scss";
import { Formik, Form } from "formik";
import { SelfServiceRegistrationFlow } from "@ory/kratos-client";
import { addSchool, querySchools } from "../../api/school";
import { Grid, GridItem } from "../Grid/Grid";
import { SchoolSelect } from "../SchoolSelect/SchoolSelect";
import { useCallback, useMemo, useState } from "react";
import { AddSchoolTeacherFrench } from "../AddSchool/AddSchoolTeacherFrench/AddSchoolTeacherFrench";
import { Radios } from "../Radios/Radios";
import { TeachingLevelSelect } from "../TeachingLevelSelect/TeachingLevelSelect";
import { TextInput } from "../TextInput/TextInput";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { Button } from "../Button/Button";
import { Trans } from "@lingui/react";
import { BlockContent } from "../BlockContent/BlockContent";
import { RegistrationFrenchTeacherFormSchema } from "./FormModel/ValidatePersonalData";
import { validEmailFormat } from "../../utils/emailValidation";
import { useAppConfig } from "../../hooks/use-app-config";
import { useTracking } from "../../hooks/use-tracking";
import { Country } from "../../types/Country";
import { FrenchInstructorRole, Role } from "../../types/Role";
import { IdProvider } from "../../types/IdProvider";
import {
  RegistrationUser,
  checkDuplicateContactEmail,
  registerUser,
} from "../../api/user";
import { kratos, getErrorMessages } from "./RegisterHelper";
import { TextArea } from "../TextArea/TextArea";
import { FileRejection, useDropzone } from "react-dropzone";
import {
  checkEmailDTCIsWhiteList,
  convertFileSizeKBToMB,
  MAX_TOTAL_SIZE_MB,
} from "../../utils/utils";
import { useToasts } from "../../provider/toast-provider";
import { sendTeacherVerificationRequestEmail } from "../../api/teacher-verification";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import { RegisterFromEnum } from "../../types/RegisterFrom";

interface FormProps {
  flow: SelfServiceRegistrationFlow;
  from?: RegisterFromEnum;
}
interface RegisterFormInstructorFrenchErrors {
  school?: string;
  addSchool?: {
    schoolname?: string;
    schoolzip?: string;
    schoolstreet?: string;
    schoolcity?: string;
    schooladdresscountry?: string;
  };
  addManualSchool?: boolean;
  firstname?: string;
  lastname?: string;
  contactAnotherEmail?: boolean;
  salutation?: string;
  role?: string;
  email?: string;
  confirmEmail?: string;
  emailAcademic?: string;
  confirmEmailAcademic?: string;
  teachingLevel?: string;
  telephone?: string;
  password?: string;
  confirmPassword?: string;
  subjects?: string;
  termAndCondition?: string;
  teacherVerificationRequestNote?: string;
  errorFilesUploaded?: string;
}

export const RegisterFormInstructorFrench: React.FC<FormProps> = ({
  flow,
  from,
}) => {
  const { publicUrl } = useAppConfig();
  const { trackEvent } = useTracking();

  const [addManualSchool, setAddManualSchool] = useState<boolean>(false);
  const [isDisableRegisterButton, setIsDisableRegisterButton] =
    useState<boolean>(true);

  const config = flow?.ui;
  const [errorMessages, setErrorMessages] = useState(getErrorMessages(config));

  const { addToast } = useToasts();

  const [totalFileSize, setTotalFileSize] = useState<number>(0);
  const [errorFilesUploaded, setFilesUploadedError] = useState<string | null>(
    null
  );

  const [files, setFiles] = useState<File[]>([]);
  const [
    isShowSubmitRequestVerificationCheckbox,
    setIsShowSubmitRequestVerificationCheckbox,
  ] = useState<boolean>(false);

  const TEACHING_SUBJECTS_FRENCH_TEACHER = useMemo(() => {
    return [
      {
        value: "maths",
        label: i18n._({ id: "subject.maths" }),
        data: "maths",
      },
      {
        value: "physics",
        label: i18n._({ id: "subject.physics" }),
        data: "physics",
      },
      {
        value: "math-science",
        label: i18n._({ id: "subject.math-science" }),
        data: "math-science",
      },
      { value: "svt", label: "SVT", data: "svt" },
      { value: "nsi", label: "NSI", data: "nsi" },
      {
        value: "others",
        label: i18n._({ id: "subject.others" }),
        data: "others",
      },
    ];
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectionFiles: FileRejection[]) => {
      const newFiles: File[] = [];
      let newFileSize = totalFileSize;

      if (rejectionFiles.length > 0) {
        addToast("Ce type de fichier ne peut pas être pris en charge", "error");
      }

      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        const fileSizeInMB = Number(convertFileSizeKBToMB(file.size));

        if (newFileSize + fileSizeInMB > MAX_TOTAL_SIZE_MB) {
          setFilesUploadedError(
            `La taille du fichier dépasse la limite de (${MAX_TOTAL_SIZE_MB}Mo).`
          );
        } else {
          setFilesUploadedError(null);
        }
        newFiles.push(file);
        newFileSize += fileSizeInMB;

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {};
        reader.readAsArrayBuffer(file);
      });
      setTotalFileSize(newFileSize);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
    [addToast, totalFileSize]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    multiple: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "video/mp4": [".mp4"],
      "video/quicktime": [".mov"],
    },
    validator: (file) => {
      // Custom validation to ensure consistent behavior across platforms
      const validTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
        "video/mp4",
        "video/quicktime",
      ];

      if (!validTypes.includes(file.type)) {
        return {
          code: "file-invalid-type",
          message: "Ce type de fichier ne peut pas être pris en charge",
        };
      }

      return null;
    },
  });

  const preventCopyPaste = (e: any) => {
    e.preventDefault();
  };

  const validatePersonalData = (
    values: RegistrationFrenchTeacherFormSchema
  ): RegisterFormInstructorFrenchErrors => {
    let errors: RegisterFormInstructorFrenchErrors = {};

    if (
      !values.addManualSchool &&
      (values.school === null || values.school === undefined)
    ) {
      errors.school = i18n._({ id: "forms.validation.required" });
    }

    if (values.addManualSchool) {
      errors.addSchool = {};

      if (!values.addSchool?.schoolname) {
        errors.addSchool.schoolname = i18n._({
          id: "forms.validation.required",
        });
      }

      if (!values.addSchool?.schoolstreet) {
        errors.addSchool.schoolstreet = i18n._({
          id: "forms.validation.required",
        });
      }

      if (!values.addSchool?.schoolzip) {
        errors.addSchool.schoolzip = i18n._({
          id: "forms.validation.required",
        });
      }

      if (!values.addSchool?.schoolcity) {
        errors.addSchool.schoolcity = i18n._({
          id: "forms.validation.required",
        });
      }

      if (!values.addSchool?.schooladdresscountry) {
        errors.addSchool.schooladdresscountry = i18n._({
          id: "forms.validation.required",
        });
      }

      if (
        values.addSchool?.schoolname &&
        values.addSchool?.schoolstreet &&
        values.addSchool?.schoolzip &&
        values.addSchool?.schoolcity &&
        values.addSchool?.schooladdresscountry
      ) {
        delete errors.addSchool;
      }
    }
    // -------------------------- add manual school errors

    //---------------------------------------------------------------------------------

    if (!values.salutation) {
      errors.salutation = i18n._({ id: "forms.validation.required" });
    }

    if (!values.role) {
      errors.role = i18n._({ id: "forms.validation.required" });
    }

    if (!values.firstname) {
      errors.firstname = i18n._({ id: "forms.validation.required" });
    } else if (
      values.firstname &&
      (values.firstname?.length > 20 || values.firstname?.length < 2)
    ) {
      errors.firstname = i18n._({ id: "register.form.validation.name_length" });
    }

    if (!values.lastname) {
      errors.lastname = i18n._({ id: "forms.validation.required" });
    } else if (
      values.lastname &&
      (values.lastname.length > 20 || values.lastname.length < 2)
    ) {
      errors.lastname = i18n._({ id: "register.form.validation.name_length" });
    }

    if (values.lastname?.length === 0) {
      errors.lastname = i18n._({ id: "forms.validation.required" });
    } else if (
      values.lastname &&
      (values.lastname.length > 20 || values.lastname.length < 2)
    ) {
      errors.lastname = i18n._({ id: "register.form.validation.name_length" });
    }

    if (values.contactAnotherEmail && !values.email) {
      errors.email = i18n._({ id: "forms.validation.required" });
    } else if (
      values.contactAnotherEmail &&
      !validEmailFormat(values.email || "")
    ) {
      errors.email = i18n._({ id: "forms.validation.invalid_email" });
    }

    if (values.contactAnotherEmail && !values.confirmEmail) {
      errors.confirmEmail = i18n._({ id: "forms.validation.required" });
    } else if (
      values.contactAnotherEmail &&
      values.email?.trim() !== values.confirmEmail?.trim()
    ) {
      errors.confirmEmail = i18n._({ id: "forms.validation.not_match" });
    }
    // ----------------------------

    if (!values.emailAcademic) {
      errors.emailAcademic = i18n._({ id: "forms.validation.required" });
    } else if (!validEmailFormat(values.emailAcademic)) {
      errors.emailAcademic = i18n._({ id: "forms.validation.invalid_email" });
    }

    if (!values.confirmEmailAcademic) {
      errors.confirmEmailAcademic = i18n._({ id: "forms.validation.required" });
    } else if (
      values.emailAcademic?.trim() !== values.confirmEmailAcademic?.trim()
    ) {
      errors.confirmEmailAcademic = i18n._({
        id: "forms.validation.not_match",
      });
    }

    if (values.isSubmitTeacherVerificationRequest) {
      if (!values.teacherVerificationRequestNote) {
        errors.teacherVerificationRequestNote = i18n._({
          id: "forms.validation.required",
        });
      }
      // --------------------------------------------------------------------------------------------------------
      // error for file upload
    }

    if (!values.password) {
      errors.password = i18n._({ id: "forms.validation.required" });
    } else if (values.password.length < 8) {
      errors.password = i18n._({
        id: "register.form.validation.password_length",
      });
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = i18n._({ id: "forms.validation.required" });
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = i18n._({
        id: "forms.validation.not_match",
      });
    }

    if (!values.termAndCondition) {
      errors.termAndCondition = i18n._({ id: "forms.validation.required" });
    }

    if (
      values.emailAcademic &&
      values.email &&
      validEmailFormat(values.email) &&
      validEmailFormat(values.emailAcademic) &&
      values.emailAcademic.toLowerCase() === values.email.toLowerCase()
    ) {
      errors.email = i18n._({
        id: "forms.validation.email.contact.same.with.academic.email",
      });

      errors.emailAcademic = i18n._({
        id: "forms.validation.email.contact.same.with.academic.email",
      });
    }

    return errors;
  };

  const checkDisableRegisterButton = useCallback(
    (
      values: RegistrationFrenchTeacherFormSchema,
      errors: RegisterFormInstructorFrenchErrors
    ) => {
      // console.log("checkDisableRegisterButton errors", errors);

      // NOT addManualSchool - NOT contactAnotherEmail - NOT isSubmitTeacherVerificationRequest
      if (
        values.email &&
        values.emailAcademic &&
        validEmailFormat(values.email) &&
        validEmailFormat(values.emailAcademic) &&
        values.email.toLowerCase() === values.emailAcademic.toLowerCase()
      ) {
        setIsDisableRegisterButton(true);
      } else {
        setIsDisableRegisterButton(false);
      }

      if (
        !addManualSchool &&
        !values.contactAnotherEmail &&
        !values.isSubmitTeacherVerificationRequest
      ) {
        console.log("run case 1");
        if (
          !errors.school &&
          !errors.firstname &&
          !errors.lastname &&
          !errors.salutation &&
          !errors.role &&
          !errors.emailAcademic &&
          !errors.confirmEmailAcademic &&
          !errors.password &&
          !errors.confirmPassword &&
          !errors.termAndCondition
        ) {
          setIsDisableRegisterButton(false);
        } else {
          setIsDisableRegisterButton(true);
        }
      }

      // NOT addManualSchool - NOT contactAnotherEmail - isSubmitTeacherVerificationRequest
      if (
        !addManualSchool &&
        !values.contactAnotherEmail &&
        values.isSubmitTeacherVerificationRequest
      ) {
        console.log("run case 2");
        if (
          !errors.school &&
          !errors.firstname &&
          !errors.lastname &&
          !errors.salutation &&
          !errors.role &&
          !errors.emailAcademic &&
          !errors.confirmEmailAcademic &&
          !errors.password &&
          !errors.confirmPassword &&
          !errors.termAndCondition &&
          !errors.teacherVerificationRequestNote
        ) {
          setIsDisableRegisterButton(false);
        } else {
          setIsDisableRegisterButton(true);
        }
      }

      // NOT addManualSchool -  contactAnotherEmail - NOT isSubmitTeacherVerificationRequest
      if (
        !addManualSchool &&
        values.contactAnotherEmail &&
        !values.isSubmitTeacherVerificationRequest
      ) {
        console.log("run case 3");
        if (
          !errors.school &&
          !errors.firstname &&
          !errors.lastname &&
          !errors.salutation &&
          !errors.role &&
          !errors.emailAcademic &&
          !errors.confirmEmailAcademic &&
          !errors.email &&
          !errors.confirmEmail &&
          !errors.password &&
          !errors.confirmPassword &&
          !errors.termAndCondition
        ) {
          setIsDisableRegisterButton(false);
        } else {
          setIsDisableRegisterButton(true);
        }
      }

      // NOT addManualSchool -  contactAnotherEmail - isSubmitTeacherVerificationRequest
      if (
        !addManualSchool &&
        values.contactAnotherEmail &&
        values.isSubmitTeacherVerificationRequest
      ) {
        console.log("run case 4");
        if (
          !errors.school &&
          !errors.firstname &&
          !errors.lastname &&
          !errors.salutation &&
          !errors.role &&
          !errors.emailAcademic &&
          !errors.confirmEmailAcademic &&
          !errors.email &&
          !errors.confirmEmail &&
          !errors.password &&
          !errors.confirmPassword &&
          !errors.termAndCondition &&
          !errors.teacherVerificationRequestNote
        ) {
          setIsDisableRegisterButton(false);
        } else {
          setIsDisableRegisterButton(true);
        }
      }

      // addManualSchool -  NOT contactAnotherEmail - NOT isSubmitTeacherVerificationRequest
      if (
        addManualSchool &&
        !values.contactAnotherEmail &&
        !values.isSubmitTeacherVerificationRequest
      ) {
        console.log("run case 5");
        if (
          !errors.addSchool?.schooladdresscountry &&
          !errors.addSchool?.schoolcity &&
          !errors.addSchool?.schoolname &&
          !errors.addSchool?.schoolstreet &&
          !errors.addSchool?.schoolzip &&
          !errors.firstname &&
          !errors.lastname &&
          !errors.salutation &&
          !errors.role &&
          !errors.emailAcademic &&
          !errors.confirmEmailAcademic &&
          !errors.password &&
          !errors.confirmPassword &&
          !errors.termAndCondition
        ) {
          setIsDisableRegisterButton(false);
        } else {
          setIsDisableRegisterButton(true);
        }
      }

      // addManualSchool -  NOT contactAnotherEmail - isSubmitTeacherVerificationRequest
      if (
        addManualSchool &&
        !values.contactAnotherEmail &&
        values.isSubmitTeacherVerificationRequest
      ) {
        console.log("run case 6");
        if (
          !errors.addSchool?.schooladdresscountry &&
          !errors.addSchool?.schoolcity &&
          !errors.addSchool?.schoolname &&
          !errors.addSchool?.schoolstreet &&
          !errors.addSchool?.schoolzip &&
          !errors.firstname &&
          !errors.lastname &&
          !errors.salutation &&
          !errors.role &&
          !errors.emailAcademic &&
          !errors.confirmEmailAcademic &&
          !errors.password &&
          !errors.confirmPassword &&
          !errors.termAndCondition &&
          !errors.teacherVerificationRequestNote
        ) {
          setIsDisableRegisterButton(false);
        } else {
          setIsDisableRegisterButton(true);
        }
      }

      // addManualSchool -  contactAnotherEmail - NOT isSubmitTeacherVerificationRequest
      if (
        addManualSchool &&
        values.contactAnotherEmail &&
        !values.isSubmitTeacherVerificationRequest
      ) {
        console.log("run case 7");
        if (
          !errors.addSchool?.schooladdresscountry &&
          !errors.addSchool?.schoolcity &&
          !errors.addSchool?.schoolname &&
          !errors.addSchool?.schoolstreet &&
          !errors.addSchool?.schoolzip &&
          !errors.firstname &&
          !errors.lastname &&
          !errors.salutation &&
          !errors.role &&
          !errors.emailAcademic &&
          !errors.confirmEmailAcademic &&
          !errors.email &&
          !errors.confirmEmail &&
          !errors.password &&
          !errors.confirmPassword &&
          !errors.termAndCondition &&
          !errors.teacherVerificationRequestNote
        ) {
          setIsDisableRegisterButton(false);
        } else {
          setIsDisableRegisterButton(true);
        }
      }

      // addManualSchool -  contactAnotherEmail - isSubmitTeacherVerificationRequest
      if (
        addManualSchool &&
        values.contactAnotherEmail &&
        values.isSubmitTeacherVerificationRequest
      ) {
        console.log("run case 8");
        if (
          !errors.addSchool?.schooladdresscountry &&
          !errors.addSchool?.schoolcity &&
          !errors.addSchool?.schoolname &&
          !errors.addSchool?.schoolstreet &&
          !errors.addSchool?.schoolzip &&
          !errors.firstname &&
          !errors.lastname &&
          !errors.salutation &&
          !errors.role &&
          !errors.emailAcademic &&
          !errors.confirmEmailAcademic &&
          !errors.email &&
          !errors.confirmEmail &&
          !errors.password &&
          !errors.confirmPassword &&
          !errors.termAndCondition &&
          !errors.teacherVerificationRequestNote &&
          !errors.teacherVerificationRequestNote
        ) {
          setIsDisableRegisterButton(false);
        } else {
          setIsDisableRegisterButton(true);
        }
      }
    },
    [addManualSchool]
  );

  const validate = (
    values: RegistrationFrenchTeacherFormSchema
  ): RegisterFormInstructorFrenchErrors => {
    const errors = validatePersonalData(values);

    // check disabled register button.
    checkDisableRegisterButton(values, errors);

    if (
      values.emailAcademic &&
      validEmailFormat(values.emailAcademic) &&
      !checkEmailDTCIsWhiteList(values.emailAcademic)
    ) {
      setIsShowSubmitRequestVerificationCheckbox(true);
    } else {
      setIsShowSubmitRequestVerificationCheckbox(false);
    }
    return errors;
  };

  async function loadErrorDetails() {
    try {
      const { data } = await kratos.getSelfServiceRegistrationFlow(flow.id);
      setErrorMessages(getErrorMessages(data.ui));
    } catch (error) {
      window.location.replace("/kratos/self-service/registration/browser");
    }
  }

  async function onSubmit(values: RegistrationFrenchTeacherFormSchema) {
    try {
      let schoolId = "";
      if (values.addManualSchool && values.addSchool) {
        values.addSchool.schoolcountry = Country.FR;
        schoolId = await addSchool(values.addSchool);
      }

      let isDuplicateContactEmail: boolean = false;
      if (values.email) {
        const response = await checkDuplicateContactEmail(
          values.email.trim().toLowerCase()
        );
        if (response.duplicate) {
          // For the error contact email duplicated mock the error response with the same format of kratos
          setErrorMessages([
            {
              context: undefined,
              id: 8217942, // random 7 digits number
              text: "Contact email is duplicated",
              type: "error",
            },
          ]);

          isDuplicateContactEmail = true;
        }
      }
      if (isDuplicateContactEmail) {
        return;
      }

      let user: RegistrationUser = {
        // kratos has a bug in the password reset flow if users have upper case letters in their email address.
        email: values.emailAcademic?.toLowerCase() || "",
        password: values.password || "",
        type: Role.INSTRUCTOR,
        firstname: values.firstname || "",
        lastname: values.lastname || "",
        country: Country.FR,
        schoolId: values.addManualSchool ? schoolId : values.school?.value,
        metadata: {
          salutation: values.salutation,
          subjects: values.subjects
            ? values.subjects.map((subject) => subject.data)
            : [],
          teacherAnalyticsConsent: values.termAndCondition,
          teacherMarketingProfillingConsent:
            values.teacherMarketingProfillingConsent,
          teachingLevel: values.teachingLevel?.length
            ? values.teachingLevel.map((type) => type.value)
            : [],
          role: values.role,
          registeredFrom: from ? from : undefined,
        },
        idProvider: IdProvider.CLASSPAD,
        telephone: values.telephone,
        contactEmail: values.email && values.email.trim().toLowerCase(),
      };
      console.log("user submit", user);

      if (
        values.isSubmitTeacherVerificationRequest &&
        values.teacherVerificationRequestNote
      ) {
        user = {
          ...user,
          teacherVerificationRequestNote: values.teacherVerificationRequestNote,
        };
      }

      const newUser = await registerUser({ flow, user });

      if (values.teacherVerificationRequestNote) {
        await sendTeacherVerificationRequestEmail(newUser?.id, files);
      }

      trackEvent("registrationInstructor", {
        callback: () => {
          window.location.replace(publicUrl);
        },
      });
    } catch (error) {
      console.log("error.onsubmit", error);
      loadErrorDetails();
    }
  }

  return (
    <div
      className={styles.registerForm__teacher_french}
      data-testid="RegisterForm"
    >
      <Formik<RegistrationFrenchTeacherFormSchema>
        enableReinitialize={true}
        initialValues={{
          school: undefined,
          addSchool: undefined,
          addManualSchool: false,
          firstname: undefined,
          lastname: undefined,
          contactAnotherEmail: false,
          salutation: undefined,
          role: undefined,
          email: undefined,
          confirmEmail: undefined,
          emailAcademic: undefined,
          confirmEmailAcademic: undefined,
          teachingLevel: [],
          telephone: undefined,
          password: undefined,
          confirmPassword: undefined,
          subjects: [],
          termAndCondition: false,
          teacherMarketingProfillingConsent: false,
          isSubmitTeacherVerificationRequest: false,
          teacherVerificationRequestNote: undefined,
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          setTouched,
          handleChange,
          handleBlur,
        }) => {
          return (
            <Form>
              {errorMessages.length > 0 && (
                <div className={styles.registerForm__errors}>
                  {errorMessages.map((message) => {
                    return (
                      <div>
                        <Trans
                          id={`kratos.messages.${message.id}`}
                          message={`Error #${message.id}: ${message.text}`}
                        />
                        <br />
                      </div>
                    );
                  })}
                </div>
              )}
              <p className={styles.registerForm__teacher_french__customLabel}>
                {i18n._({ id: "register.form.salutation.french.teacher" })}
              </p>

              <div className={styles.registerForm__radios}>
                <Radios
                  id="salutation"
                  name="salutation"
                  options={[
                    {
                      label: "Madame",
                      value: "Madame",
                    },
                    {
                      label: "Monsieur",
                      value: "Monsieur",
                    },
                  ]}
                  horizontal={true}
                  small={true}
                  onChange={handleChange}
                />
              </div>

              <Grid>
                <GridItem width="1/1">
                  <TextInput
                    id={"lastname"}
                    label={i18n._({
                      id: "register.form.lastname.french.teacher",
                    })}
                    name={"lastname"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={
                      touched.lastname ? errors.lastname : undefined
                    }
                  />
                </GridItem>

                <GridItem width="1/1">
                  <TextInput
                    id={"firstname"}
                    label={i18n._({
                      id: "register.form.firstname.french.teacher",
                    })}
                    name={"firstname"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={
                      touched.firstname ? errors.firstname : undefined
                    }
                  />
                </GridItem>

                <GridItem width="1/1">
                  <p className={styles.registerForm__teacher_french__intro}>
                    L’accès aux commandes groupées étant réservées aux
                    enseignants ou aux acheteurs pour un établissement scolaire,
                    nous vous recommandons d’utiliser une adresse académique
                    pour créer votre compte afin qu’il soit automatiquement
                    validé.
                    <br />
                    Exemple : professeur@ac-academie.fr, professeur@educagri.fr,
                    ...
                  </p>
                </GridItem>

                <GridItem width="1/1">
                  <p className={styles.registerForm__teacher_french__intro}>
                    Si vous n’avez pas d’adresse académique, nous vous invitons
                    à demander la vérification de votre profil ci-dessous. Votre
                    compte sera validé par nos soins après vérification de vos
                    informations.
                  </p>
                </GridItem>

                <GridItem width="1/1">
                  <TextInput
                    id={"emailAcademic"}
                    label={i18n._({
                      id: "register.form.email.academic.french.teacher",
                    })}
                    placeholder="Veuillez saisir votre email."
                    name={"emailAcademic"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={
                      touched.emailAcademic ? errors.emailAcademic : undefined
                    }
                  />
                </GridItem>

                <GridItem width="1/1">
                  <TextInput
                    id={"confirmEmailAcademic"}
                    label={i18n._({
                      id: "register.form.email.academic.confirm.french.teacher",
                    })}
                    placeholder="Veuillez saisir à nouveau votre email."
                    name={"confirmEmailAcademic"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onCopy={preventCopyPaste}
                    onPaste={preventCopyPaste}
                    errorMessage={
                      touched.confirmEmailAcademic
                        ? errors.confirmEmailAcademic
                        : undefined
                    }
                  />
                </GridItem>

                {/* Submit teacher verification request -----------------------------------*/}
                {isShowSubmitRequestVerificationCheckbox ? (
                  <GridItem width="1/1">
                    <LabeledCheckbox
                      label={
                        <p
                          className={
                            styles.registerForm__teacher_french__email_contact_label_checkbox
                          }
                        >
                          Vérifier mon profil.
                        </p>
                      }
                      checked={values.isSubmitTeacherVerificationRequest}
                      onChange={(e) => {
                        handleChange(e);
                        if (values.isSubmitTeacherVerificationRequest) {
                          if (touched.teacherVerificationRequestNote) {
                            setTouched({
                              teacherVerificationRequestNote: false,
                            });
                          }
                          if (files.length > 0) {
                            setFiles([]);
                            setTotalFileSize(0);
                            setFilesUploadedError(null);
                          }
                        }
                      }}
                      value="isSubmitTeacherVerificationRequest"
                      name="isSubmitTeacherVerificationRequest"
                      id="isSubmitTeacherVerificationRequest"
                    />
                  </GridItem>
                ) : undefined}

                {values.isSubmitTeacherVerificationRequest &&
                  isShowSubmitRequestVerificationCheckbox && (
                    <>
                      <GridItem width="1/1">
                        <div
                          className={styles.teacherVerificationRequestSubmitBox}
                        >
                          {/* <p>Demande de vérification de l'enseignant </p> */}

                          <TextArea
                            className={
                              styles.teacherVerificationRequestSubmitBox__textArea
                            }
                            id="teacherVerificationRequestNote"
                            name="teacherVerificationRequestNote"
                            label="Message*"
                            onChange={handleChange}
                            errorMessage={
                              touched.teacherVerificationRequestNote
                                ? errors.teacherVerificationRequestNote
                                : undefined
                            }
                            onBlur={() => {
                              setFieldTouched(
                                "teacherVerificationRequestNote",
                                true,
                                true
                              );
                            }}
                          />

                          <p>
                            Si vous le souhaitez, n’hésitez pas à ajouter des
                            documents pour accélérer le processus de
                            validation. 
                          </p>

                          <div
                            className={
                              styles.teacherVerificationRequestSubmitBox__uploadFilesSection
                            }
                          >
                            <Grid>
                              <GridItem width="1/2">
                                <div
                                  {...getRootProps()}
                                  className={
                                    styles.teacherVerificationRequestSubmitBox__uploadFilesSection__fileUploader
                                  }
                                >
                                  <input {...getInputProps()} />
                                  {
                                    <>
                                      <p>Déposer votre fichier ici </p>
                                      <p>ou</p>
                                    </>
                                  }
                                  <p
                                    className={
                                      styles.teacherVerificationRequestSubmitBox__uploadFilesSection__fileUploader__browse
                                    }
                                    onClick={open}
                                  >
                                    Parcourir
                                  </p>
                                </div>
                              </GridItem>

                              <GridItem width="1/2">
                                <div
                                  className={
                                    styles.teacherVerificationRequestSubmitBox__listFilesSection
                                  }
                                >
                                  <p>Fichiers chargés</p>
                                  <div
                                    className={
                                      styles.teacherVerificationRequestSubmitBox__listFilesSection__content
                                    }
                                  >
                                    {files.map((file: File, index: number) => {
                                      return (
                                        <div
                                          className={
                                            styles.teacherVerificationRequestSubmitBox__listFilesSection__content__fileRow
                                          }
                                          key={`file-${index}`}
                                        >
                                          <p>{file.name}</p>
                                          <p>
                                            {convertFileSizeKBToMB(file.size)}MB{" "}
                                          </p>
                                          <p
                                            onClick={() => {
                                              const findIndex =
                                                files.indexOf(file);
                                              if (index > -1) {
                                                const newList = files.filter(
                                                  (_, index) =>
                                                    index !== findIndex
                                                );
                                                setFiles(newList);
                                              }

                                              const sizeReduce = Number(
                                                convertFileSizeKBToMB(
                                                  files[findIndex].size
                                                )
                                              );

                                              let newFileSize = totalFileSize;
                                              newFileSize -= sizeReduce;
                                              setTotalFileSize(newFileSize);

                                              if (
                                                newFileSize < MAX_TOTAL_SIZE_MB
                                              ) {
                                                setFilesUploadedError(null);
                                              }
                                            }}
                                          >
                                            X
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                {errorFilesUploaded && (
                                  <p
                                    className={
                                      styles.teacherVerificationRequestSubmitBox__listFilesSection__errorMsg
                                    }
                                  >
                                    {errorFilesUploaded}
                                  </p>
                                )}
                              </GridItem>
                            </Grid>
                          </div>
                        </div>
                      </GridItem>
                    </>
                  )}

                <GridItem width="1/1">
                  <LabeledCheckbox
                    label={
                      <p
                        className={
                          styles.registerForm__teacher_french__email_contact_label_checkbox
                        }
                      >
                        Si vous ne recevez pas correctement les emails sur votre
                        email académique, n’hésitez pas à nous fournir une
                        adresse complémentaire afin que nous puissions vous
                        contacter plus facilement.
                      </p>
                    }
                    checked={values.contactAnotherEmail}
                    onChange={handleChange}
                    value="contactAnotherEmail"
                    name="contactAnotherEmail"
                    id="contactAnotherEmail"
                  />
                </GridItem>

                {values.contactAnotherEmail ? (
                  <>
                    <GridItem width="1/1">
                      <TextInput
                        id="email"
                        label="Email de contact* "
                        placeholder="Veuillez saisir votre email."
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.email ? errors.email : undefined}
                      />
                    </GridItem>

                    <GridItem width="1/1">
                      <TextInput
                        id="confirmEmail"
                        label="Ressaisissez votre Email de contact*"
                        placeholder="Veuillez saisir à nouveau votre email."
                        name={"confirmEmail"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onCopy={preventCopyPaste}
                        onPaste={preventCopyPaste}
                        errorMessage={
                          touched.confirmEmail ? errors.confirmEmail : undefined
                        }
                      />
                    </GridItem>
                  </>
                ) : undefined}

                <GridItem width="1/1">
                  <TextInput
                    id="password"
                    label="Mot de passe*"
                    placeholder="Merci de choisir un mot de passe sécurisé."
                    name="password"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onCopy={preventCopyPaste}
                    onPaste={preventCopyPaste}
                    errorMessage={
                      touched.password ? errors.password : undefined
                    }
                  />
                </GridItem>

                <GridItem width="1/1">
                  <TextInput
                    id="confirmPassword"
                    label="Ressaisir le mot de passe*"
                    placeholder="Veuillez ressaisir votre mot de passe."
                    name="confirmPassword"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onCopy={preventCopyPaste}
                    onPaste={preventCopyPaste}
                    errorMessage={
                      touched.confirmPassword
                        ? errors.confirmPassword
                        : undefined
                    }
                  />
                </GridItem>

                <GridItem width="1/1">
                  <TextInput
                    type="custom-telephone"
                    id="telephone"
                    label="Téléphone"
                    name="telephone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </GridItem>

                <GridItem width="1/1">
                  <p
                    className={styles.registerForm__teacher_french__customLabel}
                  >
                    Vous êtes*
                  </p>
                </GridItem>

                <GridItem width="1/1">
                  <div className={styles.registerForm__teacher_french__radios}>
                    <Radios
                      name={"role"}
                      options={[
                        {
                          label: "Enseignant",
                          value: FrenchInstructorRole.INSTRUCTOR,
                        },
                        {
                          label: "Administration scolaire",
                          value: FrenchInstructorRole.SCHOOL_ADMIN,
                        },
                        {
                          label: "Association",
                          value: FrenchInstructorRole.SCHOOL_ASSOCIATION,
                        },
                        {
                          label: "Autres",
                          value: FrenchInstructorRole.OTHER,
                        },
                      ]}
                      horizontal={true}
                      small={true}
                      onChange={(e) => {
                        const currValue = e.currentTarget.value;
                        if (
                          currValue === FrenchInstructorRole.SCHOOL_ADMIN ||
                          currValue ===
                            FrenchInstructorRole.SCHOOL_ASSOCIATION ||
                          currValue === FrenchInstructorRole.OTHER
                        ) {
                          if (values.subjects && values.subjects.length > 0) {
                            setFieldValue("subjects", []);
                          }

                          if (
                            values.teachingLevel &&
                            values.teachingLevel.length > 0
                          ) {
                            setFieldValue("teachingLevel", []);
                          }
                        }
                        handleChange(e);
                      }}
                    />
                  </div>
                </GridItem>
              </Grid>

              <Grid>
                <GridItem width={"1"}>
                  <SchoolSelect
                    id="school"
                    label="Trouver votre établissement*"
                    value={values.school}
                    onChange={(school) => {
                      setFieldValue("school", school);
                    }}
                    onBlur={() => {
                      setFieldTouched("school", true, true);
                    }}
                    errorMessage={touched.school ? errors.school : undefined}
                    querySchools={querySchools}
                    setAddManualSchool={(addSchool: boolean) => {
                      setFieldValue("addManualSchool", addSchool, true);
                      setAddManualSchool(addSchool);
                    }}
                    addManualSchool={addManualSchool}
                    disableEnterSchoolManually={false}
                  ></SchoolSelect>
                </GridItem>
              </Grid>

              {addManualSchool && (
                <AddSchoolTeacherFrench
                  onClose={async () => {
                    setAddManualSchool(false);
                  }}
                />
              )}

              {values.role === FrenchInstructorRole.INSTRUCTOR && (
                <>
                  <p
                    className={
                      styles.registerForm__teacher_french__teacher_role_informing_text
                    }
                  >
                    Si vous êtes enseignant, pouvez-vous préciser les
                    informations suivantes?
                  </p>

                  <Grid>
                    <GridItem width="1/1">
                      <MultiSelect
                        id="subjects"
                        label="Matière enseignée"
                        options={TEACHING_SUBJECTS_FRENCH_TEACHER}
                      ></MultiSelect>
                    </GridItem>

                    <GridItem width="1">
                      <TeachingLevelSelect
                        id="school"
                        label={i18n._({ id: "register.form.schooltype" })}
                      ></TeachingLevelSelect>
                    </GridItem>
                  </Grid>
                </>
              )}

              <Grid>
                <GridItem width="1/1">
                  <BlockContent
                    className={styles.registerForm__frenchTeacherPrivacyPolicy}
                  >
                    <Trans
                      id="register.form.privacy_policy"
                      values={{
                        data_protection_link: (
                          <a
                            target="_blank"
                            href={"/fr/politique-de-confidentialite"}
                            rel="noreferrer"
                          >
                            {i18n._({
                              id: "register.form.terms_and_conditions.data_protection",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </BlockContent>
                </GridItem>

                <GridItem width="1/1">
                  <LabeledCheckbox
                    smallLabel={true}
                    label={
                      <BlockContent>
                        <Trans
                          id="register.form.terms_and_conditions"
                          values={{
                            conditions_link: (
                              <a
                                target="_blank"
                                href={"/fr/conditions-d-utilisation"}
                                rel="noreferrer"
                              >
                                {i18n._({
                                  id: "register.form.terms_and_conditions.conditions",
                                })}
                              </a>
                            ),
                          }}
                        />
                      </BlockContent>
                    }
                    checked={values.termAndCondition}
                    onChange={handleChange}
                    value="termAndCondition"
                    name="termAndCondition"
                    id="termAndCondition"
                  />
                </GridItem>

                <GridItem width="1/1">
                  <LabeledCheckbox
                    smallLabel={true}
                    label={
                      <BlockContent>
                        <Trans id="register.form.terms_and_conditions_two_1" />
                        <br />
                        <br />
                        <Trans
                          id="register.form.terms_and_conditions_two_2"
                          values={{
                            privacy_policy: (
                              <a
                                target="_blank"
                                href={
                                  "https://www.casio-education.fr/donnees-personnelles/"
                                } // Term and Condition
                                rel="noreferrer"
                              >
                                {i18n._({
                                  id: "register.form.terms_and_conditions_two_2.privacy_policy",
                                })}
                              </a>
                            ),
                          }}
                        />
                        <br />
                        <br />
                        <Trans
                          id="register.form.terms_and_conditions_two_3"
                          values={{
                            protection_email: (
                              <a
                                target="_blank"
                                href={"/fr/conditions-de-service"}
                                rel="noreferrer"
                              >
                                {i18n._({
                                  id: "register.form.terms_and_conditions_two_3.protection_email",
                                })}
                              </a>
                            ),
                          }}
                        />
                        <br />
                        <Trans id="register.form.terms_and_conditions_two_4" />
                      </BlockContent>
                    }
                    checked={values.teacherMarketingProfillingConsent}
                    onChange={handleChange}
                    value="teacherMarketingProfillingConsent"
                    name="teacherMarketingProfillingConsent"
                    id="teacherMarketingProfillingConsent"
                  />
                </GridItem>
                <GridItem width="1/1">
                  <Button
                    type="submit"
                    label="Je m'inscris"
                    style={{ width: "100%", marginTop: "1.5rem" }}
                    disabled={isDisableRegisterButton}
                  ></Button>
                </GridItem>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RegisterFormInstructorFrench;
