import React from "react";
import { classList } from "../../lib/helpers";
import { ProgressIndicator } from "../ProgressIndicator/ProgressIndicator";
import styles from "./Stepper.module.scss";

export interface Step {
  title: string;
  body: React.ReactNode;
}

export interface StepperProps {
  infoContent?: React.ReactNode;
  currentStepIndex: number;
  className?: string;
  type?: "horizontal" | "vertical";
  steps: Step[];
}

export const Stepper = ({
  infoContent,
  currentStepIndex,
  className,
  type = "horizontal",
  steps,
}: StepperProps) => {
  const classes = classList(
    styles.stepper,
    styles["stepper_" + type],
    className && className
  );

  return (
    <div className={classes}>
      <div className={styles.stepper__indicator}>
        {steps && (
          <ProgressIndicator
            steps={steps}
            currentStepIndex={currentStepIndex}
            type={type}
          />
        )}
        <div>{infoContent}</div>
      </div>
      <div className={styles.stepper__container}>
        {steps &&
          steps.map((step, index) => {
            const classes = classList(
              styles.stepper__stepBodyWrapper,
              index === currentStepIndex &&
                styles.stepper__stepBodyWrapper_active,
              index < currentStepIndex && styles.stepper__stepBodyWrapper_done,
              index > currentStepIndex &&
                styles.stepper__stepBodyWrapper_pending
            );
            return (
              <div
                className={classes}
                aria-current={index === currentStepIndex}
                aria-hidden={index !== currentStepIndex}
                aria-disabled={index !== currentStepIndex}
                key={step.title}
              >
                {step.body}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export interface StepBodyProps {
  children: React.ReactNode;
  nextButton?: React.ReactNode;
  previousButton?: React.ReactNode;
  noBackgroundColor?: boolean;
}

export const StepBody = ({
  children,
  nextButton,
  previousButton,
  noBackgroundColor,
}: StepBodyProps) => {
  return (
    <div
      className={
        styles[
          `stepper__${
            noBackgroundColor ? "stepBodyWithoutBackground" : "stepBody"
          }`
        ]
      }
    >
      <div
        className={
          styles[
            `stepper__${
              noBackgroundColor ? "stepBodyWithoutBackground" : "stepBody"
            }__content`
          ]
        }
      >
        {children}
      </div>
      <div
        className={
          styles[
            `stepper__${
              noBackgroundColor ? "stepBodyWithoutBackground" : "stepBody"
            }__navigation`
          ]
        }
      >
        {previousButton ? previousButton : <div />}
        {nextButton}
      </div>
    </div>
  );
};
