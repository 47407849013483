import React from "react";
import { classList } from "../../lib/helpers";
import styles from "./Card.module.scss";

export interface CardProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
  clickable?: boolean;
  variant?:
    | "default"
    | "matheMarathon"
    | "cplTestPeriod"
    | "small"
    | "topicOverview"
    | "dtcTeacherVerification";
  id?: string;
  disabled?: boolean;
}

export const Card = ({
  className,
  children,
  title,
  icon,
  clickable = false,
  variant = "default",
  id,
  disabled,
}: CardProps) => {
  const classes = classList(
    styles.card,
    styles[`card_${variant}`],
    clickable && styles.card_clickable,
    disabled && styles.card_disabled,
    className && className
  );
  return (
    <div id={id} className={classes}>
      {title && (
        <h2>
          {icon && <i>{icon}</i>}
          <span>{title}</span>
        </h2>
      )}
      {children}
    </div>
  );
};
