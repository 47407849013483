import { useRef, useState } from "react";
import { Stepper } from "../Stepper/Stepper";
import { PageHeader } from "../PageHeader/PageHeader";
import styles from "./UnmergeSchools.module.scss";
import {
  SelectedUnMergedSchoolStep1,
  UnmergeSchoolsStep1,
} from "./Steps/UnmergeSchoolsStep1/UnmergeSchoolsStep1";
import { UnmergeSchoolsStep2 } from "./Steps/UnmergeSchoolsStep2/UnmergeSchoolsStep2";
import { UnmergeSchoolsStep3 } from "./Steps/UnmergeSchoolsStep3/UnmergeSchoolsStep3";
import { UnmergeSchoolReponse } from "../../api/school";

export const UnmergeSchools = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [schoolSelectedStep1, setSchoolSelectedStep1] =
    useState<SelectedUnMergedSchoolStep1>();
  const [unMergeResult, setUnMergeResult] = useState<UnmergeSchoolReponse>();

  const stepOneRef = useRef<{
    resetForm: () => void;
    resetTableData: () => void;
  }>();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleEndProgress = () => {
    setCurrentStep(0);

    stepOneRef?.current?.resetForm();
    stepOneRef?.current?.resetTableData();
  };

  return (
    <div className={styles.container}>
      <PageHeader title="Unmerge schools" />
      <div className={styles.container__stepperContainer}>
        <Stepper
          type="horizontal"
          steps={[
            {
              title: "Select School to Unmerge",
              body: (
                <UnmergeSchoolsStep1
                  handleNext={() => handleNext()}
                  ref={stepOneRef}
                  getSelectedSchool={(
                    selectedSchool: SelectedUnMergedSchoolStep1
                  ) => {
                    setSchoolSelectedStep1(selectedSchool);
                  }}
                />
              ),
            },
            {
              title: "Confirm",
              body: (
                <UnmergeSchoolsStep2
                  handleNext={() => handleNext()}
                  handleBack={() => handleBack()}
                  confirmationData={schoolSelectedStep1}
                  getUnmergedResult={(data: UnmergeSchoolReponse) => {
                    setUnMergeResult(data);
                  }}
                />
              ),
            },
            {
              title: "Result",
              body: (
                <UnmergeSchoolsStep3
                  handleEndProgress={() => handleEndProgress()}
                  data={unMergeResult}
                />
              ),
            },
          ]}
          currentStepIndex={currentStep}
        />
      </div>
    </div>
  );
};
