import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import { useMemo, useState } from "react";
import {
  ArrowLeft,
  Book,
  MessageSquare,
  Settings,
  Award,
  User as UserIcon,
} from "react-feather";
import { Link } from "react-router-dom";
import { Context } from "../../types/Context";
import { Country, Language } from "../../types/Country";
import { IdProvider } from "../../types/IdProvider";
import { FrenchInstructorRole, Role } from "../../types/Role";
import { TeacherVerificationMetadataStatus, User } from "../../types/User";
import { Button } from "../Button/Button";
import { Card } from "../Card/Card";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { LMSBadge } from "../LMSBadge/LMSBadge";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { Modal } from "../Modal/Modal";
import { PageHeader } from "../PageHeader/PageHeader";
import { PageToolbar, PageToolbarAction } from "../PageToolbar/PageToolbar";
import { Spinner } from "../Spinner/Spinner";
import { UserProfileBadge } from "../UserProfileBadge/UserProfileBadge";
import {
  UpdateTisConsentLMSSchema,
  UpdateTisConsentSchema,
  UpdateUserSchema,
  UpdateUserSchoolSchema,
} from "./UserProfile";
import styles from "./UserProfile.module.scss";
import { UserProfilePersonalData } from "./UserProfilePersonalData";
import { UserProfileSchoolData } from "./UserProfileSchoolData";
import { UserProfileTeacherInfoService } from "./UserProfileTeacherInfoService";
import { UserProfileTeacherInfoServiceLMS } from "./UserProfileTeacherInfoServiceLMS";
import { BlockContent } from "../BlockContent/BlockContent";
import RequestVerificationModal from "../RequestVerificationModal/RequestVerificationModal";
import { useAuth } from "../../hooks/use-auth";
import { TextArea } from "../TextArea/TextArea";
import { RegisterFromEnum } from "../../types/RegisterFrom";
import { useAppConfig } from "../../hooks/use-app-config";

export interface UserProfileViewProps {
  user: User;
  solelyManagedContexts: Context[];
  handleSubmit: (schema: UpdateUserSchema) => void;
  handleSchoolDataSubmit: (schema: UpdateUserSchoolSchema) => void;
  handleDeletionAttempt: () => void;
  handleTISSubmit: (values: UpdateTisConsentSchema) => Promise<void>;
  handleDeletionConfirm: (keepTIS: boolean) => void;
  handleLMSDataChange: (data: UpdateTisConsentLMSSchema) => Promise<void>;
  handleSaveTeacherMarketingProfillingConsent: (
    checked: boolean
  ) => Promise<void>;
  loading: boolean;
}

export const UserProfileView = ({
  user,
  solelyManagedContexts,
  handleSubmit,
  handleSchoolDataSubmit,
  handleDeletionAttempt,
  handleDeletionConfirm,
  handleTISSubmit,
  handleLMSDataChange,
  handleSaveTeacherMarketingProfillingConsent,
  loading,
}: UserProfileViewProps) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const auth = useAuth();

  const openDeleteModal = async () => {
    handleDeletionAttempt();
    setDeleteModalVisible((v) => !v);
  };

  const internalUser = user.idProvider === IdProvider.CLASSPAD;
  const pilotAccount = user.metadata?.isPilotAccount;
  const frenchPilotAccount = pilotAccount && user.country === Country.FR;
  const defaultLocale = detectLocale();
  const isFrench = defaultLocale === Language.fr;
  const userProfileFrom = sessionStorage.getItem("profileFrom");
  const { casioEduProfileUrl } = useAppConfig();

  const [keepTIS, setKeepTIS] = useState<boolean>(false);
  const [isShowRequestVerificationModal, setIsShowRequestVerificationModal] =
    useState<boolean>(false);
  const [isShowRejectionNoteModal, setIsShowRejectionNoteModal] =
    useState<boolean>(false);

  const [
    teacherMarketingProfillingConsent,
    setTeacherMarketingProfillingConsent,
  ] = useState<boolean | undefined>(
    user.metadata?.teacherMarketingProfillingConsent
  );

  const [
    isChangeTeacherMarketingProfillingConsent,
    setIsChangeTeacherMarketingProfillingConsent,
  ] = useState<boolean>(false);

  const teacherVerificationStatus = useMemo(() => {
    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.APPROVED
    ) {
      return "Validé";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.PENDING
    ) {
      return "Validation en cours";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.REJECTED
    ) {
      return "Refusé";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.NOT_SUBMITTED
    ) {
      return "Validation non effectuée";
    }
  }, [auth.user?.metadata?.teacherVerificationStatus]);

  const teacherVerificationDescription = useMemo(() => {
    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.APPROVED
    ) {
      return (
        <p>
          Votre compte enseignant a été validé ! Vous avez dorénavant accès à
          toutes les fonctionnalités du site, dont le portail de commandes
          groupées CASIO.
        </p>
      );
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.PENDING
    ) {
      return (
        <p>
          Votre compte enseignant est en cours de validation. Vous obtiendrez
          une réponse sous 2 à 3 jours ouvrés. 
        </p>
      );
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.REJECTED
    ) {
      return (
        <p>
          Nous sommes navrés, mais votre demande de validation de compte
          enseignant n’a pas pu être validée. Nous vous invitons à soumettre une
          nouvelle demande.
        </p>
      );
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.NOT_SUBMITTED
    ) {
      return (
        <p>
          Vous n’avez pas encore effectué de demande de validation de votre
          compte enseignant. La validation de votre compte enseignant vous
          permettra d’accéder à toutes les fonctionnalités du site, dont le
          portail de commandes groupées CASIO. Pour valider votre compte
          enseignant, veuillez soumettre votre demande en cliquant sur le bouton
          ci-contre.
        </p>
      );
    }
  }, [auth.user?.metadata?.teacherVerificationStatus]);

  const teacherVerificationBtnLabel = useMemo(() => {
    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.APPROVED
    ) {
      return "Demander la validation";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.PENDING
    ) {
      return "Soumettre une nouvelle demande";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.REJECTED
    ) {
      return "Voir les détails / Soumettre une nouvelle demande";
    }

    if (
      auth.user?.metadata?.teacherVerificationStatus ===
      TeacherVerificationMetadataStatus.NOT_SUBMITTED
    ) {
      return "Demander la validation";
    }
  }, [auth.user?.metadata?.teacherVerificationStatus]);

  const deleteAccountModalText: {
    title: string;
    informingText: React.ReactElement;
    accountDeleteDescription: React.ReactElement;
  } = useMemo(() => {
    if (
      auth.userInfo?.role === Role.INSTRUCTOR &&
      auth.user?.country === Country.FR &&
      auth.userInfo.idProvider === IdProvider.CLASSPAD &&
      (auth.user?.metadata?.role === FrenchInstructorRole.INSTRUCTOR ||
        auth.user?.metadata?.role === FrenchInstructorRole.SCHOOL_ASSOCIATION ||
        auth.user?.metadata?.role === FrenchInstructorRole.SCHOOL_ADMIN ||
        auth.user?.metadata?.role === FrenchInstructorRole.OTHER)
    ) {
      return {
        title: i18n._({
          id: "user.profile.dtc.fr.teacher.delete.confirm.header",
        }),
        informingText: (
          <>
            {solelyManagedContexts.length > 0 ? (
              <>
                <Trans id="user.profile.dtc.fr.teacher.delete.confirm.warning.rooms.one" />
                <br />
                <br />
                <Trans id="user.profile.dtc.fr.teacher.delete.confirm.warning.rooms.two" />
              </>
            ) : (
              <>
                <Trans id="user.profile.dtc.fr.teacher.delete.confirm.warning.one" />
                <br />
                <br />
                <Trans id="user.profile.dtc.fr.teacher.delete.confirm.warning.two" />
              </>
            )}
          </>
        ),
        accountDeleteDescription: (
          <Trans id="user.profile.dtc.teacher.account.delete.description" />
        ),
      };
    } else {
      return {
        title: i18n._({ id: "user.profile.delete.confirm.header" }),
        informingText: (
          <>
            {solelyManagedContexts.length > 0 ? (
              <Trans id="user.profile.delete.confirm.warning.rooms" />
            ) : (
              <Trans id="user.profile.delete.confirm.warning" />
            )}
          </>
        ),
        accountDeleteDescription: (
          <Trans id="user.profile.account.delete.description" />
        ),
      };
    }
  }, [
    auth.user?.country,
    auth.user?.metadata?.role,
    auth.userInfo?.idProvider,
    auth.userInfo?.role,
    solelyManagedContexts.length,
  ]);

  return (
    <ContentLayout>
      <PageToolbar
        leftActions={
          userProfileFrom === RegisterFromEnum.CASIO_EDU ? (
            <Link
              to={casioEduProfileUrl}
              onClick={() => {
                sessionStorage.removeItem("profileFrom");
              }}
            >
              <PageToolbarAction
                icon={<ArrowLeft />}
                label={i18n._({ id: "user.profile.backLink_casio_edu" })}
                type="back"
              />
            </Link>
          ) : (
            <Link to="/navigation">
              <PageToolbarAction
                icon={<ArrowLeft />}
                label={i18n._({ id: "user.profile.backLink" })}
                type="back"
              />
            </Link>
          )
        }
      />
      <>
        <Spinner
          type="fullPage"
          title={i18n._({ id: "loading" })}
          visible={loading}
        />
        <PageHeader title={i18n._({ id: "user.profile.header" })} />
        <Modal
          size="medium"
          title={deleteAccountModalText.title}
          isShowing={deleteModalVisible}
          closeButton={false}
          onHide={() => setDeleteModalVisible(false)}
        >
          <div className={styles.userProfile__modal}>
            <p className={styles.userProfile__modal__description}>
              {deleteAccountModalText.informingText}
            </p>
            {solelyManagedContexts.length > 0 && (
              <ul>
                {solelyManagedContexts.map((context) => {
                  return <li key={context.name}>{context.name}</li>;
                })}
              </ul>
            )}

            {user.tisMarketingConsent && (
              <LabeledCheckbox
                className={styles.userProfile__modal__tis}
                mode="small"
                label={i18n._({ id: "user.profile.delete.tis" })}
                value="continue-tis"
                onChange={() => {
                  setKeepTIS(!keepTIS);
                }}
              />
            )}
            <div className={styles.userProfile__modal__buttons}>
              <Button
                btnStyle="secondary"
                label={i18n._({
                  id: "user.profile.delete.confirm.button.cancel",
                })}
                onClick={() => {
                  setDeleteModalVisible(false);
                }}
              />
              <Button
                btnStyle="warning"
                label={i18n._({
                  id: "user.profile.delete.confirm.button.continue",
                })}
                onClick={() => {
                  handleDeletionConfirm(keepTIS);
                }}
              />
            </div>
          </div>
        </Modal>
        <div className={styles.userProfile}>
          <div className={styles.userProfile__nameHeader}>
            <div>
              <UserProfileBadge
                className={styles.userProfile__badge}
                name={internalUser ? user?.firstname : user?.nickname}
              />
            </div>
            <div>
              <p className={styles.userProfile__nickname}>{user.nickname}</p>
              {internalUser && (
                <p className={styles.userProfile__name}>
                  {user.firstname} {user.lastname}
                </p>
              )}
            </div>
          </div>
          {!internalUser && (
            <Card className={styles.userProfile__lmsHint}>
              <LMSBadge
                idProvider={user.idProvider}
                className={
                  user.idProvider === IdProvider.EDUPLACES
                    ? styles.userProfile__lmsHint__eduplacesBadge
                    : styles.userProfile__lmsHint__badge
                }
              />
              <p data-testid="lmsHint">
                {i18n._({ id: `user.profile.lms.hint.${user.idProvider}` })}
                {user.idProvider === IdProvider.GAR && (
                  <BlockContent>
                    <Trans
                      id="user.profile.lms.gar.terms"
                      values={{
                        conditions_link: (
                          <a
                            target="_blank"
                            href="/app/gar/pdfs/conditions_generales"
                          >
                            {i18n._({
                              id: "user.profile.terms_and_conditions.conditions",
                            })}
                          </a>
                        ),
                        data_protection_link: (
                          <a
                            target="_blank"
                            href="/app/gar/pdfs/politique_de_confidentialite"
                          >
                            {i18n._({
                              id: "register.form.terms_and_conditions.data_protection",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </BlockContent>
                )}
              </p>
            </Card>
          )}

          <Card
            title={i18n._({ id: "user.profile.data.header" })}
            icon={<UserIcon />}
          >
            <UserProfilePersonalData user={user} onSubmit={handleSubmit} />
          </Card>
          {user.country === Country.FR &&
            user.idProvider === IdProvider.CLASSPAD &&
            user.type === Role.INSTRUCTOR && (
              <Card
                title="Validation du compte enseignant"
                className={styles.userProfile__teacherVerificationStatus}
                icon={<Award />}
              >
                <div
                  className={
                    styles.userProfile__teacherVerificationStatus__item
                  }
                >
                  <div>
                    <h4>Statut: {teacherVerificationStatus}</h4>
                    {teacherVerificationDescription}
                  </div>

                  {user.metadata?.teacherVerificationStatus !==
                  TeacherVerificationMetadataStatus.REJECTED ? (
                    <Button
                      label={teacherVerificationBtnLabel}
                      disabled={
                        user.metadata?.teacherVerificationStatus ===
                        TeacherVerificationMetadataStatus.APPROVED
                      }
                      onClick={() => {
                        setIsShowRequestVerificationModal(true);
                      }}
                      className={
                        styles.userProfile__teacherVerificationStatus__button
                      }
                    />
                  ) : (
                    <Button
                      label={teacherVerificationBtnLabel}
                      onClick={() => {
                        setIsShowRejectionNoteModal(true);
                      }}
                      className={
                        styles.userProfile__teacherVerificationStatus__button
                      }
                    ></Button>
                  )}
                </div>
              </Card>
            )}
          {user.type === Role.INSTRUCTOR && internalUser && (
            <>
              <Card
                title={i18n._({ id: "user.profile.schooldata.header" })}
                icon={<Book />}
              >
                <UserProfileSchoolData
                  user={user}
                  onSubmit={handleSchoolDataSubmit}
                />
              </Card>
              {!isFrench && (
                <Card
                  className={styles.registerForm__teacherInfoService}
                  title={i18n._({
                    id: "teacher_info_service",
                  })}
                  icon={<MessageSquare />}
                  variant="small"
                  id={"tis"}
                >
                  <UserProfileTeacherInfoService
                    user={user}
                    handleTISSubmit={handleTISSubmit}
                  />
                </Card>
              )}
            </>
          )}

          {user.type === Role.INSTRUCTOR &&
            user.country === Country.FR &&
            internalUser && (
              <>
                <Card
                  className={
                    styles.userProfile__teacherMarketingProfillingConsent
                  }
                  title="Casio Community & Communication Preferences"
                  icon={<MessageSquare />}
                >
                  <LabeledCheckbox
                    className={
                      styles.userProfile__teacherMarketingProfillingConsent__checkbox
                    }
                    label={
                      <BlockContent>
                        <Trans id="register.form.terms_and_conditions_two_1" />
                        <br />
                        <br />
                        <Trans
                          id="register.form.terms_and_conditions_two_2"
                          values={{
                            privacy_policy: (
                              <a
                                target="_blank"
                                href={
                                  "https://www.casio-education.fr/donnees-personnelles/"
                                } // Term and Condition
                                rel="noreferrer"
                              >
                                {i18n._({
                                  id: "register.form.terms_and_conditions_two_2.privacy_policy",
                                })}
                              </a>
                            ),
                          }}
                        />
                        <br />
                        <br />
                        <Trans
                          id="register.form.terms_and_conditions_two_3"
                          values={{
                            protection_email: (
                              <a
                                target="_blank"
                                href={"/fr/conditions-de-service"}
                                rel="noreferrer"
                              >
                                {i18n._({
                                  id: "register.form.terms_and_conditions_two_3.protection_email",
                                })}
                              </a>
                            ),
                          }}
                        />
                        <br />
                        <Trans id="register.form.terms_and_conditions_two_4" />
                      </BlockContent>
                    }
                    onChange={(event) => {
                      console.log("event.target.checked", event.target.checked);
                      setTeacherMarketingProfillingConsent(
                        event.target.checked
                      );
                      setIsChangeTeacherMarketingProfillingConsent(
                        !isChangeTeacherMarketingProfillingConsent
                      );
                    }}
                    checked={teacherMarketingProfillingConsent}
                    value="teacherMarketingProfillingConsent"
                    name="teacherMarketingProfillingConsent"
                    id="teacherMarketingProfillingConsent"
                  />

                  <Button
                    label={i18n._({
                      id: "user.profile.save",
                    })}
                    onClick={() => {
                      handleSaveTeacherMarketingProfillingConsent(
                        teacherMarketingProfillingConsent as boolean
                      );
                      setIsChangeTeacherMarketingProfillingConsent(false);
                    }}
                    disabled={!isChangeTeacherMarketingProfillingConsent}
                  />
                </Card>
              </>
            )}
          {user.type === Role.INSTRUCTOR && !internalUser && !isFrench && (
            <Card
              title={i18n._({
                id: "user.profile.teacher_info_service_lms_.header.school_tis",
              })}
              icon={<MessageSquare />}
              id={"tis"}
            >
              <UserProfileTeacherInfoServiceLMS
                user={user}
                handleTISSubmit={handleLMSDataChange}
                loading={loading}
              />
            </Card>
          )}
          {!frenchPilotAccount && user.idProvider !== IdProvider.GAR && (
            <Card
              title={i18n._({ id: "user.profile.account.title" })}
              icon={<Settings />}
            >
              <div className={styles.userProfile__accountSettings}>
                {internalUser && (
                  <div className={styles.userProfile__accountSettings__item}>
                    <div>
                      <h4>
                        <Trans id="user.profile.account.change-password.title" />
                      </h4>
                      <p>
                        <Trans id="user.profile.account.change-password.description" />
                      </p>
                    </div>
                    <a href="/auth/settings">
                      <Button
                        label={i18n._({
                          id: "user.profile.account.change-password.button",
                        })}
                        btnStyle="primary"
                        type="button"
                      />
                    </a>
                  </div>
                )}
                <div className={styles.userProfile__accountSettings__item}>
                  <div>
                    <h4>
                      <Trans id="user.profile.account.delete.title" />
                    </h4>
                    <p>{deleteAccountModalText.accountDeleteDescription}</p>
                  </div>
                  <Button
                    label={i18n._({
                      id: "user.profile.account.delete.button",
                    })}
                    btnStyle="warning"
                    type="button"
                    id="delete-user-button"
                    onClick={openDeleteModal}
                  />
                </div>
              </div>
            </Card>
          )}
        </div>

        <RequestVerificationModal
          onConfirm={() => {}}
          onCancel={() => {
            setIsShowRequestVerificationModal(false);
          }}
          isShowing={isShowRequestVerificationModal}
        />

        <RejectionNoteModal
          isShowing={isShowRejectionNoteModal}
          // isShowing={true}
          onCancel={() => {
            setIsShowRejectionNoteModal(false);
          }}
          showRequestVerificationModal={() => {
            setIsShowRequestVerificationModal(true);
          }}
          reviewerNote={user.teacherVerificationRequests?.reviewerNote}
        />
      </>
    </ContentLayout>
  );
};

const RejectionNoteModal = ({
  isShowing,
  onCancel,
  showRequestVerificationModal,
  reviewerNote,
}: {
  isShowing: boolean;
  onCancel: () => void;
  showRequestVerificationModal: (isShowing: boolean) => void;
  reviewerNote?: string;
}) => {
  return (
    <Modal
      title="Compte enseignant : refus de la demande"
      isShowing={isShowing}
      closeButton={false}
      size="medium"
      className={styles.rejectionNoteContainer}
    >
      <p className={styles.rejectionNoteContainer__title}>
        Nous sommes navrés, mais votre demande de validation de compte
        enseignant n’a pas pu être validée. Nous vous invitons à découvrir la
        raison du refus ci-dessous et à soumettre une nouvelle demande.
      </p>

      <div className={styles.rejectionNoteContainer__content}>
        <p>Message</p>

        <TextArea
          className={styles.rejectionNoteContainer__content__textArea}
          id="note"
          label=""
          whiteBackground={true}
          defaultValue={reviewerNote}
          disabled={true}
        />
      </div>

      <div className={styles.rejectionNoteContainer__btnGroup}>
        <Button
          label="SOUMETTRE LA DEMANDE"
          onClick={() => {
            showRequestVerificationModal(true);
            onCancel();
          }}
        ></Button>

        <Button
          label="ANNULER"
          btnStyle="secondary"
          onClick={onCancel}
        ></Button>
      </div>
    </Modal>
  );
};
