import { useRef, useState } from "react";
import { Stepper } from "../Stepper/Stepper";
import {
  MergeSchoolsStep1,
  SelectedSchoolStep1,
} from "./Steps/MergeSchoolsStep1/MergeSchoolsStep1";
import { PageHeader } from "../PageHeader/PageHeader";
import styles from "./MergeSchools.module.scss";
import {
  MergeSchoolsStep2,
  SelectedSchoolStep2,
} from "./Steps/MergeSchoolsStep2/MergeSchoolsStep2";
import { MergeSchoolsStep3 } from "./Steps/MergeSchoolsStep3/MergeSchoolsStep3";
import { MergeSchoolsStep4 } from "./Steps/MergeSchoolsStep4/MergeSchoolsStep4";
import { MergedSchoolResponse } from "../../api/school";

export const MergeSchools = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const stepOneRef = useRef<{
    resetForm: () => void;
    resetTableData: () => void;
  }>();
  const stepTwoRef = useRef<{
    resetForm: () => void;
    resetTableData: () => void;
  }>();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const [schoolSelectedStep1, setSchoolSelectedStep1] =
    useState<SelectedSchoolStep1>();
  const [schoolSelectedStep2, setSchoolSelectedStep2] =
    useState<SelectedSchoolStep2>();

  const [mergedResult, setMergedResult] = useState<MergedSchoolResponse>();

  const handleEndProgress = () => {
    setCurrentStep(0);
    stepOneRef?.current?.resetForm();
    stepOneRef?.current?.resetTableData();

    stepTwoRef?.current?.resetForm();
    stepTwoRef?.current?.resetTableData();
  };

  return (
    <div className={styles.container}>
      <PageHeader title="Merge schools" />
      <div className={styles.container__stepperContainer}>
        <Stepper
          type="horizontal"
          steps={[
            {
              title: "Select Merging School",
              body: (
                <MergeSchoolsStep1
                  handleNext={() => handleNext()}
                  ref={stepOneRef}
                  getSelectedSchool={(selectedSchool: SelectedSchoolStep1) => {
                    setSchoolSelectedStep1(selectedSchool);
                  }}
                />
              ),
            },
            {
              title: "Select CPA School",
              body: (
                <MergeSchoolsStep2
                  handleNext={() => handleNext()}
                  handleBack={() => handleBack()}
                  ref={stepTwoRef}
                  getSelectedSchool={(selectedSchool: SelectedSchoolStep2) => {
                    setSchoolSelectedStep2(selectedSchool);
                  }}
                />
              ),
            },

            {
              title: "Confirm Merge Schools",
              body: (
                <MergeSchoolsStep3
                  handleNext={() => handleNext()}
                  handleBack={() => handleBack()}
                  mergingSchoolRecord={schoolSelectedStep1}
                  cpaSchoolRecord={schoolSelectedStep2}
                  getMergedSchoolResult={(
                    mergeSchoolsResponse: MergedSchoolResponse
                  ) => {
                    setMergedResult(mergeSchoolsResponse);
                  }}
                />
              ),
            },
            {
              title: "Result",
              body: (
                <MergeSchoolsStep4
                  handleEndProgress={() => handleEndProgress()}
                  mergedResult={mergedResult}
                />
              ),
            },
          ]}
          currentStepIndex={currentStep}
        />
      </div>
    </div>
  );
};
