import React from "react";
import { classList } from "../../lib/helpers";
import styles from "./Badge.module.scss";

export interface BadgeProps {
  label: string;
  type?:
    | "default"
    | "disabled"
    | "cplTestPeriod"
    | "secondary"
    | "dtcTeacherVerification_verified"
    | "dtcTeacherVerification_pending"
    | "dtcTeacherVerification_rejected"
    | "dtcTeacherVerification_notsubmitted";
  size?: "default" | "small" | "x-small";
  className?: string;
  icon?: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = ({
  label,
  type = "default",
  size = "default",
  className,
  icon,
}) => {
  const badgeClasses = classList(
    className,
    styles.badge,
    styles["badge_" + type],
    styles["badge_size_" + size]
  );
  return (
    <div className={badgeClasses}>
      {icon && <i>{icon}</i>}
      <span>{label}</span>
    </div>
  );
};
