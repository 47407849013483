import React from "react";
import styles from "./Main.module.scss";
import { Routes, Route } from "react-router-dom";
import Register from "../Register/Register";
import { RegisteredAreaRouter } from "../RegisteredAreaRouter";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import RegisterTISOnly from "../TISOnly/Register/RegisterTISOnly";
import { VerificationFeedbackPageTISOnly } from "../TISOnly/VerificationFeedbackPage/VerificationFeedbackPageTISOnly";
import { NewVerificationLinkPageTISOnly } from "../TISOnly/NewVerificationLinkPage/NewVerificationLinkPage";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Language } from "../../types/Country";
import { Footer } from "../FooterLandingPage/Footer";
import { useAuth } from "../../hooks/use-auth";
import { IdProvider } from "../../types/IdProvider";
import { RecoveryPage } from "../Recover/RecoverPage";
import { LegalGuardianVerification } from "../LegalGuardianVerification/LegalGuardianVerification";
import { ResendGuardianVerification } from "../LegalGuardianVerification/ResendGuardianVerification/ResendGuardianVerification";
import { RegisterFrom } from "../Register/RegisterFrom";
import { UserProfileFrom } from "../UserProfile/UserProfileFrom";
import { LegalGuardianUpdateEmail } from "../LegalGuardianVerification/LegalGuardianUpdateEmail/LegalGuardianUpdateEmail";
import { Logout } from "../Logout/Logout";

const Main: React.FC = () => {
  const auth = useAuth();

  const defaultLocale = detectLocale();
  const isFrench = defaultLocale === Language.fr;
  return (
    <>
      <main className={styles.Main} tabIndex={0} data-testid="Main">
        <Routes>
          <Route path="/registration-from/:from?" element={<RegisterFrom />} />
          <Route
            path="/user-profile-from/:from?"
            element={<UserProfileFrom />}
          />
          <Route path="/registration/:lang?" element={<Register />} />
          {window.featureToggles?.FT_TIS_ONLY_REGISTRATION && (
            <>
              {!isFrench && (
                <Route
                  path="/tis/registration/:lang?"
                  element={<RegisterTISOnly />}
                />
              )}
              <Route
                path="/tis/verification/:userId/:code"
                element={<VerificationFeedbackPageTISOnly />}
              />
              <Route
                path="/tis/verification/"
                element={<NewVerificationLinkPageTISOnly />}
              />
            </>
          )}
          <Route
            path="*"
            element={<PrivateRoute element={<RegisteredAreaRouter />} />}
          ></Route>
          <Route path="/log-out" element={<Logout />}></Route>
          <Route path="/recovery" element={<RecoveryPage />}></Route>
          <Route
            path="/double-opt-in"
            element={<LegalGuardianVerification />}
          ></Route>
          <Route
            path="/double-opt-in/resend-email"
            element={<ResendGuardianVerification />}
          ></Route>
          <Route
            path="/double-opt-in/update-legal-guardian-email"
            element={<LegalGuardianUpdateEmail />}
          ></Route>
        </Routes>
      </main>
      {auth.user?.idProvider !== IdProvider.GAR && (
        <Footer isFrench={isFrench} />
      )}
    </>
  );
};

export default Main;
