import { i18n } from "@lingui/core";
import { Link } from "react-router-dom";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { PageToolbar, PageToolbarAction } from "../PageToolbar/PageToolbar";
import { ArrowLeft } from "react-feather";
import { PageHeader } from "../PageHeader/PageHeader";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { Role } from "../../types/Role";
import { FAQContent } from "./FAQItem";

export const FAQ = () => {
  return (
    <RestrictedTo roles={[Role.LEARNER, Role.INSTRUCTOR]} redirects={true}>
      <ContentLayout>
        <PageToolbar
          leftActions={
            <Link to="/navigation">
              <PageToolbarAction
                icon={<ArrowLeft />}
                label={i18n._({ id: "user.profile.backLink" })}
                type="back"
              />
            </Link>
          }
        />
        <PageHeader title={"Häufige Fragen"} custom={40} />
        <FAQContent />
      </ContentLayout>
    </RestrictedTo>
  );
};
