import axios, { CancelTokenSource } from "axios";
const url = "internal/v2/teacher-create-user";

export interface GetStudentsByTeacherParams {
  page: number;
  limit: number;
  email?: string;
  dateFrom?: Date;
  dateTo?: Date;
  firstname?: string;
  lastname?: string;
  nickname?: string;
}

export const getStudentByTeacherId = async (
  teacherId: string,
  searchParams: GetStudentsByTeacherParams,
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/${url}/${teacherId}`, {
      params: searchParams,
    });
    return response.data;
  } catch (err) {
    throw new Error();
  }
};

export const deleteStudentByTeacherId = async (
  teacherId: string,
  studentIds: string[],
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/${url}/${teacherId}`, {
      data: studentIds,
    });
    return response.data;
  } catch (err) {
    throw new Error();
  }
};

export const getTeacherContexts = async (
  teacherId: string,
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.get(
      `${apiBaseUrl}/${url}/${teacherId}/contexts`
    );
    return response.data;
  } catch (err) {
    throw new Error();
  }
};

export const addStudentToClass = async (
  contextIds: string[],
  studentIds: string[],
  apiBaseUrl = "/app/api"
) => {
  // Store the CancelTokenSource
  let cancelToken: CancelTokenSource | undefined;

  // Cancel the previous request, if any
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to a new request.");
  }

  // Create a new CancelToken
  cancelToken = axios.CancelToken.source();

  try {
    const response = await axios.post(
      `${apiBaseUrl}/${url}/context/add-student`,
      { contextIds, studentIds },
      { cancelToken: cancelToken.token }
    );
    return response.data;
  } catch (err) {
    throw new Error();
  }
};
