import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "../Modal/Modal";
import styles from "./RequestVerificationModal.module.scss";
import { TextArea } from "../TextArea/TextArea";
import { Grid, GridItem } from "../Grid/Grid";
import { FileRejection, useDropzone } from "react-dropzone";
import { MAX_TOTAL_SIZE_MB, convertFileSizeKBToMB } from "../../utils/utils";
import { Button } from "../Button/Button";
import { sendRequestVerification } from "../../api/teacher-verification";
import { useAuth } from "../../hooks/use-auth";
import { useToasts } from "../../provider/toast-provider";
import { i18n } from "@lingui/core";
import { Spinner } from "../Spinner/Spinner";

const RequestVerificationModal = ({
  isShowing,
  onConfirm,
  onCancel,
}: {
  isShowing: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const auth = useAuth();
  const { addToast } = useToasts();

  const [files, setFiles] = useState<File[]>([]);
  const [notes, setNotes] = useState<string | null>(null);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  const [totalFileSize, setTotalFileSize] = useState<number>(0);

  const [errorFilesUploaded, setFilesUploadedError] = useState<string | null>(
    null
  );
  const [isDisableSubmitBtn, setIsDisableSubmitBtn] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectionFiles: FileRejection[]) => {
      const newFiles: File[] = [];
      let newFileSize = totalFileSize;

      if (rejectionFiles.length > 0) {
        addToast("Ce type de fichier ne peut pas être pris en charge", "error");
      }

      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        const fileSizeInMB = Number(convertFileSizeKBToMB(file.size));

        if (newFileSize + fileSizeInMB > MAX_TOTAL_SIZE_MB) {
          setFilesUploadedError(
            ` La taille du fichier dépasse la limite de (${MAX_TOTAL_SIZE_MB}Mo).`
          );
        } else {
          setFilesUploadedError(null);
        }
        newFiles.push(file);
        newFileSize += fileSizeInMB;

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          // console.log(
          //   "name - size",
          //   file.name,
          //   fileSizeInMB
          // );
          // // const binaryStr = reader.result;
          // // console.log(binaryStr);
        };
        reader.readAsArrayBuffer(file);
      });
      setTotalFileSize(newFileSize);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
    [addToast, totalFileSize]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    multiple: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "video/mp4": [".mp4"],
      "video/quicktime": [".mov"],
    },
    validator: (file) => {
      // Custom validation to ensure consistent behavior across platforms
      const validTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
        "video/mp4",
        "video/quicktime",
      ];

      if (!validTypes.includes(file.type)) {
        return {
          code: "file-invalid-type",
          message: "Ce type de fichier ne peut pas être pris en charge",
        };
      }

      return null;
    },
  });

  useEffect(() => {
    setNotes(null);
    setFiles([]);
    setFilesUploadedError(null);
    setTotalFileSize(0);
  }, [isShowing]);

  useEffect(() => {
    if (notes === null || notes.length === 0 || errorFilesUploaded) {
      setIsDisableSubmitBtn(true);
    } else {
      setIsDisableSubmitBtn(false);
    }
  }, [errorFilesUploaded, notes]);

  return (
    <Modal
      title="Validation de votre compte enseignant"
      isShowing={isShowing}
      closeButton={true}
      size="large"
      centerTitle={true}
      onClose={onCancel}
    >
      <p className={styles.title}>
        La validation de votre compte enseignant vous permettra d’accéder à
        toutes les fonctionnalités du site, dont le portail de commandes
        groupées CASIO. Pour valider votre compte enseignant, veuillez soumettre
        votre demande ci-dessous.
      </p>
      <div className={styles.content}>
        <p>Message*</p>

        <TextArea
          className={styles.content__textArea}
          id="note"
          label=""
          whiteBackground={true}
          onChange={(event) => {
            setNotes(event.target.value);
          }}
          errorMessage={notes?.length === 0 ? "Champ requis!" : ""}
        />

        <p>
          Si vous le souhaitez, n’hésitez pas à ajouter des attestations
          d’enseignement pour accélérer le processus de validation.
        </p>

        <div className={styles.content__uploadFilesSection}>
          <Grid>
            <GridItem width="1/2">
              <div
                {...getRootProps()}
                className={styles.content__uploadFilesSection__fileUploader}
              >
                <input {...getInputProps()} />
                {
                  <>
                    <p>Déposer votre fichier ici</p>
                    <p>ou</p>
                  </>
                }
                <p
                  className={
                    styles.content__uploadFilesSection__fileUploader__browse
                  }
                  onClick={open}
                >
                  Parcourir
                </p>
              </div>
            </GridItem>

            <GridItem width="1/2">
              <div className={styles.content__listFilesSection}>
                <p>Fichiers chargés</p>
                <div className={styles.content__listFilesSection__content}>
                  {files.map((file: File, index: number) => {
                    return (
                      <div
                        className={
                          styles.content__listFilesSection__content__fileRow
                        }
                        key={`request-verification-modal-file-${index}`}
                      >
                        <p>{file.name}</p>
                        <p>{convertFileSizeKBToMB(file.size)}MB </p>
                        <p
                          onClick={() => {
                            const findIndex = files.indexOf(file);
                            if (index > -1) {
                              const newList = files.filter(
                                (item, index) => index !== findIndex
                              );
                              setFiles(newList);
                            }

                            const sizeReduce = Number(
                              convertFileSizeKBToMB(files[findIndex].size)
                            );

                            let newFileSize = totalFileSize;
                            newFileSize -= sizeReduce;
                            setTotalFileSize(newFileSize);

                            if (newFileSize < MAX_TOTAL_SIZE_MB) {
                              setFilesUploadedError(null);
                            }
                          }}
                        >
                          X
                        </p>
                      </div>
                    );
                  })}
                </div>
                {errorFilesUploaded && (
                  <p className={styles.content__listFilesSection__errorMsg}>
                    {errorFilesUploaded}
                  </p>
                )}
              </div>
            </GridItem>
          </Grid>
        </div>

        <Button
          label="SOUMETTRE LA DEMANDE"
          className={styles.content__buttonSubmit}
          disabled={isDisableSubmitBtn}
          onClick={async () => {
            try {
              setIsShowSpinner(true);
              await sendRequestVerification(files, auth.user?.id, notes);
              addToast(
                "Votre demande de validation de compte enseignant a bien été envoyée.",
                "success"
              );
              onCancel();
              auth.refreshAuth && auth.refreshAuth();
            } catch (error) {
              setIsShowSpinner(false);
              addToast("Failed", "error");
            } finally {
              setIsShowSpinner(false);
            }
          }}
        ></Button>
      </div>

      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </Modal>
  );
};

export default RequestVerificationModal;
