import { Card } from "../Card/Card";
import { UserCsvImport } from "./UploadStudentUser";
import styles from "./UploadStudentUserResult.module.scss";
import { PageHeader } from "../PageHeader/PageHeader";
import { Table } from "../Table/Table";
import { Column } from "react-data-grid";
import { Button } from "../Button/Button";
import { TeacherCreateUserResult } from "./AddStudentModal/AddStudentModal";
import { i18n } from "@lingui/core";

export const UploadStudentUserResult = ({
  data,
  setVisible,
}: {
  data: TeacherCreateUserResult[];
  setVisible: (isShowing: boolean) => void;
}) => {
  const columns: readonly Column<UserCsvImport>[] = [
    {
      key: "firstname",
      name: i18n._({
        id: "student_list.upload.result.screen.column.firstname",
      }),
      sortable: false,
    },
    {
      key: "lastname",
      name: i18n._({
        id: "student_list.upload.result.screen.column.lastname",
      }),
      sortable: false,
    },
    {
      key: "email",
      name: i18n._({
        id: "student_list.upload.result.screen.column.email",
      }),
      sortable: false,
    },
    {
      key: "password",
      name: i18n._({
        id: "student_list.upload.result.screen.column.password",
      }),
      sortable: false,
    },
    {
      key: "classes",
      name: i18n._({ id: "student_list.upload.result.screen.column.classes" }),
      sortable: false,
    },
  ];

  const usersData = data.map((user) => {
    const classes = user.classes?.map((value) => value.name).join("; ");
    return {
      ...user,
      classes: classes,
    };
  });

  return (
    <div className={styles.container}>
      <PageHeader
        title={i18n._({ id: "student_list.upload.result.screen.header" })}
      />
      <Card title={i18n._({ id: "student_list.upload.result.screen.title" })}>
        <div className={styles.description}>
          <span>
            {i18n._({ id: "student_list.upload.result.screen.description1" })}
          </span>
          <span>
            {i18n._({ id: "student_list.upload.result.screen.description2" })}
          </span>
          <span>
            {i18n._({ id: "student_list.upload.result.screen.description3" })}
          </span>
        </div>
      </Card>
      <Card
        title={i18n._({
          id: "student_list.upload.result.screen.title.student_list",
        })}
      >
        <div>
          <Table
            columns={columns}
            rows={usersData}
            onRowsChange={() => {}}
            sortColumns={[]}
          ></Table>
        </div>
      </Card>
      <div className={styles.btn}>
        <Button
          label={i18n._({
            id: "student_list.upload.result.screen.btn.close",
          })}
          type="submit"
          onClick={() => {
            setVisible(false);
          }}
        ></Button>
      </div>
    </div>
  );
};
