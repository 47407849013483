import { i18n } from "@lingui/core";
import { useEffect, useState } from "react";
import {
  BookOpen,
  Box,
  Folder,
  Home,
  Link as LinkIcon,
  Book,
  User,
  List,
  HelpCircle,
} from "react-feather";
import { Link, Navigate, Route, Routes, useMatch } from "react-router-dom";
import { ReactComponent as MatheMarathonLogo } from "../assets/images/svg/mm-icon-2023-normalized.svg";
import { useAppConfig } from "../hooks/use-app-config";
import { useAuth } from "../hooks/use-auth";
import useUnzerScript from "../hooks/use-unzer-script";
import { Country } from "../types/Country";
import { IdProvider } from "../types/IdProvider";
import { Product } from "../types/Product";
import { FrenchInstructorRole, Role } from "../types/Role";
import { ContextRouter } from "./Contexts/ContextRouter";
import { GarPdfs } from "./GarPdfs/GarPdfs";
import { LMSNicknameModal } from "./LMSNicknameModal/LMSNicknameModal";
import { LicensesPage } from "./LicensesPage/LicensesPage";
import { ManagementRouter } from "./ManagementRouter/ManagementRouter";
import { MatheMarathonPage } from "./MatheMarathon/MatheMarathonPage";
import { NavigationPage } from "./NavigationPage/NavigationPage";
import { InstructorVerificationCode } from "./Register/InstructorVerificationCode";
import { SidebarLayout, SidebarTab } from "./SidebarLayout/SidebarLayout";
import { UserProfile } from "./UserProfile/UserProfile";
import { VerificationPendingPage } from "./VerificationPendingPage/VerificationPendingPage";
// import { ConfirmModal } from "./ConfirmModal/ConfirmModal";
import { TopicOverview } from "./TopicOverview/TopicOverview";
import { TopicOverViewRouter } from "./TopicOverview/TopicOverviewRouter";
import { captureLicenseUsage, updateUser } from "../api/user";
import { DoubleOptInPendingPage } from "./DoubleOptInPendingPage/DoubleOptInPendingPage";
import { StudentListGeneral } from "./StudentList/StudentListGeneral";
import { RegisterFromEnum } from "../types/RegisterFrom";
import { Operation } from "fast-json-patch";
import { Spinner } from "./Spinner/Spinner";
// import { Trans } from "@lingui/react";
import { FAQRouter } from "./FAQ/FAQRouter";
import { FAQ } from "./FAQ/FAQ";

export const RegisteredAreaRouter: React.FC = () => {
  const auth = useAuth();

  const [verificationPending, setVp] = useState(false);
  const [instructorVerified, setIv] = useState(true);
  const [idProvider, setIdProvider] = useState(IdProvider.CLASSPAD);
  const [nicknameChanged, setNicknameChanged] = useState(true);
  const [doubleOptInPending, setDoubleOptInPending] = useState<boolean>(false);
  const { casioEduLaunchUrl } = useAppConfig();
  const managementTypes = [Role.ADMINISTRATOR, Role.SUPPORT];
  const userProfileFrom = sessionStorage.getItem("userProfileFrom");
  const logoutRequest =
    Boolean(sessionStorage.getItem("logoutRequest")) || false;

  useEffect(() => {
    if (userProfileFrom === RegisterFromEnum.CASIO_EDU) {
      sessionStorage.removeItem("userProfileFrom");
      sessionStorage.setItem("profileFrom", userProfileFrom);
      window.location.replace(`/app/profile/`);
    }
  }, [userProfileFrom]);

  useEffect(() => {
    if (logoutRequest === true) {
      window.location.replace("/app/meta/logout");
    }
    sessionStorage.removeItem("logoutRequest");
  }, [logoutRequest]);

  useEffect(() => {
    const loggedIn = auth?.loggedIn || false;
    const verificationPending = !auth?.userInfo?.emailVerified || false;
    const instructorVerified = auth?.user?.verified ?? true;
    const doubleOptInPending =
      (auth.user?.type === Role.LEARNER &&
        auth?.user?.metadata?.guardian &&
        auth?.user?.guardianVerified === false) ||
      false;
    setVp(loggedIn && verificationPending);
    setIv(instructorVerified);
    setDoubleOptInPending(doubleOptInPending);
  }, [setVp, setIv, auth]);

  useEffect(() => {
    const fetchUserInfos = async () => {
      if (auth.user) {
        const userIdProvider = auth.user?.idProvider;
        setIdProvider(userIdProvider);
        const userId = auth?.userInfo?.userId || "";
        let nicknameChanged = false;

        if (userIdProvider === IdProvider.GAR) {
          if (userId !== auth.user.nickname) nicknameChanged = true;
        } else {
          if (auth.user?.tacAccepted !== false) nicknameChanged = true;
        }

        setNicknameChanged(nicknameChanged);
      }
    };
    fetchUserInfos();
  }, [auth]);

  useUnzerScript(auth?.user?.idProvider);

  if (userProfileFrom === RegisterFromEnum.CASIO_EDU || logoutRequest) {
    return <></>;
  }
  if (verificationPending) {
    return (
      <VerificationPendingPage
        headline={i18n._({ id: "verification.headline" })}
        text={i18n._({ id: "verification.text" })}
        link={{
          target: "/auth/verification",
          text: i18n._({ id: "verification.linkText" }),
          dataTestId: "verification-link",
        }}
      />
    );
  }

  if (doubleOptInPending) {
    return <DoubleOptInPendingPage />;
  }
  if (!instructorVerified) {
    return <InstructorVerificationCode></InstructorVerificationCode>;
  }
  if (auth?.userInfo?.role && managementTypes.includes(auth?.userInfo?.role)) {
    return <ManagementRouter />;
  }
  if (
    auth?.user?.metadata?.registeredFrom === RegisterFromEnum.CASIO_EDU &&
    auth?.userInfo?.emailVerified
  ) {
    updateUser({
      userId: auth?.user?.id,
      patch: [
        { op: "remove", path: "/metadata/registeredFrom" },
      ] as Operation[],
    });
    window.location.replace(casioEduLaunchUrl);
    return <Spinner />;
  }

  return (
    <Routes>
      <Route
        path="/profile"
        element={
          <>
            <LMSNicknameModal
              idProvider={idProvider}
              nicknameChanged={nicknameChanged}
              setNicknameChanged={setNicknameChanged}
            ></LMSNicknameModal>
            <UserProfile nicknameChanged={nicknameChanged} />
          </>
        }
      ></Route>
      {(auth.user?.country !== Country.FR ||
        window.featureToggles?.FT_ENABLED_FOR_FRANCE) && (
        <Route
          path="/licenses/*"
          element={
            <>
              <LMSNicknameModal
                idProvider={idProvider}
                nicknameChanged={nicknameChanged}
                setNicknameChanged={setNicknameChanged}
              ></LMSNicknameModal>
              <LicensesPage />
            </>
          }
        ></Route>
      )}
      {(auth.user?.country !== Country.FR ||
        window.featureToggles?.FT_ENABLED_FOR_FRANCE) && (
        <Route path="/themenuebersicht/*" element={<TopicOverview />}></Route>
      )}
      {auth.user?.country !== Country.FR && (
        <Route path="/faq/*" element={<FAQ />}></Route>
      )}
      {auth.user?.idProvider === IdProvider.GAR && (
        <Route
          path="/gar/pdfs/:name"
          element={
            <>
              <GarPdfs />
            </>
          }
        ></Route>
      )}
      <Route
        path="*"
        element={
          <>
            <LMSNicknameModal
              idProvider={idProvider}
              nicknameChanged={nicknameChanged}
              setNicknameChanged={setNicknameChanged}
            ></LMSNicknameModal>
            <SidebarLayoutArea
              daysSinceRegistration={auth?.user?.daysSinceRegistration}
            />
          </>
        }
      ></Route>
    </Routes>
  );
};

interface SidebarLayoutAreaProps {
  daysSinceRegistration?: number;
}
const SidebarLayoutArea = ({
  daysSinceRegistration,
}: SidebarLayoutAreaProps) => {
  const auth = useAuth();

  const role = auth.userInfo?.role || null;
  const mmActive = useMatch("/mathe-marathon") ? true : false;
  const {
    classpadNetUrl,
    cplTestPeriodLearnerDuration,
    classpadLearningUrl,
    mesExcercisesLyceeLaunchUrl,
    // cpLearningLicenseForDE,
    // cpLearningLicenseForFR,
  } = useAppConfig();
  // const [isShowConfirmRedirect, setIsShowConfirmRedirect] =
  //   useState<boolean>(false);
  const [checkToggledSidebar, setCheckToggleSidebar] = useState(false);

  const redirectToClassPadDotNet = async () => {
    /** Capture license usage here */
    window.open(classpadNetUrl, "_blank", "noreferrer");
    // setIsShowConfirmRedirect(false);

    await captureLicenseUsage(auth.user?.id, {
      userId: auth.user?.id,
      externalId: auth.user?.externalUserId,
      product: Product.CLASSPAD_NET_PLUS,
      accessDate: new Date(),
    });
  };
  const handleCheckToggleSidebar = (value: boolean) => {
    setCheckToggleSidebar(value);
  };

  /* daysSinceRegistration is roundet up to full days.
  Users are able to use test period between 1 und 30 days since registration*/
  const remainingLearnerTestDays =
    cplTestPeriodLearnerDuration - (daysSinceRegistration || 31) + 1;

  // deactive trial license badge label
  // const isActiveTrialLicenseBadgeLabel = false;
  // const isActiveCPLearningForTeacherDE =
  //   auth.user?.type === Role.INSTRUCTOR &&
  //   auth.user?.licenseDate &&
  //   auth.user.licenseDate < new Date(cpLearningLicenseForDE);
  // const isActiveCPLearningForTeacherFR =
  //   auth.user?.type === Role.INSTRUCTOR &&
  //   new Date() < new Date(cpLearningLicenseForFR);
  // const isActiveCPLearningForStudenDE =
  //   auth.user?.type === Role.LEARNER && !auth.user?.licenseDate;
  // const isActiveCPLearningForStudenFR =
  //   auth.user?.type === Role.LEARNER &&
  //   new Date() < new Date(cpLearningLicenseForFR);

  const learnerTestPeriodActive =
    window.featureToggles?.FT_CPL_TEST_PERIOD_LEARNER &&
    role === Role.LEARNER &&
    auth.user?.licensesFromTrial.includes(Product.CLASSPAD_LEARNING) &&
    remainingLearnerTestDays !== undefined &&
    remainingLearnerTestDays > 0;
  const CustomUseMatchHook = (url: string) => {
    return useMatch(url);
  };

  const renderSidebarTabs = (country?: Country) => {
    if (country === Country.DE) {
      return (
        <div>
          <Link to="/navigation">
            <SidebarTab
              icon={<Home />}
              title={i18n._({ id: "sidebar.links.navigation" })}
              active={CustomUseMatchHook("/navigation") ? true : false}
            />
          </Link>
          {auth.user?.type === Role.INSTRUCTOR &&
            auth.user?.idProvider === IdProvider.CLASSPAD && (
              <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#">
                  <SidebarTab
                    icon={<List />}
                    title={i18n._({ id: "sidebar.links.management" })}
                  />
                </a>
                <Link to="/contexts">
                  <SidebarTab
                    icon={<Folder />}
                    title={i18n._({ id: "sidebar.links.contexts" })}
                    active={CustomUseMatchHook("/contexts") ? true : false}
                    badgeIcon={
                      auth.userInfo?.externalGroups &&
                      auth.userInfo?.externalGroups.length > 0 && <LinkIcon />
                    }
                    indented={checkToggledSidebar ? 35 : undefined}
                  />
                </Link>
                <Link to="/student-list">
                  <SidebarTab
                    icon={<User />}
                    title={i18n._({ id: "sidebar.links.student_list" })}
                    active={CustomUseMatchHook("/student-list") ? true : false}
                    indented={checkToggledSidebar ? 35 : undefined}
                  />
                </Link>
              </>
            )}
          {(auth.user?.type === Role.LEARNER ||
            auth.user?.idProvider !== IdProvider.CLASSPAD) && (
            <Link to="/contexts">
              <SidebarTab
                icon={<Folder />}
                title={i18n._({ id: "sidebar.links.contexts" })}
                active={CustomUseMatchHook("/contexts") ? true : false}
                badgeIcon={
                  auth.userInfo?.externalGroups &&
                  auth.userInfo?.externalGroups.length > 0 && <LinkIcon />
                }
              />
            </Link>
          )}
          <a
            href={classpadLearningUrl}
            target="_blank"
            rel="noreferrer"
            onClick={async () => {
              /** Capture license usage here */
              await captureLicenseUsage(auth.user?.id, {
                userId: auth.user?.id,
                externalId: auth.user?.externalUserId,
                product: Product.CLASSPAD_LEARNING,
                accessDate: new Date(),
              });
            }}
          >
            <SidebarTab
              icon={<BookOpen />}
              title={i18n._({ id: "sidebar.links.kikora" })}
              badgeLabel={
                learnerTestPeriodActive
                  ? i18n._({
                      id: "sidebar.kikora.test.days",
                      values: { days: remainingLearnerTestDays },
                    })
                  : undefined
              }
            />
          </a>

          <Link to="/themenuebersicht">
            <SidebarTab
              icon={<Book />}
              title={i18n._({ id: "sidebar.links.topic.overview" })}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              active={useMatch("/themenuebersicht") ? true : false}
              indented={checkToggledSidebar ? 35 : undefined}
            />
          </Link>

          {auth.user?.idProvider !== IdProvider.GAR && (
            <div onClick={redirectToClassPadDotNet}>
              <SidebarTab
                icon={<Box />}
                title={i18n._({ id: "sidebar.links.classpad_net" })}
              />
            </div>
          )}

          {window.featureToggles?.FT_MATHE_MARATHON_VISIBLE &&
            auth.user?.country === Country.DE && (
              <Link to="/mathe-marathon">
                <SidebarTab
                  icon={<MatheMarathonLogo />}
                  title={"Mathe Marathon"}
                  active={mmActive}
                />
              </Link>
            )}

          {/* <ConfirmModal
            header={i18n._({
              id: `sidebar.links.redirect.title.classpad_net`,
            })}
            description={
              <Trans
                id="sidebar.links.redirect.description.classpad_net"
                values={{
                  classpad_net: (
                    <a
                      target="_blank"
                      href="http://ClassPad.net"
                      rel="noreferrer"
                      style={{ color: "#339af0" }}
                    >
                      {i18n._({
                        id: "sidebar.links.classpad_net",
                      })}
                    </a>
                  ),
                }}
              />
            }
            onDone={redirectToClassPadDotNet}
            onRejection={() => setIsShowConfirmRedirect(false)}
            isShowing={isShowConfirmRedirect}
          ></ConfirmModal> */}
          {auth.user?.country !== Country.FR && (
            <Link to="/faq">
              <SidebarTab
                icon={<HelpCircle />}
                title={"Häufige Fragen"}
                // eslint-disable-next-line react-hooks/rules-of-hooks
                active={useMatch("/faq") ? true : false}
              />
            </Link>
          )}
        </div>
      );
    }

    if (country === Country.FR) {
      return (
        <div>
          <Link to="/navigation">
            <SidebarTab
              icon={<Home />}
              title="Accueil"
              active={CustomUseMatchHook("/navigation") ? true : false}
            />
          </Link>

          {/* Classpad.net */}
          {auth.user?.idProvider !== IdProvider.GAR && (
            <div onClick={redirectToClassPadDotNet}>
              <SidebarTab icon={<Box />} title="ClassPad.net" />
            </div>
          )}

          {/*-------------------------------- Moodle link ------------------------------------------------ */}
          {(auth.user?.idProvider === IdProvider.GAR ||
            (auth.user?.idProvider === IdProvider.CLASSPAD &&
              auth.user.metadata?.role !== FrenchInstructorRole.SCHOOL_ADMIN &&
              auth.user.metadata?.role !==
                FrenchInstructorRole.SCHOOL_ASSOCIATION &&
              auth.user.metadata?.role !== FrenchInstructorRole.OTHER &&
              auth.user?.country === Country.FR)) && (
            <a
              href={mesExcercisesLyceeLaunchUrl}
              target="_blank"
              rel="noreferrer"
            >
              <SidebarTab icon={<Book />} title="Mes Exercices Lycée" />
            </a>
          )}

          {/*-------------------------------- Moodle link ------------------------------------------------ */}

          {/* Classpad Learning */}
          <a
            href={classpadLearningUrl}
            target="_blank"
            rel="noreferrer"
            onClick={async () => {
              /** Capture license usage here */
              await captureLicenseUsage(auth.user?.id, {
                userId: auth.user?.id,
                externalId: auth.user?.externalUserId,
                product: Product.CLASSPAD_LEARNING,
                accessDate: new Date(),
              });
            }}
          >
            <SidebarTab
              icon={<BookOpen />}
              title={"ClassPad Learning"}
              badgeLabel={
                learnerTestPeriodActive
                  ? i18n._({
                      id: "sidebar.kikora.test.days",
                      values: { days: remainingLearnerTestDays },
                    })
                  : undefined
              }
            />
          </a>

          {auth.user?.type === Role.INSTRUCTOR &&
            auth.user?.idProvider === IdProvider.CLASSPAD && (
              <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#">
                  <SidebarTab
                    icon={<List />}
                    title={i18n._({ id: "sidebar.links.management" })}
                  />
                </a>
                <Link to="/contexts">
                  <SidebarTab
                    icon={<Folder />}
                    title={i18n._({ id: "sidebar.links.contexts" })}
                    active={CustomUseMatchHook("/contexts") ? true : false}
                    badgeIcon={
                      auth.userInfo?.externalGroups &&
                      auth.userInfo?.externalGroups.length > 0 && <LinkIcon />
                    }
                    indented={checkToggledSidebar ? 35 : undefined}
                  />
                </Link>
                <Link to="/student-list">
                  <SidebarTab
                    icon={<User />}
                    title={i18n._({ id: "sidebar.links.student_list" })}
                    active={CustomUseMatchHook("/student-list") ? true : false}
                    indented={checkToggledSidebar ? 35 : undefined}
                  />
                </Link>
              </>
            )}
          {(auth.user?.type === Role.LEARNER ||
            auth.user?.idProvider !== IdProvider.CLASSPAD) && (
            <Link to="/contexts">
              <SidebarTab
                icon={<Folder />}
                title={i18n._({ id: "sidebar.links.contexts" })}
                active={CustomUseMatchHook("/contexts") ? true : false}
                badgeIcon={
                  auth.userInfo?.externalGroups &&
                  auth.userInfo?.externalGroups.length > 0 && <LinkIcon />
                }
              />
            </Link>
          )}

          {/* <ConfirmModal
            header={i18n._({
              id: `sidebar.links.redirect.title.classpad_net`,
            })}
            description={i18n._({
              id: "sidebar.links.redirect.description.classpad_net",
            })}
            onDone={redirectToClassPadDotNet}
            onRejection={() => setIsShowConfirmRedirect(false)}
            isShowing={isShowConfirmRedirect}
          ></ConfirmModal> */}
        </div>
      );
    }
  };

  useEffect(() => {
    if (window.innerWidth > 992) {
      setCheckToggleSidebar(true);
    }
  }, []);

  return (
    <SidebarLayout
      variant={useMatch("/mathe-marathon") ? "matheMarathon" : "default"}
      sidebarChildren={renderSidebarTabs(auth.user?.country)}
      handleCheckToggleSidebar={(value) => handleCheckToggleSidebar(value)}
    >
      <Routes>
        <Route
          path="/navigation"
          element={
            <NavigationPage
              remainingLearnerTestDays={remainingLearnerTestDays}
              learnerTestPeriodActive={learnerTestPeriodActive}
            />
          }
        ></Route>
        <Route path="/contexts/*" element={<ContextRouter />}></Route>
        {auth.user?.type === Role.INSTRUCTOR &&
          auth.user?.idProvider === IdProvider.CLASSPAD && (
            <Route
              path="/student-list/*"
              element={<StudentListGeneral />}
            ></Route>
          )}
        <Route
          path="/themenuebersicht/*"
          element={<TopicOverViewRouter />}
        ></Route>
        <Route path="/faq/*" element={<FAQRouter />}></Route>
        {window.featureToggles?.FT_MATHE_MARATHON_VISIBLE &&
          auth.user?.country === Country.DE && (
            <Route
              path="/mathe-marathon"
              element={<MatheMarathonPage />}
            ></Route>
          )}
        <Route path="*" element={<Navigate to="/navigation" />}></Route>
      </Routes>
    </SidebarLayout>
  );
};
