import { Color } from "./Color";
import { IdProvider } from "./IdProvider";
import { User, PlainUser, userFromPlain } from "./User";

export interface PlainContext {
  id: string;
  code: string;
  name: string;
  color: string;
  origin: IdProvider;
  externalId?: string;
  users?: PlainUser[];
}

export type PlainContextWithUsers = PlainContext & { users: PlainUser[] };

export type ExternalContext = {
  id: string;
  code?: string;
  name: string;
  color: string;
  origin: IdProvider;
  externalId: string;
  users?: PlainUser[];
};

export class Context {
  constructor(
    public readonly id: string,
    public readonly code: string,
    public readonly name: string,
    public readonly color: Color,
    public readonly origin: IdProvider,
    public readonly externalId?: string,
    public readonly users?: User[]
  ) {}
}

export function contextFromPlain({
  id,
  code,
  name,
  color,
  origin,
  externalId,
  users,
}: PlainContext): Context {
  return new Context(
    id,
    code,
    name,
    Color[color as keyof typeof Color],
    origin,
    externalId,
    users?.map((u) => userFromPlain(u))
  );
}
