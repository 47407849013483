import { i18n } from "@lingui/core";
import { ReactNode, SetStateAction, useEffect, useState } from "react";
import {
  getClasswizEmulatorLicenseOfUser,
  redeemAdditionalLicense,
} from "../../api/license-codes";
import {
  getLicensesV2,
  getUserAdditionalLicense,
} from "../../api/user-licenses";
import { getPurchases, getPurchasesV2 } from "../../api/user-purchases";
import { getActivatedLicenseCodesByUser } from "../../api/license-codes";
import {
  getSchoolLicenseForUser,
  SchoolLicenseWithAddtitionalLicense,
} from "../../api/school-license";
import { checkUserIsInTrialClass } from "../../api/user";
import { useAuth } from "../../hooks/use-auth";
import { useToasts } from "../../provider/toast-provider";
import { AdditionalLicenseType } from "../../types/AdditionalLicenseType";
import { Language } from "../../types/Country";
import { AdditionalLicense, License, LicenseCode } from "../../types/License";
import { Product } from "../../types/Product";
import { PurchaseListItem } from "../../types/Purchase";
import { Card } from "../Card/Card";
import { Grid, GridItem } from "../Grid/Grid";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { LicenseEntryClasspadLearning } from "../LicenseEntry/LicenseEntryClasspadLearning";
import { LicenseEntryClasspadPlus } from "../LicenseEntry/LicenseEntryClasspadPlus";
import { LicenseRedeemModal } from "../LicenseRedeemModal/LicenseRedeemModal";
import { LicensePurchaseModal } from "../PurchaseLicense/LicensePurchaseModal";
import { PurchasesList } from "../PurchasesList/PurchasesList";
import styles from "./UserProfile.module.scss";
import { Role } from "../../types/Role";
import { formatSchoolLicenses } from "../../utils/utils";
import { isAfter, addDays, differenceInDays } from "date-fns";
import { Spinner } from "../Spinner/Spinner";
import { AdminUserLicenseViewSelectedUserData } from "../UserManagement/LicenseDetail/AdminUserLicenseView/AdminUserLicenseView";

const TEMP_LICENSE_DATA_FOR_TEACHER = [
  {
    product: Product.CLASSPAD_LEARNING,
    validUntil: new Date(),
  },
  {
    product: Product.CLASSPAD_NET_PLUS,
    validUntil: new Date(),
  },
];

export interface UserProfileLicensesProps {
  selectedUserData?: AdminUserLicenseViewSelectedUserData;
}

export const UserProfileLicenses: React.FC<UserProfileLicensesProps> = ({
  selectedUserData,
}) => {
  const auth = useAuth();
  const userId = selectedUserData
    ? selectedUserData.id ?? ""
    : auth.userInfo?.userId ?? "";

  const [licenses, setLicenses] = useState<License[]>([]);
  const [redeems, setRedeems] = useState<LicenseCode[]>([]);
  const [purchases, setPurchases] = useState<PurchaseListItem[]>([]);
  const [schoolLicenses, setSchoolLicenses] = useState<
    SchoolLicenseWithAddtitionalLicense[]
  >([]);
  const [inTrialChecker, setInTrialChecker] = useState<{
    isInTrialClass: boolean;
    cplTestPeriodEndDate: string;
  }>({
    isInTrialClass: false,
    cplTestPeriodEndDate: "",
  });

  const [teacherAdditionalLicense, setTeacherAddtionalLicense] = useState<
    AdditionalLicense[]
  >([]);
  const [purchasesWithStartEndDate, setPurchasesWithStartEndDate] = useState<
    any[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRedeemCPAppPlusFailed, setIsRedeemCPAppPlusFailed] =
    useState<boolean>(false);
  const [isRedeemCPPlusManagerFailed, setIsRedeemCPPlusManagerFailed] =
    useState<boolean>(false);
  const [classwizAdditionalLicense, setClasswizAdditionalLicense] =
    useState<AdditionalLicense>();

  const { addToast } = useToasts();

  const fetchLicensesV2 = async (userId: string, token?: string) => {
    let licenses: License[] = TEMP_LICENSE_DATA_FOR_TEACHER;
    if (auth.user?.type !== Role.INSTRUCTOR) {
      licenses = await getLicensesV2({ userId });
    }
    setLicenses(licenses);
  };

  const fetchPurchases = async (userId: string) => {
    const purchases = await getPurchases(userId);
    setPurchases(purchases);
  };

  const fetchPurchasesV2 = async (userId: string) => {
    const purchasesWithStartEndDate = await getPurchasesV2(userId);
    setPurchasesWithStartEndDate(purchasesWithStartEndDate);
  };

  const fetchLicenseCodes = async () => {
    const licenseCodes = await getActivatedLicenseCodesByUser();
    setRedeems(licenseCodes);
  };

  const fetchSchoolLicense = async (userId: string | undefined) => {
    const schoolLicenses = await getSchoolLicenseForUser(userId);
    const schoolLicensesFormat: SetStateAction<
      SchoolLicenseWithAddtitionalLicense[]
    > = formatSchoolLicenses(schoolLicenses);

    setSchoolLicenses(schoolLicensesFormat);
  };

  const fetchUserIsInTrialClass = async (userId: string) => {
    // check if user is in any class that trial in 30 days
    const inTrialChecker = await checkUserIsInTrialClass(userId);
    setInTrialChecker(inTrialChecker);
  };

  /**
   * This function use to check additional license of INSTRUCTOR role.
   * Because in INSTRUCTOR role can not fetch additional license in get licenses api.
   * */
  const fetchAdditionalLicense = async (userId: string) => {
    const additionalLicense = await getUserAdditionalLicense({ userId });
    setTeacherAddtionalLicense(additionalLicense);
  };

  const allowRequestAdditionalLicense = (): {
    allow: boolean;
    allowType: string;
  } => {
    if (auth.user?.type === Role.INSTRUCTOR) {
      return {
        allow: true,
        allowType: "Teacher",
      };
    }

    const classpadPlusLicense = licenses.filter(
      (license) => license.product === Product.CLASSPAD_NET_PLUS
    );

    if (
      classpadPlusLicense.length > 0 &&
      isAfter(classpadPlusLicense[0]?.validUntil, new Date())
    ) {
      return {
        allow: true,
        allowType: "Individual License",
      };
    }

    if (schoolLicenses.length > 0) {
      // check phải có school license có validUntil > current date
      let isSchoolLicenseValid: boolean = false;

      for (let schoolLicense of schoolLicenses) {
        if (isAfter(new Date(schoolLicense.validUntil), new Date())) {
          isSchoolLicenseValid = true;
        }
      }

      if (isSchoolLicenseValid) {
        return {
          allow: true,
          allowType: "School License",
        };
      }
    }

    return {
      allow: false,
      allowType: "",
    };
  };
  const toastForFailedRedeemAddtionalLicense = (
    type: AdditionalLicenseType
  ) => {
    addToast(
      "Derzeit sind alle Lizenzen ausverkauft. Bitte kontaktiere unser Casio Support Team: classpad_support@casio.de",
      "error"
    );
    switch (type) {
      case AdditionalLicenseType.CLASSPAD_PLUS_APP:
        setIsRedeemCPAppPlusFailed(true);
        break;
      case AdditionalLicenseType.CLASSPAD_PLUS_MANAGER:
        setIsRedeemCPPlusManagerFailed(true);
        break;
    }
  };

  const requestAdditionalLicense = async (type: AdditionalLicenseType) => {
    if (type === AdditionalLicenseType.CLASSPAD_PLUS_MANAGER) {
      try {
        if (
          allowRequestAdditionalLicense().allow &&
          allowRequestAdditionalLicense().allowType === "Teacher"
        ) {
          redeemAdditionalLicense({ type, role: auth.user?.type })
            .then(async () => {
              if (auth.user?.type === Role.INSTRUCTOR) {
                fetchAdditionalLicense(userId);
              }
            })
            .catch((err) => {
              console.log("request.additional.license.error 1", type);
              toastForFailedRedeemAddtionalLicense(type);
            });
          return;
        }

        if (
          allowRequestAdditionalLicense().allow &&
          allowRequestAdditionalLicense().allowType === "Individual License"
        ) {
          redeemAdditionalLicense({ type, role: auth.user?.type })
            .then(async () => {
              await fetchLicensesV2(userId);
              await fetchSchoolLicense(userId);
            })
            .catch((err) => {
              console.log("request.additional.license.error 2", type);
              toastForFailedRedeemAddtionalLicense(type);
            });
          return;
        }

        if (
          allowRequestAdditionalLicense().allow &&
          allowRequestAdditionalLicense().allowType === "School License"
        ) {
          redeemAdditionalLicense({ type, role: auth.user?.type })
            .then(async () => {
              await fetchLicensesV2(userId);
              await fetchSchoolLicense(userId);
            })
            .catch((err) => {
              console.log("request.additional.license.error 3", type);
              toastForFailedRedeemAddtionalLicense(type);
            });
          return;
        }

        throw new Error();
      } catch (e) {
        addToast(
          "Du hast keine aktive ClassPad Plus-Lizenz und kannst daher die Software-Produkte von ClassPad Plus derzeit nicht nutzen. Bitte kaufe eine Lizenz im Webshop, oder wende dich an deine Lehrkraft für weitere Informationen",
          "error"
        );
        setIsRedeemCPPlusManagerFailed(true);
      }
    }

    if (type === AdditionalLicenseType.CLASSPAD_PLUS_APP) {
      if (auth.user?.type === Role.INSTRUCTOR) {
        redeemAdditionalLicense({ type, role: auth.user?.type })
          .then(async () => {
            fetchAdditionalLicense(userId);
          })
          .catch((err) => {
            console.log("request.additional.license.error 4", type);
            toastForFailedRedeemAddtionalLicense(type);
          });
      } else {
        redeemAdditionalLicense({ type, role: auth.user?.type })
          .then(async () => {
            await fetchLicensesV2(userId);
            await fetchSchoolLicense(userId);
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response) {
              if (err.response.status === 403) {
                console.log("request.additional.license.error 5", type);
                addToast(
                  "Du hast keine aktive ClassPad Plus-Lizenz und kannst daher die Software-Produkte von ClassPad Plus derzeit nicht nutzen. Bitte kaufe eine Lizenz im Webshop, oder wende dich an deine Lehrkraft für weitere Informationen",
                  "error"
                );
                setIsRedeemCPAppPlusFailed(true);
              }

              if (err.response.status === 500) {
                console.log("request.additional.license.error 6", type);
                toastForFailedRedeemAddtionalLicense(type);
              }
            } else {
              addToast(
                "Du hast keine aktive ClassPad Plus-Lizenz und kannst daher die Software-Produkte von ClassPad Plus derzeit nicht nutzen. Bitte kaufe eine Lizenz im Webshop, oder wende dich an deine Lehrkraft für weitere Informationen",
                "error"
              );
              console.log("request.additional.license.error 7", type);
              toastForFailedRedeemAddtionalLicense(type);
            }
          });
      }
    }
  };
  const fetchClasswizAdditionalLicense = async (userId: string) => {
    const classwizAdditionalLicense = await getClasswizEmulatorLicenseOfUser(
      userId
    );
    console.log("classwiz license: ", classwizAdditionalLicense);
    setClasswizAdditionalLicense(classwizAdditionalLicense);
  };

  const defaultLocale = detectLocale();
  const isFrench = defaultLocale === Language.fr;

  useEffect(() => {
    try {
      setIsLoading(true);
      fetchClasswizAdditionalLicense(userId);
      if (auth.user?.type === Role.LEARNER) {
        fetchPurchases(userId);
        fetchPurchasesV2(userId);
      }

      if (
        auth.user?.type === Role.INSTRUCTOR ||
        (auth.user?.type === Role.ADMINISTRATOR &&
          selectedUserData?.role === Role.INSTRUCTOR)
      ) {
        fetchAdditionalLicense(userId);
      }
      fetchUserIsInTrialClass(userId);
      fetchLicenseCodes();
      fetchSchoolLicense(userId);
      if (
        auth.user?.type === Role.LEARNER ||
        (auth.user?.type === Role.ADMINISTRATOR &&
          selectedUserData?.role === Role.LEARNER)
      ) {
        fetchLicensesV2(userId);
      }
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const translations = {
    columnTitles: {
      date: i18n._({ id: "license.purchase.list.titles.date" }),
      orderNo: i18n._({ id: "license.purchase.list.titles.order-number" }),
      price: i18n._({ id: "license.purchase.list.titles.price" }),
      status: i18n._({ id: "license.purchase.list.titles.status" }),
      withdrawal: i18n._({ id: "license.purchase.list.titles.withdrawal" }),
      invoice: i18n._({ id: "license.purchase.list.titles.invoice" }),
    },
    expiredText: i18n._({ id: "license.purchase.list.status.expired" }),
    withdrawnText: i18n._({ id: "license.purchase.list.status.withdrawn" }),
    withdrawnInfoText: i18n._({ id: "license.purchase.list.info.withdrawn" }),
    withdrawRequestedText: i18n._({
      id: "license.purchase.list.status.requested",
    }),
    withdrawRequestedInfoText: i18n._({
      id: "license.purchase.list.info.requested",
    }),
  };

  const renderLicenseEntry = () => {
    const licensesData = [Product.CLASSPAD_LEARNING, Product.CLASSPAD_NET_PLUS];

    let individualLicenseData: {
      product: Product;
      validUntil: Date | null;
    };
    let isIndividualTrialExpired: boolean = false;

    const classPadLearningLicense = licenses.filter(
      (license) => license.product === Product.CLASSPAD_LEARNING
    )[0];

    const isClasspadLearningValid =
      new Date().getTime() - classPadLearningLicense?.validUntil.getTime() < 0;

    const classPadLearningSchoolLicense = schoolLicenses.filter(
      (schoolLicense) => schoolLicense.products[0] === Product.CLASSPAD_LEARNING
    )[0];

    const classPadPlusLicense = licenses.filter(
      (license) => license.product === Product.CLASSPAD_NET_PLUS
    )[0];

    const isClasspadPlusValid =
      new Date().getTime() - classPadPlusLicense?.validUntil.getTime() < 0;

    const durationFromNowToCreatedAt =
      auth.user?.createdAt && differenceInDays(new Date(), auth.user.createdAt);

    const isAccountExpired = durationFromNowToCreatedAt
      ? durationFromNowToCreatedAt > 30
      : false;

    // Check user in invidual license period.
    if (
      // This condition check if user has CPLearning license but it expired
      (classPadLearningLicense?.product &&
        !isClasspadLearningValid &&
        !classPadLearningSchoolLicense &&
        !inTrialChecker?.isInTrialClass &&
        auth.user) ||
      // This condition check if user has no CPLearning
      (!classPadLearningLicense?.product &&
        !classPadLearningSchoolLicense &&
        !inTrialChecker?.isInTrialClass &&
        auth.user)
    ) {
      if (isAccountExpired) {
        isIndividualTrialExpired = true;
      }

      let validUntil;

      if (!isIndividualTrialExpired) {
        validUntil = auth.user.createdAt
          ? addDays(auth.user.createdAt, 30)
          : null;
      } else {
        validUntil = null;
      }

      individualLicenseData = {
        product: Product.CLASSPAD_LEARNING,
        validUntil,
      };
    }

    const licenseEntry: ReactNode = licensesData.map((license) => {
      if (license === Product.CLASSPAD_LEARNING) {
        return (
          <LicenseEntryClasspadLearning
            license={classPadLearningLicense}
            schoolLicense={classPadLearningSchoolLicense}
            licenseTrial={{
              product: Product.CLASSPAD_LEARNING,
              validUntil: inTrialChecker?.cplTestPeriodEndDate,
            }}
            authInfo={{
              role: auth.user?.type,
              idProvider: auth.userInfo?.idProvider,
            }}
            redeems={redeems}
            purchases={purchasesWithStartEndDate}
            inTrialChecker={inTrialChecker}
            individualLicense={individualLicenseData}
            isIndividualTrialExpired={isIndividualTrialExpired}
            isClasspadLearningValid={isClasspadLearningValid}
            isAccountExpired={isAccountExpired}
            selectedUserData={selectedUserData}
          />
        );
      } else {
        return (
          <LicenseEntryClasspadPlus
            license={
              licenses.filter(
                (license) => license.product === Product.CLASSPAD_NET_PLUS
              )[0]
            }
            classwizAdditionalLicense={classwizAdditionalLicense}
            schoolLicense={
              schoolLicenses.filter(
                (schoolLicense) =>
                  schoolLicense.products[0] === Product.CLASSPAD_NET_PLUS
              )[0]
            }
            authInfo={{
              role: auth.user?.type,
              idProvider: auth.userInfo?.idProvider,
            }}
            onClick={(type) => requestAdditionalLicense(type)}
            redeems={redeems}
            purchases={purchasesWithStartEndDate}
            teacherAdditionalLicense={teacherAdditionalLicense}
            isClasspadPlusValid={isClasspadPlusValid}
            selectedUserData={selectedUserData}
            isRedeemCPAppPlusFailed={isRedeemCPAppPlusFailed}
            isRedeemCPPlusManagerFailed={isRedeemCPPlusManagerFailed}
          />
        );
      }
    });
    return licenseEntry;
  };
  const isTeacher = selectedUserData
    ? selectedUserData?.role === Role.INSTRUCTOR
    : auth.userInfo?.role === Role.INSTRUCTOR;

  return (
    <>
      <Card title={i18n._({ id: "license.section.active-licenses.title" })}>
        {renderLicenseEntry()}
      </Card>

      {!isTeacher && (
        <>
          {!selectedUserData && (
            <Card title={i18n._({ id: "license.section.redeem.title" })}>
              <Grid>
                <GridItem width="1/2" className={styles.licenseRedeemGridItem}>
                  <LicenseRedeemModal
                    onClose={() => fetchLicensesV2(userId || "")}
                    schoolLicenses={schoolLicenses}
                    fetchLicenseCodes={() => fetchLicenseCodes() || ""}
                  />
                </GridItem>
                <GridItem width="1/2">
                  {window.featureToggles?.FT_LICENSE_SHOP && !isFrench && (
                    <LicensePurchaseModal
                      schoolLicenses={schoolLicenses}
                      fetchPurchases={() => fetchPurchases(userId) || ""}
                    />
                  )}
                </GridItem>
              </Grid>
            </Card>
          )}
        </>
      )}

      {purchases && purchases.length > 0 && (
        <Card title={i18n._({ id: "license.section.purchase-list.title" })}>
          <PurchasesList
            items={purchases}
            translations={translations}
            fetchPurchases={fetchPurchases}
            userId={userId}
            selectedUserData={selectedUserData}
          />
        </Card>
      )}

      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isLoading}
      />
    </>
  );
};
