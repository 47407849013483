import styles from "./ComparisonBox.module.scss";
import { SelectedSchoolStep1 } from "../MergeSchoolsStep1/MergeSchoolsStep1";
import { SelectedSchoolStep2 } from "../MergeSchoolsStep2/MergeSchoolsStep2";
import { MergedSchoolResponse } from "../../../../api/school";

interface ComparisonBoxProps {
  title: string;
  data?: SelectedSchoolStep1 | SelectedSchoolStep2 | MergedSchoolResponse;
  disabled?: boolean;
}

const RowItem = ({ title, value }: { title: string; value?: string }) => {
  return (
    <div className={styles.container__row}>
      <div className={styles.container__row__item}>
        <p className={styles.container__row__item__title}>{title}</p>
        <p
          className={
            value
              ? styles.container__row__item__value
              : styles.container__row__item__valueHidden
          }
        >
          {value ? value : "--"}
        </p>
      </div>
    </div>
  );
};
export const ComparisonBox = ({
  title,
  data,
  disabled,
}: ComparisonBoxProps) => {
  return (
    <div className={disabled ? styles.containerDisable : styles.container}>
      <div className={styles.container__row}>
        <p className={styles.container__row__title}>{title}</p>
        <p className={styles.container__row__titleHidden}>abc</p>
      </div>

      {data &&
        Object.entries(data).map(([key, value]) => {
          let title: string;

          switch (key) {
            case "id": {
              title = "ID";
              break;
            }
            case "name": {
              title = "School Name";
              break;
            }
            case "country": {
              title = "Country";
              break;
            }
            case "status": {
              title = "Status";
              break;
            }
            case "address": {
              title = "Address";
              break;
            }
            case "addressAddition": {
              title = "Address Addition";
              break;
            }
            case "city": {
              title = "City";
              break;
            }
            case "state": {
              title = "State";
              break;
            }
            case "zip": {
              title = "Zip Code";
              break;
            }
            case "schoolId": {
              title = "schoolID (Salesforce School Id Local 1)";
              break;
            }
            case "salesforceId": {
              title = "Salesforce ID (Salesforce School Id Local 2)";
              break;
            }
            case "eduplacesSchoolId": {
              title = "Eduplaces School ID";
              break;
            }
            case "salesforceRecordId": {
              title = "Salesforce Record ID";
              break;
            }
            case "addressCountry": {
              title = "Address Country";
              break;
            }
            case "type": {
              title = "Type";
              break;
            }
            case "lmsInstance": {
              title = "LMS Instance";
              break;
            }
            default:
              return [];
          }

          return <RowItem key={`item-${key}`} title={title} value={value} />;
        })}
    </div>
  );
};
