import { MergedSchoolResponse } from "../../../../api/school";
import { Button } from "../../../Button/Button";
import { StepBody } from "../../../Stepper/Stepper";
import { ComparisonBox } from "../MergeSchoolsStep3/ComparisonBox";

export interface MergeSchoolsStep4Props {
  handleEndProgress?(): void;
  mergedResult?: MergedSchoolResponse;
}

export const MergeSchoolsStep4 = ({
  handleEndProgress,
  mergedResult,
}: MergeSchoolsStep4Props) => {
  return (
    <StepBody
      noBackgroundColor={true}
      nextButton={
        <Button label="done" btnStyle="secondary" onClick={handleEndProgress} />
      }
    >
      <ComparisonBox
        title="Merged School Result"
        data={mergedResult}
        disabled={true}
      />
    </StepBody>
  );
};
